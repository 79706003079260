// Actions
export const FETCH = 'FOREST_ESTIMATES/FETCH_FELLING_ROWS';
export const FETCH_STARTED = 'FOREST_ESTIMATES/FETCH_FELLING_ROWS_STARTED';
export const RECEIVED = 'FOREST_ESTIMATES/RECEIVED_FELLING_ROWS';
export const ERRORED = 'FOREST_ESTIMATES/ERRORED_FELLING_ROWS';

export const DELETE = 'FELLING_ROWS/DELETE';
export const DELETE_STARTED = 'FELLING_ROWS/DELETE_STARTED';
export const DELETE_SUCCESSFUL = 'FELLING_ROWS/DELETE_SUCCESSFUL';
export const DELETE_FAILED = 'FELLING_ROWS/DELETE_FAILED';

// Initial state for reducer
export const initialState = {
    loading: false,
    response: null,
    error: null,
    deleteSuccess: null,
};

// Reducer(s)
export default function fellingRowsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_STARTED:
            return {
                ...state,
                loading: true,
            };
        case RECEIVED:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case ERRORED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case DELETE_STARTED:
            return {
                ...state,
                loading: true,
                deleteSuccess: null,
            };
        case DELETE_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                deleteSuccess: true,
            };
        case DELETE_FAILED:
            return {
                ...state,
                loading: false,
                deleteSuccess: false,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchFellingRows = id => ({
    type: FETCH,
    id,
});

export const deleteFellingRowAction = id => ({
    type: DELETE,
    id,
});
