import PropTypes from 'prop-types';
import React from 'react';

import { WaybillFilterQueryShape } from 'shapes/waybills';
import { gettext } from '../../utils/text';
import { FILTERS } from '../../ducks/filters';
import TableEntityFilter from '../Table/TableEntityFilter';

const WaybillBuyerFilter = ({ buyerRegCode, changeBuyerRegCode, query }) => (
    <TableEntityFilter
        initialValue={buyerRegCode}
        onChange={changeBuyerRegCode}
        itemsToOptions={item => ({
            text: `${item.receiver_name} [${item.receiver_reg_code}]`,
            value: item.receiver_reg_code,
        })}
        name="buyer_filter"
        buttonPlaceholder={gettext('Select buyer')}
        buttonLeftIcon="office"
        filterType={FILTERS.WAYBILL.BUYERS}
        query={query}
    />
);

WaybillBuyerFilter.propTypes = {
    buyerRegCode: PropTypes.string.isRequired,
    changeBuyerRegCode: PropTypes.func.isRequired,
    query: WaybillFilterQueryShape.isRequired,
};

export default WaybillBuyerFilter;
