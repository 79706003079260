import PropTypes from 'prop-types';

const ForestEstimateBaseShape = {
    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.oneOf([undefined]),
    ]),
    notes: PropTypes.string,
    date_of_analysis: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.oneOf([null]),
    ]),
    initial_cost_price: PropTypes.string,
    purchase_date: PropTypes.string,
    purchase_price: PropTypes.string,
    truck_load_size_log: PropTypes.string.isRequired,
    truck_load_size_pulpwood: PropTypes.string.isRequired,
    truck_load_size_firewood: PropTypes.string.isRequired,
    extra_cost: PropTypes.string,
    owner_of_logging_rights: PropTypes.string,
    is_archived: PropTypes.bool.isRequired,
    timber_warehouse: PropTypes.oneOfType([
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            code: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        PropTypes.oneOf([null]),
    ]),
};

export const ForestEstimateListShape = PropTypes.shape({
    ...ForestEstimateBaseShape,
    analyzer: PropTypes.oneOfType([
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
        }),
        PropTypes.oneOf([null]),
    ]),
});

export const ForestEstimateDetailsShape = PropTypes.shape({
    ...ForestEstimateBaseShape,
    lank: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.oneOf([null]),
    ]),
    analyzer: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.oneOf([null]),
    ]),
});

const FellingRowBaseShape = {
    id: PropTypes.number.isRequired,
    quarter_number: PropTypes.string,
    provision_number: PropTypes.string.isRequired,
    felling_type: PropTypes.string.isRequired,
    provision_area: PropTypes.string.isRequired,
    percentage_of_exit: PropTypes.number.isRequired,
    round_material_harvesting_cost: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.oneOf([null]),
    ]),
    round_material_transport_cost: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.oneOf([null]),
    ]),
    energy_bush_harvesting_cost: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.oneOf([null]),
    ]),
    energy_bush_transport_cost: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.oneOf([null]),
    ]),
    stump_harvesting_cost: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.oneOf([null]),
    ]),
    stump_transport_cost: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.oneOf([null]),
    ]),
    transportation_cost: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.oneOf([null]),
    ]),
    forest_estimate: PropTypes.number.isRequired,
};

export const FellingRowDetailsShape = PropTypes.shape({
    ...FellingRowBaseShape,
});
