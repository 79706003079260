import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@blueprintjs/core';

import { gettext, interpolate } from '../../utils/text';

export const TablePagination = ({ page, totalPages, onPageChange }) => {
    const pageText = gettext('Page %(page)s of %(totalPages)s');

    return (
        <ButtonGroup>
            <Button
                icon="chevron-left"
                onClick={() => {
                    onPageChange(page - 1);
                }}
                disabled={page <= 1}
            />
            <Button
                text={interpolate(pageText, { page, totalPages }, true)}
                disabled
                minimal
            />
            <Button
                icon="chevron-right"
                onClick={() => {
                    onPageChange(page + 1);
                }}
                disabled={page >= totalPages}
            />
        </ButtonGroup>
    );
};

TablePagination.propTypes = {
    page: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default TablePagination;
