// Actions
export const FETCH_FELLING_ROW = 'FELLING_ROWS/FETCH_FELLING_ROW';
export const FETCH_FELLING_ROW_STARTED =
    'FELLING_ROWS/FETCH_FELLING_ROW_STARTED';
export const FETCH_FELLING_ROW_SUCCESSFUL =
    'FELLING_ROWS/FETCH_FELLING_ROW_SUCCESSFUL';
export const FETCH_FELLING_ROW_FAILED = 'FELLING_ROWS/FETCH_FELLING_ROW_FAILED';

// Initial state for reducer
const initialState = {
    loading: false,
    response: null,
    error: null,
};

// Reducer(s)
export default function fetchFellingRowReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_FELLING_ROW_STARTED:
            return {
                ...state,
                loading: true,
            };
        case FETCH_FELLING_ROW_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case FETCH_FELLING_ROW_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchFellingRow = fellingRowId => ({
    type: FETCH_FELLING_ROW,
    fellingRowId,
});
