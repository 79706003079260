import PropTypes from 'prop-types';

export const ToastShape = PropTypes.shape({
    className: PropTypes.string,
    icon: PropTypes.string,
    intent: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onDismiss: PropTypes.func,
    timeout: PropTypes.number,
});
