// Actions
export const CREATE_FOREST_ESTIMATE = 'FOREST_ESTIMATES/CREATE_FOREST_ESTIMATE';
export const CREATE_FOREST_ESTIMATE_STARTED =
    'FOREST_ESTIMATES/CREATE_FOREST_ESTIMATE_STARTED';
export const CREATE_FOREST_ESTIMATE_SUCCESSFUL =
    'FOREST_ESTIMATES/CREATE_FOREST_ESTIMATE_SUCCESSFUL';
export const CREATE_FOREST_ESTIMATE_FAILED =
    'FOREST_ESTIMATES/CREATE_FOREST_ESTIMATE_FAILED';

// Action creators
export const createForestEstimate = (payload, callback, errorCallback) => ({
    type: CREATE_FOREST_ESTIMATE,
    payload,
    callback,
    errorCallback,
});
