import { ListValidationError, SingleValidationError } from 'tg-resources';

/*
Reduce nested validation errors.

(Referenced from tg-spa-utils)
*/
export const reduceNestedErrors = error => {
    if (!error || !error.hasError()) {
        return null;
    }

    if (error instanceof SingleValidationError) {
        return error.toString();
    }

    if (error instanceof ListValidationError) {
        return error.errors.map(reduceNestedErrors);
    }

    const errors = Object.values(error.errors);

    return errors
        .map(e => ({
            field: e.fieldName,
            error: e,
        }))
        .reduce((result, current) => {
            // eslint-disable-next-line no-param-reassign
            result[current.field] = reduceNestedErrors(current.error);
            return result;
        }, {});
};

/* Get validation errors per field */
export const getFieldErrors = error => {
    if (!error || !error.isValidationError) {
        return null;
    }

    const errors = {};
    const { nonFieldErrors } = error.errors;

    if (nonFieldErrors) {
        errors.nonFieldErrors = nonFieldErrors.toString();
    }

    return error.errors
        .filter(e => e.fieldName !== 'nonFieldErrors')
        .map(e => ({
            field: e.fieldName,
            error: e,
        }))
        .reduce((result, current) => {
            const currentError = reduceNestedErrors(current.error);

            if (currentError === null) {
                return null;
            }

            // eslint-disable-next-line no-param-reassign
            result[current.field] = currentError;
            return result;
        }, errors);
};
