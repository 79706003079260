import PropTypes from 'prop-types';
import React from 'react';

import { WaybillFilterQueryShape } from 'shapes/waybills';
import { gettext } from '../../utils/text';
import { FILTERS } from '../../ducks/filters';
import TableEntityFilter from '../Table/TableEntityFilter';

const WaybillDestinationFilter = ({
    destinationName,
    changeDestinationName,
    query,
}) => {
    return (
        <TableEntityFilter
            initialValue={destinationName}
            onChange={changeDestinationName}
            itemsToOptions={item => ({
                text: item,
                value: item,
            })}
            name="destination_filter"
            buttonPlaceholder={gettext('Select destination')}
            buttonLeftIcon="map-marker"
            filterType={FILTERS.WAYBILL.DESTINATIONS}
            query={query}
        />
    );
};

WaybillDestinationFilter.propTypes = {
    destinationName: PropTypes.string.isRequired,
    changeDestinationName: PropTypes.func.isRequired,
    query: WaybillFilterQueryShape.isRequired,
};

export default WaybillDestinationFilter;
