import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import { WaybillFilterQueryShape } from 'shapes/waybills';
import CustomSelect from '../CustomSelect';
import { fetchFilterItems, FILTERS } from '../../ducks/filters';

const TableEntityFilter = ({
    loading,
    items,
    initialValue,
    fetchItems,
    fetchItemsAsync,
    onChange,
    itemsToOptions,
    name,
    buttonPlaceholder,
    buttonLeftIcon,
    filterType,
    fill,
    query,
}) => {
    const popoverProps = { fill };

    const [debouncedFetchItems] = useDebouncedCallback(fetchItems, 500);

    useEffect(() => {
        if (filterType.items) {
            return;
        }

        if ((initialValue && fetchItemsAsync) || !fetchItemsAsync) {
            fetchItems(filterType, {
                [filterType.queryParamName]: initialValue,
                ...query,
            });
        }
    }, []);

    useEffect(() => {
        if (filterType.items) {
            return;
        }

        fetchItems(filterType, query);
    }, [query]);

    const options = useMemo(() => {
        return (filterType.items || items).map(itemsToOptions);
    }, [items, debouncedFetchItems]);

    const onClear = useCallback(() => {
        onChange('');
    }, [onChange]);

    return (
        <CustomSelect
            name={name}
            items={options}
            buttonPlaceholder={buttonPlaceholder}
            buttonLeftIcon={buttonLeftIcon}
            isLoading={loading}
            onSelect={onChange}
            onClear={onClear}
            initialValue={initialValue}
            popoverProps={popoverProps}
            fill={fill}
            minimal
        />
    );
};

TableEntityFilter.propTypes = {
    loading: PropTypes.bool.isRequired,
    items: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]),
        ),
        PropTypes.arrayOf(PropTypes.object),
    ]).isRequired,
    initialValue: PropTypes.string.isRequired,
    fetchItems: PropTypes.func.isRequired,
    fetchItemsAsync: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    itemsToOptions: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    buttonPlaceholder: PropTypes.string.isRequired,
    buttonLeftIcon: PropTypes.string.isRequired,
    filterType: PropTypes.oneOf([
        ...Object.values(FILTERS.WAYBILL),
        ...Object.values(FILTERS.ENERGY_WAREHOUSE),
        ...Object.values(FILTERS.TIMBER_WAREHOUSE),
    ]).isRequired,
    fill: PropTypes.bool,
    query: WaybillFilterQueryShape.isRequired,
};

TableEntityFilter.defaultProps = {
    fill: true,
    fetchItemsAsync: true,
};

const mapStateToProps = (state, ownProps) => {
    const { filterType } = ownProps;

    return {
        loading: state[filterType.stateKey].loading,
        items: state[filterType.stateKey].response,
    };
};

const mapDispatchToProps = {
    fetchItems: fetchFilterItems,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TableEntityFilter);
