// TODO: Cover with tests
// Actions
export const DELETE_INVOICE = 'ACCOUNTING/DELETE_INVOICE';
export const DELETE_INVOICE_STARTED = 'ACCOUNTING/DELETE_INVOICE_STARTED';
export const DELETE_INVOICE_SUCCESSFUL = 'ACCOUNTING/DELETE_INVOICE_SUCCESSFUL';
export const DELETE_INVOICE_FAILED = 'ACCOUNTING/DELETE_INVOICE_FAILED';

// Action creators
export const deleteInvoice = (invoiceID, callback) => ({
    type: DELETE_INVOICE,
    invoiceID,
    callback,
});
