// Actions
export const UPDATE_FOREST_ESTIMATE = 'FOREST_ESTIMATES/UPDATE_FOREST_ESTIMATE';
export const UPDATE_FOREST_ESTIMATE_STARTED =
    'FOREST_ESTIMATES/UPDATE_FOREST_ESTIMATE_STARTED';
export const UPDATE_FOREST_ESTIMATE_SUCCESSFUL =
    'FOREST_ESTIMATES/UPDATE_FOREST_ESTIMATE_SUCCESSFUL';
export const UPDATE_FOREST_ESTIMATE_FAILED =
    'FOREST_ESTIMATES/UPDATE_FOREST_ESTIMATE_FAILED';

// Action creators
export const updateForestEstimate = (payload, callback, errorCallback) => ({
    type: UPDATE_FOREST_ESTIMATE,
    payload,
    callback,
    errorCallback,
});
