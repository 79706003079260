import PropTypes from 'prop-types';

export const ForestMasterShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    person_code: PropTypes.string.isRequired,
});

const CommonWarehouseListShape = PropTypes.shape({
    // General fields
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    status_display: PropTypes.string.isRequired,
    company_name: PropTypes.string.isRequired,
    company_reg_code: PropTypes.string.isRequired,
    forest_master: ForestMasterShape,
    // Previous owner
    previous_owner_reg_code: PropTypes.string.isRequired,
    previous_owner_name: PropTypes.string.isRequired,
    // Location and address
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    county: PropTypes.string.isRequired,
    parish: PropTypes.string.isRequired,
    village: PropTypes.string.isRequired,
    cadastral_number: PropTypes.string.isRequired,
    // Notice
    notice_number: PropTypes.string.isRequired,
    notice_date: PropTypes.string,
});

export const AverageUnitCostShape = PropTypes.shape({
    average_cost: PropTypes.string,
    latest_average_unit_cost_entry: PropTypes.string,
    total_waybill_amounts: PropTypes.string.isRequired,
    total_deduction_amount: PropTypes.string.isRequired,
    total_waybill_costs: PropTypes.string.isRequired,
    total_deduction_cost: PropTypes.string.isRequired,
    waybill_numbers_included: PropTypes.arrayOf(PropTypes.string).isRequired,
    outgoing_buckets_included: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const EnergyWarehouseListShape = PropTypes.shape({
    ...CommonWarehouseListShape,
    average_unit_cost: AverageUnitCostShape,
    // Assortments
    assortments: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            unit: PropTypes.string.isRequired,
            stock: PropTypes.number.isRequired,
        }),
    ),
});

export const TimberWarehouseListShape = PropTypes.shape({
    ...CommonWarehouseListShape,
    // Stock
    planned_amount: PropTypes.string.isRequired,
    harvester_amount: PropTypes.string.isRequired,
    forwarder_amount: PropTypes.string.isRequired,
    truck_amount: PropTypes.string.isRequired,
    warehouse_amount: PropTypes.string.isRequired,
    correction_amount: PropTypes.string.isRequired,
});
