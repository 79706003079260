import { call, put, spawn, takeLatest } from 'redux-saga/effects';
import api from '../api';
import {
    ERRORED,
    FETCH,
    FETCH_STARTED,
    RECEIVED,
} from '../ducks/waybills/waybills';
import {
    NON_SELECTABLES_ERRORED,
    NON_SELECTABLES_FETCH,
    NON_SELECTABLES_FETCH_STARTED,
    NON_SELECTABLES_RECEIVED,
} from '../ducks/waybills/nonSelectableWaybills';
import { formatError } from '../utils/errors';
import {
    FETCH_WAYBILL_TOTALS,
    FETCH_WAYBILL_TOTALS_FAILED,
    FETCH_WAYBILL_TOTALS_STARTED,
    FETCH_WAYBILL_TOTALS_SUCCESSFUL,
} from '../ducks/waybills/waybillTotals';
import invoiceDeletionSaga from './invoiceDeletion';
import filterItemsSaga from './filters';
import transportInvoiceNumberUpdateSaga from './transportInvoiceNumberUpdate';

export function* fetchWaybills(action) {
    yield put({ type: FETCH_STARTED });
    const { query } = action;
    try {
        const response = yield call(api.waybills.list.fetch, null, query);
        yield put({ type: RECEIVED, response });
    } catch (error) {
        yield put({ type: ERRORED, error: formatError(error) });
    }
}

export function* waybillsSaga() {
    yield takeLatest(FETCH, fetchWaybills);
}

export function* fetchNonSelectableWaybills(action) {
    yield put({ type: NON_SELECTABLES_FETCH_STARTED });

    try {
        const response = yield call(
            api.waybills.getNonSelectables.post,
            null,
            action.payload,
        );
        yield put({ type: NON_SELECTABLES_RECEIVED, response });
    } catch (error) {
        yield put({ type: NON_SELECTABLES_ERRORED, error: formatError(error) });
    }
}

export function* nonSelectableWaybillsSaga() {
    yield takeLatest(NON_SELECTABLES_FETCH, fetchNonSelectableWaybills);
}

export function* fetchWaybillTotals(action) {
    yield put({ type: FETCH_WAYBILL_TOTALS_STARTED });
    const { query } = action;
    try {
        const response = yield call(api.waybills.totals.fetch, null, query);
        yield put({ type: FETCH_WAYBILL_TOTALS_SUCCESSFUL, response });
    } catch (error) {
        yield put({
            type: FETCH_WAYBILL_TOTALS_FAILED,
            error: formatError(error),
        });
    }
}

export function* waybillTotalsSaga() {
    yield takeLatest(FETCH_WAYBILL_TOTALS, fetchWaybillTotals);
}

export default function* waybillsRootSaga() {
    yield spawn(waybillsSaga);
    yield spawn(nonSelectableWaybillsSaga);
    yield spawn(filterItemsSaga);
    yield spawn(waybillTotalsSaga);
    yield spawn(invoiceDeletionSaga);
    yield spawn(transportInvoiceNumberUpdateSaga);
}
