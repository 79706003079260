import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, getIn } from 'formik';
import {
    Classes,
    FormGroup,
    InputGroup,
    Intent,
    RadioGroup,
    Checkbox,
} from '@blueprintjs/core';

import { DateInput } from '@blueprintjs/datetime';
import { FieldProps } from '../../shapes/formik';
import CustomSelect from '../../components/CustomSelect';

const FormField = ({
    formik,
    getNextValue,
    name,
    label,
    helperText,
    defaultValue,
    inline,
    formGroupClassName,
    contentClassName,
    inputComponent,
    ...props
}) => {
    const error = getIn(formik.errors, name, null);
    const value = getIn(formik.values, name, undefined);

    const intent = error ? Intent.DANGER : null;

    const onChange = useMemo(() => {
        if (getNextValue === null) {
            return formik.handleChange;
        } else {
            return (...args) => {
                const newValue = getNextValue(...args);
                formik.setFieldValue(name, newValue);
            };
        }
    }, [getNextValue, name, formik.handleChange, formik.setFieldValue]);

    const inputProps = {
        name,
        intent,
        onBlur: formik.handleBlur,
        ...props,
    };

    if (inputComponent === RadioGroup) {
        inputProps.selectedValue = defaultValue === null ? value : defaultValue;
    } else if (inputComponent === Checkbox) {
        inputProps.checked = defaultValue === null ? value : defaultValue;
    } else {
        inputProps.value = defaultValue === null ? value : defaultValue;
    }

    if (inputComponent === DateInput) {
        inputProps.inputProps = {
            intent,
        };
    }

    if (inputComponent === CustomSelect) {
        inputProps.onSelect = onChange;
    } else {
        inputProps.onChange = onChange;
    }

    const InputComponent = inputComponent;

    return (
        <FormGroup
            label={label}
            inline={inline}
            className={`${formGroupClassName || ''}${
                inline ? ' fixed-label-width' : ''
            }`}
            contentClassName={`${contentClassName || ''}${
                inline ? ' flex-grow-1' : ''
            }`}
            helperText={helperText}
        >
            <InputComponent {...inputProps} />
            {error ? (
                <p className={`${Classes.TEXT_SMALL} text-danger mt-2`}>
                    {error}
                </p>
            ) : null}
        </FormGroup>
    );
};

FormField.propTypes = {
    ...FieldProps.props,

    defaultValue: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    getNextValue: PropTypes.func,
    inline: PropTypes.bool,
    formGroupClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    inputComponent: PropTypes.elementType,
};

FormField.defaultProps = {
    ...FieldProps.defaults,

    defaultValue: null,
    getNextValue: null,
    inline: false,
    formGroupClassName: null,
    contentClassName: null,
    inputComponent: InputGroup,
};

export default connect(FormField);
