// TODO: Cover with tests
import { call, put, takeLeading } from 'redux-saga/effects';

import api from '../api';
import {
    DELETE_ACT,
    DELETE_ACT_STARTED,
    DELETE_ACT_SUCCESSFUL,
    DELETE_ACT_FAILED,
} from '../ducks/acts/actDeletion';
import { toastError } from '../ducks/toasts';
import { formatError } from '../utils/errors';
import { gettext } from '../utils/text';

function* deleteAct(action) {
    yield put({ type: DELETE_ACT_STARTED });
    try {
        const response = yield call(api.acts.delete.del, null, action.payload);
        yield put({ type: DELETE_ACT_SUCCESSFUL, response });
        yield call(action.callback);
    } catch (error) {
        yield put({ type: DELETE_ACT_FAILED, error });
        yield put(
            toastError({
                message: error.responseText
                    ? formatError(error)
                    : gettext('Act(s) deletion failed'),
            }),
        );
        yield call(action.errorCallback);
    }
}

export default function* actDeletionSaga() {
    yield takeLeading(DELETE_ACT, deleteAct);
}
