import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    AnchorButton,
    Button,
    Card,
    Callout,
    HTMLTable,
    Intent,
    NonIdealState,
    Spinner,
} from '@blueprintjs/core';

import { gettext } from '../../utils/text';
import api from '../../api';
import { ContainerShape } from '../../shapes/signing';
import { SIGNATURE_CONTAINER_STATUS } from '../../utils/constants';
import SigningDialog from '../SigningDialog';
import { fetchContainer, signingContainerStart } from '../../ducks/signing';
import { useIsOpenState } from '../../utils/hooks';

/**
 * This view is for the second party who signs acts,
 * they don't need to be authenticated but they have
 * a URL containing a token granting them rights to sign
 * an act.
 */
const SecondPartySignature = ({
    loading,
    error,
    externalID,
    container,
    fetchContainerDetails,
    startSigning,
}) => {
    useEffect(() => {
        fetchContainerDetails(externalID);
    }, [externalID, fetchContainerDetails]);

    const {
        isOpen: isSigningDialogOpen,
        toggleOpen: toggleSigningDialog,
    } = useIsOpenState(false);

    const getContainerFileLink = useCallback(
        fileID => {
            return api.signing.containerFile.renderPath({ externalID, fileID });
        },
        [externalID],
    );

    const BDOCLink = useMemo(() => {
        return api.signing.containerBDOC.renderPath({
            externalID,
        });
    }, [externalID]);

    const signDigitallyButton = useMemo(() => {
        if (container === null) {
            return null;
        }

        return (
            <Button
                icon="annotation"
                onClick={() => {
                    toggleSigningDialog();
                    startSigning(externalID);
                }}
            >
                {gettext('Sign digitally')}
            </Button>
        );
    }, [container, externalID]);

    const signingSection = useMemo(() => {
        if (container === null) {
            return null;
        }

        const isSignedByBothParties =
            container.status === SIGNATURE_CONTAINER_STATUS.signed;
        return (
            <Callout
                className="mb-3"
                intent={isSignedByBothParties ? Intent.SUCCESS : Intent.PRIMARY}
                title={
                    <div className="d-flex justify-content-between">
                        <div>
                            {isSignedByBothParties
                                ? gettext(
                                      'Container has been signed by all parties',
                                  )
                                : gettext('Sign container')}
                        </div>
                        <div>
                            {gettext('Container')}: {container.name}
                        </div>
                    </div>
                }
            >
                {!isSignedByBothParties ? (
                    <div>
                        <p>
                            {gettext(
                                'A container is waiting for your signature.',
                            )}
                        </p>
                        {signDigitallyButton}
                    </div>
                ) : null}
            </Callout>
        );
    }, [container, signDigitallyButton]);

    const documentsSection = useMemo(() => {
        if (container === null) {
            return null;
        }

        return (
            <Card className="h-100">
                <h5 className="bp3-heading">{gettext('Documents')}</h5>
                <AnchorButton
                    intent={Intent.PRIMARY}
                    icon="document"
                    text={gettext('BDOC')}
                    minimal
                    small
                    target="_blank"
                    href={BDOCLink}
                />
                {container.files.map(file => (
                    <div key={file.id}>
                        <AnchorButton
                            intent={Intent.PRIMARY}
                            icon="document"
                            text={file.file_name}
                            minimal
                            small
                            target="_blank"
                            href={getContainerFileLink(file.id)}
                        />
                    </div>
                ))}
            </Card>
        );
    }, [container, BDOCLink, getContainerFileLink]);

    const signaturesSection = useMemo(() => {
        if (container === null) {
            return null;
        }

        return (
            <Card className="h-100">
                <h5 className="bp3-heading">{gettext('Signatures')}</h5>
                <HTMLTable bordered striped className="w-100">
                    <thead>
                        <tr>
                            <th>{gettext('Name')}</th>
                            <th>{gettext('ID Code')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {container.signatures.map(signature => (
                            <tr key={signature.id_code}>
                                <td>{signature.name}</td>
                                <td>{signature.id_code}</td>
                            </tr>
                        ))}
                    </tbody>
                </HTMLTable>
            </Card>
        );
    }, [container]);

    if (!loading && error !== null) {
        return (
            <NonIdealState
                icon="issue"
                title={gettext('Oops! Could not load the container..')}
                description={error}
            />
        );
    }

    if (loading) {
        return <Spinner className="mt-5" />;
    }

    return (
        <>
            <div className="row">
                <div className="col col-md-12">{signingSection}</div>
                <div className="col col-md-6">{documentsSection}</div>
                <div className="col col-md-6">{signaturesSection}</div>
            </div>

            <SigningDialog
                isDialogOpen={isSigningDialogOpen}
                toggleDialog={toggleSigningDialog}
            />
        </>
    );
};

SecondPartySignature.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    externalID: PropTypes.string.isRequired,
    container: ContainerShape,
    fetchContainerDetails: PropTypes.func.isRequired,
    startSigning: PropTypes.func.isRequired,
};

SecondPartySignature.defaultProps = {
    loading: false,
    container: null,
    error: null,
};

const mapStateToProps = state => ({
    loading: state.signing.containerLoading,
    error: state.signing.containerError,
    container: state.signing.container,
});

const mapDispatchToProps = dispatch => ({
    fetchContainerDetails: externalID => dispatch(fetchContainer(externalID)),
    startSigning: () => dispatch(signingContainerStart(null, true)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SecondPartySignature);
