// TODO: Cover with tests
import { call, put, takeLeading } from 'redux-saga/effects';

import api from '../api';
import {
    DELETE_INVOICE,
    DELETE_INVOICE_STARTED,
    DELETE_INVOICE_SUCCESSFUL,
    DELETE_INVOICE_FAILED,
} from '../ducks/accounting/invoiceDeletion';
import { toastError } from '../ducks/toasts';
import { formatError } from '../utils/errors';
import { gettext } from '../utils/text';

function* deleteInvoice(action) {
    yield put({ type: DELETE_INVOICE_STARTED });
    try {
        const response = yield call(api.accounting.invoiceDelete.del, {
            id: action.invoiceID,
        });
        yield put({ type: DELETE_INVOICE_SUCCESSFUL, response });
        yield call(action.callback);
    } catch (error) {
        yield put({ type: DELETE_INVOICE_FAILED, error });
        yield put(
            toastError({
                message: error.responseText
                    ? formatError(error)
                    : gettext('Invoice deletion failed'),
            }),
        );
    }
}

export default function* invoiceDeletionSaga() {
    yield takeLeading(DELETE_INVOICE, deleteInvoice);
}
