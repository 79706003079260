/**
 * Higher-order reducer factory that accepts a given reducer function and reducer name.
 * It provides customized reducers for an action.
 * It only handles actions if reducerName matches with the name (action.name) of dispatched actions.
 * More reference: https://redux.js.org/recipes/structuring-reducers/reusing-reducer-logic
 */
export function createNamedWrapperReducer(reducerFunction, reducerName) {
    return (state, action) => {
        const { name } = action;
        const isInitializationCall = state === undefined;
        if (name !== reducerName && !isInitializationCall) {
            return state;
        }

        return reducerFunction(state, action);
    };
}
