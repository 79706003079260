import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from '@blueprintjs/core';

import { formatError } from 'utils/errors';
import { gettext } from 'utils/text';
import { fetchForestEstimate } from 'ducks/forest_estimates/forestEstimateDetails';
import { updateForestEstimate } from 'ducks/forest_estimates/forestEstimateUpdate';
import { BackToForestEstimatesNonIdealState } from 'components/NonIdealStates';
import ValidationErrorsList from 'components/ValidationErrorsList';
import ForestEstimateForm from 'forms/ForestEstimateForm';

import { fetchForestEstimateAnalyzerChoices } from '../../ducks/forest_estimates/forestEstimateAnalyzerChoices';
import { ForestEstimateDetailsShape } from '../../shapes/forestEstimates';

const ForestEstimateUpdate = props => {
    const {
        forestEstimateError,
        fetchForestEstimateDetails,
        fetchForestEstimateAnalyzers,
        forestEstimateId,
        forestEstimate,
        loading,
        loadingAnalyzerChoices,
        forestEstimateAnalyzerChoices,
        onFormSubmit,
    } = props;

    useEffect(() => {
        fetchForestEstimateDetails(forestEstimateId);
        fetchForestEstimateAnalyzers();
    }, []);

    if (!loading && forestEstimateError !== null) {
        return (
            <BackToForestEstimatesNonIdealState
                title={gettext(
                    "Oops! Can't update the forest estimate due to error(s)..",
                )}
                description={
                    forestEstimateError.isValidationError ? (
                        <ValidationErrorsList
                            validationErrors={forestEstimateError}
                        />
                    ) : (
                        formatError(forestEstimateError)
                    )
                }
            />
        );
    }

    if (loading || forestEstimate === null || loadingAnalyzerChoices) {
        return (
            <>
                <Spinner className="mt-5" />
            </>
        );
    }

    return (
        <ForestEstimateForm
            forestEstimate={forestEstimate}
            onFormSubmit={onFormSubmit}
            isCreationForm={false}
            analyzerChoices={forestEstimateAnalyzerChoices}
        />
    );
};

const mapStateToProps = state => ({
    loading: state.forestEstimateDetails.loading,
    forestEstimateError: state.forestEstimateDetails.error,
    forestEstimate: state.forestEstimateDetails?.response,
    forestEstimateAnalyzerChoices:
        state.forestEstimateAnalyzerChoices?.response,
    loadingAnalyzerChoices: state.forestEstimateAnalyzerChoices?.loading,
});

const mapDispatchToProps = {
    fetchForestEstimateDetails: fetchForestEstimate,
    fetchForestEstimateAnalyzers: fetchForestEstimateAnalyzerChoices,
    onFormSubmit: updateForestEstimate,
};

const ConnectedForestEstimateUpdate = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ForestEstimateUpdate);

ForestEstimateUpdate.propTypes = {
    forestEstimateId: PropTypes.string.isRequired,
    forestEstimateError: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    fetchForestEstimateDetails: PropTypes.func.isRequired,
    forestEstimate: ForestEstimateDetailsShape,
    loading: PropTypes.bool,
    onFormSubmit: PropTypes.func.isRequired,
    fetchForestEstimateAnalyzers: PropTypes.func.isRequired,
    forestEstimateAnalyzerChoices: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            }),
        ).isRequired,
        PropTypes.oneOf([null]),
    ]),
    loadingAnalyzerChoices: PropTypes.bool.isRequired,
};

ForestEstimateUpdate.defaultProps = {
    loading: false,
    forestEstimateError: null,
    forestEstimate: null,
    forestEstimateAnalyzerChoices: null,
};

export default ConnectedForestEstimateUpdate;
