import {
    AnchorButton,
    Button,
    ButtonGroup,
    Checkbox,
    HTMLTable,
    Icon,
    Intent,
    NonIdealState,
    Spinner,
} from '@blueprintjs/core';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import DoubleScrollbar from '@proscom/react-double-scrollbar';

import api from 'api';
import {
    sessionStorageGet,
    sessionStorageSet,
    sessionStorageRemoveItem,
} from '../../utils/session-storage';
import { fetchWaybills } from '../../ducks/waybills/waybills';
import { fetchNonSelectableWaybills } from '../../ducks/waybills/nonSelectableWaybills';
import { fetchWaybillTotals } from '../../ducks/waybills/waybillTotals';
import { deleteInvoice } from '../../ducks/accounting/invoiceDeletion';
import { updateTransportInvoiceNumber } from '../../ducks/waybills/transportInvoiceNumberUpdate';
import { WaybillShape, WaybillTotalsShape } from '../../shapes/waybills';
import { actSignatureStatusIcon } from '../../utils/icons';
import { floatformat } from '../../utils/formatting';
import { gettext } from '../../utils/text';
import { reverseUrl, useQueryString } from '../../utils/urls';
import {
    RANGE_CURRENT_MONTH,
    fromISODateString,
    getDefaultDateRange,
    toLocaleDateString,
} from '../../utils/date';
import {
    ITEM_SELECTION,
    getPageStatus,
    toggleItemSelection,
} from '../../utils/tableSelection';
import { useEffectExceptOnMount, useIsOpenState } from '../../utils/hooks';
import {
    WaybillBuyerFilter,
    WaybillChopperFilter,
    WaybillDestinationFilter,
    WaybillDriverFilter,
    WaybillHasWeightFilter,
    WaybillMaterialFilter,
    WaybillOriginFilter,
    WaybillSellerFilter,
    WaybillTransporterFilter,
    WaybillTruckFilter,
    WaybillUnitFilter,
    WaybillForestMasterFilter,
} from '../WaybillFilters';
import { CustomNonIdealState } from '../NonIdealStates';
import WaybillTotals from '../WaybillTotals';
import WaybillInvoicesPopover from '../WaybillInvoicesPopover';
import InvoiceDeletionDialog from '../InvoiceDeletionDialog';
import {
    TableAction,
    TableColumnSelector,
    TableDateFilter,
    TableFilterGroup,
    TablePagination,
} from '../Table';
import TransportInvoiceNumberDialog from '../TransportInvoiceNumberDialog';
import FileDownloadForm from '../FileDownloadForm';
import { useDJConst } from '../../utils/DJConstContext';

const COLUMN_GROUP = {
    MATERIAL: 'material',
    CHOPPING: 'chopping',
    TRANSPORT: 'transport',
    SALES: 'sales',
    HARVESTING: 'harvesting',
    FORWARDING: 'forwarding',
    TOTALS: 'totals',
};
const COLUMN_GROUPS = [
    { id: COLUMN_GROUP.MATERIAL, title: gettext('Material') },
    { id: COLUMN_GROUP.CHOPPING, title: gettext('Chopping') },
    { id: COLUMN_GROUP.TRANSPORT, title: gettext('Transport') },
    { id: COLUMN_GROUP.SALES, title: gettext('Sales') },
    { id: COLUMN_GROUP.HARVESTING, title: gettext('Harvesting') },
    { id: COLUMN_GROUP.FORWARDING, title: gettext('Forwarding') },
];

const WAYBILL_ACTION_TYPES = {
    CREATE_ACT: 'create_act',
    CREATE_INVOICE: 'create_invoice',
    GENERATE_TRANSPORT_REPORT: 'generate_transport_report',
    GENERATE_WAYBILL_REPORT: 'generate_waybill_report',
    UPDATE_TRANSPORT_INVOICE_NR: 'update_transport_invoice_nr',
};
const WAYBILL_ACTIONS = [
    {
        type: WAYBILL_ACTION_TYPES.CREATE_INVOICE,
        title: gettext('Create invoice'),
        icon: 'clipboard',
    },
    {
        type: WAYBILL_ACTION_TYPES.GENERATE_TRANSPORT_REPORT,
        title: gettext('Generate transport report'),
        icon: 'th',
    },
    {
        type: WAYBILL_ACTION_TYPES.GENERATE_WAYBILL_REPORT,
        title: gettext('Generate waybill report'),
        icon: 'th',
    },
    {
        type: WAYBILL_ACTION_TYPES.UPDATE_TRANSPORT_INVOICE_NR,
        title: gettext('Update transport invoice nr'),
        icon: 'clipboard',
    },
];

// Keep this synced with ForestWorker model forest worker types
const FOREST_WORKER_TYPES = {
    HARVESTER: 0,
    FORWARDER: 1,
};

export const WaybillList = ({
    waybills,
    nonSelectableWaybills,
    loading,
    totalPages,
    error,
    fetchWaybillList,
    fetchNonSelectables,
    waybillTotals,
    waybillTotalsLoading,
    waybillTotalsError,
    fetchTotals,
    handleDeleteInvoice,
    handleUpdateTransportInvoiceNumber,
}) => {
    const { user } = useDJConst();

    if (!user.is_logistics_user) {
        if (
            !COLUMN_GROUPS.some(
                columnGroup => columnGroup.id === COLUMN_GROUP.TOTALS,
            )
        ) {
            COLUMN_GROUPS.push({
                id: COLUMN_GROUP.TOTALS,
                title: gettext('Totals'),
            });
        }

        if (
            !WAYBILL_ACTIONS.some(
                waybillAction =>
                    waybillAction.type === WAYBILL_ACTION_TYPES.CREATE_ACT,
            )
        ) {
            WAYBILL_ACTIONS.unshift({
                type: WAYBILL_ACTION_TYPES.CREATE_ACT,
                title: gettext('Create act'),
                icon: 'document',
            });
        }
    }

    const defaultDateRange = useMemo(() => {
        const selectedDateBeforeFromSessionStorage = sessionStorageGet(
            'date_before',
        );
        const selectedDateAfterFromSessionStorage = sessionStorageGet(
            'date_after',
        );

        if (
            !selectedDateBeforeFromSessionStorage &&
            !selectedDateAfterFromSessionStorage
        ) {
            return getDefaultDateRange(RANGE_CURRENT_MONTH, true);
        }

        return [
            selectedDateAfterFromSessionStorage,
            selectedDateBeforeFromSessionStorage,
        ];
    }, [RANGE_CURRENT_MONTH]);
    const [debouncedFetchWaybillList] = useDebouncedCallback(
        fetchWaybillList,
        500,
    );

    const {
        isOpen: isWaybillTotalsOpen,
        toggleOpen: toggleWaybillTotalsOpen,
    } = useIsOpenState(false);
    const {
        isOpen: isTransportInvoiceNumberDialogOpen,
        handleOpen: openTransportInvoiceNumberDialog,
        handleClose: closeTransportInvoiceNumberDialog,
    } = useIsOpenState(false);

    const [selectedWaybills, setSelectedWaybills] = useState([]);
    const [
        waybillSelectionChangedManually,
        setWaybillSelectionChangedManually,
    ] = useState(false);
    const [page, changePage] = useQueryString('page', 1, {
        parseNumbers: true,
    });
    const [groups, changeGroups] = useQueryString('groups', []);
    const [waybillNumber, changeWaybillNumber] = useQueryString('number', '');
    const [dateBefore, changeDateBefore] = useQueryString(
        'date_before',
        defaultDateRange[1],
        { allowBlank: true },
    );
    const [dateAfter, changeDateAfter] = useQueryString(
        'date_after',
        defaultDateRange[0],
        { allowBlank: true },
    );
    const [buyerRegCode, changeBuyerRegCode] = useQueryString(
        'buyer_reg_code',
        '',
    );
    const [chopperName, changeChopperName] = useQueryString('chopper_name', '');
    const [destinationName, changeDestinationName] = useQueryString(
        'destination_name',
        '',
    );
    const [driverName, changeDriverName] = useQueryString('driver_name', '');
    const [materialName, changeMaterialName] = useQueryString(
        'material_name',
        '',
    );
    const [originName, changeOriginName] = useQueryString('origin_name', '');
    const [sellerRegCode, changeSellerRegCode] = useQueryString(
        'seller_reg_code',
        '',
    );
    const [transporterRegCode, changeTransporterRegCode] = useQueryString(
        'transporter_reg_code',
        '',
    );
    const [truckNumber, changeTruckNumber] = useQueryString('truck_number', '');
    const [unit, changeUnit] = useQueryString('unit', '');
    const [hasWeight, changeHasWeight] = useQueryString('has_any_weight', '');
    const [forestMasterName, changeForestMasterName] = useQueryString(
        'forest_master_name',
        '',
    );

    const query = useMemo(
        () => ({
            number: waybillNumber,
            date_before: dateBefore,
            date_after: dateAfter,
            buyer_reg_code: buyerRegCode,
            chopper_name: chopperName,
            destination_name: destinationName,
            driver_name: driverName,
            material_name: materialName,
            origin_name: originName,
            seller_reg_code: sellerRegCode,
            transporter_reg_code: transporterRegCode,
            truck_number: truckNumber,
            unit,
            has_weight: hasWeight,
            forest_master_name: forestMasterName,
        }),
        [
            waybillNumber,
            dateBefore,
            dateAfter,
            originName,
            buyerRegCode,
            materialName,
            destinationName,
            sellerRegCode,
            chopperName,
            transporterRegCode,
            truckNumber,
            driverName,
            unit,
            hasWeight,
            forestMasterName,
        ],
    );

    const onPageChange = useCallback(
        newPage => {
            changePage(newPage);
            fetchWaybillList({
                ...query,
                page: newPage,
            });
        },
        [query, changePage, fetchWaybillList],
    );

    const [selectedInvoiceID, setSelectedInvoiceID] = useState(null);
    const [downloadWaybillReport, setDownloadWaybillReport] = useState(false);

    // An effect for the first page load
    useEffect(() => {
        fetchWaybillList({
            ...query,
            page,
        });
    }, []);

    // An effect for filters that do not work on the first mount
    useEffectExceptOnMount(() => {
        const newPage = 1;
        changePage(newPage);
        debouncedFetchWaybillList({
            ...query,
            page: newPage,
        });
    }, [changePage, setSelectedWaybills, debouncedFetchWaybillList, query]);

    // An effect to fetch waybill totals
    useEffect(() => {
        if (isWaybillTotalsOpen) {
            fetchTotals({
                ...query,
                ids: selectedWaybills.map(wb => wb.id).join(','),
            });
        }
    }, [query, selectedWaybills, isWaybillTotalsOpen]);

    const customTableFilters = useMemo(() => {
        // eslint-disable-next-line no-unused-vars
        const { number, ...filterQuery } = query;

        return (
            <>
                <WaybillUnitFilter
                    unit={unit}
                    changeUnit={changeUnit}
                    query={filterQuery}
                />
                <WaybillBuyerFilter
                    buyerRegCode={buyerRegCode}
                    changeBuyerRegCode={changeBuyerRegCode}
                    query={filterQuery}
                />
                <WaybillSellerFilter
                    sellerRegCode={sellerRegCode}
                    changeSellerRegCode={changeSellerRegCode}
                    query={filterQuery}
                />
                <WaybillChopperFilter
                    chopperName={chopperName}
                    changeChopperName={changeChopperName}
                    query={filterQuery}
                />
                <WaybillTransporterFilter
                    transporterRegCode={transporterRegCode}
                    changeTransporterRegCode={changeTransporterRegCode}
                    query={filterQuery}
                />
                <WaybillDriverFilter
                    driverName={driverName}
                    changeDriverName={changeDriverName}
                    query={filterQuery}
                />
                <WaybillTruckFilter
                    truckNumber={truckNumber}
                    changeTruckNumber={changeTruckNumber}
                    query={filterQuery}
                />
                <WaybillOriginFilter
                    originName={originName}
                    changeOriginName={changeOriginName}
                    query={filterQuery}
                />
                <WaybillDestinationFilter
                    destinationName={destinationName}
                    changeDestinationName={changeDestinationName}
                    query={filterQuery}
                />
                <WaybillMaterialFilter
                    materialName={materialName}
                    changeMaterialName={changeMaterialName}
                    query={filterQuery}
                />
                <WaybillHasWeightFilter
                    hasWeight={hasWeight}
                    changeHasWeight={changeHasWeight}
                    query={filterQuery}
                />
                {!user.is_forest_master && (
                    <WaybillForestMasterFilter
                        forestMasterName={forestMasterName}
                        changeForestMasterName={changeForestMasterName}
                        query={filterQuery}
                    />
                )}
            </>
        );
    }, [
        buyerRegCode,
        changeBuyerRegCode,
        sellerRegCode,
        changeSellerRegCode,
        chopperName,
        changeChopperName,
        transporterRegCode,
        changeTransporterRegCode,
        driverName,
        changeDriverName,
        truckNumber,
        changeTruckNumber,
        originName,
        changeOriginName,
        destinationName,
        changeDestinationName,
        materialName,
        changeMaterialName,
        unit,
        changeUnit,
        hasWeight,
        changeHasWeight,
        dateBefore,
        dateAfter,
        forestMasterName,
        changeForestMasterName,
    ]);

    const filters = useMemo(
        () => (
            <>
                <TableFilterGroup
                    search={waybillNumber}
                    changeSearch={changeWaybillNumber}
                    searchPlaceholder={gettext('Search waybill number...')}
                    dateFilter={
                        <TableDateFilter
                            dateBefore={dateBefore}
                            changeDateBefore={changeDateBefore}
                            dateAfter={dateAfter}
                            changeDateAfter={changeDateAfter}
                        />
                    }
                    customFilters={customTableFilters}
                />
            </>
        ),
        [
            waybillNumber,
            changeWaybillNumber,
            dateBefore,
            changeDateBefore,
            dateAfter,
            changeDateAfter,
            customTableFilters,
        ],
    );

    // TODO: Error in MH-265 can occur if you use this one. It's better to remove this and use only changeLocationWithStore
    // Change location by preserving existing query params as well as setting selected waybills into the params.
    // Please check MH-269 https://thorgatedigital.atlassian.net/browse/MH-269
    const changeLocationWithWaybillParams = useCallback(
        url => {
            const waybillListFilter = qs.parse(window.location.search);
            window.location = qs.stringifyUrl({
                url,
                query: {
                    ...waybillListFilter,
                    waybills: selectedWaybills.map(wb => wb.id),
                },
            });
        },
        [selectedWaybills],
    );

    // Change location and save waybill ids to session store with specified key
    const changeLocationWithSessionStore = useCallback(
        (url, sessionStorageKey) => {
            sessionStorageSet(
                sessionStorageKey,
                selectedWaybills.map(wb => wb.id),
            );

            window.location = qs.stringifyUrl({
                url,
            });
        },
        [selectedWaybills],
    );

    const getTransportReportLink = useCallback(() => {
        if (!selectedWaybills.length) {
            return null;
        }

        return qs.stringifyUrl({
            url: api.waybills.transportReport.renderPath(),
            query: {
                waybills: selectedWaybills.map(wb => wb.id),
            },
        });
    }, [selectedWaybills]);

    useEffect(() => {
        if (nonSelectableWaybills) {
            return;
        }

        fetchNonSelectables(selectedWaybills.map(waybill => waybill.id));
    }, [selectedWaybills]);

    const [selectedTableAction, setSelectedTableAction] = useState(null);

    // An effect to save selected waybills to session storage when they change.
    useEffect(() => {
        if (!waybillSelectionChangedManually) {
            return;
        }

        sessionStorageSet('selected_waybills', selectedWaybills);
    }, [selectedWaybills]);

    // An effect to save selected table action to session storage when it changes.
    useEffect(() => {
        if (selectedTableAction) {
            sessionStorageSet('selected_table_action', selectedTableAction);
        }
    }, [selectedTableAction]);

    // An effect to save selected dates to session storage when it changes.
    useEffect(() => {
        sessionStorageSet('date_before', dateBefore);
        sessionStorageSet('date_after', dateAfter);
    }, [dateBefore, dateAfter]);

    const changeTableAction = useCallback(action => {
        setSelectedTableAction(action);
        setSelectedWaybills([]);
    }, []);
    const executeTableAction = useCallback(() => {
        if (selectedTableAction.type === WAYBILL_ACTION_TYPES.CREATE_ACT) {
            changeLocationWithWaybillParams(reverseUrl('acts:create'));
        } else if (
            selectedTableAction.type === WAYBILL_ACTION_TYPES.CREATE_INVOICE
        ) {
            changeLocationWithSessionStore(
                reverseUrl('accounting:create-invoice'),
                'waybills_for_invoice_creation',
            );
        } else if (
            selectedTableAction.type ===
            WAYBILL_ACTION_TYPES.GENERATE_TRANSPORT_REPORT
        ) {
            window.location = getTransportReportLink();
        } else if (
            selectedTableAction.type ===
            WAYBILL_ACTION_TYPES.GENERATE_WAYBILL_REPORT
        ) {
            setDownloadWaybillReport(true);
        } else if (
            selectedTableAction.type ===
            WAYBILL_ACTION_TYPES.UPDATE_TRANSPORT_INVOICE_NR
        ) {
            openTransportInvoiceNumberDialog();
        } else {
            throw new Error('Invalid action.');
        }
    }, [selectedTableAction, selectedWaybills]);
    const tableActions = useMemo(
        () => (
            <TableAction
                actions={WAYBILL_ACTIONS}
                selectedAction={selectedTableAction}
                changeAction={changeTableAction}
                executeAction={executeTableAction}
                hasSelectedEntities={selectedWaybills.length > 0}
            />
        ),
        [
            selectedWaybills,
            selectedTableAction,
            executeTableAction,
            changeTableAction,
        ],
    );
    const isWaybillSelectable = useCallback(
        // Acted waybills can not be selected if waybills are selected for acting. It is similar for invoicing.
        wb =>
            !(
                (selectedTableAction?.type ===
                    WAYBILL_ACTION_TYPES.CREATE_ACT &&
                    wb.act) ||
                (selectedTableAction?.type ===
                    WAYBILL_ACTION_TYPES.CREATE_INVOICE &&
                    wb.invoices.length > 0)
            ),
        [selectedTableAction],
    );

    const columnSelector = useMemo(
        () => (
            <TableColumnSelector
                selectedGroups={groups}
                changeGroups={changeGroups}
                groups={COLUMN_GROUPS}
            />
        ),
        [groups],
    );

    const pagination = useMemo(() => {
        if (totalPages === null) {
            return null;
        }

        return (
            <TablePagination
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
            />
        );
    }, [page, totalPages, changePage]);

    const navigation = useMemo(
        () => (
            <div className="mb-4">
                {filters}
                {tableActions}
                <div className="float-right">
                    <ButtonGroup className="pr-3">
                        <Button
                            icon="delete"
                            onClick={() => {
                                setWaybillSelectionChangedManually(true);
                                setSelectedWaybills([]);
                            }}
                        >
                            {gettext('Uncheck all')}
                        </Button>
                    </ButtonGroup>
                    {columnSelector}
                    {pagination}
                </div>
            </div>
        ),
        [columnSelector, tableActions, filters, pagination],
    );

    const waybillTotalsTable = useMemo(() => {
        if (waybills === null || !waybills.length) {
            return null;
        }

        if (!waybillTotalsLoading && waybillTotalsError !== null) {
            return (
                <NonIdealState icon="issue" description={waybillTotalsError} />
            );
        }

        return (
            <WaybillTotals
                loading={waybillTotalsLoading}
                waybillTotals={waybillTotals}
                isOpen={isWaybillTotalsOpen}
                toggleOpen={toggleWaybillTotalsOpen}
            />
        );
    }, [
        waybills,
        waybillTotalsLoading,
        waybillTotalsError,
        waybillTotals,
        isWaybillTotalsOpen,
        toggleWaybillTotalsOpen,
    ]);

    const [
        transportInvoiceNumberUpdateErrors,
        setTransportInvoiceNumberUpdateErrors,
    ] = useState(null);
    const onTransportInvoiceNumberUpdate = useCallback(
        transportInvoiceNumber => {
            handleUpdateTransportInvoiceNumber(
                {
                    waybills: selectedWaybills.map(waybill => waybill.id),
                    transport_invoice_nr: transportInvoiceNumber,
                },
                () => {
                    closeTransportInvoiceNumberDialog();
                    window.location = reverseUrl('waybills:list');
                },
                errors => {
                    setTransportInvoiceNumberUpdateErrors(errors);
                },
            );
        },
        [
            handleUpdateTransportInvoiceNumber,
            selectedWaybills,
            closeTransportInvoiceNumberDialog,
        ],
    );

    const transportInvoiceNumberDialog = useMemo(
        () => (
            <TransportInvoiceNumberDialog
                isOpen={isTransportInvoiceNumberDialogOpen}
                onClose={closeTransportInvoiceNumberDialog}
                selectedWaybills={selectedWaybills}
                onConfirm={onTransportInvoiceNumberUpdate}
                errors={transportInvoiceNumberUpdateErrors}
            />
        ),
        [
            isTransportInvoiceNumberDialogOpen,
            closeTransportInvoiceNumberDialog,
            selectedWaybills,
            onTransportInvoiceNumberUpdate,
            transportInvoiceNumberUpdateErrors,
        ],
    );

    const waybillUpdateURL = useCallback(number => {
        const url = reverseUrl('waybills:update', { waybill_nr: number });
        // Rely on `query-string` package to get rid of question mark in the beginning
        const listFilter = qs.stringify(qs.parse(window.location.search));
        return qs.stringifyUrl({
            url,
            query: { waybill_list_filters: listFilter },
        });
    }, []);

    // An effect to load last selected table action from session storage on first page open
    useEffect(() => {
        const selectedTableActionFromSessionStorage = sessionStorageGet(
            'selected_table_action',
        );

        setSelectedTableAction(selectedTableActionFromSessionStorage);
    }, []);

    const selectableWaybills = useMemo(() => {
        if (waybills) {
            return waybills.filter(isWaybillSelectable);
        }

        return [];
    });

    // An effect to auto unselect waybills if they are not selectable on page load.
    useEffect(() => {
        if (
            !selectedTableAction ||
            !selectedWaybills ||
            !nonSelectableWaybills
        ) {
            return;
        }

        let currentNonSelectableWaybills = [];

        if (selectedTableAction.type === WAYBILL_ACTION_TYPES.CREATE_ACT) {
            currentNonSelectableWaybills = nonSelectableWaybills.filter(
                nonSelectableWaybill => nonSelectableWaybill.act,
            );
        } else if (
            selectedTableAction.type === WAYBILL_ACTION_TYPES.CREATE_INVOICE
        ) {
            currentNonSelectableWaybills = nonSelectableWaybills.filter(
                nonSelectableWaybill => nonSelectableWaybill.invoice,
            );
        }

        setSelectedWaybills(
            selectedWaybills.filter(
                selectedWaybill =>
                    !currentNonSelectableWaybills.some(
                        nonSelectableWaybill =>
                            nonSelectableWaybill.id === selectedWaybill.id,
                    ),
            ),
        );
    }, [nonSelectableWaybills]);

    // An effect to load selected waybills from session storage on first page open
    useEffect(() => {
        const selectedWaybillsFromSessionStorage = sessionStorageGet(
            'selected_waybills',
        );

        if (selectedWaybillsFromSessionStorage) {
            selectedWaybillsFromSessionStorage.forEach(
                selectedWaybillFromSessionStorage => {
                    toggleItemSelection(
                        selectedWaybillFromSessionStorage,
                        selectableWaybills,
                        setSelectedWaybills,
                    );
                },
            );
        }
    }, []);

    const htmlTable = useMemo(() => {
        if (waybills === null) {
            return null;
        }

        if (!waybills.length) {
            return (
                <CustomNonIdealState
                    icon="search"
                    title={gettext('Oops! No waybills found..')}
                    description={
                        <div>
                            <p>
                                {gettext(
                                    'No waybills found matching your filters.',
                                )}
                            </p>
                        </div>
                    }
                    action={
                        <AnchorButton
                            text={gettext('Clear filters')}
                            href={reverseUrl('waybills:list')}
                            onClick={() => {
                                sessionStorageRemoveItem('date_before');
                                sessionStorageRemoveItem('date_after');
                            }}
                        />
                    }
                />
            );
        }

        const pageStatus = getPageStatus(selectedWaybills, selectableWaybills);

        return (
            <div className="table-full-width">
                <DoubleScrollbar>
                    <HTMLTable bordered condensed interactive striped>
                        <thead>
                            <tr>
                                <th colSpan={5}>&nbsp;</th>
                                {groups.indexOf(COLUMN_GROUP.MATERIAL) >= 0 ? (
                                    <th
                                        colSpan={
                                            user.is_logistics_user ? 8 : 12
                                        }
                                    >
                                        {gettext('Material')}
                                    </th>
                                ) : null}
                                {groups.indexOf(COLUMN_GROUP.CHOPPING) >= 0 ? (
                                    <th colSpan={4}>{gettext('Chopping')}</th>
                                ) : null}
                                {groups.indexOf(COLUMN_GROUP.TRANSPORT) >= 0 ? (
                                    <th colSpan={10}>{gettext('Transport')}</th>
                                ) : null}
                                {groups.indexOf(COLUMN_GROUP.SALES) >= 0 ? (
                                    <th
                                        colSpan={user.is_logistics_user ? 2 : 7}
                                    >
                                        {gettext('Sales')}
                                    </th>
                                ) : null}
                                {groups.indexOf(COLUMN_GROUP.HARVESTING) >=
                                0 ? (
                                    <th colSpan={4}>{gettext('Harvesting')}</th>
                                ) : null}
                                {groups.indexOf(COLUMN_GROUP.FORWARDING) >=
                                0 ? (
                                    <th colSpan={4}>{gettext('Forwarding')}</th>
                                ) : null}
                                {groups.indexOf(COLUMN_GROUP.TOTALS) >= 0 &&
                                !user.is_logistics_user ? (
                                    <th colSpan={3}>{gettext('Totals')}</th>
                                ) : null}
                            </tr>
                            <tr>
                                <th className="cell-minimal">
                                    <Checkbox
                                        inline
                                        disabled={selectedTableAction === null}
                                        checked={pageStatus.pageChecked}
                                        indeterminate={
                                            pageStatus.pageIndeterminate
                                        }
                                        onChange={() => {
                                            toggleItemSelection(
                                                pageStatus.pageChecked
                                                    ? ITEM_SELECTION.PAGE_NONE
                                                    : ITEM_SELECTION.PAGE,
                                                selectableWaybills,
                                                setSelectedWaybills,
                                            );
                                        }}
                                    />
                                </th>
                                <th key="waybill-number">
                                    {gettext('Waybill')}
                                </th>
                                <th key="waybill-date">{gettext('Date')}</th>
                                <th key="waybill-status">
                                    {gettext('Status')}
                                </th>
                                <th key="waybill-amount">
                                    {gettext('Amount')}
                                </th>
                                {groups.indexOf(COLUMN_GROUP.MATERIAL) >= 0
                                    ? [
                                          <th key="material-seller">
                                              {gettext('Seller')}
                                          </th>,
                                          <th key="material-cadastre">
                                              {gettext('Cadastral number')}
                                          </th>,
                                          <th key="material-origin">
                                              {gettext('Real estate')}
                                          </th>,
                                          <th key="material-forest-master">
                                              {gettext('Forest master')}
                                          </th>,
                                          <th key="material-name">
                                              {gettext('Material')}
                                          </th>,
                                          <th key="material-dispatched-weight">
                                              {gettext('Dispatched weight')}
                                          </th>,
                                          <th key="material-accepted-weight">
                                              {gettext('Accepted weight')}
                                          </th>,
                                          user.is_logistics_user ? null : (
                                              <th key="material-unit-cost">
                                                  {gettext('Unit price')}
                                              </th>
                                          ),
                                          user.is_logistics_user ? null : (
                                              <th key="material-total-cost">
                                                  {gettext('Total cost')}
                                              </th>
                                          ),
                                          user.is_logistics_user ? null : (
                                              <th key="material-act">
                                                  {gettext('Act number')}
                                              </th>
                                          ),
                                          <th key="material-purchase-invoice">
                                              {gettext('Purchase invoice nr')}
                                          </th>,
                                          user.is_logistics_user ? null : (
                                              <th key="act-assortment-prices">
                                                  {gettext(
                                                      'Act assortment prices',
                                                  )}
                                              </th>
                                          ),
                                      ]
                                    : []}
                                {groups.indexOf(COLUMN_GROUP.CHOPPING) >= 0
                                    ? [
                                          <th key="chopping-chopper-name">
                                              {gettext('Chopper')}
                                          </th>,
                                          <th key="chopping-amount">
                                              {gettext('Amount')}
                                          </th>,
                                          <th key="chopping-unit-cost">
                                              {gettext('Unit price')}
                                          </th>,
                                          <th key="chopping-cost">
                                              {gettext('Total cost')}
                                          </th>,
                                      ]
                                    : []}
                                {groups.indexOf(COLUMN_GROUP.TRANSPORT) >= 0
                                    ? [
                                          <th key="transport-transporter">
                                              {gettext('Transporter')}
                                          </th>,
                                          <th key="transport-driver">
                                              {gettext('Driver name')}
                                          </th>,
                                          <th key="transport-truck">
                                              {gettext('Truck')}
                                          </th>,
                                          <th key="transport-invoice">
                                              {gettext('Purchase invoice')}
                                          </th>,
                                          <th key="transport-destination">
                                              {gettext('Destination')}
                                          </th>,
                                          <th key="transport-mileage">
                                              {gettext('Mileage')}
                                          </th>,
                                          <th key="transport-backloading">
                                              {gettext('Backloading')}
                                          </th>,
                                          <th key="transport-cost">
                                              {gettext('Total cost')}
                                          </th>,
                                          <th key="transport-extra-cost">
                                              {gettext('Extra cost')}
                                          </th>,
                                          <th key="transport-extra-total">
                                              {gettext('Extra cost total')}
                                          </th>,
                                      ]
                                    : []}
                                {groups.indexOf(COLUMN_GROUP.SALES) >= 0
                                    ? [
                                          <th key="sale-buyer">
                                              {gettext('Buyer')}
                                          </th>,
                                          user.is_logistics_user ? null : (
                                              <th key="sale-invoice">
                                                  {gettext('Sales invoice')}
                                              </th>
                                          ),
                                          <th key="sale-transport-price">
                                              {gettext('Transport sale price')}
                                          </th>,
                                          user.is_logistics_user ? null : (
                                              <th key="sale-unit-price">
                                                  {gettext('Unit sale price')}
                                              </th>
                                          ),
                                          user.is_logistics_user ? null : (
                                              <th key="sale-mwh-price">
                                                  {gettext('MWh sale price')}
                                              </th>
                                          ),
                                          user.is_logistics_user ? null : (
                                              <th key="sale-mwh">
                                                  {gettext('MWh')}
                                              </th>
                                          ),
                                          user.is_logistics_user ? null : (
                                              <th key="sale-material-price">
                                                  {gettext(
                                                      'Material sale price',
                                                  )}
                                              </th>
                                          ),
                                      ]
                                    : []}
                                {groups.indexOf(COLUMN_GROUP.HARVESTING) >= 0
                                    ? [
                                          <th key="harvesting-harvester">
                                              {gettext('Harvester')}
                                          </th>,
                                          <th key="harvesting-amount">
                                              {gettext('Amount')}
                                          </th>,
                                          <th key="harvesting-unit-price">
                                              {gettext('Unit price')}
                                          </th>,
                                          <th key="harvesting-total-cost">
                                              {gettext('Total cost')}
                                          </th>,
                                      ]
                                    : []}
                                {groups.indexOf(COLUMN_GROUP.FORWARDING) >= 0
                                    ? [
                                          <th key="forwarding-forwarder">
                                              {gettext('Forwarder')}
                                          </th>,
                                          <th key="forwarding-amount">
                                              {gettext('Amount')}
                                          </th>,
                                          <th key="forwarding-unit-price">
                                              {gettext('Unit price')}
                                          </th>,
                                          <th key="forwarding-total-cost">
                                              {gettext('Total cost')}
                                          </th>,
                                      ]
                                    : []}
                                {groups.indexOf(COLUMN_GROUP.TOTALS) >= 0 &&
                                !user.is_logistics_user
                                    ? [
                                          <th key="totals-expenses">
                                              {gettext('Expenses')}
                                          </th>,
                                          <th key="totals-income">
                                              {gettext('Income')}
                                          </th>,
                                          <th key="totals-profit">
                                              {gettext('Profit')}
                                          </th>,
                                      ]
                                    : []}
                            </tr>
                        </thead>
                        <tbody>
                            {waybills.map(wb => (
                                <tr key={wb.id}>
                                    <th className="cell-minimal">
                                        <Checkbox
                                            inline
                                            disabled={
                                                selectedTableAction === null ||
                                                !isWaybillSelectable(wb)
                                            }
                                            checked={
                                                selectedWaybills.filter(
                                                    w => w.id === wb.id,
                                                ).length > 0
                                            }
                                            onChange={() => {
                                                toggleItemSelection(
                                                    wb,
                                                    selectableWaybills,
                                                    setSelectedWaybills,
                                                );

                                                setWaybillSelectionChangedManually(
                                                    true,
                                                );
                                            }}
                                        />
                                        <a
                                            href={waybillUpdateURL(wb.number)}
                                            className="mr-2"
                                        >
                                            <span className="bp3-icon-standard bp3-icon-edit" />
                                        </a>
                                        {wb.is_actable ? (
                                            <Icon
                                                icon="tick-circle"
                                                intent={Intent.SUCCESS}
                                                className="mr-2"
                                            />
                                        ) : null}
                                        {wb.invoices.length ? (
                                            <WaybillInvoicesPopover
                                                invoices={wb.invoices}
                                                setSelectedInvoiceID={
                                                    setSelectedInvoiceID
                                                }
                                                className="mr-2"
                                            />
                                        ) : null}
                                        {actSignatureStatusIcon(wb.act)}
                                    </th>
                                    <td key="waybill-number">{wb.number}</td>
                                    <td key="waybill-date">
                                        {toLocaleDateString(
                                            fromISODateString(wb.date),
                                            'P p',
                                        )}
                                    </td>
                                    <td key="waybill-status">
                                        {wb.status_display}
                                    </td>
                                    <td
                                        key="waybill-amount"
                                        className="text-right"
                                    >
                                        {floatformat(wb.total_amount, 3)}
                                    </td>
                                    {groups.indexOf(COLUMN_GROUP.MATERIAL) >= 0
                                        ? [
                                              <td key="material-seller">
                                                  {wb.previous_owner_name}
                                              </td>,
                                              <td key="material-cadastre">
                                                  {wb.cadastral_number}
                                              </td>,
                                              <td key="material-origin">
                                                  {wb.origin_name}
                                              </td>,
                                              <td key="material-forest-master">
                                                  {wb.forest_master !== null
                                                      ? `${wb.forest_master.name} (${wb.forest_master.person_code})`
                                                      : ''}
                                              </td>,
                                              <td key="material-name">
                                                  {wb.assortment_names.join(
                                                      ', ',
                                                  )}
                                              </td>,
                                              <td
                                                  key="material-dispatched-weight"
                                                  className="text-right"
                                              >
                                                  {wb.dispatched_weight
                                                      ? floatformat(
                                                            wb.dispatched_weight,
                                                            3,
                                                        )
                                                      : ''}
                                              </td>,
                                              <td
                                                  key="material-accepted-weight"
                                                  className="text-right"
                                              >
                                                  {wb.accepted_weight
                                                      ? floatformat(
                                                            wb.accepted_weight,
                                                            3,
                                                        )
                                                      : ''}
                                              </td>,
                                              user.is_logistics_user ? null : (
                                                  <td
                                                      key="material-unit-cost"
                                                      className="text-right"
                                                  >
                                                      {wb.material_unit_cost}
                                                  </td>
                                              ),
                                              user.is_logistics_user ? null : (
                                                  <td
                                                      key="material-total-cost"
                                                      className="text-right"
                                                  >
                                                      {wb.material_total_cost}
                                                  </td>
                                              ),
                                              user.is_logistics_user ? null : (
                                                  <td key="material-act">
                                                      {wb.act_number}
                                                  </td>
                                              ),
                                              <td key="material-purchase-invoice">
                                                  {wb.act !== null
                                                      ? wb.act
                                                            .purchase_invoice_nr
                                                      : ''}
                                              </td>,
                                              user.is_logistics_user ? null : (
                                                  <td key="act-assortment-prices">
                                                      {wb.act_assortment_prices}
                                                  </td>
                                              ),
                                          ]
                                        : []}
                                    {groups.indexOf(COLUMN_GROUP.CHOPPING) >= 0
                                        ? [
                                              <td key="chopping-chopper-name">
                                                  {wb.chopper !== null
                                                      ? `${wb.chopper.name} (${wb.chopper.person_code})`
                                                      : ''}
                                              </td>,
                                              <td key="chopping-amount">
                                                  {wb.chopping_amount
                                                      ? floatformat(
                                                            wb.chopping_amount,
                                                            3,
                                                        )
                                                      : ''}
                                              </td>,
                                              <td
                                                  key="chopping-unit-cost"
                                                  className="text-right"
                                              >
                                                  {// eslint-disable-next-line camelcase
                                                  wb.chopper?.unit_cost
                                                      ? floatformat(
                                                            wb.chopper
                                                                .unit_cost,
                                                            3,
                                                        )
                                                      : ''}
                                              </td>,
                                              <td
                                                  key="chopping-cost"
                                                  className="text-right"
                                              >
                                                  {floatformat(
                                                      wb.chopping_cost,
                                                      2,
                                                  )}
                                              </td>,
                                          ]
                                        : []}
                                    {groups.indexOf(COLUMN_GROUP.TRANSPORT) >= 0
                                        ? [
                                              <td key="transport-transporter">
                                                  {wb.transporter_name}
                                              </td>,
                                              <td key="transport-driver">
                                                  {wb.driver_name}
                                              </td>,
                                              <td key="transport-truck">
                                                  {wb.truck}
                                              </td>,
                                              <td key="transport-invoice">
                                                  {wb.transport_invoice_nr}
                                              </td>,
                                              <td key="transport-destination">
                                                  {wb.destination_name}
                                              </td>,
                                              <td
                                                  key="transport-mileage"
                                                  className="text-right"
                                              >
                                                  {floatformat(wb.mileage, 1)}
                                              </td>,
                                              <td
                                                  key="transport-backloading"
                                                  className="text-center"
                                              >
                                                  {wb.backloading ? (
                                                      <Icon
                                                          icon="tick-circle"
                                                          intent={
                                                              Intent.SUCCESS
                                                          }
                                                      />
                                                  ) : (
                                                      <Icon
                                                          icon="cross"
                                                          intent={Intent.DANGER}
                                                      />
                                                  )}
                                              </td>,
                                              <td
                                                  key="transport-cost"
                                                  className="text-right"
                                              >
                                                  {wb.transport_cost
                                                      ? floatformat(
                                                            wb.transport_cost,
                                                            2,
                                                        )
                                                      : ''}
                                              </td>,
                                              <td key="transport-extra-cost">
                                                  {wb.extra_cost_title}
                                              </td>,
                                              <td
                                                  key="transport-extra-total"
                                                  className="text-right"
                                              >
                                                  {wb.extra_cost
                                                      ? floatformat(
                                                            wb.extra_cost,
                                                            2,
                                                        )
                                                      : ''}
                                              </td>,
                                          ]
                                        : []}
                                    {groups.indexOf(COLUMN_GROUP.SALES) >= 0
                                        ? [
                                              <td key="sale-buyer">
                                                  {wb.receiver_name}
                                              </td>,
                                              user.is_logistics_user ? null : (
                                                  <td key="sale-invoice">
                                                      {wb.sales_invoice_nr}
                                                  </td>
                                              ),
                                              <td
                                                  key="sale-transport-price"
                                                  className="text-right"
                                              >
                                                  {floatformat(
                                                      wb.transport_sale_price,
                                                      2,
                                                  )}
                                              </td>,
                                              user.is_logistics_user ? null : (
                                                  <td
                                                      key="sale-unit-price"
                                                      className="text-right"
                                                  >
                                                      {wb.unit_sale_price}
                                                  </td>
                                              ),
                                              user.is_logistics_user ? null : (
                                                  <td
                                                      key="sale-mwh-price"
                                                      className="text-right"
                                                  >
                                                      {wb.mwh_sale_price}
                                                  </td>
                                              ),
                                              user.is_logistics_user ? null : (
                                                  <td
                                                      key="sale-mwh"
                                                      className="text-right"
                                                  >
                                                      {wb.mw_hours}
                                                  </td>
                                              ),
                                              user.is_logistics_user ? null : (
                                                  <td
                                                      key="sale-material-price"
                                                      className="text-right"
                                                  >
                                                      {wb.material_sale_price}
                                                  </td>
                                              ),
                                          ]
                                        : []}
                                    {groups.indexOf(COLUMN_GROUP.HARVESTING) >=
                                    0
                                        ? [
                                              <td
                                                  key="harvesting-harvester-info"
                                                  className="text-right"
                                              >
                                                  {wb.forest_workers
                                                      .filter(forestWorker => {
                                                          return (
                                                              forestWorker.worker_type ===
                                                              FOREST_WORKER_TYPES.HARVESTER
                                                          );
                                                      })
                                                      .map(forestWorker => {
                                                          return (
                                                              <div
                                                                  key={`${forestWorker.pk}`}
                                                              >
                                                                  {
                                                                      forestWorker.name
                                                                  }
                                                              </div>
                                                          );
                                                      })}
                                              </td>,
                                              <td
                                                  key="harvesting-harvester-amount"
                                                  className="text-right"
                                              >
                                                  {wb.harvester_amount}
                                              </td>,
                                              <td
                                                  key="harvesting-harvester-unit-price"
                                                  className="text-right"
                                              >
                                                  {wb.harvester_cost}
                                              </td>,
                                              <td
                                                  key="harvesting-harvester-total-price"
                                                  className="text-right"
                                              >
                                                  {wb.total_harvester_cost}
                                              </td>,
                                          ]
                                        : []}
                                    {groups.indexOf(COLUMN_GROUP.FORWARDING) >=
                                    0
                                        ? [
                                              <td
                                                  key="forwarding-forwarder-info"
                                                  className="text-right"
                                              >
                                                  {wb.forest_workers
                                                      .filter(forestWorker => {
                                                          return (
                                                              forestWorker.worker_type ===
                                                              FOREST_WORKER_TYPES.FORWARDER
                                                          );
                                                      })
                                                      .map(forestWorker => {
                                                          return (
                                                              <div
                                                                  key={`${forestWorker.pk}`}
                                                              >
                                                                  {
                                                                      forestWorker.name
                                                                  }
                                                              </div>
                                                          );
                                                      })}
                                              </td>,
                                              <td
                                                  key="forwarding-forwarder-amount"
                                                  className="text-right"
                                              >
                                                  {wb.forwarder_amount}
                                              </td>,
                                              <td
                                                  key="forwarding-forwarder-unit-price"
                                                  className="text-right"
                                              >
                                                  {wb.forwarder_cost}
                                              </td>,
                                              <td
                                                  key="forwarding-forwarder-total-price"
                                                  className="text-right"
                                              >
                                                  {wb.total_forwarder_cost}
                                              </td>,
                                          ]
                                        : []}
                                    {groups.indexOf(COLUMN_GROUP.TOTALS) >= 0 &&
                                    !user.is_logistics_user
                                        ? [
                                              <td
                                                  key="totals-expenses"
                                                  className="text-right"
                                              >
                                                  {wb.total_costs}
                                              </td>,
                                              <td
                                                  key="totals-income"
                                                  className="text-right"
                                              >
                                                  {wb.total_sale_price}
                                              </td>,
                                              <td
                                                  key="totals-profit"
                                                  className="text-right"
                                              >
                                                  {wb.profit}
                                              </td>,
                                          ]
                                        : []}
                                </tr>
                            ))}
                        </tbody>
                    </HTMLTable>
                </DoubleScrollbar>
            </div>
        );
    }, [
        groups,
        loading,
        selectedWaybills,
        isWaybillSelectable,
        waybills,
        selectableWaybills,
    ]);

    if (!loading && error !== null) {
        return (
            <>
                <div className="container-fluid">{navigation}</div>
                <NonIdealState
                    icon="issue"
                    title={gettext('Oops! Could not load waybills..')}
                    description={error}
                />
            </>
        );
    }

    if (loading) {
        return (
            <>
                <div className="container-fluid">{navigation}</div>
                <Spinner className="mt-5" />
            </>
        );
    }

    return (
        <>
            <div className="container-fluid">{navigation}</div>
            <div className="container-fluid">{waybillTotalsTable}</div>
            <div className="container-fluid pb-5">{htmlTable}</div>
            <InvoiceDeletionDialog
                selectedInvoiceID={selectedInvoiceID}
                setSelectedInvoiceID={setSelectedInvoiceID}
                handleDeleteInvoice={handleDeleteInvoice}
            />
            {transportInvoiceNumberDialog}
            {downloadWaybillReport ? (
                <FileDownloadForm
                    formData={{
                        waybills: selectedWaybills.map(waybill => waybill.id),
                    }}
                    method="post"
                    actionPath={api.waybills.waybillReport.renderPath()}
                    onDownloadComplete={() => {
                        setDownloadWaybillReport(!downloadWaybillReport);
                    }}
                />
            ) : null}
        </>
    );
};

WaybillList.propTypes = {
    loading: PropTypes.bool,
    waybills: PropTypes.arrayOf(WaybillShape),
    waybillTotals: WaybillTotalsShape,
    waybillTotalsLoading: PropTypes.bool,
    totalPages: PropTypes.number,
    error: PropTypes.string,
    fetchWaybillList: PropTypes.func.isRequired,
    fetchTotals: PropTypes.func.isRequired,
    handleDeleteInvoice: PropTypes.func.isRequired,
    handleUpdateTransportInvoiceNumber: PropTypes.func.isRequired,
};

WaybillList.defaultProps = {
    loading: false,
    waybills: null,
    waybillTotals: null,
    waybillTotalsLoading: false,
    waybillTotalsError: null,
    totalPages: null,
    error: null,
};

const mapStateToProps = state => ({
    /* eslint-disable camelcase */
    loading: state.waybills.loading,
    waybills: state.waybills?.response?.results,
    nonSelectableWaybills: state.nonSelectableWaybills?.response,
    totalPages: state.waybills?.response?.total_pages,
    error: state.waybills.error,
    /* Waybill totals */
    waybillTotalsLoading: state.waybillTotals.loading,
    waybillTotalsError: state.waybillTotals.error,
    waybillTotals: state.waybillTotals.response,
    /* Waybill totals end */
    /* eslint-enable camelcase */
});

const mapDispatchToProps = {
    fetchWaybillList: fetchWaybills,
    fetchNonSelectables: fetchNonSelectableWaybills,
    fetchTotals: fetchWaybillTotals,
    handleDeleteInvoice: deleteInvoice,
    handleUpdateTransportInvoiceNumber: updateTransportInvoiceNumber,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WaybillList);
