import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from '@blueprintjs/core';

import FellingRowForm from 'forms/FellingRowForm';

import { createFellingRow } from '../../ducks/forest_estimates/fellingRowCreation';
import { fetchFellingRowFellingTypeChoices } from '../../ducks/forest_estimates/fellingRowFellingTypeChoices';
import { fetchFellingRowLocationChoices } from '../../ducks/forest_estimates/fellingRowLocationChoices';
import { fetchFellingRowTreeSpecieNameChoices } from '../../ducks/forest_estimates/fellingRowTreeSpecieNameChoices';

const FellingRowCreation = props => {
    const {
        onFormSubmit,
        fetchFellingRowFellingTypes,
        fetchFellingRowLocations,
        fetchFellingRowTreeSpecieNames,
        fellingRowFellingTypeChoices,
        fellingRowLocationChoices,
        fellingRowTreeSpecieNameChoices,
        loadingFellingTypeChoices,
        loadingLocationChoices,
        loadingFellingRowTreeSpecieNameChoices,
    } = props;

    useEffect(() => {
        fetchFellingRowFellingTypes();
        fetchFellingRowLocations();
        fetchFellingRowTreeSpecieNames();
    }, []);

    if (
        loadingFellingTypeChoices ||
        loadingLocationChoices ||
        loadingFellingRowTreeSpecieNameChoices ||
        fellingRowFellingTypeChoices === null ||
        fellingRowLocationChoices === null ||
        fellingRowTreeSpecieNameChoices === null
    ) {
        return (
            <>
                <Spinner className="mt-5" />
            </>
        );
    }

    return (
        <FellingRowForm
            onFormSubmit={onFormSubmit}
            isCreationForm
            fellingTypeChoices={fellingRowFellingTypeChoices}
            locationChoices={fellingRowLocationChoices}
            treeSpecieNameChoices={fellingRowTreeSpecieNameChoices}
        />
    );
};

const mapStateToProps = state => ({
    fellingRowFellingTypeChoices: state.fellingRowFellingTypeChoices?.response,
    loadingFellingTypeChoices: state.fellingRowFellingTypeChoices?.loading,
    fellingRowLocationChoices: state.fellingRowLocationChoices?.response,
    loadingLocationChoices: state.fellingRowLocationChoices?.loading,
    fellingRowTreeSpecieNameChoices:
        state.fellingRowTreeSpecieNameChoices?.response,
    loadingFellingRowTreeSpecieNameChoices:
        state.fellingRowTreeSpecieNameChoices?.loading,
});

const mapDispatchToProps = {
    fetchFellingRowFellingTypes: fetchFellingRowFellingTypeChoices,
    fetchFellingRowLocations: fetchFellingRowLocationChoices,
    fetchFellingRowTreeSpecieNames: fetchFellingRowTreeSpecieNameChoices,
    onFormSubmit: createFellingRow,
};

const ConnectedFellingRowCreation = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FellingRowCreation);

FellingRowCreation.propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
    fetchFellingRowFellingTypes: PropTypes.func.isRequired,
    fetchFellingRowLocations: PropTypes.func.isRequired,
    fetchFellingRowTreeSpecieNames: PropTypes.func.isRequired,
    fellingRowFellingTypeChoices: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
        ).isRequired,
        PropTypes.oneOf([null]),
    ]),
    fellingRowLocationChoices: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            }),
        ).isRequired,
        PropTypes.oneOf([null]),
    ]),
    fellingRowTreeSpecieNameChoices: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
        ).isRequired,
        PropTypes.oneOf([null]),
    ]),
    loadingFellingTypeChoices: PropTypes.bool.isRequired,
    loadingLocationChoices: PropTypes.bool.isRequired,
    loadingFellingRowTreeSpecieNameChoices: PropTypes.bool.isRequired,
};

FellingRowCreation.defaultProps = {
    fellingRowFellingTypeChoices: null,
    fellingRowLocationChoices: null,
    fellingRowTreeSpecieNameChoices: null,
};

export default ConnectedFellingRowCreation;
