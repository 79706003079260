import React from 'react';
import { HTMLTable } from '@blueprintjs/core';

import { ForestEstimateDetailsShape } from '../../shapes/forestEstimates';
import { pgettext } from '../../utils/text';
import EditableCell from '../ForestEstimateAssessment/EditableCell';

const ForestEstimateAnalysisHeader = ({ forestEstimate }) => (
    <>
        <HTMLTable striped className="pl-3 float-right w-50">
            <tbody>
                <tr>
                    <th className="text-right">
                        {pgettext(
                            'Forest estimate analysis table header',
                            'Cadaster number',
                        )}
                    </th>
                    <td>{forestEstimate.cadaster_number || '-'}</td>
                </tr>
                <tr>
                    <th className="text-right">
                        {pgettext(
                            'Forest estimate analysis table header',
                            'Analyzer name',
                        )}
                    </th>
                    <td>{forestEstimate.analyzer_name || '-'}</td>
                </tr>
                <tr>
                    <th className="text-right">
                        {pgettext(
                            'Forest estimate analysis table header',
                            'Analyzer email',
                        )}
                    </th>
                    <td>
                        {(
                            <a href={`mailto:${forestEstimate.analyzer_email}`}>
                                {forestEstimate.analyzer_email}
                            </a>
                        ) || '-'}
                    </td>
                </tr>
                <tr>
                    <th className="text-right">
                        {pgettext(
                            'Forest estimate analysis table header',
                            'Harvest time - start',
                        )}
                    </th>
                    <EditableCell
                        forestEstimateId={forestEstimate.id}
                        valueKey="harvest_time_start"
                        isDateField
                        isEstimateField
                        nullable
                    />
                </tr>
                <tr>
                    <th className="text-right">
                        {pgettext(
                            'Forest estimate analysis table header',
                            'Harvest time - end',
                        )}
                    </th>
                    <EditableCell
                        forestEstimateId={forestEstimate.id}
                        valueKey="harvest_time_end"
                        isDateField
                        isEstimateField
                        nullable
                    />
                </tr>
            </tbody>
        </HTMLTable>
        <HTMLTable striped className="w-50">
            <tbody>
                <tr>
                    <th className="text-right">
                        {pgettext(
                            'Forest estimate analysis table header',
                            'Property owner',
                        )}
                    </th>
                    <td>{forestEstimate.owner_name || '-'}</td>
                </tr>
                <tr>
                    <th className="text-right">
                        {pgettext(
                            'Forest estimate analysis table header',
                            'Village / Parish / County',
                        )}
                    </th>
                    <td>
                        {forestEstimate.village || '-'} /{' '}
                        {forestEstimate.municipality || '-'} /{' '}
                        {forestEstimate.county || '-'}
                    </td>
                </tr>
                <tr>
                    <th className="text-right">
                        {pgettext(
                            'Forest estimate analysis table header',
                            'Property name',
                        )}
                    </th>
                    <td>{forestEstimate.property_name || '-'}</td>
                </tr>
                <tr>
                    <th className="text-right">
                        {pgettext(
                            'Forest estimate analysis table header',
                            'Property code',
                        )}
                    </th>
                    <td>{forestEstimate.timber_warehouse?.code || '-'}</td>
                </tr>
            </tbody>
        </HTMLTable>
    </>
);

ForestEstimateAnalysisHeader.propTypes = {
    forestEstimate: ForestEstimateDetailsShape.isRequired,
};

export default ForestEstimateAnalysisHeader;
