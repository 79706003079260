import { call, put, spawn, takeLatest, takeLeading } from 'redux-saga/effects';
import api from '../api';
import {
    ERRORED,
    FETCH,
    FETCH_STARTED,
    RECEIVED,
    DELETE,
    DELETE_STARTED,
    DELETE_SUCCESSFUL,
    DELETE_FAILED,
} from '../ducks/forest_estimates/fellingRows';
import { formatError } from '../utils/errors';

export function* fetchFellingRows(action) {
    yield put({ type: FETCH_STARTED });

    const { id } = action;

    try {
        const response = yield call(api.forestEstimates.getFellingRows.fetch, {
            id,
        });
        yield put({ type: RECEIVED, response });
    } catch (error) {
        yield put({ type: ERRORED, error: formatError(error) });
    }
}

export function* fellingRowsSaga() {
    yield takeLatest(FETCH, fetchFellingRows);
}

function* deleteFellingRow(action) {
    yield put({ type: DELETE_STARTED });

    try {
        const response = yield call(api.forestEstimates.deleteFellingRow.del, {
            id: action.id,
        });

        yield put({ type: DELETE_SUCCESSFUL, response });
    } catch (error) {
        yield put({ type: DELETE_FAILED, error });
    }
}

export function* fellingRowDeletionSaga() {
    yield takeLeading(DELETE, deleteFellingRow);
}

export default function* fellingRowsRootSaga() {
    yield spawn(fellingRowsSaga);
    yield spawn(fellingRowDeletionSaga);
}
