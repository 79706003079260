import { ControlGroup, InputGroup } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { gettext } from '../../utils/text';

const TableFilterGroup = ({
    search,
    changeSearch,
    searchPlaceholder,
    dateFilter,
    customFilters,
}) => {
    const handleSearchChange = useCallback(
        event => {
            changeSearch(event.target.value);
        },
        [changeSearch],
    );
    return (
        <>
            <ControlGroup className="mb-3">
                <InputGroup
                    id="search"
                    placeholder={searchPlaceholder}
                    leftIcon="search"
                    fill
                    value={search}
                    onChange={handleSearchChange}
                    data-testid="waybill-search"
                />
                {dateFilter}
            </ControlGroup>
            {customFilters && (
                <ControlGroup className="mb-3 custom-table-filters">
                    {customFilters}
                </ControlGroup>
            )}
        </>
    );
};

TableFilterGroup.propTypes = {
    search: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,
    searchPlaceholder: PropTypes.string,
    dateFilter: PropTypes.node,
    customFilters: PropTypes.node,
};

TableFilterGroup.defaultProps = {
    search: '',
    searchPlaceholder: gettext('Search'),
    dateFilter: null,
    customFilters: null,
};

export default TableFilterGroup;
