// TODO: Cover with tests
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from '@blueprintjs/core';

import { formatError } from 'utils/errors';
import { gettext } from 'utils/text';
import { fetchAct } from 'ducks/acts/actDetails';
import { updateAct } from 'ducks/acts/actUpdate';
import { ActDetailsShape } from 'shapes/acts';
import { BackToActsNonIdealState } from 'components/NonIdealStates';
import ValidationErrorsList from 'components/ValidationErrorsList';
import ActForm from 'forms/ActForm';

const ActUpdate = props => {
    const {
        actError,
        fetchActDetails,
        actID,
        act,
        loading,
        onFormSubmit,
    } = props;

    useEffect(() => {
        fetchActDetails(actID);
    }, []);

    if (!loading && actError !== null) {
        return (
            <BackToActsNonIdealState
                title={gettext("Oops! Can't update the act due to error(s)..")}
                description={
                    actError.isValidationError ? (
                        <ValidationErrorsList validationErrors={actError} />
                    ) : (
                        formatError(actError)
                    )
                }
            />
        );
    }

    if (loading || act === null) {
        return (
            <>
                <Spinner className="mt-5" />
            </>
        );
    }

    return (
        <ActForm act={act} onFormSubmit={onFormSubmit} isCreationForm={false} />
    );
};

const mapStateToProps = state => ({
    loading: state.actDetails.loading,
    actError: state.actDetails.error,
    act: state.actDetails?.response,
});

const mapDispatchToProps = {
    fetchActDetails: fetchAct,
    onFormSubmit: updateAct,
};

const ConnectedActUpdate = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ActUpdate);

ActUpdate.propTypes = {
    actID: PropTypes.string.isRequired,
    actError: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    fetchActDetails: PropTypes.func.isRequired,
    act: ActDetailsShape,
    loading: PropTypes.bool,
    onFormSubmit: PropTypes.func.isRequired,
};

ActUpdate.defaultProps = {
    loading: false,
    actError: null,
    act: null,
};

export default ConnectedActUpdate;
