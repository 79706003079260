// Actions
export const START_SIGNING = 'SIGNING/START_SIGNING';
export const CANCEL_SIGNING = 'SIGNING/CANCEL_SIGNING';

export const SIGN_MOBILE_ID = 'SIGNING/SIGN_MOBILE_ID';
export const SIGN_MOBILE_ID_PARAM_CHANGE =
    'SIGNING/SIGN_MOBILE_ID_PARAM_CHANGE';
export const SIGN_MOBILE_ID_START = 'SIGNING/SIGN_MOBILE_ID_START';
export const SIGN_MOBILE_ID_STARTED = 'SIGNING/SIGN_MOBILE_ID_STARTED';
export const SIGN_MOBILE_ID_CHALLENGE = 'SIGNING/SIGN_MOBILE_ID_CHALLENGE';
export const SIGN_MOBILE_ID_SUCCEEDED = 'SIGNING/SIGN_MOBILE_ID_SUCCEEDED';
export const SIGN_MOBILE_ID_FAILED = 'SIGNING/SIGN_MOBILE_ID_FAILED';

export const SIGN_ID_CARD_START = 'SIGNING/SIGN_ID_CARD_START';
export const SIGN_ID_CARD_STARTED = 'SIGNING/SIGN_ID_CARD_STARTED';
export const SIGN_ID_CARD_SUCCEEDED = 'SIGNING/SIGN_ID_CARD_SUCCEEDED';
export const SIGN_ID_CARD_FAILED = 'SIGNING/SIGN_ID_CARD_FAILED';

export const FETCH_CONTAINER = 'SIGNING/FETCH_CONTAINER';
export const FETCH_CONTAINER_STARTED = 'SIGNING/FETCH_CONTAINER_STARTED';
export const FETCH_CONTAINER_SUCCEEDED = 'SIGNING/FETCH_CONTAINER_SUCCEEDED';
export const FETCH_CONTAINER_FAILED = 'SIGNING/FETCH_CONTAINER_FAILED';

export const CREATE_CONTAINER_STARTED = 'SIGNING/CREATE_CONTAINER_STARTED';
export const CREATE_CONTAINER_SUCCEEDED = 'SIGNING/CREATE_CONTAINER_SUCCEEDED';
export const CREATE_CONTAINER_FAILED = 'SIGNING/CREATE_CONTAINER_FAILED';

export const DELETE_CONTAINER_STARTED = 'SIGNING/DELETE_CONTAINER_STARTED';
export const DELETE_CONTAINER_SUCCEEDED = 'SIGNING/DELETE_CONTAINER_SUCCEEDED';
export const DELETE_CONTAINER_FAILED = 'SIGNING/DELETE_CONTAINER_FAILED';

// Initial state for reducer
const initialState = {
    acts: [],
    container: null,
    containerExternalID: null,
    containerLoading: false,
    containerError: null,

    idCardInProgress: false,
    idCardError: null,

    mobileIdPhoneNr: null,
    mobileIdPersonCode: null,
    mobileIdChallenge: null,
    mobileIdError: null,
};

// Reducer(s)
export default function signingReducer(state = initialState, action) {
    switch (action.type) {
        case CANCEL_SIGNING:
            return {
                ...state,
                idCardInProgress: false,
                idCardError: null,
                mobileIdChallenge: null,
                mobileIdPersonCode: null,
                mobileIdPhoneNr: null,
                mobileIdError: null,
            };
        case CREATE_CONTAINER_STARTED:
            return {
                ...state,
                acts: action.acts,
                containerLoading: true,
            };
        case CREATE_CONTAINER_SUCCEEDED:
            return {
                ...state,
                containerExternalID: action.containerExternalID,
                containerLoading: false,
                containerError: null,
            };
        case CREATE_CONTAINER_FAILED:
            return {
                ...state,
                containerExternalID: null,
                containerLoading: false,
                containerError: action.error,
            };
        case DELETE_CONTAINER_STARTED:
            return {
                ...state,
                acts: [],
                containerExternalID: null,
                containerLoading: false,
                containerError: null,
            };
        case FETCH_CONTAINER_STARTED:
            return {
                ...state,
                containerLoading: true,
            };
        case FETCH_CONTAINER_SUCCEEDED:
            return {
                ...state,
                acts: action.acts,
                container: action.container,
                containerExternalID: action.containerExternalID,
                containerLoading: false,
                containerError: null,
            };
        case FETCH_CONTAINER_FAILED:
            return {
                ...state,
                containerExternalID: action.containerExternalID,
                containerLoading: false,
                containerError: action.error,
            };
        case SIGN_ID_CARD_START:
            return {
                ...state,
                idCardInProgress: true,
                mobileIdChallenge: null,
                mobileIdPersonCode: null,
                mobileIdPhoneNr: null,
                mobileIdError: null,
            };
        case SIGN_ID_CARD_SUCCEEDED:
            return {
                ...state,
                acts: [],
                containerExternalID: null,
                containerLoading: false,
                containerError: null,
                idCardInProgress: false,
                idCardError: null,
            };
        case SIGN_ID_CARD_FAILED:
            return {
                ...state,
                idCardInProgress: false,
                idCardError: action.error,
            };
        case SIGN_MOBILE_ID:
            return {
                ...state,
                mobileIdPersonCode: '',
                mobileIdPhoneNr: '',
            };
        case SIGN_MOBILE_ID_PARAM_CHANGE:
            return {
                ...state,
                ...action.params,
            };
        case SIGN_MOBILE_ID_START:
            return {
                ...state,
                idCardInProgress: false,
                idCardError: null,
                mobileIdChallenge: '',
            };
        case SIGN_MOBILE_ID_CHALLENGE:
            return {
                ...state,
                mobileIdChallenge: action.mobileIdChallenge,
            };
        case SIGN_MOBILE_ID_SUCCEEDED:
            return {
                ...state,
                acts: [],
                containerExternalID: null,
                containerLoading: false,
                containerError: null,
                mobileIdChallenge: null,
                mobileIdPersonCode: null,
                mobileIdPhoneNr: null,
                mobileIdError: null,
            };
        case SIGN_MOBILE_ID_FAILED:
            return {
                ...state,
                mobileIdChallenge: null,
                mobileIdError: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchContainer = containerExternalID => ({
    type: FETCH_CONTAINER,
    containerExternalID,
});

export const signingContainerStart = (acts, isSecondParty = false) => ({
    type: START_SIGNING,
    isSecondParty,
    acts,
});

export const signingCancel = containerExternalID => ({
    type: CANCEL_SIGNING,
    containerExternalID,
});

export const signingIDCardStart = containerExternalID => ({
    type: SIGN_ID_CARD_START,
    containerExternalID,
});

export const signingMobileID = () => ({
    type: SIGN_MOBILE_ID,
});

export const signingMobileIDChangeParams = params => ({
    type: SIGN_MOBILE_ID_PARAM_CHANGE,
    params,
});

export const signingMobileIDStart = (
    containerExternalID,
    mobileIdPhoneNr,
    mobileIdPersonCode,
) => ({
    type: SIGN_MOBILE_ID_START,
    containerExternalID,
    mobileIdPhoneNr,
    mobileIdPersonCode,
});
