import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

export const RTKApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/internal/',
        prepareHeaders: headers => {
            headers.set('X-CSRFToken', Cookies.get('csrftoken'));
            return headers;
        },
    }),
    tagTypes: [
        'ForestEstimate',
        'ForestEstimateWithAssessment',
        'ForestEstimateFellingRow',
        'ForestEstimateSpecies',
    ],
    endpoints: build => ({
        getForestEstimateSpecies: build.query({
            query: id => ({ url: `forest-estimates/${id}/species/` }),
            transformResponse: response => response.felling_tree_species,
            providesTags: ['ForestEstimateSpecies'],
        }),
        setForestEstimateSpecies: build.mutation({
            query: ({ id, data }) => ({
                url: `forest-estimates/${id}/species/`,
                method: 'PUT',
                body: { felling_tree_species: data },
            }),
            invalidatesTags: (result, error, { keepCache }) =>
                keepCache
                    ? []
                    : ['ForestEstimateSpecies', 'ForestEstimateWithAssessment'],
        }),
        getForestEstimateFellingRows: build.query({
            query: id => ({ url: `forest-estimates/${id}/get_felling_rows/` }),
            providesTags: ['ForestEstimateFellingRow'],
        }),
        getForestEstimate: build.query({
            query: id => ({ url: `forest-estimates/${id}/` }),
            providesTags: ['ForestEstimate'],
        }),
        getForestEstimateAssessment: build.query({
            query: id => ({ url: `forest-estimates/${id}/assessment/` }),
            providesTags: ['ForestEstimateWithAssessment'],
        }),
        updateForestEstimateAssessment: build.mutation({
            query: ({ id, data }) => ({
                url: `forest-estimates/${id}/assessment/`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['ForestEstimateWithAssessment'],
        }),
        deleteFellingRow: build.mutation({
            query: ({ id }) => ({
                url: `felling-rows/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ForestEstimateFellingRow'],
        }),
        createForestEstimateTimberWarehouse: build.mutation({
            query: ({ id }) => ({
                url: `forest-estimates/${id}/create-timber-warehouse/`,
                method: 'POST',
            }),
            invalidatesTags: (result, error) => {
                console.warn(error);
                return !error
                    ? ['ForestEstimate', 'ForestEstimateWithAssessment']
                    : [];
            },
        }),
    }),
});
