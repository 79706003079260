import React from 'react';
import PropTypes from 'prop-types';
import { Button, Classes, Icon, Intent, Popover } from '@blueprintjs/core';

import { gettext } from '../utils/text';
import { WaybillInvoiceShape } from '../shapes/accounting';

const WaybillInvoicesPopover = ({
    invoices,
    setSelectedInvoiceID,
    className,
}) => {
    return (
        <Popover popoverClassName={Classes.POPOVER_CONTENT_SIZING}>
            <Icon
                icon="clipboard"
                intent={Intent.SUCCESS}
                className={className}
            />

            <div>
                {invoices.map((invoice, index) => (
                    <div
                        key={invoice.invoice_type}
                        className={index !== 0 ? 'border-top pt-2 mt-2' : null}
                    >
                        <h4 className="mt-0 mb-3">
                            {invoice.invoice_type_display}
                        </h4>
                        <div>
                            <span className="mr-1">
                                {gettext('Invoice number')}:
                            </span>
                            <span>{invoice.number}</span>
                        </div>
                        <Button
                            intent={Intent.DANGER}
                            icon="cross"
                            text={gettext('Delete')}
                            className="mt-3"
                            minimal
                            outlined
                            onClick={() => setSelectedInvoiceID(invoice.id)}
                        />
                    </div>
                ))}
            </div>
        </Popover>
    );
};

WaybillInvoicesPopover.defaultProps = {
    className: null,
};

WaybillInvoicesPopover.propTypes = {
    invoices: PropTypes.arrayOf(WaybillInvoiceShape).isRequired,
    setSelectedInvoiceID: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default WaybillInvoicesPopover;
