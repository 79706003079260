import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Intent } from '@blueprintjs/core';

import { gettext } from '../utils/text';

const ConfirmationAlert = ({
    children,
    isOpen,
    onClose,
    onConfirm,
    cancelButtonText,
    confirmButtonText,
    ...props
}) => {
    return (
        <Alert
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={onConfirm}
            cancelButtonText={cancelButtonText || gettext('No')}
            confirmButtonText={confirmButtonText || gettext('Yes')}
            {...props}
        >
            {children}
        </Alert>
    );
};

ConfirmationAlert.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    intent: PropTypes.string,
};

ConfirmationAlert.defaultProps = {
    children: null,
    cancelButtonText: undefined,
    confirmButtonText: undefined,
    intent: Intent.DANGER,
};

export default ConfirmationAlert;
