import {
    Button,
    ButtonGroup,
    HTMLTable,
    NonIdealState,
    Spinner,
} from '@blueprintjs/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import DoubleScrollbar from '@proscom/react-double-scrollbar';

import { fetchForestEstimates } from '../../ducks/forest_estimates/forest_estimates';
import { ForestEstimateListShape } from '../../shapes/forestEstimates';
import { fromISODateString, toLocaleDateString } from '../../utils/date';
import { reverseUrl, useQueryString } from '../../utils/urls';
import { gettext } from '../../utils/text';
import { CustomNonIdealState } from '../NonIdealStates';
import { TablePagination } from '../Table';
import { floatformat } from '../../utils/formatting';

export const ForestEstimateList = ({
    forestEstimates,
    loading,
    error,
    fetchForestEstimateList,
    totalPages,
}) => {
    const [page, changePage] = useQueryString('page', 1, {
        parseNumbers: true,
    });

    // An effect for the first page load
    useEffect(() => {
        fetchForestEstimateList({
            page,
        });
    }, []);

    const onPageChange = useCallback(
        newPage => {
            changePage(newPage);
            fetchForestEstimateList({
                page: newPage,
            });
        },
        [changePage, fetchForestEstimateList],
    );

    const pagination = useMemo(() => {
        if (totalPages === null) {
            return null;
        }

        return (
            <TablePagination
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
            />
        );
    }, [page, totalPages, changePage]);

    const navigation = useMemo(
        () => (
            <div className="mb-4">
                <div className="float-right">
                    <ButtonGroup className="pr-3">
                        <Button
                            icon="add"
                            onClick={() => {
                                window.location = reverseUrl(
                                    'forest_estimates:create',
                                );
                            }}
                        >
                            {gettext('Create')}
                        </Button>
                    </ButtonGroup>
                    {pagination}
                </div>
            </div>
        ),
        [pagination],
    );

    const htmlTable = useMemo(() => {
        if (forestEstimates === null) {
            return null;
        }

        if (!forestEstimates.length) {
            return (
                <CustomNonIdealState
                    icon="search"
                    title={gettext('Oops! No forest estimates found..')}
                    description={
                        <div>
                            <p>{gettext('No forest estimates found.')}</p>
                        </div>
                    }
                />
            );
        }

        return (
            <div className="table-full-width">
                <DoubleScrollbar>
                    <HTMLTable bordered condensed interactive striped>
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th key="forest-estimate-property-name">
                                    {gettext('Property name')}
                                </th>
                                <th key="forest-estimate-created-at">
                                    {gettext('Created at')}
                                </th>
                                <th key="forest-estimate-analyzer">
                                    {gettext('Analyzer')}
                                </th>
                                <th key="forest-estimate-maximum-price">
                                    {gettext('Maximum price (without VAT)')}
                                </th>
                                <th key="forest-estimate-initial-cost-price">
                                    {gettext(
                                        'Initial cost price (without VAT)',
                                    )}
                                </th>
                                <th key="forest-estimate-purchase-price">
                                    {gettext('Purchase price (without VAT)')}
                                </th>
                                <th key="forest-estimate-purchase-date">
                                    {gettext('Purchase date')}
                                </th>
                                <th key="forest-estimate-status">
                                    {gettext('Status')}
                                </th>
                                <th key="forest-estimate-felling-row-count">
                                    {gettext('Number of felling rows')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {forestEstimates.map(forestEstimate => (
                                <tr key={forestEstimate.id}>
                                    <td key="forest-estimate-quick-action-buttons">
                                        <a
                                            href={reverseUrl(
                                                'forest_estimates:update',
                                                { pk: forestEstimate.id },
                                            )}
                                            className="mr-2"
                                        >
                                            <span className="bp3-icon-standard bp3-icon-edit" />
                                        </a>
                                        <a
                                            href={reverseUrl(
                                                'forest_estimates:felling-row-list',
                                                { pk: forestEstimate.id },
                                            )}
                                            title={gettext('View felling rows')}
                                            className="mr-2"
                                        >
                                            <span className="bp3-icon-standard bp3-icon-tree" />
                                        </a>
                                        <a
                                            href={reverseUrl(
                                                'forest_estimates:assessment',
                                                { pk: forestEstimate.id },
                                            )}
                                            title={gettext('Assessment')}
                                        >
                                            <span className="bp3-icon-standard bp3-icon-th" />
                                        </a>
                                    </td>
                                    <td key="forest-estimate-property-name">
                                        {forestEstimate.property_name}
                                    </td>
                                    <td key="forest-estimate-created-at">
                                        {toLocaleDateString(
                                            new Date(forestEstimate.created_at),
                                            'P p',
                                        )}
                                    </td>
                                    <td
                                        key="forest-estimate-analyzer"
                                        title={
                                            forestEstimate.analyzer?.email
                                                ? forestEstimate.analyzer?.email
                                                : undefined
                                        }
                                    >
                                        {forestEstimate.analyzer?.name || ''}
                                    </td>
                                    <td key="forest-estimate-maximum-price">
                                        {floatformat(
                                            forestEstimate.maximum_price_without_tax,
                                            2,
                                        )}
                                    </td>
                                    <td key="forest-estimate-initial-cost-price">
                                        {floatformat(
                                            forestEstimate.initial_cost_price,
                                            2,
                                        )}
                                    </td>
                                    <td key="forest-estimate-purchase-price">
                                        {floatformat(
                                            forestEstimate.purchase_price,
                                            2,
                                        )}
                                    </td>
                                    <td key="forest-estimate-purchase-date">
                                        {toLocaleDateString(
                                            fromISODateString(
                                                forestEstimate.purchase_date,
                                            ),
                                        )}
                                    </td>
                                    <td key="forest-estimate-status">
                                        {forestEstimate.is_archived
                                            ? gettext('Archived')
                                            : gettext('Active')}
                                    </td>
                                    <td key="forest-estimate-felling-row-count">
                                        {forestEstimate.felling_row_count}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </HTMLTable>
                </DoubleScrollbar>
            </div>
        );
    }, [loading, forestEstimates]);

    if (!loading && error !== null) {
        return (
            <>
                <div className="container-fluid">{navigation}</div>
                <NonIdealState
                    icon="issue"
                    title={gettext('Oops! Could not load forest estimates..')}
                    description={error}
                />
            </>
        );
    }

    if (loading) {
        return (
            <>
                <div className="container-fluid">{navigation}</div>
                <Spinner className="mt-5" />
            </>
        );
    }

    return (
        <>
            <div className="container-fluid">{navigation}</div>
            <div className="container-fluid pb-5">{htmlTable}</div>
        </>
    );
};

ForestEstimateList.propTypes = {
    loading: PropTypes.bool,
    forestEstimates: PropTypes.arrayOf(ForestEstimateListShape),
    error: PropTypes.string,
    fetchForestEstimateList: PropTypes.func.isRequired,
    totalPages: PropTypes.number,
};

ForestEstimateList.defaultProps = {
    loading: false,
    forestEstimates: null,
    error: null,
    totalPages: null,
};

const mapStateToProps = state => ({
    /* eslint-disable camelcase */
    loading: state.forestEstimates.loading,
    forestEstimates: state.forestEstimates?.response?.results,
    error: state.forestEstimates.error,
    totalPages: state.forestEstimates?.response?.total_pages,
    /* eslint-enable camelcase */
});

const mapDispatchToProps = {
    fetchForestEstimateList: fetchForestEstimates,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ForestEstimateList);
