import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    AnchorButton,
    Button,
    Menu,
    MenuItem,
    Spinner,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { gettext } from '../utils/text';

/* Reference to:
 * https://github.com/palantir/blueprint/blob/develop/packages/docs-app/src/examples/select-examples/films.tsx
 * for more detail.
 * */
const escapeRegExpChars = text =>
    // eslint-disable-next-line no-useless-escape
    text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');

const highlightText = (text, query) => {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }

    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens = [];

    // eslint-disable-next-line no-constant-condition
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const { length } = match[0];
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        ({ lastIndex } = regexp);
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }

    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }

    return tokens;
};

const itemRenderer = (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }

    return (
        <MenuItem
            active={modifiers.active}
            text={highlightText(item.text, query)}
            // item.text used as key instead of value because seems item.value is not unique.
            key={item.text}
            onClick={handleClick}
        />
    );
};

const itemListRenderer = (
    initialContent,
    { query, filteredItems, renderItem },
) => {
    if (query.length === 0 && initialContent !== undefined) {
        return initialContent;
    }

    const items = filteredItems.map(renderItem).filter(item => item != null);

    if (items.length > 0) return <Menu>{items}</Menu>;

    return query.length > 0 ? (
        <Menu>
            <MenuItem disabled text={gettext('No results.')} />
        </Menu>
    ) : null;
};

const itemListPredicate = (query, items) =>
    items.filter(item => item.text.toLowerCase().includes(query.toLowerCase()));

const itemEqual = (a, b) => {
    if (typeof a === 'object' && typeof b === 'object' && a.key && b.key) {
        return a.key === b.key;
    }
    return a === b;
};

/* Referenced from YMO */
const CustomSelect = ({
    items,
    name,
    initialValue,
    buttonPlaceholder,
    buttonLeftIcon,
    disabled,
    large,
    onSelect,
    onClear,
    isLoading,
    fill,
    ...props
}) => {
    const initialItemSet = useRef(false);
    const [activeItem, setActiveItem] = useState(null);

    const onItemSelect = useCallback(
        item => {
            setActiveItem(item);
            onSelect(item.value);
        },
        [setActiveItem, onSelect],
    );

    const onClearSelection = useCallback(
        e => {
            e.stopPropagation();
            setActiveItem(null);
            onClear();
        },
        [setActiveItem, onClear],
    );

    useEffect(() => {
        // Set the initial item once
        if (!initialItemSet.current && items.length) {
            const selectedItem =
                items.find(item => (item.key || item.value) === initialValue) ||
                null;
            setActiveItem(selectedItem);
            initialItemSet.current = true;
        }
    }, [items, initialValue]);

    const combinedProps = {
        ...props,
    };
    if (isLoading) {
        combinedProps.initialContent = (
            <Spinner size={Spinner.SIZE_SMALL} className="p-5" />
        );
    }

    return (
        <>
            <Select
                items={items}
                itemRenderer={itemRenderer}
                itemListRenderer={listRendererProps =>
                    itemListRenderer(
                        combinedProps.initialContent,
                        listRendererProps,
                    )
                }
                itemListPredicate={itemListPredicate}
                name={name}
                onItemSelect={onItemSelect}
                activeItem={activeItem}
                disabled={disabled}
                itemsEqual={itemEqual}
                {...combinedProps}
            >
                <Button
                    className="bp3-button-truncated"
                    text={activeItem?.text || buttonPlaceholder}
                    rightIcon={
                        activeItem !== null && !disabled ? (
                            <AnchorButton
                                className="bp3-button-clear-selection"
                                icon="cross"
                                minimal
                                onClick={onClearSelection}
                            />
                        ) : (
                            'caret-down'
                        )
                    }
                    icon={buttonLeftIcon}
                    disabled={disabled}
                    large={large}
                    fill={fill}
                />
            </Select>
        </>
    );
};

CustomSelect.propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
                PropTypes.instanceOf(Date),
                PropTypes.array,
            ]),
            text: PropTypes.string.isRequired,
        }),
    ).isRequired,
    buttonPlaceholder: PropTypes.node.isRequired,
    onSelect: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    initialValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.instanceOf(Date),
        PropTypes.array,
    ]),
    large: PropTypes.bool,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    buttonLeftIcon: PropTypes.string,
    fill: PropTypes.bool,
};

CustomSelect.defaultProps = {
    large: false,
    disabled: false,
    initialValue: null,
    onButtonClick: null,
    isLoading: false,
    buttonLeftIcon: null,
    fill: false,
};

export default CustomSelect;
