// TODO: Cover with tests
import { Languages } from 'esteid-helper';
import IdCardManager from 'esteid-helper/IdCardManager';
import {
    call,
    delay,
    put,
    race,
    take,
    takeLatest,
    takeLeading,
} from 'redux-saga/effects';

import api from 'api';
import {
    CANCEL_SIGNING,
    FETCH_CONTAINER,
    FETCH_CONTAINER_FAILED,
    FETCH_CONTAINER_STARTED,
    FETCH_CONTAINER_SUCCEEDED,
    CREATE_CONTAINER_FAILED,
    CREATE_CONTAINER_STARTED,
    CREATE_CONTAINER_SUCCEEDED,
    DELETE_CONTAINER_FAILED,
    DELETE_CONTAINER_STARTED,
    DELETE_CONTAINER_SUCCEEDED,
    SIGN_ID_CARD_START,
    SIGN_ID_CARD_STARTED,
    SIGN_ID_CARD_SUCCEEDED,
    SIGN_ID_CARD_FAILED,
    SIGN_MOBILE_ID_START,
    SIGN_MOBILE_ID_STARTED,
    SIGN_MOBILE_ID_CHALLENGE,
    SIGN_MOBILE_ID_FAILED,
    SIGN_MOBILE_ID_SUCCEEDED,
    START_SIGNING,
} from 'ducks/signing';
import { gettext } from 'utils/text';
import { formatError } from 'utils/errors';
import hwcrypto from 'utils/hwcrypto';

export function* handleContainerFetch({ containerExternalID }) {
    yield put({ type: FETCH_CONTAINER_STARTED });
    try {
        const response = yield call(
            api.signing.containerDetail.fetch,
            { externalID: containerExternalID },
            null,
        );
        yield put({
            type: FETCH_CONTAINER_SUCCEEDED,
            containerExternalID,
            container: response,
            acts: response.acts,
        });
    } catch (error) {
        yield put({ type: FETCH_CONTAINER_FAILED, error: formatError(error) });
    }
}

export function* handleContainerCreation(acts) {
    yield put({ type: CREATE_CONTAINER_STARTED, acts });
    try {
        const response = yield call(api.acts.createContainer.post, null, {
            acts: acts.map(act => act.id),
        });
        yield put({
            type: CREATE_CONTAINER_SUCCEEDED,
            containerExternalID: response.external_id,
        });
    } catch (e) {
        let error = gettext('Signing failed with unexpected error');
        if (e.hasError && e.errors.hasError('acts')) {
            // Some type of 400 response with a message for the error, display it
            error = e.errors.getError('acts').errorByIndex(0);
        }
        yield put({ type: CREATE_CONTAINER_FAILED, error });
    }
}

export function* handleContainerDeletion({ externalID }) {
    yield put({ type: DELETE_CONTAINER_STARTED });

    if (externalID === null) {
        yield put({ type: DELETE_CONTAINER_SUCCEEDED });
        return;
    }

    try {
        yield call(api.signing.containerDetail.del, { externalID });
        yield put({
            type: DELETE_CONTAINER_SUCCEEDED,
        });
    } catch (error) {
        yield put({
            type: DELETE_CONTAINER_FAILED,
            error,
        });
    }
}

function getEsteidLanguage() {
    // eslint-disable-next-line camelcase
    const langCode = DJ_CONST?.user.language_code;
    const language = Languages[langCode.toUpperCase()];

    if (language === undefined) {
        // eslint-disable-next-line no-console
        console.warn(
            'Unable to map user language to esteid language',
            langCode,
        );
        return Languages.EN;
    }
    return language;
}

export function* handleIDCardSignature({ containerExternalID }) {
    yield put({ type: SIGN_ID_CARD_STARTED });
    const kwargs = {
        externalID: containerExternalID,
        signType: 'idcard',
    };
    window.hwcrypto = hwcrypto;

    try {
        const language = getEsteidLanguage();
        const manager = new IdCardManager(language);

        yield call([manager, 'initializeIdCard']);
        const cert = yield call([manager, 'getCertificate']);

        const response = yield call(api.signing.containerAction.post, kwargs, {
            certificate: cert,
        });

        const { digest } = response;

        const signatureValue = yield call([manager, 'signHexData'], digest);

        yield call(
            api.signing.containerAction.patch,
            kwargs,
            {
                signature_value: signatureValue,
            },
            null,
            null,
            {
                statusSuccess: [200, 202],
                // possible status codes returned by `finalize signing` request
                statusValidationError: [400, 409, 500, 503],
            },
        );

        yield put({
            type: SIGN_ID_CARD_SUCCEEDED,
        });
        return true;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Signing with ID card failed', error);
        yield put({
            type: SIGN_ID_CARD_FAILED,
            error: error.responseText
                ? formatError(error)
                : gettext('Signing with ID card has failed'),
        });
        return false;
    }
}

export function* handleMobileIDSignature({
    containerExternalID,
    mobileIdPhoneNr,
    mobileIdPersonCode,
}) {
    yield put({ type: SIGN_MOBILE_ID_STARTED });
    const kwargs = {
        externalID: containerExternalID,
        signType: 'mobileid',
    };

    let sanitizedMobileIdPhoneNr = mobileIdPhoneNr;

    if (!sanitizedMobileIdPhoneNr.startsWith('+372')) {
        sanitizedMobileIdPhoneNr = `+372${sanitizedMobileIdPhoneNr}`;
    }

    try {
        let response = yield call(api.signing.containerAction.post, kwargs, {
            id_code: mobileIdPersonCode,
            phone_number: sanitizedMobileIdPhoneNr,
        });

        yield put({
            type: SIGN_MOBILE_ID_CHALLENGE,
            mobileIdChallenge: response.verification_code,
        });

        while (true) {
            yield delay(3000);

            response = yield call(
                api.signing.containerAction.patch,
                kwargs,
                null,
                null,
                null,
                {
                    statusSuccess: [200, 202],
                    // possible status codes returned by `finalize signing` request
                    statusValidationError: [400, 409, 500, 503],
                },
            );
            const { status } = response;
            if (status !== 'pending') {
                break;
            }
        }

        yield put({
            type: SIGN_MOBILE_ID_SUCCEEDED,
        });
        return true;
    } catch (error) {
        yield put({
            type: SIGN_MOBILE_ID_FAILED,
            error: formatError(error),
        });
        return false;
    }
}

export function* handleSigning({ acts, isSecondParty = false }) {
    /* We don`t need to create a container if the signing is done for the second party */
    if (!isSecondParty) {
        const { cancel } = yield race({
            containerCreation: call(handleContainerCreation, acts),
            cancel: take(CANCEL_SIGNING),
        });
        if (typeof cancel !== 'undefined') {
            yield call(handleContainerDeletion, {
                externalID: cancel.containerExternalID,
            });
            return;
        }
    }

    while (true) {
        const nextActions = yield race({
            idCard: take(SIGN_ID_CARD_START),
            mobileID: take(SIGN_MOBILE_ID_START),
            cancel: take(CANCEL_SIGNING),
        });
        if (typeof nextActions.idCard !== 'undefined') {
            if (yield call(handleIDCardSignature, nextActions.idCard)) {
                window.location.reload();
                break;
            }
        } else if (typeof nextActions.mobileID !== 'undefined') {
            if (yield call(handleMobileIDSignature, nextActions.mobileID)) {
                window.location.reload();
                break;
            }
        } else {
            if (!isSecondParty) {
                yield call(handleContainerDeletion, {
                    externalID: nextActions.cancel.containerExternalID,
                });
            }
            break;
        }
    }
}

export function* signingSaga() {
    yield takeLeading(START_SIGNING, handleSigning);
}

export function* fetchContainerSaga() {
    yield takeLatest(FETCH_CONTAINER, handleContainerFetch);
}
