import * as React from 'react';
import PropTypes from 'prop-types';
import { H2 } from '@blueprintjs/core';

const PageHeader = ({ children }) => <H2 className="mb-5 ml-3">{children}</H2>;

PageHeader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default PageHeader;
