import PropTypes from 'prop-types';
import React from 'react';

import { WaybillFilterQueryShape } from 'shapes/waybills';
import { gettext } from '../../utils/text';
import { FILTERS } from '../../ducks/filters';
import TableEntityFilter from '../Table/TableEntityFilter';

const WaybillTransporterFilter = ({
    transporterRegCode,
    changeTransporterRegCode,
    query,
}) => (
    <TableEntityFilter
        initialValue={transporterRegCode}
        onChange={changeTransporterRegCode}
        itemsToOptions={item => ({
            text: `${item.transporter_name} [${item.transporter_reg_code}]`,
            value: item.transporter_reg_code,
        })}
        name="transporter_filter"
        buttonPlaceholder={gettext('Select transporter')}
        buttonLeftIcon="office"
        filterType={FILTERS.WAYBILL.TRANSPORTERS}
        query={query}
    />
);

WaybillTransporterFilter.propTypes = {
    transporterRegCode: PropTypes.string.isRequired,
    changeTransporterRegCode: PropTypes.func.isRequired,
    query: WaybillFilterQueryShape.isRequired,
};

export default WaybillTransporterFilter;
