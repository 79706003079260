// TODO: Cover with tests
// Actions
export const FETCH_ACT = 'ACTS/FETCH_ACT';
export const FETCH_ACT_STARTED = 'ACTS/FETCH_ACT_STARTED';
export const FETCH_ACT_SUCCESSFUL = 'ACTS/FETCH_ACT_SUCCESSFUL';
export const FETCH_ACT_FAILED = 'ACTS/FETCH_ACT_FAILED';

// Initial state for reducer
const initialState = {
    loading: false,
    response: null,
    error: null,
};

// Reducer(s)
export default function fetchActReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ACT_STARTED:
            return {
                ...state,
                loading: true,
            };
        case FETCH_ACT_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case FETCH_ACT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchAct = actID => ({
    type: FETCH_ACT,
    actID,
});
