import numeral from 'numeral';
import 'numeral/locales/et';
import Big from 'big.js';

numeral.locale('et');

export const big = stringNumber =>
    Big(stringNumber.replace(',', '.').replace(' ', ''));

export function floatformat(number, decimalPlaces) {
    const numeralNumber =
        number instanceof Big
            ? numeral(number.toFixed(decimalPlaces).replace('.', ','))
            : numeral(`${number}`.replace('.', ',').replace(' ', ''));

    const decimalZeros = '0'.repeat(decimalPlaces);
    return numeralNumber.format(`0,0.${decimalZeros}`);
}
