// TODO: Cover with tests
import {
    AnchorButton,
    HTMLTable,
    NonIdealState,
    Spinner,
} from '@blueprintjs/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import DoubleScrollbar from '@proscom/react-double-scrollbar';

import { gettext } from 'utils/text';
import { floatformat } from 'utils/formatting';
import { reverseUrl, useQueryString } from 'utils/urls';
import { fromISODateString, toLocaleDateString } from 'utils/date';
import { useEffectExceptOnMount } from 'utils/hooks';
import { TimberWarehouseListShape } from 'shapes/stockManagement';
import { CustomNonIdealState } from '../NonIdealStates';
import {
    TableColumnSelector,
    TableFilterGroup,
    TablePagination,
} from '../Table';
import { WAREHOUSE_TYPES, WarehouseStatusFilter } from '../WarehouseFilters';
import { timberWarehouseListActions as actions } from '../../slices/timberWarehouseList';

const COLUMN_GROUP = {
    LOCATION: 'location',
    STOCK: 'stock',
};
const COLUMN_GROUPS = [
    { id: COLUMN_GROUP.LOCATION, title: gettext('Location') },
    { id: COLUMN_GROUP.STOCK, title: gettext('Stock') },
];

export const TimberWarehouseList = ({
    timberWarehouses,
    loading,
    totalPages,
    error,
}) => {
    const dispatch = useDispatch();

    const [page, changePage] = useQueryString('page', 1, {
        parseNumbers: true,
    });
    const [groups, changeGroups] = useQueryString('groups', []);
    const [search, changeSearch] = useQueryString('search', '');
    const [status, changeStatus] = useQueryString('status', '', {
        parseNumbers: true,
    });

    const onPageChange = useCallback(
        newPage => {
            changePage(newPage);
            dispatch(
                actions.fetchTimberWarehouses({
                    page: newPage,
                    search,
                    status,
                }),
            );
        },
        [changePage, search, status],
    );

    // An effect for the first page load
    useEffect(() => {
        dispatch(actions.fetchTimberWarehouses({ page, search, status }));
    }, []);
    // An effect for filters that do not work on the first mount
    useEffectExceptOnMount(() => {
        changePage(1);
        dispatch(actions.fetchTimberWarehouses({ page: 1, search, status }));
    }, [changePage, search, status]);

    const filters = useMemo(
        () => (
            <TableFilterGroup
                search={search}
                changeSearch={changeSearch}
                searchPlaceholder={gettext(
                    'Search for timber warehouse by code or name...',
                )}
                customFilters={
                    <WarehouseStatusFilter
                        status={status}
                        changeStatus={changeStatus}
                        warehouseType={WAREHOUSE_TYPES.TIMBER}
                    />
                }
            />
        ),
        [search, changeSearch, status, changeStatus],
    );

    const pagination = useMemo(() => {
        if (totalPages === null) {
            return null;
        }

        return (
            <TablePagination
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
            />
        );
    }, [page, totalPages, onPageChange]);

    const navigation = useMemo(
        () => (
            <div className="mb-4">
                {filters}
                <div className="float-right">
                    <TableColumnSelector
                        selectedGroups={groups}
                        changeGroups={changeGroups}
                        groups={COLUMN_GROUPS}
                    />
                    {pagination}
                </div>
            </div>
        ),
        [groups, pagination],
    );

    const htmlTable = useMemo(() => {
        if (timberWarehouses === null) {
            return null;
        }

        if (!timberWarehouses.length) {
            return (
                <CustomNonIdealState
                    icon="search"
                    title={gettext('Oops! No timber warehouses found..')}
                    description={
                        <div>
                            <p>
                                {gettext(
                                    'No timber warehouses found matching your filters.',
                                )}
                            </p>
                        </div>
                    }
                    action={
                        <AnchorButton
                            text={gettext('Clear filters')}
                            href={reverseUrl(
                                'stock_management:timber-warehouse-list',
                            )}
                        />
                    }
                />
            );
        }

        return (
            <div className="table-full-width">
                <DoubleScrollbar>
                    <HTMLTable bordered condensed interactive striped>
                        <thead>
                            <tr>
                                <th colSpan={9}>&nbsp;</th>
                                {groups.indexOf(COLUMN_GROUP.LOCATION) >= 0 ? (
                                    <th colSpan={6}>{gettext('Location')}</th>
                                ) : null}
                                {groups.indexOf(COLUMN_GROUP.STOCK) >= 0 ? (
                                    <th colSpan={6}>{gettext('Stock')}</th>
                                ) : null}
                            </tr>
                            <tr>
                                <th key="timber-warehouse-name">
                                    {gettext('Name')}
                                </th>
                                <th key="timber-warehouse-code">
                                    {gettext('Code')}
                                </th>
                                <th key="timber-warehouse-status">
                                    {gettext('Status')}
                                </th>
                                <th key="timber-warehouse-date">
                                    {gettext('Date')}
                                </th>
                                <th key="timber-warehouse-company">
                                    {gettext('Company')}
                                </th>
                                <th key="timber-warehouse-forest-master">
                                    {gettext('Forest master')}
                                </th>
                                <th key="timber-warehouse-seller">
                                    {gettext('Seller')}
                                </th>
                                <th key="timber-warehouse-notice-number">
                                    {gettext('Notice number')}
                                </th>
                                <th key="timber-warehouse-notice-date">
                                    {gettext('Notice date')}
                                </th>
                                {groups.indexOf(COLUMN_GROUP.LOCATION) >= 0
                                    ? [
                                          <th key="timber-warehouse-latitude">
                                              {gettext('Latitude')}
                                          </th>,
                                          <th key="timber-warehouse-longitude">
                                              {gettext('Longitude')}
                                          </th>,
                                          <th key="timber-warehouse-county">
                                              {gettext('County')}
                                          </th>,
                                          <th key="timber-warehouse-parish">
                                              {gettext('Parish')}
                                          </th>,
                                          <th key="timber-warehouse-village">
                                              {gettext('Village')}
                                          </th>,
                                          <th key="timber-warehouse-cadastre">
                                              {gettext('Cadastral number')}
                                          </th>,
                                      ]
                                    : []}
                                {groups.indexOf(COLUMN_GROUP.STOCK) >= 0
                                    ? [
                                          <th key="timber-warehouse-planned-amount">
                                              {gettext('Planned amount')}
                                          </th>,
                                          <th key="timber-warehouse-harvester-amount">
                                              {gettext('Harvester amount')}
                                          </th>,
                                          <th key="timber-warehouse-forwarder-amount">
                                              {gettext('Forwarder amount')}
                                          </th>,
                                          <th key="timber-warehouse-truck-amount">
                                              {gettext('Truck amount')}
                                          </th>,
                                          <th key="timber-warehouse-warehouse-amount">
                                              {gettext('Warehouse amount')}
                                          </th>,
                                          <th key="timber-warehouse-correction-amount">
                                              {gettext('Correction amount')}
                                          </th>,
                                      ]
                                    : []}
                            </tr>
                        </thead>
                        <tbody>
                            {timberWarehouses.map(timberWarehouse => (
                                <tr key={timberWarehouse.id}>
                                    <td key="timber-warehouse-name">
                                        {timberWarehouse.name}
                                    </td>
                                    <td key="timber-warehouse-code">
                                        {timberWarehouse.code}
                                    </td>
                                    <td key="timber-warehouse-status">
                                        {timberWarehouse.status_display}
                                    </td>
                                    <td key="timber-warehouse-date">
                                        {toLocaleDateString(
                                            fromISODateString(
                                                timberWarehouse.date,
                                            ),
                                        )}
                                    </td>
                                    <td key="timber-warehouse-company">
                                        {timberWarehouse.company_name}
                                    </td>
                                    <td key="timber-warehouse-forest-master">
                                        {timberWarehouse.forest_master !== null
                                            ? `${timberWarehouse.forest_master.name} (${timberWarehouse.forest_master.person_code})`
                                            : ''}
                                    </td>
                                    <td key="timber-warehouse-seller">
                                        {timberWarehouse.previous_owner_name}
                                    </td>
                                    <td key="timber-warehouse-notice-number">
                                        {timberWarehouse.notice_number}
                                    </td>
                                    <td key="timber-warehouse-notice-date">
                                        {timberWarehouse.notice_date}
                                    </td>
                                    {groups.indexOf(COLUMN_GROUP.LOCATION) >= 0
                                        ? [
                                              <td key="timber-warehouse-latitude">
                                                  {timberWarehouse.latitude}
                                              </td>,
                                              <td key="timber-warehouse-longitude">
                                                  {timberWarehouse.longitude}
                                              </td>,
                                              <td key="timber-warehouse-county">
                                                  {timberWarehouse.county}
                                              </td>,
                                              <td key="timber-warehouse-parish">
                                                  {timberWarehouse.parish}
                                              </td>,
                                              <td key="timber-warehouse-village">
                                                  {timberWarehouse.village}
                                              </td>,
                                              <td key="timber-warehouse-cadastre">
                                                  {
                                                      timberWarehouse.cadastral_number
                                                  }
                                              </td>,
                                          ]
                                        : []}
                                    {groups.indexOf(COLUMN_GROUP.STOCK) >= 0
                                        ? [
                                              <td key="timber-warehouse-planned-amount">
                                                  {floatformat(
                                                      timberWarehouse.planned_amount,
                                                      3,
                                                  )}
                                              </td>,
                                              <td key="timber-warehouse-harvester-amount">
                                                  {floatformat(
                                                      timberWarehouse.harvester_amount,
                                                      3,
                                                  )}
                                              </td>,
                                              <td key="timber-warehouse-forwarder-amount">
                                                  {floatformat(
                                                      timberWarehouse.forwarder_amount,
                                                      3,
                                                  )}
                                              </td>,
                                              <td key="timber-warehouse-truck-amount">
                                                  {floatformat(
                                                      timberWarehouse.truck_amount,
                                                      3,
                                                  )}
                                              </td>,
                                              <td key="timber-warehouse-warehouse-amount">
                                                  {floatformat(
                                                      timberWarehouse.warehouse_amount,
                                                      3,
                                                  )}
                                              </td>,
                                              <td key="timber-warehouse-correction-amount">
                                                  {floatformat(
                                                      timberWarehouse.correction_amount,
                                                      3,
                                                  )}
                                              </td>,
                                          ]
                                        : []}
                                </tr>
                            ))}
                        </tbody>
                    </HTMLTable>
                </DoubleScrollbar>
            </div>
        );
    }, [loading, timberWarehouses, groups]);

    if (!loading && error !== null) {
        return (
            <>
                <div className="container-fluid">{navigation}</div>
                <NonIdealState
                    icon="issue"
                    title={gettext('Oops! Could not load timber warehouses..')}
                    description={error}
                />
            </>
        );
    }

    if (loading) {
        return (
            <>
                <div className="container-fluid">{navigation}</div>
                <Spinner className="mt-5" />
            </>
        );
    }

    return (
        <>
            <div className="container-fluid">{navigation}</div>
            <div className="container-fluid pb-5">{htmlTable}</div>
        </>
    );
};

TimberWarehouseList.propTypes = {
    loading: PropTypes.bool,
    timberWarehouses: PropTypes.arrayOf(TimberWarehouseListShape),
    totalPages: PropTypes.number,
    error: PropTypes.string,
};

TimberWarehouseList.defaultProps = {
    loading: false,
    timberWarehouses: null,
    totalPages: null,
    error: null,
};

const mapStateToProps = state => ({
    loading: state.timberWarehouseList.loading,
    timberWarehouses: state.timberWarehouseList.timberWarehouses,
    totalPages: state.timberWarehouseList.totalPages,
    error: state.timberWarehouseList.error,
});

export default connect(
    mapStateToProps,
    null,
)(TimberWarehouseList);
