import { combineReducers } from 'redux';

import actInitials from './ducks/acts/actInitials';
import actDetails from './ducks/acts/actDetails';
import acts from './ducks/acts/actList';
import energyWarehouses from './ducks/stock_management/energyWarehouseList';
import { reducer as timberWarehouseList } from './slices/timberWarehouseList';
import filterReducer, { FILTERS } from './ducks/filters';
import invoiceInitials from './ducks/accounting/invoiceInitials';
import signing from './ducks/signing';
import toasts from './ducks/toasts';
import waybills from './ducks/waybills/waybills';
import nonSelectableWaybills from './ducks/waybills/nonSelectableWaybills';
import waybillTotals from './ducks/waybills/waybillTotals';
import fellingRows from './ducks/forest_estimates/fellingRows';
import fellingRowFellingTypeChoices from './ducks/forest_estimates/fellingRowFellingTypeChoices';
import fellingRowLocationChoices from './ducks/forest_estimates/fellingRowLocationChoices';
import fellingRowTreeSpecieNameChoices from './ducks/forest_estimates/fellingRowTreeSpecieNameChoices';
import fellingRowDetails from './ducks/forest_estimates/fellingRowDetails';
import forestEstimates from './ducks/forest_estimates/forest_estimates';
import forestEstimateDetails from './ducks/forest_estimates/forestEstimateDetails';
import forestEstimateAnalyzerChoices from './ducks/forest_estimates/forestEstimateAnalyzerChoices';
import { createNamedWrapperReducer } from './utils/reducers';
import { RTKApi } from './queries';

const rootReducer = combineReducers({
    /* Acts */
    actInitials,
    actDetails,
    acts,
    /* End Acts */
    /* Signing */
    signing,
    /* End Signing */
    /* Waybills */
    [FILTERS.WAYBILL.BUYERS.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.WAYBILL.BUYERS.stateKey,
    ),
    [FILTERS.WAYBILL.CHOPPERS.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.WAYBILL.CHOPPERS.stateKey,
    ),
    [FILTERS.WAYBILL.DESTINATIONS.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.WAYBILL.DESTINATIONS.stateKey,
    ),
    [FILTERS.WAYBILL.DRIVERS.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.WAYBILL.DRIVERS.stateKey,
    ),
    [FILTERS.WAYBILL.MATERIALS.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.WAYBILL.MATERIALS.stateKey,
    ),
    [FILTERS.WAYBILL.ORIGINS.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.WAYBILL.ORIGINS.stateKey,
    ),
    [FILTERS.WAYBILL.SELLERS.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.WAYBILL.SELLERS.stateKey,
    ),
    [FILTERS.WAYBILL.TRANSPORTERS.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.WAYBILL.TRANSPORTERS.stateKey,
    ),
    [FILTERS.WAYBILL.TRUCKS.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.WAYBILL.TRUCKS.stateKey,
    ),
    [FILTERS.WAYBILL.UNITS.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.WAYBILL.UNITS.stateKey,
    ),
    [FILTERS.WAYBILL.HAS_WEIGHT.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.WAYBILL.HAS_WEIGHT.stateKey,
    ),
    [FILTERS.WAYBILL.FORESTMASTER.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.WAYBILL.FORESTMASTER.stateKey,
    ),
    waybills,
    nonSelectableWaybills,
    waybillTotals,
    /* End Waybills */
    // Start forest estimates
    fellingRows,
    fellingRowFellingTypeChoices,
    fellingRowLocationChoices,
    fellingRowTreeSpecieNameChoices,
    fellingRowDetails,
    forestEstimates,
    forestEstimateDetails,
    forestEstimateAnalyzerChoices,
    // End forest estimates
    /* Start Stock Management */
    energyWarehouses,
    [FILTERS.ENERGY_WAREHOUSE.STATUSES.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.ENERGY_WAREHOUSE.STATUSES.stateKey,
    ),
    [FILTERS.TIMBER_WAREHOUSE.STATUSES.stateKey]: createNamedWrapperReducer(
        filterReducer,
        FILTERS.TIMBER_WAREHOUSE.STATUSES.stateKey,
    ),
    timberWarehouseList,
    /* End Stock Management */
    /* Accounting */
    invoiceInitials,
    /* End Accounting */
    /* Start Toasts */
    toasts,
    /* End Toasts */
    /* RTK Query */
    [RTKApi.reducerPath]: RTKApi.reducer,
    /* End RTK Query */
});

export default rootReducer;
