import React from 'react';

import { Button, ButtonGroup } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { reverseUrl } from '../utils/urls';
import { gettext } from '../utils/text';
import { RTKApi } from '../queries';
import { fetchForestEstimate } from '../ducks/forest_estimates/forestEstimateDetails';
import { toastError } from '../ducks/toasts';

const ForestEstimateNavigation = ({
    currentView,
    forestEstimate: {
        id: forestEstimateId,
        timber_warehouse: timberWarehouse,
        can_create_timber_warehouse: canCreateTimberWarehouse,
    },
}) => {
    const dispatch = useDispatch();

    const { useCreateForestEstimateTimberWarehouseMutation } = RTKApi;

    const [
        createForestEstimateTimberWarehouse,
        createForestEstimateTimberWarehouseResult,
    ] = useCreateForestEstimateTimberWarehouseMutation();

    React.useEffect(() => {
        if (createForestEstimateTimberWarehouseResult.isSuccess) {
            // Some views historically do not use RTK, but use sagas instead. This notifies the saga to reload data.
            dispatch(fetchForestEstimate(forestEstimateId));
        } else if (createForestEstimateTimberWarehouseResult.isError) {
            const message =
                createForestEstimateTimberWarehouseResult.error &&
                createForestEstimateTimberWarehouseResult.error.data &&
                createForestEstimateTimberWarehouseResult.error.data
                    .non_field_errors
                    ? Array.from(
                          createForestEstimateTimberWarehouseResult.error.data
                              .non_field_errors,
                      ).join(', ')
                    : gettext(
                          'Could not create timber warehouse with current Forest Estimate data, ' +
                              'check if all the fields are filled correctly.',
                      );
            dispatch(
                toastError({
                    message,
                }),
            );
        }
    }, [createForestEstimateTimberWarehouseResult]);

    return (
        <ButtonGroup className="pr-3">
            {currentView !== 'assessment' && (
                <a
                    className="ml-3"
                    href={reverseUrl('forestEstimates:assessment', {
                        pk: forestEstimateId,
                    })}
                >
                    <Button icon="comparison">{gettext('Assessment')}</Button>
                </a>
            )}
            {currentView !== 'analysis' && timberWarehouse && (
                <a
                    className="ml-3"
                    href={reverseUrl('forestEstimates:analysis', {
                        pk: forestEstimateId,
                    })}
                >
                    <Button icon="comparison">{gettext('Analysis')}</Button>
                </a>
            )}
            {currentView !== 'edit-estimate' && (
                <a
                    className="ml-3"
                    href={reverseUrl('forestEstimates:update', {
                        pk: forestEstimateId,
                    })}
                >
                    <Button icon="edit">{gettext('Edit estimate')}</Button>
                </a>
            )}
            {currentView !== 'felling-row-list' && (
                <a
                    className="ml-3"
                    href={reverseUrl('forestEstimates:fellingRowList', {
                        pk: forestEstimateId,
                    })}
                >
                    <Button icon="tree">{gettext('Felling rows')}</Button>
                </a>
            )}
            {currentView !== 'create-felling-row' && (
                <a
                    className="ml-3"
                    href={reverseUrl('forest_estimates:felling-row-create', {
                        pk: forestEstimateId,
                    })}
                >
                    <Button icon="add">{gettext('Create row')}</Button>
                </a>
            )}
            {timberWarehouse ? (
                <a
                    className="ml-3"
                    href={`${reverseUrl(
                        'stock_management:timber_warehouse_list',
                    )}?search=${timberWarehouse.code}`}
                >
                    <Button icon="map-marker">
                        {timberWarehouse.name} [{timberWarehouse.code}]
                    </Button>
                </a>
            ) : (
                <span className="ml-3">
                    <Button
                        icon="add"
                        disabled={
                            !canCreateTimberWarehouse ||
                            createForestEstimateTimberWarehouseResult.isLoading
                        }
                        onClick={() =>
                            createForestEstimateTimberWarehouse({
                                id: forestEstimateId,
                            })
                        }
                    >
                        {gettext('Create site')}
                    </Button>
                </span>
            )}
            <a
                className="ml-3"
                href={reverseUrl('api:forestestimate-contract', {
                    pk: forestEstimateId,
                })}
            >
                <Button icon="document-share">{gettext('Contract')}</Button>
            </a>
        </ButtonGroup>
    );
};

ForestEstimateNavigation.propTypes = {
    forestEstimate: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]).isRequired,
        timber_warehouse: PropTypes.shape({
            code: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
        can_create_timber_warehouse: PropTypes.bool,
    }).isRequired,
    currentView: PropTypes.oneOf([
        'assessment',
        'analysis',
        'edit-estimate',
        'felling-row-list',
        'create-felling-row',
    ]).isRequired,
};

export default ForestEstimateNavigation;
