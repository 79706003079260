import { call, put, spawn, takeLatest, takeLeading } from 'redux-saga/effects';

import api from '../api';
import {
    FETCH_FELLING_ROW,
    FETCH_FELLING_ROW_STARTED,
    FETCH_FELLING_ROW_SUCCESSFUL,
    FETCH_FELLING_ROW_FAILED,
} from '../ducks/forest_estimates/fellingRowDetails';
import {
    UPDATE_FELLING_ROW,
    UPDATE_FELLING_ROW_STARTED,
    UPDATE_FELLING_ROW_SUCCESSFUL,
    UPDATE_FELLING_ROW_FAILED,
} from '../ducks/forest_estimates/fellingRowUpdate';

function* fetchFellingRow(action) {
    yield put({ type: FETCH_FELLING_ROW_STARTED });
    try {
        const response = yield call(
            api.forestEstimates.fellingRowDetails.fetch,
            {
                id: action.fellingRowId,
            },
        );
        yield put({ type: FETCH_FELLING_ROW_SUCCESSFUL, response });
    } catch (error) {
        yield put({ type: FETCH_FELLING_ROW_FAILED, error });
    }
}

function* fetchFellingRowSaga() {
    yield takeLatest(FETCH_FELLING_ROW, fetchFellingRow);
}

function* updateFellingRow(action) {
    yield put({ type: UPDATE_FELLING_ROW_STARTED });
    try {
        const response = yield call(
            api.forestEstimates.updateFellingRow.put,
            { id: action.payload.id },
            action.payload,
        );
        yield put({ type: UPDATE_FELLING_ROW_SUCCESSFUL, response });
        yield call(action.callback);
    } catch (error) {
        yield put({ type: UPDATE_FELLING_ROW_FAILED, error });
        yield call(action.errorCallback, error);
    }
}

function* updateFellingRowSaga() {
    yield takeLeading(UPDATE_FELLING_ROW, updateFellingRow);
}

export default function* fetchUpdateRootSaga() {
    yield spawn(fetchFellingRowSaga);
    yield spawn(updateFellingRowSaga);
}
