// Actions
export const FETCH_FOREST_ESTIMATE = 'FOREST_ESTIMATES/FETCH_FOREST_ESTIMATE';
export const FETCH_FOREST_ESTIMATE_STARTED =
    'FOREST_ESTIMATES/FETCH_FOREST_ESTIMATE_STARTED';
export const FETCH_FOREST_ESTIMATE_SUCCESSFUL =
    'FOREST_ESTIMATES/FETCH_FOREST_ESTIMATE_SUCCESSFUL';
export const FETCH_FOREST_ESTIMATE_FAILED =
    'FOREST_ESTIMATES/FETCH_FOREST_ESTIMATE_FAILED';

// Initial state for reducer
const initialState = {
    loading: false,
    response: null,
    error: null,
};

// Reducer(s)
export default function fetchForestEstimateReducer(
    state = initialState,
    action,
) {
    switch (action.type) {
        case FETCH_FOREST_ESTIMATE_STARTED:
            return {
                ...state,
                loading: true,
            };
        case FETCH_FOREST_ESTIMATE_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case FETCH_FOREST_ESTIMATE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchForestEstimate = forestEstimateId => ({
    type: FETCH_FOREST_ESTIMATE,
    forestEstimateId,
});
