// TODO: Cover with tests
// Actions
export const UPDATE_ACT = 'ACTS/UPDATE_ACT';
export const UPDATE_ACT_STARTED = 'ACTS/UPDATE_ACT_STARTED';
export const UPDATE_ACT_SUCCESSFUL = 'ACTS/UPDATE_ACT_SUCCESSFUL';
export const UPDATE_ACT_FAILED = 'ACTS/UPDATE_ACT_FAILED';

// Action creators
export const updateAct = (payload, callback, errorCallback) => ({
    type: UPDATE_ACT,
    payload,
    callback,
    errorCallback,
});
