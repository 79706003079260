import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { RTKApi } from './queries';

const isDevEnv = process.env.NODE_ENV === 'development';

export default function configureStore(reducer) {
    const reduxSageMonitorOptions = {};
    const sagaMiddleware = createSagaMiddleware(reduxSageMonitorOptions);
    const middlewares = [sagaMiddleware, RTKApi.middleware];

    if (isDevEnv) {
        middlewares.push(
            createLogger({
                collapsed: true,
                duration: true,
            }),
        );
    }

    // serializableCheck is disabled to provide functions and other non serializable objects inside the action payload.
    // Ref: https://redux-toolkit.js.org/api/getDefaultMiddleware#getdefaultmiddleware
    const store = rtkConfigureStore({
        reducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: false,
            }).concat(middlewares),
        devTools: isDevEnv,
    });

    setupListeners(store.dispatch);
    return { store, sagaMiddleware };
}
