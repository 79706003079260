import { gettext, interpolate } from './text';

/* Helper function to format API errors */
export const formatError = error => {
    let errorMessage = gettext('Unknown error: %s');
    errorMessage = interpolate(errorMessage, [error]);
    if (error.responseText) {
        try {
            const responseJSON = JSON.parse(error.responseText);
            errorMessage =
                responseJSON.detail ||
                responseJSON.message ||
                error.responseText;
        } catch (e) {
            errorMessage = error.responseText;
        }
    }
    return errorMessage;
};
