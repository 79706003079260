import React from 'react';
import { HTMLTable } from '@blueprintjs/core';

import { ForestEstimateDetailsShape } from '../../shapes/forestEstimates';
import { pgettext } from '../../utils/text';
import NumberCell from '../ForestEstimateAssessment/NumberCell';
import EstimateTableSpacer from '../ForestEstimateAssessment/EstimateTableSpacer';

const ForestEstimateAnalysisAmountsTable = ({ forestEstimate }) => (
    <HTMLTable className="w-100 editable-table pt-5 pb-5" striped>
        <thead>
            <tr>
                <th rowSpan={2} className="border-right">
                    {pgettext(
                        'Forest estimate analysis',
                        'Amount without bark',
                    )}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <th
                        key={specie.name}
                        colSpan={3}
                        className="border-right text-center"
                    >
                        {specie.title}
                    </th>
                ))}
                <th className="border-right" rowSpan={2}>
                    {pgettext('Forest estimate analysis', 'Firewood')}
                </th>
                <th rowSpan={2}>
                    {pgettext('Forest estimate analysis', 'Total')}
                </th>
            </tr>
            <tr>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <th className="border-right">
                            {pgettext('Wood type', 'Thick')}
                        </th>
                        <th className="border-right">
                            {pgettext('Wood type', 'Thin')}
                        </th>
                        <th className="border-right">
                            {pgettext('Wood type', 'Pulp')}
                        </th>
                    </React.Fragment>
                ))}
            </tr>
        </thead>
        <tbody>
            <tr>
                <th className="border-right">
                    {pgettext('Forest estimate analysis', 'Forecast m3')}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <NumberCell
                            value={specie.thick_wood_amount}
                            decimalPlaces={3}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.thin_wood_amount}
                            decimalPlaces={3}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.pulp_wood_amount}
                            decimalPlaces={3}
                            className="border-right"
                        />
                    </React.Fragment>
                ))}
                <NumberCell
                    value={forestEstimate.fire_wood_amount}
                    decimalPlaces={3}
                    className="border-right"
                />
                <NumberCell
                    value={forestEstimate.neto_amount}
                    decimalPlaces={3}
                />
            </tr>
            <tr>
                <th className="border-right">
                    {pgettext('Forest estimate analysis', 'Forecast share')}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <NumberCell
                            value={specie.thick_wood_share_of_total}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.thin_wood_share_of_total}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.pulp_wood_share_of_total}
                            decimalPlaces={2}
                            className="border-right"
                        />
                    </React.Fragment>
                ))}
                <NumberCell
                    value={forestEstimate.fire_wood_share}
                    decimalPlaces={2}
                    className="border-right"
                />
                <td>&nbsp;</td>
            </tr>
            <tr>
                <th className="border-right">
                    {pgettext('Forest estimate analysis', 'Forecast sum')}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <NumberCell
                            value={specie.thick_wood_expected_revenue}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.thin_wood_expected_revenue}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.pulp_wood_expected_revenue}
                            decimalPlaces={2}
                            className="border-right"
                        />
                    </React.Fragment>
                ))}
                <NumberCell
                    value={forestEstimate.fire_wood_expected_revenue}
                    decimalPlaces={3}
                    className="border-right"
                />
                <NumberCell
                    value={forestEstimate.expected_revenue}
                    decimalPlaces={3}
                />
            </tr>
            <EstimateTableSpacer
                species={forestEstimate.felling_tree_species}
            />
            <tr>
                <th className="border-right">
                    {pgettext('Forest estimate analysis', 'Realization m3')}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <NumberCell
                            value={specie.thick_wood_realization_amount}
                            decimalPlaces={3}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.thin_wood_realization_amount}
                            decimalPlaces={3}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.pulp_wood_realization_amount}
                            decimalPlaces={3}
                            className="border-right"
                        />
                    </React.Fragment>
                ))}
                <NumberCell
                    value={forestEstimate.fire_wood_realization_amount}
                    decimalPlaces={3}
                    className="border-right"
                />
                <NumberCell
                    value={forestEstimate.realization_total_amount}
                    decimalPlaces={3}
                />
            </tr>
            <tr>
                <th className="border-right">
                    {pgettext(
                        'Forest estimate analysis',
                        'Realization percent',
                    )}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <NumberCell
                            value={specie.thick_wood_realization_share}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.thin_wood_realization_share}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.pulp_wood_realization_share}
                            decimalPlaces={2}
                            className="border-right"
                        />
                    </React.Fragment>
                ))}
                <NumberCell
                    value={forestEstimate.fire_wood_realization_share}
                    decimalPlaces={2}
                    className="border-right"
                />
                <td>&nbsp;</td>
            </tr>
            <tr>
                <th className="border-right">
                    {pgettext('Forest estimate analysis', 'Realization sum')}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <NumberCell
                            value={specie.thick_wood_realization_revenue}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.thin_wood_realization_revenue}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.pulp_wood_realization_revenue}
                            decimalPlaces={2}
                            className="border-right"
                        />
                    </React.Fragment>
                ))}
                <NumberCell
                    value={forestEstimate.fire_wood_realization_revenue}
                    decimalPlaces={2}
                    className="border-right"
                />
                <NumberCell
                    value={forestEstimate.realization_total_revenue}
                    decimalPlaces={2}
                />
            </tr>
            <EstimateTableSpacer
                species={forestEstimate.felling_tree_species}
            />
            <tr>
                <th className="border-right">
                    {pgettext('Forest estimate analysis', 'Difference m3')}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <NumberCell
                            value={
                                specie.thick_wood_realization_amount_difference
                            }
                            decimalPlaces={3}
                            className="border-right"
                        />
                        <NumberCell
                            value={
                                specie.thin_wood_realization_amount_difference
                            }
                            decimalPlaces={3}
                            className="border-right"
                        />
                        <NumberCell
                            value={
                                specie.pulp_wood_realization_amount_difference
                            }
                            decimalPlaces={3}
                            className="border-right"
                        />
                    </React.Fragment>
                ))}
                <NumberCell
                    value={
                        forestEstimate.fire_wood_realization_amount_difference
                    }
                    decimalPlaces={3}
                    className="border-right"
                />
                <NumberCell
                    value={forestEstimate.realization_amount_difference}
                    decimalPlaces={3}
                />
            </tr>
            <tr>
                <th className="border-right">
                    {pgettext('Forest estimate analysis', 'Difference percent')}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <NumberCell
                            value={specie.thick_wood_realization_amount_ratio}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.thin_wood_realization_amount_ratio}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.pulp_wood_realization_amount_ratio}
                            decimalPlaces={2}
                            className="border-right"
                        />
                    </React.Fragment>
                ))}
                <NumberCell
                    value={forestEstimate.fire_wood_realization_amount_ratio}
                    decimalPlaces={2}
                    className="border-right"
                />
                <NumberCell
                    value={forestEstimate.realization_amount_ratio}
                    decimalPlaces={2}
                />
            </tr>
            <tr>
                <th
                    className="border-right text-right"
                    colSpan={2 + 3 * forestEstimate.felling_tree_species.length}
                >
                    {pgettext('Forest estimate analysis', 'Revenue')}
                </th>
                <NumberCell
                    value={forestEstimate.realization_revenue_difference}
                    decimalPlaces={2}
                />
            </tr>
            <tr>
                <th
                    className="border-right text-right"
                    colSpan={2 + 3 * forestEstimate.felling_tree_species.length}
                >
                    {pgettext('Forest estimate analysis', 'Revenue percent')}
                </th>
                <NumberCell
                    value={forestEstimate.realization_revenue_ratio}
                    decimalPlaces={2}
                />
            </tr>
        </tbody>
    </HTMLTable>
);

ForestEstimateAnalysisAmountsTable.propTypes = {
    forestEstimate: ForestEstimateDetailsShape.isRequired,
};

export default ForestEstimateAnalysisAmountsTable;
