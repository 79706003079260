import { call, put, spawn, takeLeading } from 'redux-saga/effects';

import api from '../api';
import {
    CREATE_FELLING_ROW,
    CREATE_FELLING_ROW_STARTED,
    CREATE_FELLING_ROW_SUCCESSFUL,
    CREATE_FELLING_ROW_FAILED,
} from '../ducks/forest_estimates/fellingRowCreation';

function* createFellingRow(action) {
    yield put({ type: CREATE_FELLING_ROW_STARTED });
    try {
        const response = yield call(
            api.forestEstimates.createFellingRow.post,
            null,
            action.payload,
        );
        yield put({ type: CREATE_FELLING_ROW_SUCCESSFUL, response });
        yield call(action.callback);
    } catch (error) {
        yield put({ type: CREATE_FELLING_ROW_FAILED, error });
        yield call(action.errorCallback, error);
    }
}

function* createFellingRowSaga() {
    yield takeLeading(CREATE_FELLING_ROW, createFellingRow);
}

export default function* fellingRowCreationRootSaga() {
    yield spawn(createFellingRowSaga);
}
