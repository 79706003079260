import PropTypes from 'prop-types';

/* Generate prop type of every form field */
const getFieldMapping = (fields, type) =>
    fields.reduce((result, field) => {
        result[field] = type; // eslint-disable-line no-param-reassign
        return result;
    }, {});

export const formFieldType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.instanceOf(Date),
    PropTypes.array,
]);

export const touchedType = PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.objectOf(PropTypes.bool),
    PropTypes.array,
]);

export const errorType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
]);

export const getFormPropTypes = fields => ({
    values: PropTypes.shape(getFieldMapping(fields, formFieldType)).isRequired,
    status: PropTypes.shape({
        color: PropTypes.string,
        message: PropTypes.string,
        success: PropTypes.bool,
    }),
    errors: PropTypes.shape(getFieldMapping(fields, errorType)).isRequired,
    touched: PropTypes.shape(getFieldMapping(fields, touchedType)).isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
});

export const FormikShape = PropTypes.shape({
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
});

export const FieldProps = {
    props: {
        name: PropTypes.string.isRequired,
        formik: FormikShape.isRequired,

        id: PropTypes.string,
        label: PropTypes.node,
        helperText: PropTypes.node,
        placeholder: PropTypes.string,
        inline: PropTypes.bool,
    },
    defaults: {
        id: null,
        label: null,
        helperText: null,
        placeholder: null,
        inline: false,
    },
};
