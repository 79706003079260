// TODO: Cover with tests
import {
    Button,
    Callout,
    Dialog,
    FormGroup,
    InputGroup,
    Intent,
    Spinner,
} from '@blueprintjs/core';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import {
    signingCancel,
    signingIDCardStart,
    signingMobileID,
    signingMobileIDChangeParams,
    signingMobileIDStart,
} from '../../ducks/signing';
import { LightActShape } from '../../shapes/acts';
import { gettext } from '../../utils/text';

export const SigningDialog = ({
    isDialogOpen,
    toggleDialog,
    changeMobileIDParams,
    containerExternalID,
    containerActs,
    containerLoading,
    containerError,
    cancelSigning,
    idCardInProgress,
    idCardError,
    mobileIdChallenge,
    mobileIdPersonCode,
    mobileIdPhoneNr,
    mobileIdError,
    signIDCardStart,
    signMobileID,
    signMobileIDStart,
}) => {
    const isOpen = useMemo(() => {
        return isDialogOpen && !!containerActs.length;
    }, [isDialogOpen, containerActs]);

    const errorCallout = useMemo(() => {
        if (!containerError && !idCardError && !mobileIdError) {
            return null;
        }
        return (
            <Callout intent={Intent.DANGER}>
                {idCardError || mobileIdError || containerError}
            </Callout>
        );
    }, [containerError, idCardError, mobileIdError]);

    const actList = useMemo(() => {
        if (containerActs.length === 0) {
            return null;
        }
        return (
            <div className="px-4 pt-3">
                <p>{gettext('Chosen acts:')}</p>
                <p>{containerActs.map(a => a.number).join(', ')}</p>
            </div>
        );
    }, [containerActs]);

    const canUserCancel = useMemo(() => {
        return !idCardInProgress && mobileIdChallenge === null;
    }, [idCardInProgress, mobileIdChallenge]);

    const content = useMemo(() => {
        if (containerError || containerLoading || containerActs.length === 0) {
            return null;
        }
        if (idCardInProgress) {
            return <Spinner className="my-3" />;
        } else if (mobileIdChallenge !== null) {
            if (mobileIdChallenge === '') {
                return <Spinner className="my-3" />;
            }
            return (
                <div className="px-4 mt-4 text-center">
                    <div>{gettext('Your mobile ID challenge is')}</div>
                    <div className="bp3-text-large my-3">
                        <b>{mobileIdChallenge}</b>
                    </div>
                </div>
            );
        } else if (mobileIdPhoneNr !== null && mobileIdPersonCode !== null) {
            return (
                <div className="px-4 pt-5">
                    <FormGroup
                        label={gettext('Phone number')}
                        labelFor="phone-number"
                    >
                        <InputGroup
                            id="phone-number"
                            onChange={e =>
                                changeMobileIDParams({
                                    mobileIdPhoneNr: e.target.value,
                                })
                            }
                            value={mobileIdPhoneNr}
                        />
                    </FormGroup>
                    <FormGroup
                        label={gettext('Personal identity code')}
                        labelFor="id-code"
                    >
                        <InputGroup
                            id="id-code"
                            onChange={e =>
                                changeMobileIDParams({
                                    mobileIdPersonCode: e.target.value,
                                })
                            }
                            value={mobileIdPersonCode}
                        />
                    </FormGroup>
                    <Button
                        disabled={
                            !mobileIdPhoneNr.length ||
                            !mobileIdPersonCode.length
                        }
                        onClick={() =>
                            signMobileIDStart(
                                containerExternalID,
                                mobileIdPhoneNr,
                                mobileIdPersonCode,
                            )
                        }
                        text={gettext('Sign')}
                    />
                </div>
            );
        } else {
            return (
                <div className="px-4 pt-3 text-right">
                    <button
                        type="button"
                        className="signing__btn signing__logo--id-card"
                        onClick={() => signIDCardStart(containerExternalID)}
                    />
                    <button
                        type="button"
                        className="signing__btn signing__logo--mobile-id ml-3"
                        onClick={() => signMobileID(containerExternalID)}
                    />
                </div>
            );
        }
    }, [
        changeMobileIDParams,
        containerError,
        containerExternalID,
        containerLoading,
        containerActs,
        idCardInProgress,
        mobileIdChallenge,
        mobileIdPersonCode,
        mobileIdPhoneNr,
        signIDCardStart,
        signMobileID,
    ]);

    const onClose = useCallback(() => {
        if (toggleDialog !== null) {
            toggleDialog();
        }
        cancelSigning(containerExternalID);
    }, [toggleDialog, cancelSigning, containerExternalID]);

    return (
        <Dialog
            title={gettext('Signing')}
            isOpen={isOpen}
            onClose={onClose}
            isCloseButtonShown={canUserCancel}
            canEscapeKeyClose={canUserCancel}
            canOutsideClickClose={canUserCancel}
        >
            <div>{errorCallout}</div>
            {actList}
            {content}
            {containerLoading ? <Spinner className="mt-5" /> : null}
        </Dialog>
    );
};

SigningDialog.propTypes = {
    isDialogOpen: PropTypes.bool,
    toggleDialog: PropTypes.func,
    changeMobileIDParams: PropTypes.func.isRequired,
    containerActs: PropTypes.arrayOf(LightActShape).isRequired,
    containerExternalID: PropTypes.string,
    containerLoading: PropTypes.bool.isRequired,
    containerError: PropTypes.string,
    cancelSigning: PropTypes.func.isRequired,
    idCardInProgress: PropTypes.bool.isRequired,
    idCardError: PropTypes.string,
    mobileIdChallenge: PropTypes.string,
    mobileIdPersonCode: PropTypes.string,
    mobileIdPhoneNr: PropTypes.string,
    mobileIdError: PropTypes.string,
    signIDCardStart: PropTypes.func.isRequired,
    signMobileID: PropTypes.func.isRequired,
    signMobileIDStart: PropTypes.func.isRequired,
};

SigningDialog.defaultProps = {
    isDialogOpen: false,
    toggleDialog: null,
    containerExternalID: null,
    containerError: null,
    idCardError: null,
    mobileIdChallenge: null,
    mobileIdPersonCode: null,
    mobileIdPhoneNr: null,
    mobileIdError: null,
};

const mapStateToProps = state => ({
    containerActs: state.signing.acts,
    containerExternalID: state.signing.containerExternalID,
    containerLoading: state.signing.containerLoading,
    containerError: state.signing.containerError,
    idCardInProgress: state.signing.idCardInProgress,
    idCardError: state.signing.idCardError,
    mobileIdChallenge: state.signing.mobileIdChallenge,
    mobileIdPersonCode: state.signing.mobileIdPersonCode,
    mobileIdPhoneNr: state.signing.mobileIdPhoneNr,
    mobileIdError: state.signing.mobileIdError,
});

const mapDispatchToProps = {
    cancelSigning: signingCancel,
    changeMobileIDParams: signingMobileIDChangeParams,
    signIDCardStart: signingIDCardStart,
    signMobileID: signingMobileID,
    signMobileIDStart: signingMobileIDStart,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SigningDialog);
