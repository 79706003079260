import React from 'react';

import { DateRangePicker as BlueprintDateRangePicker } from '@blueprintjs/datetime';

import { DEFAULT_LOCALE_CODE } from '../../utils/date';
import { DATE_FNS_LOCALE_UTILS } from '../../utils/dateFnsLocaleUtils';

const DateRangePicker = props => {
    return (
        <BlueprintDateRangePicker
            locale={DEFAULT_LOCALE_CODE}
            localeUtils={DATE_FNS_LOCALE_UTILS}
            {...props}
        />
    );
};

export default DateRangePicker;
