// TODO: Cover with tests
// Actions
export const FETCH = 'ENERGY_WAREHOUSES/FETCH';
export const FETCH_STARTED = 'ENERGY_WAREHOUSES/FETCH_STARTED';
export const RECEIVED = 'ENERGY_WAREHOUSES/RECEIVED';
export const ERRORED = 'ENERGY_WAREHOUSES/ERRORED';

// Initial state for reducer
const initialState = {
    loading: false,
    response: null,
    error: null,
};

// Reducer(s)
export default function energyWarehousesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_STARTED:
            return {
                ...state,
                loading: true,
            };
        case RECEIVED:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case ERRORED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchEnergyWarehouses = query => ({
    type: FETCH,
    query,
});
