import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { floatformat, big } from '../../utils/formatting';

const NumberCell = ({
    value,
    decimalPlaces,
    mute,
    className,
    unit,
    colSpan,
}) => {
    const isZero = value === null || big(value).eq(0);
    return (
        <td
            className={classNames('text-nowrap', className, {
                'text-muted': isZero && mute,
            })}
            colSpan={colSpan}
        >
            {value === null ? '-' : floatformat(value, decimalPlaces)}
            {unit ? ` ${unit}` : null}
        </td>
    );
};

NumberCell.propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    decimalPlaces: PropTypes.number,
    mute: PropTypes.bool,
    unit: PropTypes.string,
    colSpan: PropTypes.number,
};

NumberCell.defaultProps = {
    value: '0',
    className: undefined,
    decimalPlaces: 3,
    mute: true,
    unit: '',
    colSpan: undefined,
};

export default NumberCell;
