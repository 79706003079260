import React from 'react';
import { HTMLTable } from '@blueprintjs/core';

import { ForestEstimateDetailsShape } from '../../shapes/forestEstimates';
import { pgettext } from '../../utils/text';
import NumberCell from '../ForestEstimateAssessment/NumberCell';

const ForestEstimateAnalysisTimberWarehousePriceTable = ({
    forestEstimate,
}) => (
    <HTMLTable className="w-100 editable-table pt-5 pb-5">
        <thead>
            <tr>
                <th rowSpan={2}>
                    {pgettext(
                        'Forest estimate analysis',
                        'Timber warehouse price',
                    )}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <th
                        key={specie.name}
                        colSpan={3}
                        className="border-right text-center"
                    >
                        {specie.title}
                    </th>
                ))}
                <th className="border-right" rowSpan={2}>
                    {pgettext('Forest estimate analysis', 'Firewood')}
                </th>
            </tr>
            <tr>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <th className="border-right">
                            {pgettext('Wood type', 'Thick')}
                        </th>
                        <th className="border-right">
                            {pgettext('Wood type', 'Thin')}
                        </th>
                        <th className="border-right">
                            {pgettext('Wood type', 'Pulp')}
                        </th>
                    </React.Fragment>
                ))}
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>
                    {pgettext(
                        'Forest estimate analysis timber warehouse',
                        'Forecast',
                    )}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <NumberCell
                            value={specie.thick_wood_landing_area_price}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.thin_wood_landing_area_price}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={specie.pulp_wood_landing_area_price}
                            decimalPlaces={2}
                            className="border-right"
                        />
                    </React.Fragment>
                ))}
                <NumberCell
                    value={forestEstimate.fire_wood_landing_area_price}
                    decimalPlaces={2}
                />
            </tr>
            <tr>
                <th>
                    {pgettext(
                        'Forest estimate analysis timber warehouse',
                        'Realization',
                    )}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <NumberCell
                            value={
                                specie.thick_wood_realization_landing_area_price
                            }
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={
                                specie.thin_wood_realization_landing_area_price
                            }
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={
                                specie.pulp_wood_realization_landing_area_price
                            }
                            decimalPlaces={2}
                            className="border-right"
                        />
                    </React.Fragment>
                ))}
                <NumberCell
                    value={
                        forestEstimate.fire_wood_realization_landing_area_price
                    }
                    decimalPlaces={2}
                />
            </tr>
            <tr>
                <th>
                    {pgettext(
                        'Forest estimate analysis timber warehouse',
                        'Difference in €',
                    )}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <React.Fragment key={specie.name}>
                            <NumberCell
                                value={
                                    specie.thick_wood_realization_landing_area_price_difference
                                }
                                decimalPlaces={2}
                                className="border-right"
                            />
                            <NumberCell
                                value={
                                    specie.thin_wood_realization_landing_area_price_difference
                                }
                                decimalPlaces={2}
                                className="border-right"
                            />
                            <NumberCell
                                value={
                                    specie.pulp_wood_realization_landing_area_price_difference
                                }
                                decimalPlaces={2}
                                className="border-right"
                            />
                        </React.Fragment>
                    </React.Fragment>
                ))}
                <NumberCell
                    value={
                        forestEstimate.fire_wood_realization_landing_area_price_difference
                    }
                    decimalPlaces={2}
                />
            </tr>
            <tr>
                <th>
                    {pgettext(
                        'Forest estimate analysis timber warehouse',
                        'Diference in percent',
                    )}
                </th>
                {forestEstimate.felling_tree_species.map(specie => (
                    <React.Fragment key={specie.name}>
                        <React.Fragment key={specie.name}>
                            <NumberCell
                                value={
                                    specie.thick_wood_realization_landing_area_price_ratio
                                }
                                decimalPlaces={2}
                                className="border-right"
                            />
                            <NumberCell
                                value={
                                    specie.thin_wood_realization_landing_area_price_ratio
                                }
                                decimalPlaces={2}
                                className="border-right"
                            />
                            <NumberCell
                                value={
                                    specie.pulp_wood_realization_landing_area_price_ratio
                                }
                                decimalPlaces={2}
                                className="border-right"
                            />
                        </React.Fragment>
                    </React.Fragment>
                ))}
                <NumberCell
                    value={
                        forestEstimate.fire_wood_realization_landing_area_price_ratio
                    }
                    decimalPlaces={2}
                />
            </tr>
        </tbody>
    </HTMLTable>
);

ForestEstimateAnalysisTimberWarehousePriceTable.propTypes = {
    forestEstimate: ForestEstimateDetailsShape.isRequired,
};

export default ForestEstimateAnalysisTimberWarehousePriceTable;
