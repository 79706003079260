import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form, getIn } from 'formik';
import {
    Button,
    Callout,
    Card,
    Elevation,
    Intent,
    NumericInput,
    Position,
    TextArea,
    Checkbox,
} from '@blueprintjs/core';
import { gettext } from 'utils/text';
import {
    fromISODateString,
    toISODateString,
    toLocaleDateString,
} from 'utils/date';
import { reverseUrl } from 'utils/urls';
import { getFieldErrors } from 'utils/formErrors';
import { returnFirstArg } from 'utils/functional';
import { getFormPropTypes } from 'shapes/formik';
import FormField from 'forms/fields/FormField';
import DateInput from 'components/DateInput';
import CustomSelect from '../components/CustomSelect';
import { ForestEstimateDetailsShape } from '../shapes/forestEstimates';
import ForestEstimateHeader from '../components/ForestEstimateHeader';
import ForestEstimateNavigation from '../components/ForestEstimateNavigation';

const ForestEstimate = ({
    isCreationForm,
    forestEstimate,
    handleSubmit,
    setFieldValue,
    errors,
    analyzerChoices,
}) => {
    const nonFieldErrors = getIn(errors, 'nonFieldErrors', null);

    const isAnalyzerChoiceDisabled = !DJ_CONST.user.is_superuser;

    const getAnalyzerDefaultValue = () => {
        if (isCreationForm && !DJ_CONST.user.is_superuser) {
            return DJ_CONST.user.id;
        }

        return forestEstimate?.analyzer ? forestEstimate.analyzer : null;
    };

    return (
        <Form onSubmit={handleSubmit}>
            <div className="row my-4">
                <div className="col col-auto">
                    <ForestEstimateHeader forestEstimate={forestEstimate} />
                </div>
                <div className="col col-auto ml-auto">
                    {isCreationForm ? null : (
                        <ForestEstimateNavigation
                            currentView="edit-estimate"
                            forestEstimate={forestEstimate}
                        />
                    )}
                </div>
            </div>

            {nonFieldErrors ? (
                <Callout intent={Intent.DANGER} className="mb-3">
                    {nonFieldErrors}
                </Callout>
            ) : null}

            <Card elevation={Elevation.TWO}>
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="bp3-heading mt-2 mb-3">
                            {gettext('General information about the property')}
                        </h4>
                        <FormField
                            name="cadaster_number"
                            label={gettext('Cadaster number')}
                            helperText={gettext(
                                'Update the property name and data from the land cadaster database',
                            )}
                        />
                        <FormField
                            name="property_name"
                            label={gettext('Property name')}
                        />
                        <h4 className="bp3-heading mt-2 mb-3">
                            {gettext('Property address')}
                        </h4>
                        <FormField name="village" label={gettext('Village')} />
                        <FormField
                            name="municipality"
                            label={gettext('Municipality')}
                        />
                        <FormField name="county" label={gettext('County')} />
                    </div>
                    <div className="col-md-6">
                        <h4 className="bp3-heading mt-2 mb-3">
                            {gettext('Property owner')}
                        </h4>
                        <FormField
                            name="owner_name"
                            label={gettext('Owner name')}
                        />
                        <FormField
                            name="owner_address"
                            label={gettext('Owner address')}
                        />
                        <FormField
                            name="owner_id_or_reg_code"
                            label={gettext("Owner's ID or registry code")}
                        />
                        <FormField
                            name="owner_phone"
                            label={gettext("Owner's phone number")}
                        />
                        <FormField
                            name="owner_email"
                            label={gettext("Owner's email address")}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="bp3-heading mt-2 mb-3">
                            {gettext('Other info')}
                        </h4>
                    </div>
                    <div className="col-md-12">
                        <FormField
                            name="notes"
                            label={gettext('Notes')}
                            inputComponent={TextArea}
                            className="w-100"
                            growVertically
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <FormField
                            name="is_archived"
                            label={gettext('Is archived')}
                            inputComponent={Checkbox}
                        />
                    </div>
                    <div className="col-md-3">
                        <FormField
                            name="analyzer"
                            label={gettext('Analyzer')}
                            inputComponent={CustomSelect}
                            formGroupClassName="overflow-hidden w-100"
                            initialValue={getAnalyzerDefaultValue()}
                            items={analyzerChoices}
                            onClear={() => setFieldValue('analyzer', null)}
                            getNextValue={returnFirstArg}
                            buttonPlaceholder={gettext('Select analyzer')}
                            buttonLeftIcon="person"
                            disabled={isAnalyzerChoiceDisabled}
                            fill
                            minimal
                        />
                    </div>
                    <div className="col-md-3">
                        <FormField
                            name="created_at"
                            inputComponent={DateInput}
                            formatDate={dateString =>
                                toLocaleDateString(dateString, 'P p')
                            }
                            label={gettext('Estimate created')}
                            disabled
                        />
                    </div>
                    <div className="col-md-3">
                        <FormField
                            name="date_of_analysis"
                            label={gettext('Date of analysis')}
                            inputComponent={DateInput}
                            fill
                            getNextValue={returnFirstArg}
                            popoverProps={{
                                position: Position.BOTTOM_LEFT,
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="bp3-heading mt-2 mb-3">
                            {gettext('Purchase information')}
                        </h4>
                    </div>
                    <div className="col-md-4">
                        <FormField
                            name="initial_cost_price"
                            label={gettext('Initial cost price')}
                            inputComponent={NumericInput}
                            leftIcon="euro"
                            placeholder="0.00"
                            min="0"
                            minorStepSize={0.01}
                            clampValueOnBlur
                            fill
                            onValueChange={(valueAsNumber, valueAsString) =>
                                setFieldValue(
                                    'initial_cost_price',
                                    valueAsString,
                                )
                            }
                        />
                    </div>
                    <div className="col-md-4">
                        <FormField
                            name="purchase_price"
                            label={gettext('Purchase price')}
                            inputComponent={NumericInput}
                            leftIcon="euro"
                            placeholder="0.00"
                            min="0"
                            minorStepSize={0.01}
                            clampValueOnBlur
                            fill
                            onValueChange={(valueAsNumber, valueAsString) =>
                                setFieldValue('purchase_price', valueAsString)
                            }
                        />
                    </div>
                    <div className="col-md-4">
                        <FormField
                            name="purchase_date"
                            label={gettext('Purchase date')}
                            inputComponent={DateInput}
                            fill
                            getNextValue={returnFirstArg}
                            popoverProps={{
                                position: Position.BOTTOM_LEFT,
                            }}
                        />
                    </div>
                </div>
            </Card>

            <div className="my-4">
                <Button type="submit" intent={Intent.PRIMARY} large>
                    {gettext('Save')}
                </Button>
            </div>
        </Form>
    );
};

ForestEstimate.propTypes = {
    isCreationForm: PropTypes.bool.isRequired,
    forestEstimate: PropTypes.oneOfType([
        ForestEstimateDetailsShape.isRequired,
        PropTypes.oneOf([null]),
    ]),
    ...getFormPropTypes([
        'notes',
        'date_of_analysis',
        'initial_cost_price',
        'truck_load_size_log',
        'truck_load_size_pulpwood',
        'truck_load_size_firewood',
        'purchase_date',
        'purchase_price',
        'extra_cost',
        'owner_of_logging_rights',
        'is_archived',
        'analyzer',
    ]),
    analyzerChoices: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
        }),
    ).isRequired,
};

ForestEstimate.defaultProps = {
    forestEstimate: null,
};

const ForestEstimateForm = withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    mapPropsToValues: ({ forestEstimate, isCreationForm }) => {
        if (isCreationForm) {
            return {
                is_archived: false,
                initial_cost_price: NumericInput.EMPTY_VALUE,
                purchase_price: NumericInput.EMPTY_VALUE,
                created_at: new Date(),
            };
        }

        return {
            ...forestEstimate,
            date_of_analysis: fromISODateString(
                forestEstimate.date_of_analysis,
            ),
            purchase_date: fromISODateString(forestEstimate.purchase_date),
            initial_cost_price: forestEstimate.initial_cost_price
                ? forestEstimate.initial_cost_price
                : NumericInput.EMPTY_VALUE,
            purchase_price: forestEstimate.purchase_price
                ? forestEstimate.purchase_price
                : NumericInput.EMPTY_VALUE,
            created_at: fromISODateString(forestEstimate.created_at),
        };
    },
    handleSubmit: (
        values,
        { props: { onFormSubmit }, setErrors, setSubmitting },
    ) => {
        const payload = {
            ...values,
            date_of_analysis: toISODateString(values.date_of_analysis, 'date'),
            purchase_date: toISODateString(values.purchase_date, 'date'),
            initial_cost_price:
                values.initial_cost_price === ''
                    ? null
                    : Number(values.initial_cost_price),
            purchase_price:
                values.purchase_price === ''
                    ? null
                    : Number(values.purchase_price),
            created_at: undefined,
        };

        onFormSubmit(
            payload,
            () => {
                setSubmitting(false);
                window.location = reverseUrl('forest_estimates:list');
            },
            error => {
                const fieldErrors = getFieldErrors(error);
                if (fieldErrors !== null) {
                    setErrors(fieldErrors);
                } else {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                setSubmitting(false);
            },
        );
    },
    displayName: 'ForestEstimateForm',
})(ForestEstimate);

export default ForestEstimateForm;
