import { call, put, spawn, takeLatest, takeLeading } from 'redux-saga/effects';

import api from '../api';
import {
    FETCH_FOREST_ESTIMATE,
    FETCH_FOREST_ESTIMATE_STARTED,
    FETCH_FOREST_ESTIMATE_SUCCESSFUL,
    FETCH_FOREST_ESTIMATE_FAILED,
} from '../ducks/forest_estimates/forestEstimateDetails';
import {
    UPDATE_FOREST_ESTIMATE,
    UPDATE_FOREST_ESTIMATE_STARTED,
    UPDATE_FOREST_ESTIMATE_SUCCESSFUL,
    UPDATE_FOREST_ESTIMATE_FAILED,
} from '../ducks/forest_estimates/forestEstimateUpdate';

function* fetchForestEstimate(action) {
    yield put({ type: FETCH_FOREST_ESTIMATE_STARTED });
    try {
        const response = yield call(api.forestEstimates.details.fetch, {
            id: action.forestEstimateId,
        });
        yield put({ type: FETCH_FOREST_ESTIMATE_SUCCESSFUL, response });
    } catch (error) {
        yield put({ type: FETCH_FOREST_ESTIMATE_FAILED, error });
    }
}

function* fetchForestEstimateSaga() {
    yield takeLatest(FETCH_FOREST_ESTIMATE, fetchForestEstimate);
}

function* updateForestEstimate(action) {
    yield put({ type: UPDATE_FOREST_ESTIMATE_STARTED });
    try {
        const response = yield call(
            api.forestEstimates.update.put,
            { id: action.payload.id },
            action.payload,
        );
        yield put({ type: UPDATE_FOREST_ESTIMATE_SUCCESSFUL, response });
        yield call(action.callback);
    } catch (error) {
        yield put({ type: UPDATE_FOREST_ESTIMATE_FAILED, error });
        yield call(action.errorCallback, error);
    }
}

function* updateForestEstimateSaga() {
    yield takeLeading(UPDATE_FOREST_ESTIMATE, updateForestEstimate);
}

export default function* fetchUpdateRootSaga() {
    yield spawn(fetchForestEstimateSaga);
    yield spawn(updateForestEstimateSaga);
}
