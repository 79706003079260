import { formatISO, parseISO, isValid, isDate } from 'date-fns';

import subMonths from 'date-fns/subMonths';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import subYears from 'date-fns/subYears';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import { et } from 'date-fns/locale';
import { formatDate } from './dateFnsLocaleUtils';

export const DEFAULT_LOCALE_CODE = et.code;

export const RANGE_LAST_TWO_MONTHS = 'RANGE_LAST_TWO_MONTHS';
export const RANGE_CURRENT_MONTH = 'RANGE_CURRENT_MONTH';
export const RANGE_CURRENT_YEAR = 'RANGE_CURRENT_YEAR';
export const RANGE_CURRENT_YEAR_BY_NOW = 'RANGE_CURRENT_YEAR_BY_NOW';

/* Format date to desired locale format */
export const toLocaleDateString = (
    date,
    dateFormat = 'P',
    locale = DEFAULT_LOCALE_CODE,
) => {
    if (!locale || !(isDate(date) && isValid(date))) {
        return null;
    }

    return formatDate(date, dateFormat, locale);
};

/**
 * Format date to ISO8601 format
 * Representation can be set to format date, time, or both. Valid values: 'complete' | 'date' | 'time'
 */
export const toISODateString = (date, representation = 'complete') => {
    if (!(isDate(date) && isValid(date))) {
        return null;
    }

    return formatISO(date, { representation });
};

/**
 * Parses a date string representing a simplification of the ISO 8601 calendar date extended format.
 * Return null if the string is invalid
 */
export const fromISODateString = dateString => {
    const date = parseISO(dateString);
    if (isValid(date)) {
        return date;
    }

    return null;
};

/**
 * Create and return date range by provided utility methods.
 * Format: [dateAfter, dateBefore]
 */
export const makeDateRange = (
    amount,
    subDate,
    getRangeStart,
    getRangeEnd,
    options = {},
) => {
    const combinedOptions = {
        weekStartsOn: 1, // Monday
        ...options,
    };
    const today = Date.now();

    if (amount === 0) {
        return [
            getRangeStart(today, combinedOptions),
            getRangeEnd(today, combinedOptions),
        ];
    }

    const fromDate = subDate(today, amount);
    const toDate = amount === 1 ? fromDate : subDate(today, 1);

    return [
        getRangeStart(fromDate, combinedOptions),
        getRangeEnd(toDate, combinedOptions),
    ];
};

/**
 * Return current month or year as the default date range for the site filter
 * Returns either Date() object or ISO8601 date string (based on representation) array.
 * Format: [dateAfter, dateBefore]
 */
export const getDefaultDateRange = (
    range,
    isDateString = false,
    representation = 'complete',
) => {
    let firstDay = null;
    let lastDay = null;

    if (range === RANGE_LAST_TWO_MONTHS) {
        [firstDay, lastDay] = makeDateRange(
            2,
            subMonths,
            startOfMonth,
            endOfMonth,
        );
    } else if (range === RANGE_CURRENT_MONTH) {
        [firstDay, lastDay] = makeDateRange(
            0,
            subMonths,
            startOfMonth,
            endOfMonth,
        );
    } else if (range === RANGE_CURRENT_YEAR) {
        [firstDay, lastDay] = makeDateRange(
            0,
            subYears,
            startOfYear,
            endOfYear,
        );
    } else {
        throw new Error('Invalid range option.');
    }

    if (isDateString) {
        return [
            toISODateString(firstDay, representation),
            toISODateString(lastDay, representation),
        ];
    }
    return [firstDay, lastDay];
};
