import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import PageHeader from './PageHeader';
import ToastContainer from './ToastContainer';
import { DJConstProvider } from '../utils/DJConstContext';

export const ViewBase = ({ store, djConst, headerText, children }) => (
    <DJConstProvider djConst={djConst}>
        <Provider store={store}>
            {headerText !== undefined && <PageHeader>{headerText}</PageHeader>}
            {children}
            <ToastContainer />
        </Provider>
    </DJConstProvider>
);

ViewBase.propTypes = {
    store: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    djConst: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    headerText: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

ViewBase.defaultProps = {
    headerText: undefined,
};
