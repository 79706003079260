import React from 'react';
import PropTypes from 'prop-types';
import { HTMLTable, Spinner, H3 } from '@blueprintjs/core';
import { floatformat } from '../../utils/formatting';
import { RTKApi } from '../../queries';
import { gettext, pgettext } from '../../utils/text';
import { formatDate } from '../../utils/dateFnsLocaleUtils';
import ForestEstimateHeader from '../ForestEstimateHeader';
import EditableCell from './EditableCell';
import EstimateTableSpacer from './EstimateTableSpacer';
import NumberCell from './NumberCell';
import ForestEstimateNavigation from '../ForestEstimateNavigation';

const ForestEstimateAssessment = ({ forestEstimateId }) => {
    const { useGetForestEstimateAssessmentQuery } = RTKApi;

    const {
        data: forestEstimate,
        isSuccess: isSuccessForestEstimate,
    } = useGetForestEstimateAssessmentQuery(forestEstimateId, {
        skip: !forestEstimateId,
    });

    return isSuccessForestEstimate ? (
        <div className="container-fluid mb-4">
            <div className="float-right">
                <ForestEstimateNavigation
                    currentView="assessment"
                    forestEstimate={forestEstimate}
                />
            </div>
            <div style={{ paddingLeft: 11 }}>
                <ForestEstimateHeader forestEstimate={forestEstimate} />
            </div>
            <HTMLTable
                striped
                className="pl-3 float-right"
                style={{ width: '30%' }}
            >
                <thead>
                    <tr>
                        <th colSpan={2}>
                            <H3>
                                {pgettext(
                                    'Forest estimate assessment table header',
                                    'Property owner',
                                )}
                            </H3>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment property owner table',
                                'Property owner',
                            )}
                        </th>
                        <td>{forestEstimate.owner_name || '-'}</td>
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment property owner table',
                                'Address',
                            )}
                        </th>
                        <td>{forestEstimate.owner_address || '-'}</td>
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment property owner table',
                                'Code',
                            )}
                        </th>
                        <td>{forestEstimate.owner_id_or_reg_code || '-'}</td>
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment property owner table',
                                'Phone',
                            )}
                        </th>
                        <td>
                            {(
                                <a href={`tel:${forestEstimate.owner_phone}`}>
                                    {forestEstimate.owner_phone}
                                </a>
                            ) || '-'}
                        </td>
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment property owner table',
                                'Email',
                            )}
                        </th>
                        <td>
                            {(
                                <a
                                    href={`mailto:${forestEstimate.owner_email}`}
                                >
                                    {forestEstimate.owner_email}
                                </a>
                            ) || '-'}
                        </td>
                    </tr>
                </tbody>
            </HTMLTable>
            <HTMLTable
                striped
                className="pl-3 float-right"
                style={{ width: '30%' }}
            >
                <thead>
                    <tr>
                        <th colSpan={2}>
                            <H3>
                                {pgettext(
                                    'Forest estimate assessment table header',
                                    'Estimate',
                                )}
                            </H3>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment property owner table',
                                'Analyzer name',
                            )}
                        </th>
                        <td>{forestEstimate.analyzer_name || '-'}</td>
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment property owner table',
                                'Analyzer email',
                            )}
                        </th>
                        <td>
                            {(
                                <a
                                    href={`mailto:${forestEstimate.analyzer_email}`}
                                >
                                    {forestEstimate.analyzer_email}
                                </a>
                            ) || '-'}
                        </td>
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment property owner table',
                                'Date',
                            )}
                        </th>
                        <td>
                            {forestEstimate.created_at
                                ? formatDate(
                                      new Date(forestEstimate.created_at),
                                  )
                                : ''}
                        </td>
                    </tr>
                </tbody>
            </HTMLTable>
            <HTMLTable striped className="pb-3" style={{ width: '40%' }}>
                <thead>
                    <tr>
                        <th colSpan={2}>
                            <H3>
                                {pgettext(
                                    'Forest estimate assessment table header',
                                    'Property',
                                )}
                            </H3>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table header',
                                'Cadaster number',
                            )}
                        </th>
                        <td>{forestEstimate.cadaster_number || '-'}</td>
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table header',
                                'Village / County / Parish',
                            )}
                        </th>
                        <td>
                            {forestEstimate.village || '-'} /{' '}
                            {forestEstimate.municipality || '-'} /{' '}
                            {forestEstimate.county || '-'}
                        </td>
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table header',
                                'Property name',
                            )}
                        </th>
                        <td>{forestEstimate.property_name || '-'}</td>
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table header',
                                'Felling area',
                            )}
                        </th>
                        <td>{forestEstimate.quarter_and_provision_title}</td>
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table header',
                                'Felling type',
                            )}
                        </th>
                        <td>{forestEstimate.felling_type_title}</td>
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table header',
                                'Total area',
                            )}
                        </th>
                        <td>
                            {forestEstimate.total_area} {gettext('ha')}
                        </td>
                    </tr>
                </tbody>
            </HTMLTable>
            <HTMLTable striped className="w-50 float-right pl-3 editable-table">
                <thead>
                    <tr>
                        <th
                            colSpan={
                                forestEstimate.felling_tree_species.length + 2
                            }
                        >
                            <H3>
                                {pgettext(
                                    'Forest estimate assessment table header',
                                    'Logs per specie',
                                )}
                            </H3>
                        </th>
                    </tr>
                    <tr>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <th key={specie.name}>{specie.title}</th>
                        ))}
                        <th className="align-bottom">
                            {pgettext(
                                'Forest estimate assessment table header',
                                'Total logs share',
                            )}
                        </th>
                        <th className="align-bottom">
                            {pgettext(
                                'Forest estimate assessment table header',
                                'Total firewood share',
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <NumberCell
                                key={specie.name}
                                value={specie.logs_share}
                                decimalPlaces={2}
                                unit="%"
                            />
                        ))}
                        <NumberCell
                            value={forestEstimate.logs_share}
                            decimalPlaces={2}
                            unit="%"
                        />
                        <NumberCell
                            value={forestEstimate.fire_wood_share}
                            decimalPlaces={2}
                            unit="%"
                        />
                    </tr>
                </tbody>
            </HTMLTable>
            <HTMLTable striped className="w-50 editable-table">
                <thead>
                    <tr>
                        <th
                            colSpan={
                                forestEstimate.felling_tree_species.length + 1
                            }
                        >
                            <H3>
                                {pgettext(
                                    'Forest estimate assessment table header',
                                    'Amounts summary',
                                )}
                            </H3>
                        </th>
                    </tr>
                    <tr>
                        <th>&nbsp;</th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <th key={specie.name}>{specie.title}</th>
                        ))}
                        <th>{gettext('Total')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table summary',
                                'Bruto amount',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <td key={specie.name}>
                                {floatformat(specie.bruto_amount, 3)}
                            </td>
                        ))}
                        <td className="font-weight-bold">
                            {floatformat(forestEstimate.bruto_amount, 3)}
                        </td>
                    </tr>
                    <tr>
                        <th
                            className="text-right"
                            colSpan={
                                forestEstimate.felling_tree_species.length + 1
                            }
                        >
                            {pgettext(
                                'Forest estimate assessment table summary',
                                'Exit',
                            )}
                            %
                        </th>
                        <td className="font-weight-bold">
                            {floatformat(forestEstimate.percentage_of_exit, 2)}
                        </td>
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table summary',
                                'Neto amount',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <td key={specie.name}>
                                {floatformat(specie.neto_amount, 3)}
                            </td>
                        ))}
                        <td className="font-weight-bold">
                            {floatformat(forestEstimate.neto_amount, 3)}
                        </td>
                    </tr>
                </tbody>
            </HTMLTable>
            <H3 style={{ clear: 'both', paddingLeft: 11 }} className="mt-5">
                {pgettext(
                    'Forest estimate assessment table header',
                    'Expenses and revenue',
                )}
            </H3>
            <HTMLTable striped className="w-100 editable-table mb-5">
                <thead>
                    <tr>
                        <th className="border-right">&nbsp;</th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <th
                                key={specie.name}
                                colSpan={4}
                                className="border-right text-center"
                            >
                                {specie.title}
                            </th>
                        ))}
                        <th className="border-right">&nbsp;</th>
                        <th className="border-right">&nbsp;</th>
                        <th className="border-right">&nbsp;</th>
                        <th>{gettext('Total')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Firewood',
                            )}{' '}
                            %
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <>
                                <NumberCell
                                    key={specie.name}
                                    value={specie.fire_wood_share}
                                    decimalPlaces={2}
                                    colSpan={3}
                                    className="border-right text-center"
                                />
                                <td className="border-right">&nbsp;</td>
                            </>
                        ))}
                        <td className="border-right">&nbsp;</td>
                        <td className="border-right">&nbsp;</td>
                        <td className="border-right">&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Assortments',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <th className="border-right">
                                    {pgettext('Wood type', 'Thick (tm)')}
                                </th>
                                <th className="border-right">
                                    {pgettext('Wood type', 'Thin (tm)')}
                                </th>
                                <th className="border-right">
                                    {pgettext('Wood type', 'Pulp (tm)')}
                                </th>
                                <th className="border-right">
                                    {pgettext('Wood type', 'Residues (m3)')}
                                </th>
                            </React.Fragment>
                        ))}
                        <th className="border-right">
                            {gettext('Firewood (tm)')}
                        </th>
                        <th className="border-right">
                            {gettext('Stumps (m3)')}
                        </th>
                        <th className="border-right">
                            {gettext('Energy bushes (m3)')}
                        </th>
                        <th>&nbsp;</th>
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Share',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey={[
                                        'thick_wood_share',
                                        'thin_wood_share',
                                        'pulp_wood_share',
                                    ]}
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />
                                <td className="border-right">&nbsp;</td>
                            </React.Fragment>
                        ))}
                        <td className="border-right">&nbsp;</td>
                        <td className="border-right">&nbsp;</td>
                        <td className="border-right">&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Amount',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <NumberCell
                                    value={specie.thick_wood_amount}
                                    decimalPlaces={3}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.thin_wood_amount}
                                    decimalPlaces={3}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.pulp_wood_amount}
                                    decimalPlaces={3}
                                    className="border-right"
                                />
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="residues_quantity"
                                    specieName={specie.name}
                                    decimalPlaces={3}
                                />
                            </React.Fragment>
                        ))}
                        <NumberCell
                            value={forestEstimate.fire_wood_amount}
                            className="border-right"
                            decimalPlaces={3}
                        />
                        <NumberCell
                            value={forestEstimate.total_stump_quantity}
                            className="border-right"
                            decimalPlaces={3}
                        />
                        <NumberCell
                            value={forestEstimate.total_energy_bush_quantity}
                            className="border-right"
                            decimalPlaces={3}
                        />
                        <td>
                            <div>
                                {floatformat(forestEstimate.neto_amount, 3)} tm
                            </div>
                            <div>
                                {floatformat(forestEstimate.neto_amount_m3, 3)}{' '}
                                m3
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Harvesting cost (per unit)',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <NumberCell
                                    value={
                                        forestEstimate.round_material_harvesting_average_cost
                                    }
                                    decimalPlaces={2}
                                    className="border-right"
                                    colSpan={3}
                                />
                                <td className="border-right">-</td>
                            </React.Fragment>
                        ))}
                        <NumberCell
                            value={
                                forestEstimate.round_material_harvesting_average_cost
                            }
                            className="border-right"
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={forestEstimate.stump_harvesting_average_cost}
                            className="border-right"
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={
                                forestEstimate.energy_bush_harvesting_average_cost
                            }
                            className="border-right"
                            decimalPlaces={2}
                        />
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Transport cost (per unit)',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <NumberCell
                                    value={
                                        forestEstimate.round_material_transport_average_cost
                                    }
                                    decimalPlaces={2}
                                    className="border-right"
                                    colSpan={3}
                                />
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="residues_transport_cost"
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />
                            </React.Fragment>
                        ))}
                        <NumberCell
                            value={
                                forestEstimate.round_material_transport_average_cost
                            }
                            className="border-right"
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={forestEstimate.stump_transport_average_cost}
                            className="border-right"
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={
                                forestEstimate.energy_bush_transport_average_cost
                            }
                            className="border-right"
                            decimalPlaces={2}
                        />
                        <td>&nbsp;</td>
                    </tr>
                    <EstimateTableSpacer
                        species={forestEstimate.felling_tree_species}
                    />
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Sales point',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="thick_wood_sales_point_name"
                                    specieName={specie.name}
                                />
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="thin_wood_sales_point_name"
                                    specieName={specie.name}
                                />
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="pulp_wood_sales_point_name"
                                    specieName={specie.name}
                                />
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="residues_sales_point_name"
                                    specieName={specie.name}
                                />
                            </React.Fragment>
                        ))}
                        <EditableCell
                            className="border-right"
                            forestEstimateId={forestEstimateId}
                            valueKey="fire_wood_sales_point_name"
                            isEstimateField
                        />
                        <EditableCell
                            className="border-right"
                            forestEstimateId={forestEstimateId}
                            valueKey="stumps_sales_point_name"
                            isEstimateField
                        />
                        <EditableCell
                            className="border-right"
                            forestEstimateId={forestEstimateId}
                            valueKey="energy_bushes_sales_point_name"
                            isEstimateField
                        />
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Transport distance',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="thick_wood_transport_distance"
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="thin_wood_transport_distance"
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="pulp_wood_transport_distance"
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="residues_transport_distance"
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />
                            </React.Fragment>
                        ))}
                        <EditableCell
                            className="border-right"
                            forestEstimateId={forestEstimateId}
                            valueKey="fire_wood_transport_distance"
                            isEstimateField
                            decimalPlaces={2}
                        />
                        <EditableCell
                            className="border-right"
                            forestEstimateId={forestEstimateId}
                            valueKey="stumps_transport_distance"
                            isEstimateField
                            decimalPlaces={2}
                        />
                        <EditableCell
                            className="border-right"
                            forestEstimateId={forestEstimateId}
                            valueKey="energy_bushes_transport_distance"
                            isEstimateField
                            decimalPlaces={2}
                        />
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Price',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="thick_wood_price"
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />

                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="thin_wood_price"
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="pulp_wood_price"
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="residues_price"
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />
                            </React.Fragment>
                        ))}
                        <EditableCell
                            className="border-right"
                            forestEstimateId={forestEstimateId}
                            valueKey="fire_wood_price"
                            isEstimateField
                            decimalPlaces={2}
                        />
                        <EditableCell
                            className="border-right"
                            forestEstimateId={forestEstimateId}
                            valueKey="stumps_price"
                            isEstimateField
                            decimalPlaces={2}
                        />
                        <EditableCell
                            className="border-right"
                            forestEstimateId={forestEstimateId}
                            valueKey="energy_bushes_price"
                            isEstimateField
                            decimalPlaces={2}
                        />
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Expected revenue',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <NumberCell
                                    value={specie.thick_wood_expected_revenue}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.thin_wood_expected_revenue}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.pulp_wood_expected_revenue}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.residues_expected_revenue}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                            </React.Fragment>
                        ))}
                        <NumberCell
                            value={forestEstimate.fire_wood_expected_revenue}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.stumps_expected_revenue}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={
                                forestEstimate.energy_bushes_expected_revenue
                            }
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.expected_revenue}
                            decimalPlaces={2}
                        />
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Growing forest price',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <NumberCell
                                    value={
                                        specie.thick_wood_growing_forest_price
                                    }
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={
                                        specie.thin_wood_growing_forest_price
                                    }
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={
                                        specie.pulp_wood_growing_forest_price
                                    }
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.residues_growing_forest_price}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                            </React.Fragment>
                        ))}
                        <NumberCell
                            value={
                                forestEstimate.fire_wood_growing_forest_price
                            }
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.stumps_growing_forest_price}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={
                                forestEstimate.energy_bushes_growing_forest_price
                            }
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Landing area price',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <NumberCell
                                    value={specie.thick_wood_landing_area_price}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.thin_wood_landing_area_price}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.pulp_wood_landing_area_price}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.residues_landing_area_price}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                            </React.Fragment>
                        ))}
                        <NumberCell
                            value={forestEstimate.fire_wood_landing_area_price}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.stumps_landing_area_price}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={
                                forestEstimate.energy_bushes_landing_area_price
                            }
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <td>&nbsp;</td>
                    </tr>
                    <EstimateTableSpacer
                        species={forestEstimate.felling_tree_species}
                    />
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Number of loads',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <NumberCell
                                    value={specie.thick_wood_number_of_loads}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.thin_wood_number_of_loads}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.pulp_wood_number_of_loads}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.residues_number_of_loads}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                            </React.Fragment>
                        ))}
                        <NumberCell
                            value={forestEstimate.fire_wood_number_of_loads}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.stumps_number_of_loads}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.energy_bushes_number_of_loads}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.total_number_of_loads}
                            decimalPlaces={0}
                        />
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Shipping rate',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <NumberCell
                                    value={specie.thick_wood_shipping_rate}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.thin_wood_shipping_rate}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.pulp_wood_shipping_rate}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.residues_shipping_rate}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                            </React.Fragment>
                        ))}
                        <NumberCell
                            value={forestEstimate.fire_wood_shipping_rate}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.stumps_shipping_rate}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.energy_bushes_shipping_rate}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Truck shipping total cost',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <NumberCell
                                    value={
                                        specie.thick_wood_shipping_total_cost
                                    }
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.thin_wood_shipping_total_cost}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.pulp_wood_shipping_total_cost}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.residues_shipping_total_cost}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                            </React.Fragment>
                        ))}
                        <NumberCell
                            value={forestEstimate.fire_wood_shipping_total_cost}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.stumps_shipping_total_cost}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={
                                forestEstimate.energy_bushes_shipping_total_cost
                            }
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.shipping_total_cost}
                            decimalPlaces={2}
                        />
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Railway price',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="thick_wood_railway_cost"
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="thin_wood_railway_cost"
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="pulp_wood_railway_cost"
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />
                                <EditableCell
                                    className="border-right"
                                    forestEstimateId={forestEstimateId}
                                    valueKey="residues_railway_cost"
                                    specieName={specie.name}
                                    decimalPlaces={2}
                                />
                            </React.Fragment>
                        ))}
                        <EditableCell
                            className="border-right"
                            forestEstimateId={forestEstimateId}
                            valueKey="fire_wood_railway_cost"
                            isEstimateField
                            decimalPlaces={2}
                        />
                        <EditableCell
                            className="border-right"
                            forestEstimateId={forestEstimateId}
                            valueKey="stumps_railway_cost"
                            isEstimateField
                            decimalPlaces={2}
                        />
                        <EditableCell
                            className="border-right"
                            forestEstimateId={forestEstimateId}
                            valueKey="energy_bushes_railway_cost"
                            isEstimateField
                            decimalPlaces={2}
                        />
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Railway total cost',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <NumberCell
                                    value={specie.thick_wood_railway_total_cost}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.thin_wood_railway_total_cost}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.pulp_wood_railway_total_cost}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.residues_railway_total_cost}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                            </React.Fragment>
                        ))}
                        <NumberCell
                            value={forestEstimate.fire_wood_railway_total_cost}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.stumps_railway_total_cost}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={
                                forestEstimate.energy_bushes_railway_total_cost
                            }
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.railway_total_cost}
                            decimalPlaces={2}
                        />
                    </tr>
                    <tr>
                        <th className="border-right text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Estimated total cost',
                            )}
                        </th>
                        {forestEstimate.felling_tree_species.map(specie => (
                            <React.Fragment key={specie.name}>
                                <NumberCell
                                    value={specie.thick_wood_estimated_cost}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.thin_wood_estimated_cost}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.pulp_wood_estimated_cost}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                                <NumberCell
                                    value={specie.residues_estimated_cost}
                                    decimalPlaces={2}
                                    className="border-right"
                                />
                            </React.Fragment>
                        ))}
                        <NumberCell
                            value={forestEstimate.fire_wood_estimated_cost}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.stumps_estimated_cost}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.energy_bushes_estimated_cost}
                            decimalPlaces={2}
                            className="border-right"
                        />
                        <NumberCell
                            value={forestEstimate.estimated_cost}
                            decimalPlaces={2}
                        />
                    </tr>
                </tbody>
            </HTMLTable>

            <HTMLTable striped className="w-25 float-right pl-3 editable-table">
                <thead>
                    <tr>
                        <td>&nbsp;</td>
                        <th>{gettext('€ / unit')}</th>
                        <th>€</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Harvesting cost (tm)',
                            )}
                        </th>
                        <NumberCell
                            value={
                                forestEstimate.round_material_harvesting_average_cost
                            }
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={
                                forestEstimate.round_material_harvesting_total_cost
                            }
                            decimalPlaces={2}
                        />
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Transport cost (tm)',
                            )}
                        </th>
                        <NumberCell
                            value={
                                forestEstimate.round_material_transport_average_cost
                            }
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={
                                forestEstimate.round_material_transport_total_cost
                            }
                            decimalPlaces={2}
                        />
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Truck shipping cost',
                            )}
                        </th>
                        <NumberCell
                            value={forestEstimate.shipping_average_cost}
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={forestEstimate.shipping_total_cost}
                            decimalPlaces={2}
                        />
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Railway cost',
                            )}
                        </th>
                        <NumberCell
                            value={forestEstimate.railway_average_cost}
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={forestEstimate.railway_total_cost}
                            decimalPlaces={2}
                        />
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Extra cost',
                            )}
                        </th>
                        <EditableCell
                            forestEstimateId={forestEstimateId}
                            valueKey="extra_cost"
                            isEstimateField
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={forestEstimate.total_extra_cost}
                            decimalPlaces={2}
                        />
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Total costs',
                            )}
                        </th>
                        <NumberCell
                            value={forestEstimate.estimated_average_cost}
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={forestEstimate.estimated_cost}
                            decimalPlaces={2}
                        />
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Total revenue',
                            )}
                        </th>
                        <NumberCell
                            value={forestEstimate.expected_average_revenue}
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={forestEstimate.expected_revenue}
                            decimalPlaces={2}
                        />
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table',
                                'Cost and revenue difference',
                            )}
                        </th>
                        <NumberCell
                            value={forestEstimate.expected_average_profit}
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={forestEstimate.maximum_price_without_tax}
                            decimalPlaces={2}
                        />
                    </tr>
                </tbody>
            </HTMLTable>

            <HTMLTable striped className="w-25 pl-3 float-right editable-table">
                <thead>
                    <tr>
                        <th>
                            {pgettext(
                                'Forest estimate assessment table',
                                'Truck load sizes',
                            )}
                        </th>
                        <th>{gettext('Amount')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table summary truck load size label',
                                'Logs',
                            )}
                        </th>
                        <EditableCell
                            forestEstimateId={forestEstimateId}
                            valueKey="truck_load_size_log"
                            isEstimateField
                        />
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table summary truck load size label',
                                'Pulp',
                            )}
                        </th>
                        <EditableCell
                            forestEstimateId={forestEstimateId}
                            valueKey="truck_load_size_pulpwood"
                            isEstimateField
                        />
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table summary truck load size label',
                                'Fire',
                            )}
                        </th>
                        <EditableCell
                            forestEstimateId={forestEstimateId}
                            valueKey="truck_load_size_firewood"
                            isEstimateField
                        />
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table summary truck load size label',
                                'Residues',
                            )}
                        </th>
                        <EditableCell
                            forestEstimateId={forestEstimateId}
                            valueKey="truck_load_size_residues"
                            isEstimateField
                        />
                    </tr>
                </tbody>
            </HTMLTable>

            <HTMLTable striped className="w-25 float-right editable-table">
                <thead>
                    <tr>
                        <td>&nbsp;</td>
                        <th>€</th>
                        <th>{gettext('€ / tm')}</th>
                        <th>{gettext('€ / m3')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table summary',
                                'Maximum price without tax',
                            )}
                        </th>
                        <NumberCell
                            value={forestEstimate.maximum_price_without_tax}
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={forestEstimate.expected_average_tm_profit}
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={forestEstimate.expected_average_m3_profit}
                            decimalPlaces={2}
                        />
                    </tr>
                    <tr>
                        <th className="text-right">
                            {pgettext(
                                'Forest estimate assessment table summary',
                                'Maximum price with tax',
                            )}
                        </th>
                        <NumberCell
                            value={forestEstimate.maximum_price_with_tax}
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={
                                forestEstimate.expected_average_tm_profit_with_tax
                            }
                            decimalPlaces={2}
                        />
                        <NumberCell
                            value={
                                forestEstimate.expected_average_m3_profit_with_tax
                            }
                            decimalPlaces={2}
                        />
                    </tr>
                </tbody>
            </HTMLTable>

            <div className="w-25 pr-3" style={{ paddingLeft: 11 }}>
                <H3>
                    {' '}
                    {pgettext('Forest estimate assessment table', 'Notes')}
                </H3>
                <EditableCell
                    forestEstimateId={forestEstimateId}
                    valueKey="notes"
                    isEstimateField
                    Tag="div"
                    multiline
                />
            </div>
        </div>
    ) : (
        <Spinner className="mt-5" />
    );
};

ForestEstimateAssessment.propTypes = {
    forestEstimateId: PropTypes.string.isRequired,
};

export default ForestEstimateAssessment;
