import React from 'react';
import PropTypes from 'prop-types';

import { DateInput as BlueprintDateInput } from '@blueprintjs/datetime';

import {
    DEFAULT_LOCALE_CODE,
    fromISODateString,
    toLocaleDateString,
} from '../../utils/date';
import { DATE_FNS_LOCALE_UTILS } from '../../utils/dateFnsLocaleUtils';

const DateInput = props => {
    return (
        <BlueprintDateInput
            {...props}
            locale={DEFAULT_LOCALE_CODE}
            localeUtils={DATE_FNS_LOCALE_UTILS}
        />
    );
};

DateInput.propTypes = {
    formatDate: PropTypes.func,
    parseDate: PropTypes.func,
};

DateInput.defaultProps = {
    formatDate: date => toLocaleDateString(date),
    parseDate: dateString => fromISODateString(dateString),
};

export default DateInput;
