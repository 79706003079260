import PropTypes from 'prop-types';
import React from 'react';

import { WaybillFilterQueryShape } from 'shapes/waybills';
import { gettext } from '../../utils/text';
import { FILTERS } from '../../ducks/filters';
import TableEntityFilter from '../Table/TableEntityFilter';

const WaybillHasWeightFilter = ({ hasWeight, changeHasWeight, query }) => {
    return (
        <TableEntityFilter
            initialValue={hasWeight}
            onChange={changeHasWeight}
            itemsToOptions={item => ({
                text: item.title,
                value: item.value,
            })}
            name="unit_filter"
            buttonPlaceholder={gettext('Select weight filter')}
            buttonLeftIcon="cube"
            filterType={FILTERS.WAYBILL.HAS_WEIGHT}
            query={query}
        />
    );
};

WaybillHasWeightFilter.propTypes = {
    hasWeight: PropTypes.string.isRequired,
    changeHasWeight: PropTypes.func.isRequired,
    query: WaybillFilterQueryShape.isRequired,
};

export default WaybillHasWeightFilter;
