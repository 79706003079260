// Actions
export const CREATE_FELLING_ROW = 'FELLING_ROWS/CREATE_FELLING_ROW';
export const CREATE_FELLING_ROW_STARTED =
    'FELLING_ROWS/CREATE_FELLING_ROW_STARTED';
export const CREATE_FELLING_ROW_SUCCESSFUL =
    'FELLING_ROWS/CREATE_FELLING_ROW_SUCCESSFUL';
export const CREATE_FELLING_ROW_FAILED =
    'FELLING_ROWS/CREATE_FELLING_ROW_FAILED';

// Action creators
export const createFellingRow = (payload, callback, errorCallback) => ({
    type: CREATE_FELLING_ROW,
    payload,
    callback,
    errorCallback,
});
