// TODO: Cover with tests
import { call, delay, put, spawn, takeLatest } from 'redux-saga/effects';

import api from 'api';
import { formatError } from 'utils/errors';
import filterItemsSaga from './filters';
import { timberWarehouseListActions as actions } from '../slices/timberWarehouseList';

function* fetchTimberWarehouses(action) {
    yield delay(500);

    const query = action.payload;

    try {
        const response = yield call(
            api.stockManagement.timberWarehouseList.fetch,
            null,
            query,
        );
        yield put({ type: actions.timberWarehousesFetched.type, response });
    } catch (error) {
        yield put({
            type: actions.timberWarehousesError.type,
            error: formatError(error),
        });
    }
}

function* timberWarehouseListSaga() {
    yield takeLatest(actions.fetchTimberWarehouses.type, fetchTimberWarehouses);
}

export default function* timberWarehouseListRootSaga() {
    yield spawn(timberWarehouseListSaga);
    yield spawn(filterItemsSaga);
}
