import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ButtonGroup,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position,
} from '@blueprintjs/core';

import { gettext } from 'utils/text';
import { TableActionShape } from 'shapes/table';

export const TableAction = ({
    actions,
    selectedAction,
    changeAction,
    executeAction,
    hasSelectedEntities,
}) => {
    return (
        <ButtonGroup>
            <Popover position={Position.BOTTOM} className="mr-2">
                <Button
                    data-testid="select-table-action"
                    text={
                        selectedAction === null
                            ? gettext('Select action')
                            : selectedAction.title
                    }
                    icon={
                        selectedAction === null ? 'list' : selectedAction.icon
                    }
                />
                <Menu>
                    {actions.map(action => (
                        <MenuItem
                            data-testid={`table-action-${action.type}`}
                            key={action.type}
                            text={action.title}
                            icon={action.icon}
                            onClick={() => changeAction(action)}
                        />
                    ))}
                </Menu>
            </Popover>
            <Button
                data-testid="confirm-table-action"
                text={gettext('Confirm')}
                rightIcon="tick-circle"
                intent={Intent.PRIMARY}
                disabled={selectedAction === null || !hasSelectedEntities}
                onClick={executeAction}
            />
        </ButtonGroup>
    );
};

TableAction.defaultProps = {
    selectedAction: null,
};

TableAction.propTypes = {
    actions: PropTypes.arrayOf(TableActionShape).isRequired,
    selectedAction: TableActionShape,
    changeAction: PropTypes.func.isRequired,
    executeAction: PropTypes.func.isRequired,
    hasSelectedEntities: PropTypes.bool.isRequired,
};

export default TableAction;
