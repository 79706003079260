import PropTypes from 'prop-types';
import React from 'react';

import { WaybillFilterQueryShape } from 'shapes/waybills';
import { gettext } from '../../utils/text';
import { FILTERS } from '../../ducks/filters';
import TableEntityFilter from '../Table/TableEntityFilter';

const WaybillForestMasterFilter = ({
    forestMasterName,
    changeForestMasterName,
    query,
}) => {
    return (
        <TableEntityFilter
            initialValue={forestMasterName}
            onChange={changeForestMasterName}
            itemsToOptions={item => ({
                text: `${item.forest_master__name}`,
                value: item.forest_master__name,
            })}
            name="forest_master_filter"
            buttonPlaceholder={gettext('Select Forest master')}
            buttonLeftIcon="person"
            filterType={FILTERS.WAYBILL.FORESTMASTER}
            query={query}
        />
    );
};

WaybillForestMasterFilter.propTypes = {
    forestMasterName: PropTypes.string.isRequired,
    changeForestMasterName: PropTypes.func.isRequired,
    query: WaybillFilterQueryShape.isRequired,
};

export default WaybillForestMasterFilter;
