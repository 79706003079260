import { call, put, takeLeading } from 'redux-saga/effects';

import api from 'api';
import {
    UPDATE_PURCHASE_INVOICE_NR,
    UPDATE_PURCHASE_INVOICE_NR_STARTED,
    UPDATE_PURCHASE_INVOICE_NR_FAILED,
    UPDATE_PURCHASE_INVOICE_NR_SUCCESSFUL,
} from 'ducks/acts/purchaseInvoiceNumberUpdate';

function* updatePurchaseInvoiceNumber(action) {
    yield put({ type: UPDATE_PURCHASE_INVOICE_NR_STARTED });
    try {
        const response = yield call(
            api.acts.updatePurchaseInvoiceNumber.post,
            null,
            action.payload,
        );
        yield put({ type: UPDATE_PURCHASE_INVOICE_NR_SUCCESSFUL, response });
        yield call(action.callback);
    } catch (error) {
        yield put({ type: UPDATE_PURCHASE_INVOICE_NR_FAILED, error });
        yield call(action.errorCallback, error);
    }
}

export default function* purchaseInvoiceNumberUpdateSaga() {
    yield takeLeading(UPDATE_PURCHASE_INVOICE_NR, updatePurchaseInvoiceNumber);
}
