import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from '@blueprintjs/core';

import { formatError } from 'utils/errors';
import { gettext } from 'utils/text';
import { fetchFellingRow } from 'ducks/forest_estimates/fellingRowDetails';
import { updateFellingRow } from 'ducks/forest_estimates/fellingRowUpdate';
import { BackToFellingRowsNonIdealState } from 'components/NonIdealStates';
import ValidationErrorsList from 'components/ValidationErrorsList';
import FellingRowForm from 'forms/FellingRowForm';

import { fetchFellingRowFellingTypeChoices } from '../../ducks/forest_estimates/fellingRowFellingTypeChoices';
import { fetchFellingRowLocationChoices } from '../../ducks/forest_estimates/fellingRowLocationChoices';
import { fetchFellingRowTreeSpecieNameChoices } from '../../ducks/forest_estimates/fellingRowTreeSpecieNameChoices';
import { FellingRowDetailsShape } from '../../shapes/forestEstimates';

const FellingRowUpdate = props => {
    const {
        fellingRowError,
        fetchFellingRowDetails,
        fetchFellingRowFellingTypes,
        fetchFellingRowLocations,
        fetchFellingRowTreeSpecieNames,
        fellingRowId,
        fellingRow,
        loading,
        loadingFellingTypeChoices,
        loadingLocationChoices,
        loadingFellingRowTreeSpecieNameChoices,
        fellingRowFellingTypeChoices,
        fellingRowLocationChoices,
        fellingRowTreeSpecieNameChoices,
        onFormSubmit,
    } = props;

    useEffect(() => {
        fetchFellingRowDetails(fellingRowId);
        fetchFellingRowFellingTypes();
        fetchFellingRowLocations();
        fetchFellingRowTreeSpecieNames();
    }, []);

    if (!loading && fellingRowError !== null) {
        return (
            <BackToFellingRowsNonIdealState
                title={gettext(
                    "Oops! Can't update the felling row due to error(s)..",
                )}
                description={
                    fellingRowError.isValidationError ? (
                        <ValidationErrorsList
                            validationErrors={fellingRowError}
                        />
                    ) : (
                        formatError(fellingRowError)
                    )
                }
            />
        );
    }

    if (
        loading ||
        fellingRow === null ||
        loadingFellingTypeChoices ||
        loadingLocationChoices ||
        loadingFellingRowTreeSpecieNameChoices ||
        fellingRowFellingTypeChoices === null ||
        fellingRowLocationChoices === null ||
        fellingRowTreeSpecieNameChoices === null
    ) {
        return (
            <>
                <Spinner className="mt-5" />
            </>
        );
    }

    return (
        <FellingRowForm
            fellingRow={fellingRow}
            onFormSubmit={onFormSubmit}
            isCreationForm={false}
            fellingTypeChoices={fellingRowFellingTypeChoices}
            locationChoices={fellingRowLocationChoices}
            treeSpecieNameChoices={fellingRowTreeSpecieNameChoices}
        />
    );
};

const mapStateToProps = state => ({
    loading: state.fellingRowDetails.loading,
    fellingRowError: state.fellingRowDetails.error,
    fellingRow: state.fellingRowDetails?.response,
    fellingRowFellingTypeChoices: state.fellingRowFellingTypeChoices?.response,
    loadingFellingTypeChoices: state.fellingRowFellingTypeChoices?.loading,
    fellingRowLocationChoices: state.fellingRowLocationChoices?.response,
    loadingLocationChoices: state.fellingRowLocationChoices?.loading,
    fellingRowTreeSpecieNameChoices:
        state.fellingRowTreeSpecieNameChoices?.response,
    loadingFellingRowTreeSpecieNameChoices:
        state.fellingRowTreeSpecieNameChoices?.loading,
});

const mapDispatchToProps = {
    fetchFellingRowDetails: fetchFellingRow,
    fetchFellingRowFellingTypes: fetchFellingRowFellingTypeChoices,
    fetchFellingRowLocations: fetchFellingRowLocationChoices,
    fetchFellingRowTreeSpecieNames: fetchFellingRowTreeSpecieNameChoices,
    onFormSubmit: updateFellingRow,
};

const ConnectedFellingRowUpdate = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FellingRowUpdate);

FellingRowUpdate.propTypes = {
    fellingRowId: PropTypes.string.isRequired,
    fellingRowError: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    fetchFellingRowDetails: PropTypes.func.isRequired,
    fellingRow: FellingRowDetailsShape,
    loading: PropTypes.bool,
    onFormSubmit: PropTypes.func.isRequired,
    fetchFellingRowFellingTypes: PropTypes.func.isRequired,
    fetchFellingRowLocations: PropTypes.func.isRequired,
    fetchFellingRowTreeSpecieNames: PropTypes.func.isRequired,
    fellingRowFellingTypeChoices: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
        ).isRequired,
        PropTypes.oneOf([null]),
    ]),
    fellingRowLocationChoices: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            }),
        ).isRequired,
        PropTypes.oneOf([null]),
    ]),
    fellingRowTreeSpecieNameChoices: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
        ).isRequired,
        PropTypes.oneOf([null]),
    ]),
    loadingFellingTypeChoices: PropTypes.bool.isRequired,
    loadingLocationChoices: PropTypes.bool.isRequired,
    loadingFellingRowTreeSpecieNameChoices: PropTypes.bool.isRequired,
};

FellingRowUpdate.defaultProps = {
    loading: false,
    fellingRowError: null,
    fellingRow: null,
    fellingRowFellingTypeChoices: null,
    fellingRowLocationChoices: null,
    fellingRowTreeSpecieNameChoices: null,
};

export default ConnectedFellingRowUpdate;
