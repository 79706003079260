import { Icon, Intent } from '@blueprintjs/core';
import React from 'react';

import { SIGNATURE_CONTAINER_STATUS } from './constants';

export const actSignatureStatusIcon = act => {
    if (!act || !act.signature_status) {
        return <Icon icon="blank" />;
    }

    return act.signature_status === SIGNATURE_CONTAINER_STATUS.unsigned ? (
        <Icon icon="document" />
    ) : (
        <Icon
            icon="saved"
            intent={
                act.signature_status === SIGNATURE_CONTAINER_STATUS.signed
                    ? Intent.SUCCESS
                    : Intent.NONE
            }
        />
    );
};
