// TODO: Cover with tests
import {
    AnchorButton,
    Checkbox,
    HTMLTable,
    NonIdealState,
    Intent,
    Spinner,
} from '@blueprintjs/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import { useDebouncedCallback } from 'use-debounce';

import api from '../../api';
import {
    sessionStorageGet,
    sessionStorageSet,
    sessionStorageRemoveItem,
} from '../../utils/session-storage';
import { fetchActs } from '../../ducks/acts/actList';
import { deleteAct } from '../../ducks/acts/actDeletion';
import { updatePurchaseInvoiceNumber } from '../../ducks/acts/purchaseInvoiceNumberUpdate';
import { signingContainerStart } from '../../ducks/signing';
import { LightActShape } from '../../shapes/acts';
import { actSignatureStatusIcon } from '../../utils/icons';
import { gettext } from '../../utils/text';
import SigningDialog from '../SigningDialog';
import { reverseUrl, useQueryString } from '../../utils/urls';
import {
    RANGE_CURRENT_MONTH,
    getDefaultDateRange,
    fromISODateString,
    toLocaleDateString,
} from '../../utils/date';
import { useEffectExceptOnMount, useIsOpenState } from '../../utils/hooks';
import {
    ITEM_SELECTION,
    getPageStatus,
    toggleItemSelection,
} from '../../utils/tableSelection';
import { SIGNATURE_CONTAINER_STATUS } from '../../utils/constants';
import { CustomNonIdealState } from '../NonIdealStates';
import ConfirmationAlert from '../ConfirmationAlert';
import {
    TableAction,
    TableDateFilter,
    TableFilterGroup,
    TablePagination,
} from '../Table';
import PurchaseInvoiceNumberDialog from '../PurchaseInvoiceNumberDialog';

const ACT_ACTION_TYPES = {
    UPDATE_PURCHASE_INVOICE_NR: 'update_purchase_invoice_nr',
    SIGN_DIGITALLY: 'sign_digitally',
    DELETE: 'delete',
};
const ACT_ACTIONS = [
    {
        type: ACT_ACTION_TYPES.SIGN_DIGITALLY,
        title: gettext('Sign digitally'),
        icon: 'annotation',
    },
    {
        type: ACT_ACTION_TYPES.DELETE,
        title: gettext('Delete'),
        icon: 'cross',
    },
    {
        type: ACT_ACTION_TYPES.UPDATE_PURCHASE_INVOICE_NR,
        title: gettext('Update purchase invoice nr'),
        icon: 'clipboard',
    },
];

export const ActList = ({
    acts,
    loading,
    totalPages,
    error,
    fetchActList,
    startSigning,
    handleDeleteAct,
    handleUpdatePurchaseInvoiceNumber,
}) => {
    const defaultDateRange = useMemo(() => {
        const selectedDateBeforeFromSessionStorage = sessionStorageGet(
            'date_before',
        );
        const selectedDateAfterFromSessionStorage = sessionStorageGet(
            'date_after',
        );

        if (
            !selectedDateBeforeFromSessionStorage &&
            !selectedDateAfterFromSessionStorage
        ) {
            return getDefaultDateRange(RANGE_CURRENT_MONTH, true);
        }

        return [
            selectedDateAfterFromSessionStorage,
            selectedDateBeforeFromSessionStorage,
        ];
    }, [RANGE_CURRENT_MONTH]);
    const [debouncedFetchActList] = useDebouncedCallback(fetchActList, 500);

    const [page, changePage] = useQueryString('page', 1, {
        parseNumbers: true,
    });
    const [selectedActs, setSelectedActs] = useState([]);
    const [search, changeSearch] = useQueryString('search', '');
    const [dateBefore, changeDateBefore] = useQueryString(
        'date_before',
        defaultDateRange[1],
        {
            allowBlank: true,
        },
    );
    const [dateAfter, changeDateAfter] = useQueryString(
        'date_after',
        defaultDateRange[0],
        {
            allowBlank: true,
        },
    );
    const {
        isOpen: isDeleteActDialogOpen,
        handleOpen: openDeleteActDialog,
        handleClose: closeDeleteActDialog,
    } = useIsOpenState(false);
    const {
        isOpen: isPurchaseInvoiceNumberDialogOpen,
        handleOpen: openPurchaseInvoiceNumberDialog,
        handleClose: closePurchaseInvoiceNumberDialog,
    } = useIsOpenState(false);

    const onPageChange = useCallback(
        newPage => {
            changePage(newPage);
            fetchActList(newPage, search, dateBefore, dateAfter);
        },
        [fetchActList, changePage, search, dateBefore, dateAfter],
    );

    const getPDFLink = useCallback(
        actID => api.acts.pdf.renderPath({ id: actID }),
        [],
    );

    const getBDOCLink = useCallback(act => {
        if (!act.container) {
            return null;
        }
        return api.signing.containerBDOC.renderPath({
            externalID: act.container.external_id,
        });
    }, []);

    const onActDelete = useCallback(() => {
        handleDeleteAct(
            {
                acts: selectedActs.map(act => act.id),
            },
            () => {
                closeDeleteActDialog();
                window.location = reverseUrl('acts:list');
            },
            () => {
                closeDeleteActDialog();
            },
        );
    }, [handleDeleteAct, closeDeleteActDialog, selectedActs]);

    const [
        purchaseInvoiceNumberUpdateErrors,
        setPurchaseInvoiceNumberUpdateErrors,
    ] = useState(null);
    const onPurchaseInvoiceNumberUpdate = useCallback(
        purchaseInvoiceNumber => {
            handleUpdatePurchaseInvoiceNumber(
                {
                    acts: selectedActs.map(act => act.id),
                    purchase_invoice_nr: purchaseInvoiceNumber,
                },
                () => {
                    closePurchaseInvoiceNumberDialog();
                    window.location = reverseUrl('acts:list');
                },
                errors => {
                    setPurchaseInvoiceNumberUpdateErrors(errors);
                },
            );
        },
        [
            handleUpdatePurchaseInvoiceNumber,
            selectedActs,
            closePurchaseInvoiceNumberDialog,
        ],
    );

    // An effect to save selected dates to session storage when it changes.
    useEffect(() => {
        sessionStorageSet('date_before', dateBefore);
        sessionStorageSet('date_after', dateAfter);
    }, [dateBefore, dateAfter]);

    // An effect for the first page load
    useEffect(() => {
        fetchActList(page, search, dateBefore, dateAfter);
    }, []);

    // An effect for filters that do not work on the first mount
    useEffectExceptOnMount(() => {
        setSelectedActs([]);
        changePage(1);
        debouncedFetchActList(1, search, dateBefore, dateAfter);
    }, [
        setSelectedActs,
        changePage,
        debouncedFetchActList,
        search,
        dateBefore,
        dateAfter,
    ]);

    const actUpdateURL = useCallback(actID => {
        const url = reverseUrl('acts:update', { pk: actID });
        const query = qs.parse(window.location.search);
        return qs.stringifyUrl({
            url,
            query,
        });
    }, []);

    const filters = useMemo(
        () => (
            <TableFilterGroup
                search={search}
                changeSearch={changeSearch}
                searchPlaceholder={gettext(
                    'Search for act by number, seller reg. code or name...',
                )}
                dateFilter={
                    <TableDateFilter
                        dateBefore={dateBefore}
                        changeDateBefore={changeDateBefore}
                        dateAfter={dateAfter}
                        changeDateAfter={changeDateAfter}
                    />
                }
            />
        ),
        [
            search,
            changeSearch,
            dateBefore,
            changeDateBefore,
            dateAfter,
            changeDateAfter,
        ],
    );

    const [selectedTableAction, setSelectedTableAction] = useState(null);
    const changeTableAction = useCallback(action => {
        setSelectedTableAction(action);
        setSelectedActs([]);
    }, []);
    const executeTableAction = useCallback(() => {
        if (selectedTableAction.type === ACT_ACTION_TYPES.SIGN_DIGITALLY) {
            startSigning(selectedActs);
        } else if (selectedTableAction.type === ACT_ACTION_TYPES.DELETE) {
            openDeleteActDialog();
        } else if (
            selectedTableAction.type ===
            ACT_ACTION_TYPES.UPDATE_PURCHASE_INVOICE_NR
        ) {
            openPurchaseInvoiceNumberDialog();
        } else {
            throw new Error('Invalid action.');
        }
    }, [selectedTableAction, selectedActs, startSigning, openDeleteActDialog]);
    const tableActions = useMemo(
        () => (
            <TableAction
                actions={ACT_ACTIONS}
                selectedAction={selectedTableAction}
                changeAction={changeTableAction}
                executeAction={executeTableAction}
                hasSelectedEntities={selectedActs.length > 0}
            />
        ),
        [
            selectedActs,
            selectedTableAction,
            executeTableAction,
            changeTableAction,
        ],
    );
    const isActSelectable = useCallback(
        act =>
            !(
                selectedTableAction?.type === ACT_ACTION_TYPES.SIGN_DIGITALLY &&
                act.signature_status !== SIGNATURE_CONTAINER_STATUS.unsigned
            ),
        [selectedTableAction],
    );

    const pagination = useMemo(() => {
        if (totalPages === null) {
            return null;
        }

        return (
            <TablePagination
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
            />
        );
    }, [page, totalPages, onPageChange]);

    const navigation = useMemo(
        () => (
            <div className="mb-4">
                {filters}
                {tableActions}
                <div className="float-right">{pagination}</div>
            </div>
        ),
        [pagination, tableActions, filters],
    );

    const deleteActDialog = useMemo(
        () => (
            <ConfirmationAlert
                onClose={closeDeleteActDialog}
                onConfirm={onActDelete}
                isOpen={isDeleteActDialogOpen}
            >
                <p>{gettext('Are you sure to delete the selected act(s)?')}</p>
            </ConfirmationAlert>
        ),
        [closeDeleteActDialog, onActDelete, isDeleteActDialogOpen],
    );

    const purchaseInvoiceNumberDialog = useMemo(
        () => (
            <PurchaseInvoiceNumberDialog
                isOpen={isPurchaseInvoiceNumberDialogOpen}
                onClose={closePurchaseInvoiceNumberDialog}
                selectedActs={selectedActs}
                onConfirm={onPurchaseInvoiceNumberUpdate}
                errors={purchaseInvoiceNumberUpdateErrors}
            />
        ),
        [
            isPurchaseInvoiceNumberDialogOpen,
            closePurchaseInvoiceNumberDialog,
            selectedActs,
            onPurchaseInvoiceNumberUpdate,
            purchaseInvoiceNumberUpdateErrors,
        ],
    );

    const htmlTable = useMemo(() => {
        if (acts === null) {
            return null;
        }

        if (!acts.length) {
            return (
                <CustomNonIdealState
                    icon="search"
                    title={gettext('Oops! No acts found..')}
                    description={
                        <div>
                            <p>
                                {gettext(
                                    'No acts found matching your filters.',
                                )}
                            </p>
                        </div>
                    }
                    action={
                        <AnchorButton
                            text={gettext('Clear filters')}
                            href={reverseUrl('acts:list')}
                            onClick={() => {
                                sessionStorageRemoveItem('date_before');
                                sessionStorageRemoveItem('date_after');
                            }}
                        />
                    }
                />
            );
        }

        const selectableActs = acts.filter(isActSelectable);
        const pageStatus = getPageStatus(selectedActs, selectableActs);

        return (
            <div className="table-full-width">
                <HTMLTable bordered condensed interactive striped>
                    <thead>
                        <tr>
                            <th className="cell-minimal">
                                <Checkbox
                                    inline
                                    disabled={selectedTableAction === null}
                                    checked={pageStatus.pageChecked}
                                    indeterminate={pageStatus.pageIndeterminate}
                                    onChange={() => {
                                        toggleItemSelection(
                                            pageStatus.pageChecked
                                                ? ITEM_SELECTION.PAGE_NONE
                                                : ITEM_SELECTION.PAGE,
                                            selectableActs,
                                            setSelectedActs,
                                        );
                                    }}
                                />
                            </th>
                            <th key="act-number">{gettext('Number')}</th>
                            <th key="act-date">{gettext('Date')}</th>
                            <th key="act-purchase-invoice-nr">
                                {gettext('Invoice number')}
                            </th>
                            <th key="act-seller-name">{gettext('Seller')}</th>
                            <th key="act-seller-reg-code">
                                {gettext('Seller registry code')}
                            </th>
                            <th key="act-documents">{gettext('Documents')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {acts.map(act => (
                            <tr key={act.id}>
                                <th className="cell-minimal">
                                    <Checkbox
                                        inline
                                        disabled={
                                            selectedTableAction === null ||
                                            !isActSelectable(act)
                                        }
                                        checked={
                                            selectedActs.filter(
                                                a => a.id === act.id,
                                            ).length > 0
                                        }
                                        onChange={() =>
                                            toggleItemSelection(
                                                act,
                                                selectableActs,
                                                setSelectedActs,
                                            )
                                        }
                                    />
                                    {act.signature_status ===
                                    SIGNATURE_CONTAINER_STATUS.unsigned ? (
                                        <a
                                            href={actUpdateURL(act.id)}
                                            className="ml-2 mr-2"
                                        >
                                            <span className="bp3-icon-standard bp3-icon-edit" />
                                        </a>
                                    ) : null}
                                    {actSignatureStatusIcon(act)}
                                </th>
                                <td key="act-number">{act.number}</td>
                                <td key="act-date">
                                    {toLocaleDateString(
                                        fromISODateString(act.date),
                                    )}
                                </td>
                                <td key="act-purchase-invoice-nr">
                                    {act.purchase_invoice_nr}
                                </td>
                                <td key="act-seller-name">{act.seller_name}</td>
                                <td key="act-seller-reg-code">
                                    {act.seller_reg_code}
                                </td>
                                <td key="act-documents">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        icon="document"
                                        text={gettext('PDF')}
                                        minimal
                                        small
                                        target="_blank"
                                        href={getPDFLink(act.id)}
                                    />
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        icon="document"
                                        text={gettext('BDOC')}
                                        minimal
                                        small
                                        target="_blank"
                                        href={getBDOCLink(act)}
                                        disabled={!getBDOCLink(act)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </HTMLTable>
            </div>
        );
    }, [loading, selectedActs, acts, isActSelectable]);

    if (!loading && error !== null) {
        return (
            <>
                <div className="container-fluid">{navigation}</div>
                <NonIdealState
                    icon="issue"
                    title={gettext('Oops! Could not load acts..')}
                    description={error}
                />
            </>
        );
    }

    if (loading) {
        return (
            <>
                <div className="container-fluid">{navigation}</div>
                <Spinner className="mt-5" />
            </>
        );
    }

    return (
        <>
            <div className="container-fluid">{navigation}</div>
            <div className="container-fluid pb-5">{htmlTable}</div>
            <SigningDialog isDialogOpen />
            {deleteActDialog}
            {purchaseInvoiceNumberDialog}
        </>
    );
};

ActList.propTypes = {
    loading: PropTypes.bool,
    acts: PropTypes.arrayOf(LightActShape),
    totalPages: PropTypes.number,
    error: PropTypes.string,
    fetchActList: PropTypes.func.isRequired,
    startSigning: PropTypes.func.isRequired,
    handleDeleteAct: PropTypes.func.isRequired,
    handleUpdatePurchaseInvoiceNumber: PropTypes.func.isRequired,
};

ActList.defaultProps = {
    loading: false,
    acts: null,
    totalPages: null,
    error: null,
};

const mapStateToProps = state => ({
    /* eslint-disable camelcase */
    loading: state.acts.loading,
    acts: state.acts?.response?.results,
    totalPages: state.acts?.response?.total_pages,
    error: state.acts.error,
    /* eslint-enable camelcase */
});

const mapDispatchToProps = {
    fetchActList: fetchActs,
    startSigning: signingContainerStart,
    handleDeleteAct: deleteAct,
    handleUpdatePurchaseInvoiceNumber: updatePurchaseInvoiceNumber,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ActList);
