// TODO: Cover with tests
// Actions
export const FETCH_INVOICE_INITIALS = 'ACCOUNTING/FETCH_INVOICE_INITIALS';
export const FETCH_INVOICE_INITIALS_STARTED =
    'ACCOUNTING/FETCH_INVOICE_INITIALS_STARTED';
export const FETCH_INVOICE_INITIALS_SUCCESSFUL =
    'ACCOUNTING/FETCH_INVOICE_INITIALS_SUCCESSFUL';
export const FETCH_INVOICE_INITIALS_FAILED =
    'ACCOUNTING/FETCH_INVOICE_INITIALS_FAILED';

// Initial state for reducer
const initialState = {
    loading: false,
    response: null,
    error: null,
};

// Reducer(s)
export default function fetchInvoiceInitialsReducer(
    state = initialState,
    action,
) {
    switch (action.type) {
        case FETCH_INVOICE_INITIALS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case FETCH_INVOICE_INITIALS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case FETCH_INVOICE_INITIALS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchInvoiceInitials = payload => ({
    type: FETCH_INVOICE_INITIALS,
    payload,
});
