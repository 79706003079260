// TODO: Cover with tests
import { call, put, spawn, takeLatest } from 'redux-saga/effects';

import api from '../api';
import { ERRORED, FETCH, FETCH_STARTED, RECEIVED } from '../ducks/acts/actList';
import { formatError } from '../utils/errors';
import actDeletionSaga from './actDeletion';
import purchaseInvoiceNumberUpdateSaga from './purchaseInvoiceNumberUpdate';
import { signingSaga } from './signing';

function* fetchActs(action) {
    yield put({ type: FETCH_STARTED });
    const query = {
        page: action.page || 1,
        search: action.search || '',
        date_before: action.dateBefore || '',
        date_after: action.dateAfter || '',
    };
    try {
        const response = yield call(api.acts.list.fetch, null, query);
        yield put({ type: RECEIVED, response });
    } catch (error) {
        yield put({ type: ERRORED, error: formatError(error) });
    }
}

function* actListSaga() {
    yield takeLatest(FETCH, fetchActs);
}
export default function* actListRootSaga() {
    yield spawn(actListSaga);
    yield spawn(actDeletionSaga);
    yield spawn(purchaseInvoiceNumberUpdateSaga);
    yield spawn(signingSaga);
}
