// Actions
export const UPDATE_FELLING_ROW = 'FELLING_ROWS/UPDATE_FELLING_ROW';
export const UPDATE_FELLING_ROW_STARTED =
    'FELLING_ROWS/UPDATE_FELLING_ROW_STARTED';
export const UPDATE_FELLING_ROW_SUCCESSFUL =
    'FELLING_ROWS/UPDATE_FELLING_ROW_SUCCESSFUL';
export const UPDATE_FELLING_ROW_FAILED =
    'FELLING_ROWS/UPDATE_FELLING_ROW_FAILED';

// Action creators
export const updateFellingRow = (payload, callback, errorCallback) => ({
    type: UPDATE_FELLING_ROW,
    payload,
    callback,
    errorCallback,
});
