import { createRouter } from 'tg-resources';
import { FetchResource as Resource } from '@tg-resources/fetch';
import Cookies from 'js-cookie';

export default createRouter(
    {
        /* eslint-disable no-template-curly-in-string */
        accounting: {
            invoiceInitials: '/accounting/invoices/initials/',
            invoiceCreate: '/accounting/invoices/',
            invoiceDelete: '/accounting/invoices/${id}/',
        },
        acts: {
            initials: '/acts/initials/',
            create: '/acts/',
            delete: '/acts/',
            list: '/acts/',
            details: '/acts/${id}/',
            update: '/acts/${id}/',
            pdf: '/acts/${id}/pdf/',
            createContainer: '/acts/containers/',
            updatePurchaseInvoiceNumber: '/acts/update_purchase_invoice_nr/',
        },
        signing: {
            containerAction:
                '/signing/container/${externalID}/sign-${signType}/',
            containerBDOC: '/signing/container/${externalID}/bdoc/',
            containerDetail: '/signing/container/${externalID}/',
            containerFile: '/signing/container/${externalID}/file/${fileID}/',
        },
        stockManagement: {
            energyWarehouseList: '/stock-management/energy-warehouses/',
            /* Energy Warehouse Filters */
            energyWarehouseStatuses:
                '/stock-management/energy-warehouses/statuses/',
            /* End Energy Warehouse Filters */
            timberWarehouseList: '/stock-management/timber-warehouses/',
            /* Timber Warehouse Filters */
            timberWarehouseStatuses:
                '/stock-management/timber-warehouses/statuses/',
            /* End Timber Warehouse Filters */
        },
        waybills: {
            list: '/waybills/',
            details: '/waybills/${id}/',
            totals: '/waybills/totals/',
            transportReport: '/waybills/transport_report/',
            getNonSelectables: '/waybills/get_non_selectable_waybills/',
            waybillReport: '/waybills/waybill_report/',
            /* Waybill Filters */
            buyersList: '/waybills/buyers/',
            choppersList: '/waybills/choppers/',
            destinationsList: '/waybills/destinations/',
            driversList: '/waybills/drivers/',
            materialsList: '/waybills/materials/',
            originsList: '/waybills/origins/',
            sellersList: '/waybills/sellers/',
            transportersList: '/waybills/transporters/',
            trucksList: '/waybills/trucks/',
            unitsList: '/waybills/units/',
            weightFilterOptionsList: '/waybills/weight_filter_options/',
            updateTransportInvoiceNumber:
                '/waybills/update_transport_invoice_nr/',
            forestMastersList: '/waybills/forest_masters/',
            /* End Waybill Filters */
        },
        forestEstimates: {
            list: '/forest-estimates/',
            create: '/forest-estimates/',
            details: '/forest-estimates/${id}/',
            update: '/forest-estimates/${id}/',
            species: '/forest-estimates/${id}/species/',
            analyzerChoices: '/forest-estimates/analyzer_choices/',
            getFellingRows: '/forest-estimates/${id}/get_felling_rows/',
            deleteFellingRow: '/felling-rows/${id}/',
            locationChoices: '/felling-rows/location_choices/',
            fellingTypeChoices: '/felling-rows/felling_type_choices/',
            fellingRowTreeSpecieNameChoices:
                '/felling-rows/felling_row_tree_specie_name_choices/',
            createFellingRow: '/felling-rows/',
            fellingRowDetails: '/felling-rows/${id}/',
            updateFellingRow: '/felling-rows/${id}/',
        },
        /* eslint-enable no-template-curly-in-string */
    },
    {
        apiRoot: '/api/internal',
        withCredentials: true,
        headers: () => ({
            Accept: 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
        }),
        querySerializeOptions: {
            indices: false,
        },
    },
    Resource,
);
