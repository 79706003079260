import React from 'react';
import ReactDOM from 'react-dom';

import {
    Alignment,
    AnchorButton,
    Button,
    ButtonGroup,
    Classes,
    Menu,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NavbarHeading,
    Popover,
    PopoverInteractionKind,
    Position,
} from '@blueprintjs/core';

import { gettext } from '../../utils/text';
import { reverseUrl } from '../../utils/urls';

class NavigationBar extends React.Component {
    static renderItemsLeft() {
        return DJ_CONST.user ? (
            <>
                <NavbarDivider />
                <AnchorButton
                    className={Classes.MINIMAL}
                    text={gettext('Waybills')}
                    href={reverseUrl('waybills:list')}
                />
                {!DJ_CONST.user.is_logistics_user ? (
                    <AnchorButton
                        className={Classes.MINIMAL}
                        text={gettext('Acts')}
                        href={reverseUrl('acts:list')}
                    />
                ) : null}
                <ButtonGroup>
                    <Popover
                        interactionKind={PopoverInteractionKind.HOVER}
                        position={Position.BOTTOM}
                    >
                        <Button rightIcon="caret-down" minimal>
                            {gettext('Stock Management')}
                        </Button>
                        <Menu>
                            <Menu.Item
                                icon="map-marker"
                                href={reverseUrl(
                                    'stock_management:energy-warehouse-list',
                                )}
                                text={gettext('Energy Warehouses')}
                            />
                            <Menu.Item
                                icon="map-marker"
                                href={reverseUrl(
                                    'stock_management:timber-warehouse-list',
                                )}
                                text={gettext('Timber Warehouses')}
                            />
                        </Menu>
                    </Popover>
                </ButtonGroup>
                {DJ_CONST.user.is_logistics_user ? (
                    <AnchorButton
                        className={Classes.MINIMAL}
                        text={gettext('Transport costs')}
                        href={reverseUrl(
                            'admin:waybills_customtransportcost_changelist',
                        )}
                        target="_blank"
                    />
                ) : null}
                <AnchorButton
                    className={Classes.MINIMAL}
                    text={gettext('Forest Estimates')}
                    href={reverseUrl('forest_estimates:list')}
                />
            </>
        ) : null;
    }

    static renderItemsRight() {
        return (
            <>
                {DJ_CONST.user ? (
                    <>
                        <ButtonGroup>
                            <Popover
                                interactionKind={PopoverInteractionKind.HOVER}
                                position={Position.BOTTOM}
                                disabled={
                                    /* No links available for other roles */
                                    !(
                                        DJ_CONST.user.is_superuser ||
                                        DJ_CONST.user.is_manager
                                    )
                                }
                            >
                                <Button
                                    rightIcon={
                                        /* Add caret down for superusers and managers */
                                        DJ_CONST.user.is_superuser ||
                                        DJ_CONST.user.is_manager
                                            ? 'caret-down'
                                            : null
                                    }
                                    text={
                                        DJ_CONST.user.name ||
                                        DJ_CONST.user.email
                                    }
                                    className={Classes.MINIMAL}
                                    minimal
                                />
                                <Menu>
                                    {DJ_CONST.user.is_superuser ? (
                                        <Menu.Item
                                            icon="settings"
                                            text={gettext('Admin Panel')}
                                            href={
                                                DJ_CONST.SITE_URL +
                                                DJ_CONST.ADMIN_PANEL
                                            }
                                            target="_blank"
                                        />
                                    ) : null}
                                    {DJ_CONST.user.is_manager ? (
                                        <Menu.Item
                                            icon="applications"
                                            text={gettext(
                                                'Accounting Integrations',
                                            )}
                                            href={reverseUrl(
                                                'accounting:list-integrations',
                                            )}
                                        />
                                    ) : null}
                                </Menu>
                            </Popover>
                        </ButtonGroup>
                        <AnchorButton
                            text={gettext('Log out')}
                            href={reverseUrl('accounts:logout')}
                            className={Classes.MINIMAL}
                        />
                    </>
                ) : (
                    <AnchorButton
                        text={gettext('Log in')}
                        href={reverseUrl('accounts:login')}
                        className={Classes.MINIMAL}
                    />
                )}
            </>
        );
    }

    render() {
        return (
            <Navbar className={Classes.DARK}>
                <div className="container">
                    <NavbarGroup align={Alignment.LEFT}>
                        <NavbarHeading>{DJ_CONST.PROJECT_TITLE}</NavbarHeading>
                        {NavigationBar.renderItemsLeft()}
                    </NavbarGroup>
                    <NavbarGroup align={Alignment.RIGHT}>
                        {NavigationBar.renderItemsRight()}
                    </NavbarGroup>
                </div>
            </Navbar>
        );
    }
}

const renderNavigationBar = containerID => {
    const container = document.getElementById(containerID);

    if (container) {
        ReactDOM.render(<NavigationBar />, container);
    }
};

export default renderNavigationBar;
export { NavigationBar };
