import PropTypes from 'prop-types';

const CommonActShape = PropTypes.shape({
    // Act general details
    number: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    payment_deadline: PropTypes.string,
    places_of_delivery: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    extra_info: PropTypes.string.isRequired,
    in_house_comments: PropTypes.string.isRequired,
    // Buyer details
    buyer_name: PropTypes.string.isRequired,
    buyer_reg_code: PropTypes.string.isRequired,
    buyer_vat_number: PropTypes.string.isRequired,
    buyer_address: PropTypes.string.isRequired,
    buyer_representative_name: PropTypes.string.isRequired,
    buyer_representative_code: PropTypes.string.isRequired,
    buyer_representative_phone: PropTypes.string.isRequired,
    buyer_representative_email: PropTypes.string.isRequired,
    buyer_authorization_base: PropTypes.number.isRequired,
    // Seller details
    seller_name: PropTypes.string.isRequired,
    seller_reg_code: PropTypes.string.isRequired,
    seller_vat_number: PropTypes.string.isRequired,
    seller_address: PropTypes.string.isRequired,
    seller_email: PropTypes.string.isRequired,
    seller_bank_account_number: PropTypes.string.isRequired,
    seller_representative_name: PropTypes.string.isRequired,
    seller_representative_code: PropTypes.string.isRequired,
    seller_representative_phone: PropTypes.string.isRequired,
    seller_representative_email: PropTypes.string.isRequired,
    seller_authorization_base: PropTypes.number.isRequired,
    // Waybills
    waybills: PropTypes.arrayOf(PropTypes.number).isRequired,
    // Assortment rows
    assortment_rows: PropTypes.arrayOf(
        PropTypes.shape({
            origin_name: PropTypes.string.isRequired,
            assortment_name: PropTypes.string.isRequired,
            assortment_unit: PropTypes.string.isRequired,
            unit_cost: PropTypes.number,
            amount: PropTypes.number.isRequired,
        }),
    ).isRequired,
    // Transport rows
    transport_rows: PropTypes.arrayOf(
        PropTypes.shape({
            origin_name: PropTypes.string.isRequired,
            waybill_number: PropTypes.string.isRequired,
            waybill_date: PropTypes.string.isRequired,
            amount: PropTypes.number.isRequired,
            assortment_unit: PropTypes.string.isRequired,
            truck: PropTypes.string.isRequired,
        }),
    ).isRequired,
});

export const InitialActDataShape = PropTypes.shape({
    ...CommonActShape,
});
export const ActDetailsShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    // Accounting
    purchase_invoice_nr: PropTypes.string.isRequired,
    ...CommonActShape,
});

export const LightActShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    seller_name: PropTypes.string.isRequired,
    seller_reg_code: PropTypes.string.isRequired,
    container: PropTypes.shape({
        id: PropTypes.number.isRequired,
        external_id: PropTypes.string.isRequired,
    }),
    purchase_invoice_nr: PropTypes.string.isRequired,
});

export const WaybillActShape = PropTypes.shape({
    // Act general details
    number: PropTypes.string.isRequired,
    // Accounting
    purchase_invoice_nr: PropTypes.string.isRequired,
    // Signing
    signature_status: PropTypes.string.isRequired,
});
