const { sessionStorage } = window;

export const sessionStorageSet = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

export const sessionStorageGet = key => {
    return JSON.parse(sessionStorage.getItem(key));
};

export const sessionStorageRemoveItem = key => {
    sessionStorage.removeItem(key);
};
