// TODO: Cover with tests
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withFormik, FieldArray, Form, getIn } from 'formik';
import {
    Button,
    Callout,
    Card,
    Elevation,
    Intent,
    NumericInput,
    Position,
    RadioGroup,
    Spinner,
} from '@blueprintjs/core';

import CustomSelect from '../CustomSelect';
import { BackToWaybillsNonIdealState } from '../NonIdealStates';
import ValidationErrorsList from '../ValidationErrorsList';
import FormField from '../../forms/fields/FormField';
import DateInput from '../DateInput';
import { gettext } from '../../utils/text';
import { fromISODateString, toISODateString } from '../../utils/date';
import { getFormPropTypes } from '../../shapes/formik';
import { reverseUrl } from '../../utils/urls';
import { getFieldErrors } from '../../utils/formErrors';
import { createInvoice } from '../../ducks/accounting/invoiceCreation';
import { fetchInvoiceInitials } from '../../ducks/accounting/invoiceInitials';
import { InitialInvoiceDataShape } from '../../shapes/accounting';
import { returnFirstArg } from '../../utils/functional';
import { floatformat } from '../../utils/formatting';
import { formatError } from '../../utils/errors';
import { sessionStorageGet } from '../../utils/session-storage';

const selectInputPopoverProps = {
    fill: true,
};

//  Need to be kept synced with the backend values
const INVOICE_GROUPING_MWH = 'mwh';
const INVOICE_GROUPING_ASSORTMENT_UNIT = 'assortment_unit';
const INVOICE_GROUPING_DISPATCHED_WEIGHT = 'dispatched_weight';
const INVOICE_GROUPING_ACCEPTED_WEIGHT = 'accepted_weight';
const invoiceGroupingOptions = [
    {
        label: gettext('MWh'),
        value: INVOICE_GROUPING_MWH,
    },
    {
        label: gettext('Assortment unit'),
        value: INVOICE_GROUPING_ASSORTMENT_UNIT,
    },
    {
        label: gettext('Dispatched weight'),
        value: INVOICE_GROUPING_DISPATCHED_WEIGHT,
    },
    {
        label: gettext('Accepted weight'),
        value: INVOICE_GROUPING_ACCEPTED_WEIGHT,
    },
];

// Helper for generating select options for various invoice inputs
const generateInvoiceEntitySelectOptions = entities =>
    entities.map(entity => ({
        text: entity.name,
        value: entity.id,
    }));

const InvoiceCreation = ({
    initialInvoiceData,
    setInvoiceGrouping,
    handleSubmit,
    errors,
    values,
    setFieldValue,
}) => {
    // Waybill errors after submission
    const waybillErrors = getIn(errors, 'waybills', null);
    const nonFieldErrors = getIn(errors, 'nonFieldErrors', null);
    const invoiceGrouping = getIn(
        values,
        'invoice_grouping',
        INVOICE_GROUPING_ASSORTMENT_UNIT,
    );

    useEffect(() => {
        // Initial data fetching is done in InvoiceFormWrapper, we need to provide selected grouping option there
        setInvoiceGrouping(invoiceGrouping);
    }, [invoiceGrouping]);

    const customerOptions = useMemo(
        () => generateInvoiceEntitySelectOptions(initialInvoiceData.customers),
        [initialInvoiceData.customers],
    );
    const itemOptions = useMemo(
        () => generateInvoiceEntitySelectOptions(initialInvoiceData.items),
        [initialInvoiceData.items],
    );
    const taxOptions = useMemo(
        () => generateInvoiceEntitySelectOptions(initialInvoiceData.taxes),
        [initialInvoiceData.taxes],
    );

    const onSelectClear = useCallback(
        inputName => {
            setFieldValue(inputName, null);
        },
        [setFieldValue],
    );

    const getInvoiceRowFieldName = useCallback(
        (index, fieldName) => `invoice_rows.${index}.${fieldName}`,
        [],
    );

    const onInvoiceRowAmountChange = useCallback(
        (index, valueAsString) =>
            setFieldValue(
                getInvoiceRowFieldName(index, 'amount'),
                valueAsString,
            ),
        [setFieldValue],
    );

    const getRowSum = useCallback(
        rowValues => floatformat(rowValues.price * rowValues.amount, 2),
        [],
    );

    const onInvoiceRowPriceChange = useCallback(
        (index, valueAsString) =>
            setFieldValue(
                getInvoiceRowFieldName(index, 'price'),
                valueAsString,
            ),
        [setFieldValue],
    );

    const getInvoiceRowUnit = useCallback(
        row => {
            if (invoiceGrouping === INVOICE_GROUPING_ASSORTMENT_UNIT) {
                return row.assortment_unit;
            } else if (invoiceGrouping === INVOICE_GROUPING_MWH) {
                return gettext('MWh');
            } else if (
                [
                    INVOICE_GROUPING_DISPATCHED_WEIGHT,
                    INVOICE_GROUPING_ACCEPTED_WEIGHT,
                ].indexOf(invoiceGrouping) >= 0
            ) {
                return gettext('ton');
            } else {
                throw new Error('Invalid invoice grouping.');
            }
        },
        [invoiceGrouping],
    );

    return (
        <Form onSubmit={handleSubmit}>
            <div className="row my-4">
                <div className="col col-auto">
                    <h3 className="bp3-heading">
                        {gettext('Invoice creation')}
                    </h3>
                </div>
                <div className="col col-auto ml-auto">
                    <Button type="submit" intent={Intent.PRIMARY} large>
                        {gettext('Save')}
                    </Button>
                </div>
            </div>
            {waybillErrors ? (
                <Callout intent={Intent.DANGER} className="mb-3">
                    {waybillErrors}
                </Callout>
            ) : null}
            {nonFieldErrors ? (
                <Callout intent={Intent.DANGER} className="mb-3">
                    {nonFieldErrors}
                </Callout>
            ) : null}
            <Card elevation={Elevation.TWO}>
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="bp3-heading mt-2 mb-3">
                            {gettext('Invoice details')}
                        </h4>
                    </div>
                    <div className="col-md-6">
                        <FormField
                            name="invoice_grouping"
                            label={gettext('Invoice grouping')}
                            inputComponent={RadioGroup}
                            options={invoiceGroupingOptions}
                        />
                        <FormField
                            name="number"
                            label={gettext('Invoice number')}
                            formGroupClassName="w-50"
                        />
                        <FormField
                            name="date"
                            label={gettext('Invoice date')}
                            inputComponent={DateInput}
                            formGroupClassName="mb-0 w-50"
                            fill
                            getNextValue={returnFirstArg}
                            popoverProps={{
                                position: Position.BOTTOM_LEFT,
                            }}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormField
                            name="customer"
                            label={gettext('Customer')}
                            inputComponent={CustomSelect}
                            formGroupClassName="w-50"
                            initialValue={
                                initialInvoiceData.default_customer_id
                            }
                            items={customerOptions}
                            onClear={() => onSelectClear('customer')}
                            getNextValue={returnFirstArg}
                            buttonPlaceholder={gettext('Select customer')}
                            buttonLeftIcon="person"
                            fill
                            minimal
                            popoverProps={selectInputPopoverProps}
                        />
                        <FormField
                            name="tax"
                            label={gettext('Tax')}
                            inputComponent={CustomSelect}
                            formGroupClassName="mb-0 w-50"
                            initialValue={initialInvoiceData.default_tax_id}
                            items={taxOptions}
                            onClear={() => onSelectClear('tax')}
                            getNextValue={returnFirstArg}
                            buttonPlaceholder={gettext('Select tax')}
                            buttonLeftIcon="percentage"
                            fill
                            minimal
                            popoverProps={selectInputPopoverProps}
                        />
                    </div>
                </div>
            </Card>

            <h4 className="bp3-heading my-4">{gettext('Invoice rows')}</h4>

            <FieldArray
                name="invoice_rows"
                render={() =>
                    values.invoice_rows.map((row, index) => (
                        <Card
                            elevation={Elevation.TWO}
                            className="mt-3"
                            key={index} // eslint-disable-line react/no-array-index-key
                        >
                            <div className="row mb-3">
                                <div className="col-md-2">
                                    <label className="bp3-label">
                                        {gettext('Origin')}
                                    </label>
                                    {row.origin_name}
                                </div>
                                <div className="col-md-2">
                                    <label className="bp3-label">
                                        {gettext('Assortment')}
                                    </label>
                                    {row.assortment_name}
                                </div>
                                <div className="col-md-8">
                                    <FormField
                                        name={getInvoiceRowFieldName(
                                            index,
                                            'description',
                                        )}
                                        label={gettext('Description')}
                                        helperText={gettext(
                                            'Invoice row description',
                                        )}
                                        formGroupClassName="mb-0"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5">
                                    <FormField
                                        name={getInvoiceRowFieldName(
                                            index,
                                            'item',
                                        )}
                                        label={gettext('Item')}
                                        inputComponent={CustomSelect}
                                        formGroupClassName="mb-0"
                                        items={itemOptions}
                                        onClear={() =>
                                            onSelectClear(
                                                getInvoiceRowFieldName(
                                                    index,
                                                    'item',
                                                ),
                                            )
                                        }
                                        getNextValue={returnFirstArg}
                                        buttonPlaceholder={gettext(
                                            'Select item',
                                        )}
                                        buttonLeftIcon="tree"
                                        fill
                                        minimal
                                        popoverProps={selectInputPopoverProps}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <FormField
                                        name={getInvoiceRowFieldName(
                                            index,
                                            'amount',
                                        )}
                                        label={gettext('Amount')}
                                        inputComponent={NumericInput}
                                        formGroupClassName="mb-0"
                                        placeholder="0.000"
                                        min="0"
                                        max="999999.999"
                                        minorStepSize={0.001}
                                        clampValueOnBlur
                                        onValueChange={(
                                            valueAsNumber,
                                            valueAsString,
                                        ) =>
                                            onInvoiceRowAmountChange(
                                                index,
                                                valueAsString,
                                            )
                                        }
                                        fill
                                    />
                                </div>
                                <div className="col-md-1">
                                    <label className="bp3-label">
                                        {gettext('Unit')}
                                    </label>
                                    {getInvoiceRowUnit(row)}
                                </div>
                                <div className="col-md-2">
                                    <FormField
                                        name={getInvoiceRowFieldName(
                                            index,
                                            'price',
                                        )}
                                        label={gettext('Price')}
                                        inputComponent={NumericInput}
                                        formGroupClassName="mb-0"
                                        leftIcon="euro"
                                        min="0"
                                        max="999.999"
                                        minorStepSize={0.001}
                                        clampValueOnBlur
                                        onValueChange={(
                                            valueAsNumber,
                                            valueAsString,
                                        ) =>
                                            onInvoiceRowPriceChange(
                                                index,
                                                valueAsString,
                                            )
                                        }
                                        placeholder="0.000"
                                        fill
                                    />
                                </div>
                                <div className="col-md-2 text-md-right">
                                    <label className="bp3-label">
                                        {gettext('Sum')}
                                    </label>
                                    {getRowSum(row)}
                                </div>
                            </div>
                        </Card>
                    ))
                }
            />

            <div className="my-4">
                <Button type="submit" intent={Intent.PRIMARY} large>
                    {gettext('Save')}
                </Button>
            </div>
        </Form>
    );
};

InvoiceCreation.propTypes = {
    initialInvoiceData: InitialInvoiceDataShape.isRequired,
    setInvoiceGrouping: PropTypes.func.isRequired,
    ...getFormPropTypes([
        // Invoice general details
        'invoice_grouping',
        'number',
        'date',
        'customer',
        'tax',
        'invoice_rows',
    ]),
};

const InvoiceForm = withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    mapPropsToValues: ({ initialInvoiceData }) => {
        return {
            invoice_grouping: initialInvoiceData.invoice_grouping,
            customer: initialInvoiceData.default_customer_id,
            tax: initialInvoiceData.default_tax_id,
            number: initialInvoiceData.number,
            date: fromISODateString(initialInvoiceData.date),
            waybills: initialInvoiceData.waybills,
            // Mutate unit_cost in invoice rows since null values are not accepted by NumericInput
            invoice_rows: initialInvoiceData.invoice_rows.map(row => ({
                ...row,
                price:
                    row.price === null ? NumericInput.VALUE_EMPTY : row.price,
            })),
        };
    },
    handleSubmit: (
        values,
        { props: { onFormSubmit }, setErrors, setSubmitting },
    ) => {
        const payload = {
            ...values,
            date: toISODateString(values.date, 'date'),
        };
        onFormSubmit(
            payload,
            () => {
                setSubmitting(false);
                window.location = reverseUrl('waybills:list');
            },
            error => {
                const fieldErrors = getFieldErrors(error);
                if (fieldErrors !== null) {
                    setErrors(fieldErrors);
                } else {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                setSubmitting(false);
            },
        );
    },
    displayName: 'InvoiceForm',
})(InvoiceCreation);

export const InvoiceFormWrapper = props => {
    const {
        invoiceInitialsError,
        fetchInitialInvoiceData,
        initialInvoiceData,
        loading,
        onFormSubmit,
    } = props;

    const [invoiceGrouping, setInvoiceGrouping] = useState(
        INVOICE_GROUPING_ASSORTMENT_UNIT,
    );

    const selectedWaybillsFromWaybillList = sessionStorageGet(
        'waybills_for_invoice_creation',
    );

    useEffect(() => {
        if (selectedWaybillsFromWaybillList) {
            fetchInitialInvoiceData({
                invoice_grouping: invoiceGrouping,
                waybills: selectedWaybillsFromWaybillList,
            });
        }
    }, [invoiceGrouping]);

    if (!selectedWaybillsFromWaybillList) {
        return (
            <BackToWaybillsNonIdealState
                title={gettext(
                    "Oops! Can't create an invoice due to error(s)..",
                )}
                description={gettext(
                    'Please go back to waybill list and select some waybills',
                )}
            />
        );
    }

    if (!loading && invoiceInitialsError !== null) {
        return (
            <BackToWaybillsNonIdealState
                title={gettext(
                    "Oops! Can't create an invoice due to error(s)..",
                )}
                description={
                    invoiceInitialsError.isValidationError ? (
                        <ValidationErrorsList
                            validationErrors={invoiceInitialsError}
                        />
                    ) : (
                        formatError(invoiceInitialsError)
                    )
                }
            />
        );
    }

    if (loading || initialInvoiceData === null) {
        return (
            <>
                <Spinner className="mt-5" />
            </>
        );
    }

    return (
        <InvoiceForm
            initialInvoiceData={initialInvoiceData}
            onFormSubmit={onFormSubmit}
            setInvoiceGrouping={setInvoiceGrouping}
        />
    );
};

const mapStateToProps = state => ({
    loading: state.invoiceInitials.loading,
    invoiceInitialsError: state.invoiceInitials.error,
    initialInvoiceData: state.invoiceInitials?.response,
});

const mapDispatchToProps = {
    fetchInitialInvoiceData: fetchInvoiceInitials,
    onFormSubmit: createInvoice,
};

const ConnectedInvoiceForm = connect(
    mapStateToProps,
    mapDispatchToProps,
)(InvoiceFormWrapper);

InvoiceFormWrapper.propTypes = {
    invoiceInitialsError: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    fetchInitialInvoiceData: PropTypes.func.isRequired,
    initialInvoiceData: InitialInvoiceDataShape,
    loading: PropTypes.bool,
    onFormSubmit: PropTypes.func.isRequired,
};

InvoiceFormWrapper.defaultProps = {
    loading: false,
    invoiceInitialsError: null,
    initialInvoiceData: null,
};

export default ConnectedInvoiceForm;
