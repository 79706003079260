// TODO: It would be nicer to use hooks (`useFormik` or `useFormikContext`) instead of
//       `withFormik`
import { useState, useEffect } from 'react';
import qs from 'query-string';

export const reverseUrl = (urlName, ...params) => {
    // DJ_CONST.reverse is mocked as a function in storybook
    if (typeof DJ_CONST.reverse === 'function') {
        return DJ_CONST.reverse(urlName, ...params);
    }
    return DJ_CONST.reverse[urlName](...params);
};

export const updateQueryString = query => {
    const previousQuery = qs.parse(window.location.search);
    return { ...previousQuery, ...query };
};

export const setQueryStringWithoutPageReload = query => {
    const url = `${window.location.pathname}?${qs.stringify(query)}`;
    window.history.replaceState(query, '', url);
};

export const useQueryString = (key, initialValue, options = {}) => {
    const queryItems = qs.parse(window.location.search, {
        parseNumbers: false,
        parseBooleans: true,
        allowBlank: false,
        ...options,
    });
    let queryValue = queryItems[key];
    // If the initial value is an array and query contains a single value then convert
    // it to array, because it is impossible to determine if a single selected item
    // should be an array or not.
    if (
        !!queryValue &&
        Array.isArray(initialValue) &&
        !Array.isArray(queryValue)
    ) {
        queryValue = [queryValue];
    }
    const [value, setValue] = useState(
        options.allowBlank === true && queryValue === ''
            ? queryValue
            : queryValue || initialValue,
    );
    useEffect(() => {
        const query = updateQueryString({ [key]: value });
        setQueryStringWithoutPageReload(query);
    }, [value]);
    return [value, setValue];
};
