import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    timberWarehouses: null,
    totalPages: null,
    loading: false,
    error: null,
};

const timberWarehouseListSlice = createSlice({
    name: 'timberWarehouseList',
    initialState,
    reducers: {
        fetchTimberWarehouses(state) {
            state.loading = true;
        },
        timberWarehousesFetched(state, action) {
            state.loading = false;
            state.response = action.response;
            state.timberWarehouses = action.response?.results;
            // eslint-disable-next-line camelcase
            state.totalPages = action.response?.total_pages;
            state.error = null;
        },
        timberWarehousesError(state, action) {
            state.loading = false;
            state.error = action.error;
        },
    },
});

export const {
    actions: timberWarehouseListActions,
    reducer,
} = timberWarehouseListSlice;
