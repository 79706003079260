// TODO: Cover with tests
import {
    AnchorButton,
    HTMLTable,
    NonIdealState,
    Popover,
    Spinner,
} from '@blueprintjs/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import DoubleScrollbar from '@proscom/react-double-scrollbar';

import { gettext } from 'utils/text';
import { floatformat } from 'utils/formatting';
import { reverseUrl, useQueryString } from 'utils/urls';
import { fromISODateString, toLocaleDateString } from 'utils/date';
import { useEffectExceptOnMount } from 'utils/hooks';
import { fetchEnergyWarehouses } from 'ducks/stock_management/energyWarehouseList';
import {
    EnergyWarehouseListShape,
    AverageUnitCostShape,
} from 'shapes/stockManagement';
import { CustomNonIdealState } from '../NonIdealStates';
import {
    TableColumnSelector,
    TableFilterGroup,
    TablePagination,
} from '../Table';
import { WAREHOUSE_TYPES, WarehouseStatusFilter } from '../WarehouseFilters';

const COLUMN_GROUP = {
    LOCATION: 'location',
};
const COLUMN_GROUPS = [
    { id: COLUMN_GROUP.LOCATION, title: gettext('Location') },
];

// Somehow, gettext fails to detect half of this when it is inline in the code below, so as workaround define all
// strings here.
const totalsLabels = {
    waybillAmounts: gettext('Total waybill amount'),
    waybillCosts: gettext('Total waybill cost'),
    deductionAmount: gettext('Total deduction amount'),
    deductionCost: gettext('Total deduced cost'),
};

const AverageCostPopover = ({ averageUnitCost }) => {
    const {
        latest_average_unit_cost_entry: lastEntry,
        total_waybill_amounts: totalWaybillAmounts,
        total_deduction_amount: totalDeductionAmount,
        total_waybill_costs: totalWaybillCosts,
        total_deduction_cost: totalDeductionCost,
        waybill_numbers_included: waybillNumbersIncluded,
        outgoing_buckets_included: outgoingBucketsIncluded,
    } = averageUnitCost;

    if (!averageUnitCost || !averageUnitCost.average_cost) {
        return <> </>;
    }

    return (
        <Popover
            target={<span>{floatformat(averageUnitCost.average_cost, 3)}</span>}
            content={
                <HTMLTable condensed>
                    <tr>
                        <th>{gettext('Last entry')}</th>
                        <td>{lastEntry ? floatformat(lastEntry, 3) : '-'}</td>
                    </tr>
                    <tr>
                        <th>{gettext('Waybills')}</th>
                        <td
                            title={waybillNumbersIncluded
                                .map(e => `${e}`)
                                .join(', ')}
                        >
                            {waybillNumbersIncluded.length}
                        </td>
                    </tr>
                    <tr>
                        <th>{gettext('Buckets')}</th>
                        <td
                            title={outgoingBucketsIncluded
                                .map(e => `${e}`)
                                .join(', ')}
                        >
                            {outgoingBucketsIncluded.length}
                        </td>
                    </tr>
                    <tr>
                        <th>{totalsLabels.waybillAmounts}</th>
                        <td>{floatformat(totalWaybillAmounts, 3)}</td>
                    </tr>
                    <tr>
                        <th>{totalsLabels.waybillCosts}</th>
                        <td>{floatformat(totalWaybillCosts, 2)}</td>
                    </tr>
                    <tr>
                        <th>{totalsLabels.deductionAmount}</th>
                        <td>{floatformat(totalDeductionAmount, 3)}</td>
                    </tr>
                    <tr>
                        <th>{totalsLabels.deductionCost}</th>
                        <td>{floatformat(totalDeductionCost, 2)}</td>
                    </tr>
                </HTMLTable>
            }
        />
    );
};

AverageCostPopover.propTypes = {
    averageUnitCost: AverageUnitCostShape.isRequired,
};

export const EnergyWarehouseList = ({
    energyWarehouses,
    loading,
    totalPages,
    error,
    fetchEnergyWarehouseList,
}) => {
    const [page, changePage] = useQueryString('page', 1, {
        parseNumbers: true,
    });
    const [groups, changeGroups] = useQueryString('groups', []);
    const [search, changeSearch] = useQueryString('search', '');
    const [status, changeStatus] = useQueryString('status', '', {
        parseNumbers: true,
    });
    const [debouncedFetchEnergyWarehouseList] = useDebouncedCallback(
        fetchEnergyWarehouseList,
        500,
    );

    const onPageChange = useCallback(
        newPage => {
            changePage(newPage);
            fetchEnergyWarehouseList({ page: newPage, search, status });
        },
        [fetchEnergyWarehouseList, changePage, search, status],
    );

    // An effect for the first page load
    useEffect(() => {
        fetchEnergyWarehouseList({ page, search, status });
    }, []);
    // An effect for filters that do not work on the first mount
    useEffectExceptOnMount(() => {
        changePage(1);
        debouncedFetchEnergyWarehouseList({ page: 1, search, status });
    }, [changePage, debouncedFetchEnergyWarehouseList, search, status]);

    const filters = useMemo(
        () => (
            <TableFilterGroup
                search={search}
                changeSearch={changeSearch}
                searchPlaceholder={gettext(
                    'Search for energy warehouse by code or name...',
                )}
                customFilters={
                    <WarehouseStatusFilter
                        status={status}
                        changeStatus={changeStatus}
                        warehouseType={WAREHOUSE_TYPES.ENERGY}
                    />
                }
            />
        ),
        [search, changeSearch, status, changeStatus],
    );

    const pagination = useMemo(() => {
        if (totalPages === null) {
            return null;
        }

        return (
            <TablePagination
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
            />
        );
    }, [page, totalPages, onPageChange]);

    const navigation = useMemo(
        () => (
            <div className="mb-4">
                {filters}
                <div className="float-right">
                    <TableColumnSelector
                        selectedGroups={groups}
                        changeGroups={changeGroups}
                        groups={COLUMN_GROUPS}
                    />
                    {pagination}
                </div>
            </div>
        ),
        [groups, pagination],
    );

    const htmlTable = useMemo(() => {
        if (energyWarehouses === null) {
            return null;
        }

        if (!energyWarehouses.length) {
            return (
                <CustomNonIdealState
                    icon="search"
                    title={gettext('Oops! No energy warehouses found..')}
                    description={
                        <div>
                            <p>
                                {gettext(
                                    'No energy warehouses found matching your filters.',
                                )}
                            </p>
                        </div>
                    }
                    action={
                        <AnchorButton
                            text={gettext('Clear filters')}
                            href={reverseUrl(
                                'stock_management:energy-warehouse-list',
                            )}
                        />
                    }
                />
            );
        }

        return (
            <div className="table-full-width">
                <DoubleScrollbar>
                    <HTMLTable bordered condensed interactive striped>
                        <thead>
                            <tr>
                                <th colSpan={11}>&nbsp;</th>
                                {groups.indexOf(COLUMN_GROUP.LOCATION) >= 0 ? (
                                    <th colSpan={6}>{gettext('Location')}</th>
                                ) : null}
                            </tr>
                            <tr>
                                <th className="cell-minimal">&nbsp;</th>
                                <th key="energy-warehouse-name">
                                    {gettext('Name')}
                                </th>
                                <th key="energy-warehouse-code">
                                    {gettext('Code')}
                                </th>
                                <th key="energy-warehouse-status">
                                    {gettext('Status')}
                                </th>
                                <th key="energy-warehouse-date">
                                    {gettext('Date')}
                                </th>
                                <th key="energy-warehouse-company">
                                    {gettext('Company')}
                                </th>
                                <th key="energy-warehouse-forest-master">
                                    {gettext('Forest master')}
                                </th>
                                <th key="energy-warehouse-seller">
                                    {gettext('Seller')}
                                </th>
                                <th key="energy-warehouse-notice-number">
                                    {gettext('Notice number')}
                                </th>
                                <th key="energy-warehouse-notice-date">
                                    {gettext('Notice date')}
                                </th>
                                {groups.indexOf(COLUMN_GROUP.LOCATION) >= 0
                                    ? [
                                          <th key="energy-warehouse-latitude">
                                              {gettext('Latitude')}
                                          </th>,
                                          <th key="energy-warehouse-longitude">
                                              {gettext('Longitude')}
                                          </th>,
                                          <th key="energy-warehouse-county">
                                              {gettext('County')}
                                          </th>,
                                          <th key="energy-warehouse-parish">
                                              {gettext('Parish')}
                                          </th>,
                                          <th key="energy-warehouse-village">
                                              {gettext('Village')}
                                          </th>,
                                          <th key="energy-warehouse-cadastre">
                                              {gettext('Cadastral number')}
                                          </th>,
                                      ]
                                    : []}
                                <th key="energy-warehouse-average-unit-cost">
                                    {gettext('Average unit price')}
                                </th>
                                <th key="energy-warehouse-average-assortment-cost">
                                    {gettext('Average assortment cost')}
                                </th>
                                <th key="energy-warehouse-stock">
                                    {gettext('Stock')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {energyWarehouses.map(energyWarehouse => (
                                <tr key={energyWarehouse.id}>
                                    <th className="cell-minimal">
                                        <a
                                            href={reverseUrl(
                                                'stock_management:energy-warehouse-assortment-list',
                                                { pk: energyWarehouse.id },
                                            )}
                                            title={gettext('View stock')}
                                        >
                                            <span className="bp3-icon-standard bp3-icon-stacked-chart" />
                                        </a>
                                    </th>
                                    <td key="energy-warehouse-name">
                                        {energyWarehouse.name}
                                    </td>
                                    <td key="energy-warehouse-code">
                                        {energyWarehouse.code}
                                    </td>
                                    <td key="energy-warehouse-status">
                                        {energyWarehouse.status_display}
                                    </td>
                                    <td key="energy-warehouse-date">
                                        {toLocaleDateString(
                                            fromISODateString(
                                                energyWarehouse.date,
                                            ),
                                        )}
                                    </td>
                                    <td key="energy-warehouse-company">
                                        {energyWarehouse.company_name}
                                    </td>
                                    <td key="energy-warehouse-forest-master">
                                        {energyWarehouse.forest_master !== null
                                            ? `${energyWarehouse.forest_master.name} (${energyWarehouse.forest_master.person_code})`
                                            : ''}
                                    </td>
                                    <td key="energy-warehouse-seller">
                                        {energyWarehouse.previous_owner_name}
                                    </td>
                                    <td key="energy-warehouse-notice-number">
                                        {energyWarehouse.notice_number}
                                    </td>
                                    <td key="energy-warehouse-notice-date">
                                        {energyWarehouse.notice_date}
                                    </td>
                                    {groups.indexOf(COLUMN_GROUP.LOCATION) >= 0
                                        ? [
                                              <td key="energy-warehouse-latitude">
                                                  {energyWarehouse.latitude}
                                              </td>,
                                              <td key="energy-warehouse-longitude">
                                                  {energyWarehouse.longitude}
                                              </td>,
                                              <td key="energy-warehouse-county">
                                                  {energyWarehouse.county}
                                              </td>,
                                              <td key="energy-warehouse-parish">
                                                  {energyWarehouse.parish}
                                              </td>,
                                              <td key="energy-warehouse-village">
                                                  {energyWarehouse.village}
                                              </td>,
                                              <td key="energy-warehouse-cadastre">
                                                  {
                                                      energyWarehouse.cadastral_number
                                                  }
                                              </td>,
                                          ]
                                        : []}
                                    <td key="energy-warehouse-average-unit-cost">
                                        <AverageCostPopover
                                            averageUnitCost={
                                                energyWarehouse.average_unit_cost
                                            }
                                        />
                                        <a
                                            href={reverseUrl(
                                                'stock_management:energy-warehouse-average-unit-cost-entry-list',
                                                {
                                                    pk: energyWarehouse.id,
                                                },
                                            )}
                                            className="ml-2"
                                        >
                                            <span className="bp3-icon-standard bp3-icon-edit" />
                                        </a>
                                    </td>
                                    <td key="energy-warehouse-average-assortment-costs">
                                        {Object.keys(
                                            energyWarehouse.average_assortment_costs,
                                        ).map(assortment => (
                                            <div
                                                key={`assortment-cost-${assortment}`}
                                            >
                                                <span className="mr-1">
                                                    {assortment}:
                                                </span>
                                                <span className="mr-1">
                                                    {floatformat(
                                                        energyWarehouse
                                                            .average_assortment_costs[
                                                            assortment
                                                        ],
                                                        3,
                                                    )}
                                                </span>
                                            </div>
                                        ))}
                                    </td>
                                    <td key="energy-warehouse-stock">
                                        {energyWarehouse.assortments.map(
                                            assortment => (
                                                <div
                                                    key={`${assortment.name}-${assortment.unit}`}
                                                >
                                                    <span className="mr-1">
                                                        {assortment.name}
                                                    </span>
                                                    <span className="mr-1">
                                                        {floatformat(
                                                            assortment.stock,
                                                            3,
                                                        )}
                                                    </span>
                                                    <span>
                                                        {assortment.unit}
                                                    </span>
                                                </div>
                                            ),
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </HTMLTable>
                </DoubleScrollbar>
            </div>
        );
    }, [loading, energyWarehouses, groups]);

    if (!loading && error !== null) {
        return (
            <>
                <div className="container-fluid">{navigation}</div>
                <NonIdealState
                    icon="issue"
                    title={gettext('Oops! Could not load energy warehouses..')}
                    description={error}
                />
            </>
        );
    }

    if (loading) {
        return (
            <>
                <div className="container-fluid">{navigation}</div>
                <Spinner className="mt-5" />
            </>
        );
    }

    return (
        <>
            <div className="container-fluid">{navigation}</div>
            <div className="container-fluid pb-5">{htmlTable}</div>
        </>
    );
};

EnergyWarehouseList.propTypes = {
    loading: PropTypes.bool,
    energyWarehouses: PropTypes.arrayOf(EnergyWarehouseListShape),
    totalPages: PropTypes.number,
    error: PropTypes.string,
    fetchEnergyWarehouseList: PropTypes.func.isRequired,
};

EnergyWarehouseList.defaultProps = {
    loading: false,
    energyWarehouses: null,
    totalPages: null,
    error: null,
};

const mapStateToProps = state => ({
    /* eslint-disable camelcase */
    loading: state.energyWarehouses.loading,
    energyWarehouses: state.energyWarehouses?.response?.results,
    totalPages: state.energyWarehouses?.response?.total_pages,
    error: state.energyWarehouses.error,
    /* eslint-enable camelcase */
});

const mapDispatchToProps = {
    fetchEnergyWarehouseList: fetchEnergyWarehouses,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EnergyWarehouseList);
