import { call, put, spawn, takeLatest } from 'redux-saga/effects';

import api from '../api';
import {
    FETCH_ANALYZER_CHOICES,
    FETCH_ANALYZER_CHOICES_STARTED,
    FETCH_ANALYZER_CHOICES_SUCCESSFUL,
    FETCH_ANALYZER_CHOICES_FAILED,
} from '../ducks/forest_estimates/forestEstimateAnalyzerChoices';

function* fetchForestEstimateAnalyzerChoices() {
    yield put({ type: FETCH_ANALYZER_CHOICES_STARTED });
    try {
        const response = yield call(api.forestEstimates.analyzerChoices.fetch);
        yield put({ type: FETCH_ANALYZER_CHOICES_SUCCESSFUL, response });
    } catch (error) {
        yield put({ type: FETCH_ANALYZER_CHOICES_FAILED, error });
    }
}

function* fetchForestEstimateAnalyzerChoicesSaga() {
    yield takeLatest(
        FETCH_ANALYZER_CHOICES,
        fetchForestEstimateAnalyzerChoices,
    );
}

export default function* actCreationRootSaga() {
    yield spawn(fetchForestEstimateAnalyzerChoicesSaga);
}
