import React from 'react';
import PropTypes from 'prop-types';

import { H2 } from '@blueprintjs/core';
import { gettext, ngettext } from '../utils/text';

const ForestEstimateHeader = ({ forestEstimate }) => {
    let propertyName = forestEstimate ? forestEstimate.property_name : '';
    if (!propertyName) {
        propertyName = forestEstimate ? gettext('No name') : gettext('New');
    }
    const numberOfRows = forestEstimate
        ? ` (${forestEstimate ? forestEstimate.number_of_rows : 0} ${ngettext(
              'felling row',
              'felling rows',
              forestEstimate ? forestEstimate.number_of_rows : 0,
          )})`
        : '';
    return (
        <H2>
            {gettext('Forest estimate')} - {propertyName}
            {numberOfRows}
        </H2>
    );
};

ForestEstimateHeader.propTypes = {
    forestEstimate: PropTypes.shape({
        property_name: PropTypes.string.isRequired,
        number_of_rows: PropTypes.number.isRequired,
    }),
};
ForestEstimateHeader.defaultProps = {
    forestEstimate: undefined,
};

export default ForestEstimateHeader;
