import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';

const EstimateTableSpacer = ({ species, title, extraColumns }) => (
    <tr>
        <th className="text-right py-0 border-right">{title || <>&nbsp;</>}</th>
        {species.map(specie => (
            <React.Fragment key={specie.name}>
                <td className="py-0 border-right">&nbsp;</td>
                <td className="py-0 border-right">&nbsp;</td>
                <td className="py-0 border-right">&nbsp;</td>
                <td className="py-0 border-right">&nbsp;</td>
            </React.Fragment>
        ))}
        {new Array(extraColumns).fill(0).map((_, index) => (
            <td
                key={uuidv4()}
                className={classNames('py-0', {
                    'border-right': index + 1 < extraColumns,
                })}
            >
                &nbsp;
            </td>
        ))}
    </tr>
);

EstimateTableSpacer.propTypes = {
    species: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    title: PropTypes.string,
    extraColumns: PropTypes.number,
};

EstimateTableSpacer.defaultProps = {
    title: '',
    extraColumns: 4,
};

export default EstimateTableSpacer;
