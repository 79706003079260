// TODO: Cover with tests

/*
 * We basically need to have some configuration to define filters properly.
 *
 * stateKey: Specifies where the filter options will be stored in.
 * apiPath: Specifies where the filter options will be fetched from.
 * queryParamName: Specifies query parameter to fetch filtered options from backend.
 *
 * `filterReducer` is base reducer for all filters. We generate named reducers
 * out of that. (Check `createNamedWrapperReducer` for further information)
 * */

import api from '../api';

export const FILTERS = {
    WAYBILL: {
        BUYERS: {
            stateKey: 'buyers',
            apiPath: api.waybills.buyersList.fetch,
            queryParamName: 'buyer_name',
        },
        CHOPPERS: {
            stateKey: 'choppers',
            apiPath: api.waybills.choppersList.fetch,
            queryParamName: 'chopper_name',
        },
        DESTINATIONS: {
            stateKey: 'destinations',
            apiPath: api.waybills.destinationsList.fetch,
            queryParamName: 'destination_name',
        },
        DRIVERS: {
            stateKey: 'drivers',
            apiPath: api.waybills.driversList.fetch,
            queryParamName: 'driver_name',
        },
        MATERIALS: {
            stateKey: 'materials',
            apiPath: api.waybills.materialsList.fetch,
            queryParamName: 'material_name',
        },
        ORIGINS: {
            stateKey: 'origins',
            apiPath: api.waybills.originsList.fetch,
            queryParamName: 'origin_name',
        },
        SELLERS: {
            stateKey: 'sellers',
            apiPath: api.waybills.sellersList.fetch,
            queryParamName: 'seller_name',
        },
        TRANSPORTERS: {
            stateKey: 'transporters',
            apiPath: api.waybills.transportersList.fetch,
            queryParamName: 'transporter_name',
        },
        TRUCKS: {
            stateKey: 'trucks',
            apiPath: api.waybills.trucksList.fetch,
            queryParamName: 'truck_number',
        },
        UNITS: {
            stateKey: 'units',
            apiPath: api.waybills.unitsList.fetch,
            queryParamName: 'unit',
        },
        HAS_WEIGHT: {
            stateKey: 'has_weight',
            apiPath: api.waybills.weightFilterOptionsList.fetch,
            queryParamName: 'has_weight',
        },
        FORESTMASTER: {
            stateKey: 'forest_masters',
            apiPath: api.waybills.forestMastersList.fetch,
            queryParamName: 'forest_master_name',
        },
    },
    ENERGY_WAREHOUSE: {
        STATUSES: {
            stateKey: 'energyWarehouseStatuses',
            apiPath: api.stockManagement.energyWarehouseStatuses.fetch,
            queryParamName: 'status',
        },
    },
    TIMBER_WAREHOUSE: {
        STATUSES: {
            stateKey: 'timberWarehouseStatuses',
            apiPath: api.stockManagement.timberWarehouseStatuses.fetch,
            queryParamName: 'status',
        },
    },
};

// Actions
export const FETCH_FILTER_ITEMS = 'FILTERS/FETCH_FILTER_ITEMS';
export const FETCH_FILTER_ITEMS_STARTED = 'FILTERS/FETCH_FILTER_ITEMS_STARTED';
export const FETCH_FILTER_ITEMS_SUCCESSFUL =
    'FILTERS/FETCH_FILTER_ITEMS_SUCCESSFUL';
export const FETCH_FILTER_ITEMS_FAILED = 'FILTERS/FETCH_FILTER_ITEMS_FAILED';

// Initial state for reducer
export const initialState = {
    loading: false,
    response: [],
    error: null,
};

// Reducer(s)
export default function filterReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_FILTER_ITEMS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case FETCH_FILTER_ITEMS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case FETCH_FILTER_ITEMS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchFilterItems = (filter, queryParams) => ({
    type: FETCH_FILTER_ITEMS,
    filter, // A filter entity from FILTERS
    queryParams,
});
