import PropTypes from 'prop-types';
import React from 'react';

import { WaybillFilterQueryShape } from 'shapes/waybills';
import { gettext } from '../../utils/text';
import { FILTERS } from '../../ducks/filters';
import TableEntityFilter from '../Table/TableEntityFilter';

const WaybillDriverFilter = ({ driverName, changeDriverName, query }) => {
    return (
        <TableEntityFilter
            initialValue={driverName}
            onChange={changeDriverName}
            itemsToOptions={item => ({
                text: `${item.driver_name} [${item.driver_person_code}]`,
                value: item.driver_name,
            })}
            name="driver_filter"
            buttonPlaceholder={gettext('Select driver')}
            buttonLeftIcon="person"
            filterType={FILTERS.WAYBILL.DRIVERS}
            query={query}
        />
    );
};

WaybillDriverFilter.propTypes = {
    driverName: PropTypes.string.isRequired,
    changeDriverName: PropTypes.func.isRequired,
    query: WaybillFilterQueryShape.isRequired,
};

export default WaybillDriverFilter;
