// Actions
export const UPDATE_PURCHASE_INVOICE_NR = 'ACTS/UPDATE_PURCHASE_INVOICE_NR';
export const UPDATE_PURCHASE_INVOICE_NR_STARTED =
    'ACTS/UPDATE_PURCHASE_INVOICE_NR_STARTED';
export const UPDATE_PURCHASE_INVOICE_NR_SUCCESSFUL =
    'ACTS/UPDATE_PURCHASE_INVOICE_NR_SUCCESSFUL';
export const UPDATE_PURCHASE_INVOICE_NR_FAILED =
    'ACTS/UPDATE_PURCHASE_INVOICE_NR_FAILED';

// Action creators
export const updatePurchaseInvoiceNumber = (
    payload,
    callback,
    errorCallback,
) => ({
    type: UPDATE_PURCHASE_INVOICE_NR,
    payload,
    callback,
    errorCallback,
});
