import React from 'react';
import PropTypes from 'prop-types';
import {
    EditableText,
    Intent,
    Classes,
    Popover,
    Button,
} from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import { floatformat, big } from '../../utils/formatting';
import { RTKApi } from '../../queries';
import {
    DEFAULT_LOCALE_CODE,
    fromISODateString,
    toISODateString,
} from '../../utils/date';
import { pgettext } from '../../utils/text';
import {
    DATE_FNS_LOCALE_UTILS,
    formatDate,
} from '../../utils/dateFnsLocaleUtils';

/**
 * A component that controls one or more cells (<td> by default, overridable through Tag prop) displaying value(s) from
 * either ForestEstimate or ForestEstiamteTreeSpecie (depending on `isEstimateField`) and allowing to edit those values.
 *
 * Edits are on the go without confirmation, i.e as soon as user goes away from the cell or presses enter request is
 * made and based on request data is saved or error is shown so that user can correct the input.
 *
 * * `valueKey` can be a string, or Array of strings. This is used to get the data from ForestEstimate or
 *    ForestEstiamteTreeSpecie object. If Array is passed, multiple cells are rendered. All cells are saved together.
 *    Array version of the component should be used for values that depend on each other in regard to validation. Use
 *    case: share of wood types within one tree specie should not exceed 100%, and error on one field can be resolved
 *    by editing another field. I.e. initial data is [0, 30, 70], first edit sets it to [20, 30, 70] causing an error
 *    for whole group of fields and failure of saving, second edit sets it to [20, 30, 50] clearing error on whole
 *    group of fields. Without Array version, in this case user would be forced to do edits in exact order [0, 30, 70]
 *    -> [0, 30, 50] -> [20, 30, 50] which is not good UX.
 *
 * * `defaultFallbackKey` should be of same type as valueKey (string or Array of strings of same length as valueKey).
 *    This is used to inform the user of default value of this cell that is coming from other key on the same edited
 *    object; it is done with a placeholder. Use case: in server side calculations, some values have override fields,
 *    if those are set to null other field is used. In such case `defaultFallbackKey` needs to be set to this other
 *    field key. I.e. automobile transport amount can be overridden with a certain value, and defaults to total
 *    realization amount.
 *
 * * `defaultFallbackValue` should be of same type as valueKey, and used in same way as defaultFallbackKey but instead
 *    of taking the value from other fields it takes a static value (i.e. field can default to 0).
 *
 * If neither of defaultFallback props are set, the fallback value is '0' if decimalPlaces is specified, else '...'.
 *
 * `decimalPlaces` specifies precision options, if it is defined then the field is treated as decimal.
 * `isDateField` specified field as date field. Fields that don't have `decimalPlaces` and aren't date fields
 * are considered to be freeform text.
 */
const EditableCell = ({
    forestEstimateId,
    valueKey,
    defaultFallbackKey,
    defaultFallbackValue,
    nullable,
    specieName,
    decimalPlaces,
    isDateField,
    isEstimateField,
    className,
    multiline,
    Tag,
}) => {
    const valueKeys = React.useMemo(() => {
        if (typeof valueKey === 'string') {
            return [valueKey];
        }
        return valueKey;
    }, [valueKey]);

    const defaultFallbackKeys = React.useMemo(() => {
        if (typeof defaultFallbackKey === 'string') {
            return [defaultFallbackKey];
        }
        return defaultFallbackKey;
    }, [defaultFallbackKey]);

    const [editingIndex, setEditingIndex] = React.useState(null);

    const convertValueForDisplay = React.useCallback(
        value => {
            if (decimalPlaces === undefined) {
                return value;
            }
            if ((value === null || value === '') && nullable) {
                return '';
            }
            return floatformat(value, decimalPlaces);
        },
        [decimalPlaces],
    );

    const convertValueForSaving = React.useCallback(
        value => {
            if (decimalPlaces === undefined) {
                return value;
            }
            if (value === '' || value === null) {
                if (nullable) {
                    return null;
                }
                return big('0').toFixed();
            }
            return big(value).toFixed();
        },
        [decimalPlaces],
    );

    const {
        useSetForestEstimateSpeciesMutation,
        useUpdateForestEstimateAssessmentMutation,
        useGetForestEstimateAssessmentQuery,
    } = RTKApi;

    const {
        data: forestEstimate,
        isLoading: isLoadingForestEstimate,
        isFetching: isFetchingForestEstimate,
        isSuccess: isSuccessForestEstimate,
        requestId: requestIdForestEstimate,
    } = useGetForestEstimateAssessmentQuery(`${forestEstimateId}`, {
        skip: !forestEstimateId,
    });

    const lastConfirmedValues = React.useMemo(
        () =>
            valueKeys.map(key =>
                isEstimateField
                    ? forestEstimate[key]
                    : forestEstimate.felling_tree_species.filter(
                          specie => specie.name === specieName,
                      )[0][key],
            ),
        [valueKeys, forestEstimate, isEstimateField],
    );

    const defaultFallbackValues = React.useMemo(() => {
        const explicitDefaultFallbackValues =
            (typeof defaultFallbackValue === 'string'
                ? [defaultFallbackValue]
                : defaultFallbackValue) || [];

        return defaultFallbackKeys
            .map((key, index) => {
                if (explicitDefaultFallbackValues[index] !== undefined) {
                    return explicitDefaultFallbackValues[index];
                }
                return isEstimateField
                    ? forestEstimate[key]
                    : forestEstimate.felling_tree_species.filter(
                          specie => specie.name === specieName,
                      )[0][key];
            })
            .map(value =>
                decimalPlaces && value
                    ? floatformat(value, decimalPlaces)
                    : value,
            );
    }, [valueKeys, forestEstimate, isEstimateField, defaultFallbackValue]);

    const [
        updateSpeciesData,
        speciesDataUpdateResult,
    ] = useSetForestEstimateSpeciesMutation();

    const [
        updateForestEstimateData,
        forestEstimateDataUpdateResult,
    ] = useUpdateForestEstimateAssessmentMutation();

    const updateDataResult = React.useMemo(
        () =>
            isEstimateField
                ? forestEstimateDataUpdateResult
                : speciesDataUpdateResult,
        [
            forestEstimateDataUpdateResult,
            speciesDataUpdateResult,
            isEstimateField,
        ],
    );

    const [currentValues, setCurrentValues] = React.useState(() =>
        lastConfirmedValues.map(lastConfirmedValue =>
            convertValueForDisplay(lastConfirmedValue),
        ),
    );

    const getUpdateFromValue = React.useCallback(
        (value, index) => {
            const updateData = valueKeys.reduce(
                (data, key, keyIndex) => ({
                    ...data,
                    [key]:
                        index === keyIndex
                            ? convertValueForSaving(value)
                            : convertValueForSaving(currentValues[keyIndex]),
                }),
                {},
            );

            return isEstimateField
                ? { id: forestEstimateId, data: updateData }
                : {
                      id: forestEstimateId,
                      data: [
                          {
                              name: specieName,
                              ...updateData,
                          },
                      ],
                  };
        },
        [forestEstimateId, isEstimateField, valueKeys, currentValues],
    );

    const intent = React.useMemo(() => {
        if (updateDataResult.isLoading || updateDataResult.isFetching) {
            return Intent.PRIMARY;
        }
        if (updateDataResult.isSuccess) {
            return Intent.SUCCESS;
        }
        if (updateDataResult.isError) {
            return Intent.WARNING;
        }
        return Intent.SUCCESS;
    }, [
        updateDataResult.isSuccess,
        updateDataResult.isError,
        updateDataResult.isLoading,
        updateDataResult.isFetching,
    ]);

    const errorTexts = React.useMemo(() => {
        if (!updateDataResult.isError) {
            return valueKeys.map(() => '');
        }

        if (Array.isArray(updateDataResult.error.data)) {
            return valueKeys.map(() => updateDataResult.error.data.join(', '));
        }

        const errorData = isEstimateField
            ? [updateDataResult.error.data]
            : updateDataResult.error.data.felling_tree_species || {};

        return valueKeys.map(key =>
            errorData
                .reduce((accumulator, data) => {
                    const error = data[key];
                    if (!error) {
                        return accumulator;
                    }
                    return [
                        ...accumulator,
                        ...(typeof error === 'string' ? [error] : error),
                    ];
                }, [])
                .join(', '),
        );
    }, [updateDataResult.isError, updateDataResult.error, isEstimateField]);

    const onChange = React.useCallback(
        (value, index) =>
            setCurrentValues(previousCurrentValues =>
                previousCurrentValues.map((previousValue, previousValueIndex) =>
                    previousValueIndex === index ? value : previousValue,
                ),
            ),
        [],
    );
    const onEdit = React.useCallback(index => {
        setEditingIndex(index);
    }, []);
    const onConfirm = React.useCallback(
        (value, index) => {
            if (
                convertValueForSaving(lastConfirmedValues[index]) !==
                    convertValueForSaving(value) ||
                errorTexts[index]
            ) {
                setEditingIndex(null);
                if (isEstimateField) {
                    updateForestEstimateData(getUpdateFromValue(value, index));
                } else {
                    updateSpeciesData(getUpdateFromValue(value, index));
                }
            } else {
                setCurrentValues(previousCurrentValues =>
                    previousCurrentValues.map(
                        (previousValue, previousValueIndex) =>
                            index === previousValueIndex
                                ? convertValueForDisplay(value)
                                : previousValue,
                    ),
                );
            }
        },
        [
            getUpdateFromValue,
            lastConfirmedValues,
            convertValueForSaving,
            errorTexts,
        ],
    );

    React.useEffect(() => {
        if (
            isLoadingForestEstimate ||
            isFetchingForestEstimate ||
            updateDataResult.isLoading ||
            !isSuccessForestEstimate
        ) {
            return;
        }

        const updatedValues = valueKeys.reduce((accumulator, key, index) => {
            if (errorTexts[index]) {
                return accumulator;
            }
            const newValue = convertValueForDisplay(lastConfirmedValues[index]);
            if (currentValues[index] === newValue) {
                return accumulator;
            }
            if (index === editingIndex) {
                return accumulator;
            }
            return {
                ...accumulator,
                [key]: newValue,
            };
        }, {});

        if (!Object.keys(updatedValues).length === 0) {
            return;
        }
        setCurrentValues(previousCurrentValues =>
            valueKeys.map((key, index) =>
                updatedValues[key] !== undefined
                    ? updatedValues[key]
                    : previousCurrentValues[index],
            ),
        );
    }, [
        requestIdForestEstimate,
        isLoadingForestEstimate,
        isSuccessForestEstimate,
        isFetchingForestEstimate,
    ]);

    return (
        <>
            {valueKeys.map((key, index) => (
                <Tag key={key} className={className}>
                    {isDateField ? (
                        <Popover
                            target={
                                <Button minimal small intent={intent}>
                                    {(currentValues[index] &&
                                        formatDate(
                                            fromISODateString(
                                                currentValues[index],
                                            ),
                                        )) ||
                                        (defaultFallbackValues[index] &&
                                            formatDate(
                                                fromISODateString(
                                                    defaultFallbackValues[
                                                        index
                                                    ],
                                                ),
                                            )) ||
                                        '...'}
                                </Button>
                            }
                            content={
                                <DatePicker
                                    value={fromISODateString(
                                        currentValues[index],
                                    )}
                                    placeholder={
                                        defaultFallbackValues[index] || '...'
                                    }
                                    onChange={value =>
                                        onConfirm(
                                            toISODateString(value, 'date'),
                                            index,
                                        )
                                    }
                                    canClearSelection={nullable}
                                    clearButtonText={pgettext(
                                        'Clera date on date input',
                                        'Clear',
                                    )}
                                    showActionsBar
                                    locale={DEFAULT_LOCALE_CODE}
                                    localeUtils={DATE_FNS_LOCALE_UTILS}
                                />
                            }
                        />
                    ) : (
                        <EditableText
                            value={currentValues[index]}
                            placeholder={defaultFallbackValues[index] || '...'}
                            onConfirm={value => onConfirm(value, index)}
                            onChange={value => onChange(value, index)}
                            onEdit={() => onEdit(index)}
                            intent={intent}
                            multiline={multiline}
                            selectAllOnFocus={!multiline}
                            minLines={multiline ? 5 : undefined}
                        />
                    )}
                    {errorTexts[index] ? (
                        <p className={`${Classes.TEXT_SMALL} text-danger mt-2`}>
                            {errorTexts[index]}
                        </p>
                    ) : null}
                </Tag>
            ))}
        </>
    );
};

EditableCell.propTypes = {
    forestEstimateId: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
    ]).isRequired,
    valueKey: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    ]).isRequired,
    defaultFallbackKey: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    ]),
    defaultFallbackValue: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    ]),
    nullable: PropTypes.bool,
    specieName: PropTypes.string,
    decimalPlaces: PropTypes.number,
    isDateField: PropTypes.bool,
    isEstimateField: PropTypes.bool,
    className: PropTypes.string,
    Tag: PropTypes.string,
    multiline: PropTypes.bool,
};

EditableCell.defaultProps = {
    getUpdateFromValue: () => ({}),
    decimalPlaces: undefined,
    isDateField: false,
    isEstimateField: false,
    specieName: '',
    defaultFallbackKey: '',
    defaultFallbackValue: undefined,
    nullable: false,
    className: undefined,
    Tag: 'td',
    multiline: false,
};

export default EditableCell;
