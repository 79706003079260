// TODO: Cover with tests
import { call, put, spawn, takeLatest, takeLeading } from 'redux-saga/effects';

import api from '../api';
import {
    FETCH_INITIALS,
    FETCH_INITIALS_STARTED,
    FETCH_INITIALS_SUCCESSFUL,
    FETCH_INITIALS_FAILED,
} from '../ducks/acts/actInitials';
import {
    CREATE_ACT,
    CREATE_ACT_STARTED,
    CREATE_ACT_SUCCESSFUL,
    CREATE_ACT_FAILED,
} from '../ducks/acts/actCreation';

function* fetchActInitials(action) {
    yield put({ type: FETCH_INITIALS_STARTED });
    try {
        const response = yield call(
            api.acts.initials.fetch,
            null,
            action.query,
        );
        yield put({ type: FETCH_INITIALS_SUCCESSFUL, response });
    } catch (error) {
        yield put({ type: FETCH_INITIALS_FAILED, error });
    }
}

function* fetchActInitialsSaga() {
    yield takeLatest(FETCH_INITIALS, fetchActInitials);
}

function* createAct(action) {
    yield put({ type: CREATE_ACT_STARTED });
    try {
        const response = yield call(api.acts.create.post, null, action.payload);
        yield put({ type: CREATE_ACT_SUCCESSFUL, response });
        yield call(action.callback);
    } catch (error) {
        yield put({ type: CREATE_ACT_FAILED, error });
        yield call(action.errorCallback, error);
    }
}

function* createActSaga() {
    yield takeLeading(CREATE_ACT, createAct);
}

export default function* actCreationRootSaga() {
    yield spawn(fetchActInitialsSaga);
    yield spawn(createActSaga);
}
