import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, getIn } from 'formik';
import {
    Button,
    ButtonGroup,
    Callout,
    Card,
    Elevation,
    Intent,
    NumericInput,
} from '@blueprintjs/core';
import { gettext } from 'utils/text';
import { reverseUrl } from 'utils/urls';
import { getFieldErrors } from 'utils/formErrors';
import { returnFirstArg } from 'utils/functional';
import { v4 as uuidv4 } from 'uuid';
import FormField from 'forms/fields/FormField';
import CustomSelect from '../components/CustomSelect';

const DEFAULT_TREE_SPECIES_ROW_SIZE = 3;

const FellingRowForm = ({
    fellingRow,
    onFormSubmit,
    isCreationForm,
    fellingTypeChoices,
    treeSpecieNameChoices,
}) => {
    const [treeSpeciesState, setTreeSpeciesState] = useState([]);

    // Used to generate a new empty tree specie with uuid.
    // When we have regular id from 1 to postgres id max
    // That means we have that tree specie in DB
    // When we have UUID as id, that means not in DB yet
    const generateNewTreeSpecie = id => {
        let treeSpecieId = id;

        if (treeSpecieId === undefined || treeSpecieId === null) {
            treeSpecieId = uuidv4();
        }
        return {
            id: treeSpecieId,
        };
    };

    // Set state for current tree species
    // Minus values means they are new rows
    useEffect(() => {
        const treeSpecies = [];

        if (!fellingRow) {
            [...Array(DEFAULT_TREE_SPECIES_ROW_SIZE)].forEach(_ => {
                treeSpecies.push(generateNewTreeSpecie());
            });
        } else {
            fellingRow.tree_species.forEach(treeSpecie => {
                treeSpecies.push(generateNewTreeSpecie(treeSpecie.id));
            });

            const missingRowCount =
                DEFAULT_TREE_SPECIES_ROW_SIZE - fellingRow.tree_species.length;

            if (missingRowCount > 0) {
                [...Array(missingRowCount)].forEach(_ => {
                    treeSpecies.push(generateNewTreeSpecie());
                });
            }
        }

        setTreeSpeciesState(treeSpecies);
    }, []);

    const getGenericFieldValues = (nameStartsWith, formValues) => {
        return Object.keys(formValues)
            .filter(key => {
                return key.indexOf(nameStartsWith) === 0;
            })
            .reduce((newData, key) => {
                // eslint-disable-next-line no-param-reassign
                newData[key] = formValues[key];

                return newData;
            }, {});
    };

    const getInitialValues = () => {
        if (isCreationForm) {
            return {
                // Add default values here if there is any
            };
        }

        const treeSpeciesDefaults = {};

        fellingRow.tree_species.forEach(treeSpecie => {
            treeSpeciesDefaults[`tree-specie-name-${treeSpecie.id}`] =
                treeSpecie.name;
            treeSpeciesDefaults[`tree-specie-quantity-${treeSpecie.id}`] =
                treeSpecie.quantity;
            treeSpeciesDefaults[`tree-specie-id-${treeSpecie.id}`] =
                treeSpecie.id;
        });

        return {
            ...fellingRow,
            ...treeSpeciesDefaults,
            percentage_of_exit: fellingRow.percentage_of_exit
                ? fellingRow.percentage_of_exit
                : NumericInput.EMPTY_VALUE,
            round_material_harvesting_cost: fellingRow.round_material_harvesting_cost
                ? fellingRow.round_material_harvesting_cost
                : NumericInput.EMPTY_VALUE,
            round_material_transport_cost: fellingRow.round_material_transport_cost
                ? fellingRow.round_material_transport_cost
                : NumericInput.EMPTY_VALUE,
            energy_bush_harvesting_cost: fellingRow.energy_bush_harvesting_cost
                ? fellingRow.energy_bush_harvesting_cost
                : NumericInput.EMPTY_VALUE,
            energy_bush_transport_cost: fellingRow.energy_bush_transport_cost
                ? fellingRow.energy_bush_transport_cost
                : NumericInput.EMPTY_VALUE,
            stump_harvesting_cost: fellingRow.stump_harvesting_cost
                ? fellingRow.stump_harvesting_cost
                : NumericInput.EMPTY_VALUE,
            stump_transport_cost: fellingRow.stump_transport_cost
                ? fellingRow.stump_transport_cost
                : NumericInput.EMPTY_VALUE,
        };
    };

    const onSubmit = (values, { setErrors, setSubmitting }) => {
        const forestEstimateId = window.location.pathname.split('/')[2];

        const treeSpecieNames = getGenericFieldValues(
            'tree-specie-name',
            values,
        );
        const treeSpecieQuantities = getGenericFieldValues(
            'tree-specie-quantity',
            values,
        );
        const treeSpecieIds = getGenericFieldValues('tree-specie-id', values);
        const treeSpecieIsDeleteds = getGenericFieldValues(
            'tree-specie-is-deleted',
            values,
        );

        const treeSpecies = [];

        Object.keys(treeSpecieNames).forEach(treeSpecieName => {
            const matchingQuantityKey = treeSpecieName.replace(
                'tree-specie-name',
                'tree-specie-quantity',
            );
            const matchingIdKey = treeSpecieName.replace(
                'tree-specie-name',
                'tree-specie-id',
            );
            const matchingIsDeletedKey = treeSpecieName.replace(
                'tree-specie-name',
                'tree-specie-is-deleted',
            );

            treeSpecies.push({
                name: treeSpecieNames[treeSpecieName],
                quantity: treeSpecieQuantities[matchingQuantityKey],
                id: treeSpecieIds[matchingIdKey],
                is_deleted: treeSpecieIsDeleteds[matchingIsDeletedKey],
            });
        });

        const payload = {
            ...values,
            tree_species: treeSpecies,
            forest_estimate: Number(forestEstimateId),
            percentage_of_exit:
                values.percentage_of_exit === ''
                    ? null
                    : Number(values.percentage_of_exit),
            round_material_harvesting_cost:
                values.round_material_harvesting_cost === ''
                    ? null
                    : Number(values.round_material_harvesting_cost),
            round_material_transport_cost:
                values.round_material_transport_cost === ''
                    ? null
                    : Number(values.round_material_transport_cost),
            energy_bush_harvesting_cost:
                values.energy_bush_harvesting_cost === ''
                    ? null
                    : Number(values.energy_bush_harvesting_cost),
            energy_bush_transport_cost:
                values.energy_bush_transport_cost === ''
                    ? null
                    : Number(values.energy_bush_transport_cost),
            stump_harvesting_cost:
                values.stump_harvesting_cost === ''
                    ? null
                    : Number(values.stump_harvesting_cost),
            stump_transport_cost:
                values.stump_transport_cost === ''
                    ? null
                    : Number(values.stump_transport_cost),
        };

        onFormSubmit(
            payload,
            () => {
                setSubmitting(false);
                window.location = reverseUrl(
                    'forest_estimates:felling-row-list',
                    { pk: forestEstimateId },
                );
            },
            error => {
                const fieldErrors = getFieldErrors(error);

                if (fieldErrors !== null) {
                    setErrors(fieldErrors);
                } else {
                    console.error(error);
                }

                setSubmitting(false);
            },
        );
    };

    const validate = values => {
        const treeSpecieNames = getGenericFieldValues(
            'tree-specie-name',
            values,
        );
        const treeSpecieQuantities = getGenericFieldValues(
            'tree-specie-quantity',
            values,
        );

        const errors = {};

        Object.keys(treeSpecieNames).forEach(treeSpecieName => {
            const matchingQuantityKey = treeSpecieName.replace(
                'tree-specie-name',
                'tree-specie-quantity',
            );

            if (
                treeSpecieNames[treeSpecieName] &&
                !(matchingQuantityKey in treeSpecieQuantities)
            ) {
                errors[matchingQuantityKey] = gettext('Required');
            }

            if (
                treeSpecieNames[treeSpecieName] &&
                !treeSpecieQuantities[matchingQuantityKey]
            ) {
                errors[matchingQuantityKey] = gettext('Required');
            }
        });

        Object.keys(treeSpecieQuantities).forEach(treeSpecieQuantity => {
            const matchingNameKey = treeSpecieQuantity.replace(
                'tree-specie-quantity',
                'tree-specie-name',
            );

            if (
                treeSpecieQuantities[treeSpecieQuantity] &&
                !(matchingNameKey in treeSpecieNames)
            ) {
                errors[matchingNameKey] = gettext('Required');
            }

            if (
                treeSpecieQuantities[treeSpecieQuantity] &&
                !treeSpecieNames[matchingNameKey]
            ) {
                errors[matchingNameKey] = gettext('Required');
            }
        });

        return errors;
    };

    return (
        <Formik
            initialValues={getInitialValues()}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validate={values => validate(values)}
        >
            {({ handleSubmit, setFieldValue, errors }) => {
                const nonFieldErrors = getIn(errors, 'nonFieldErrors', null);

                const getInitialNameValue = key => {
                    if (!fellingRow || fellingRow.tree_species.length === 0) {
                        return null;
                    }

                    // eslint-disable-next-line camelcase
                    const fellingRowTreeSpecie = fellingRow?.tree_species.find(
                        treeSpecie => {
                            return key === treeSpecie.id;
                        },
                    );

                    if (fellingRowTreeSpecie) {
                        return fellingRowTreeSpecie.name;
                    }

                    return null;
                };

                const renderTreeSpecieRow = key => {
                    return (
                        <React.Fragment key={`tree-specie-form-row-${key}`}>
                            <div className="col-md-3">
                                <FormField
                                    name={`tree-specie-name-${key}`}
                                    label={gettext('Name')}
                                    inputComponent={CustomSelect}
                                    initialValue={getInitialNameValue(key)}
                                    items={treeSpecieNameChoices}
                                    onClear={() =>
                                        setFieldValue(
                                            `tree-specie-name-${key}`,
                                            null,
                                        )
                                    }
                                    getNextValue={returnFirstArg}
                                    buttonPlaceholder={gettext(
                                        'Select tree specie name',
                                    )}
                                    fill
                                    minimal
                                />
                            </div>
                            <div className="col-md-7">
                                <FormField
                                    name={`tree-specie-quantity-${key}`}
                                    label={gettext('Quantity')}
                                    inputComponent={NumericInput}
                                    placeholder="0.000"
                                    min="0"
                                    minorStepSize={0.001}
                                    clampValueOnBlur
                                    fill
                                    onValueChange={(
                                        valueAsNumber,
                                        valueAsString,
                                    ) =>
                                        setFieldValue(
                                            `tree-specie-quantity-${key}`,
                                            valueAsString,
                                        )
                                    }
                                />
                            </div>
                            <FormField
                                name={`tree-specie-id-${key}`}
                                type="hidden"
                            />
                            <FormField
                                name={`tree-specie-is-deleted-${key}`}
                                type="hidden"
                            />
                            <div
                                className="col-md-2"
                                style={{
                                    position: 'relative',
                                }}
                            >
                                <ButtonGroup
                                    className="pr-3"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                    }}
                                >
                                    <Button
                                        icon="delete"
                                        onClick={() => {
                                            setFieldValue(
                                                `tree-specie-is-deleted-${key}`,
                                                true,
                                            );
                                            setTreeSpeciesState(
                                                treeSpeciesState.filter(
                                                    treeSpecie => {
                                                        return (
                                                            treeSpecie.id !==
                                                            key
                                                        );
                                                    },
                                                ),
                                            );
                                        }}
                                    >
                                        {gettext('Delete')}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </React.Fragment>
                    );
                };

                return (
                    <Form onSubmit={handleSubmit}>
                        <div className="row my-4">
                            <div className="col col-auto">
                                <h3 className="bp3-heading">
                                    {isCreationForm
                                        ? gettext('Felling row creation')
                                        : gettext('Felling row update')}
                                </h3>
                            </div>
                            <div className="col col-auto ml-auto">
                                <Button
                                    type="submit"
                                    intent={Intent.PRIMARY}
                                    large
                                >
                                    {gettext('Save')}
                                </Button>
                            </div>
                        </div>

                        {nonFieldErrors ? (
                            <Callout intent={Intent.DANGER} className="mb-3">
                                {nonFieldErrors}
                            </Callout>
                        ) : null}

                        <Card elevation={Elevation.TWO}>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="bp3-heading mt-2 mb-3">
                                        {gettext('Provision')}
                                    </h4>
                                    <FormField
                                        name="provision_number"
                                        label={gettext('Provision number')}
                                    />
                                    <FormField
                                        name="provision_area"
                                        label={gettext('Provision area (ha)')}
                                    />
                                    <FormField
                                        name="quarter_number"
                                        label={gettext('Quarter number')}
                                        helperText={gettext(
                                            'Only filled when State forest is being purchased.',
                                        )}
                                    />
                                    <h4 className="bp3-heading mt-2 mb-3">
                                        {gettext('Felling')}
                                    </h4>
                                    <FormField
                                        name="felling_type"
                                        label={gettext('Felling type')}
                                        inputComponent={CustomSelect}
                                        formGroupClassName="w-50"
                                        initialValue={
                                            // eslint-disable-next-line camelcase
                                            fellingRow?.felling_type
                                                ? fellingRow.felling_type
                                                : null
                                        }
                                        items={fellingTypeChoices}
                                        onClear={() =>
                                            setFieldValue('felling_type', '')
                                        }
                                        getNextValue={returnFirstArg}
                                        buttonPlaceholder={gettext(
                                            'Select felling type',
                                        )}
                                        fill
                                        minimal
                                    />
                                    <FormField
                                        name="felling_time"
                                        label={gettext('Felling time')}
                                    />
                                    <FormField
                                        name="percentage_of_exit"
                                        label={gettext('Percentage of exit')}
                                        inputComponent={NumericInput}
                                        leftIcon="percentage"
                                        placeholder="0"
                                        min="0"
                                        max="100"
                                        minorStepSize={1}
                                        clampValueOnBlur
                                        fill
                                        onValueChange={(
                                            valueAsNumber,
                                            valueAsString,
                                        ) =>
                                            setFieldValue(
                                                'percentage_of_exit',
                                                valueAsString,
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <h4 className="bp3-heading mt-2 mb-3">
                                        {gettext('Expenses')}
                                    </h4>
                                    <FormField
                                        name="round_material_harvesting_cost"
                                        label={gettext(
                                            'Round material harvesting cost (€/tm)',
                                        )}
                                        inputComponent={NumericInput}
                                        leftIcon="euro"
                                        placeholder="0.00"
                                        min="0"
                                        minorStepSize={0.01}
                                        clampValueOnBlur
                                        fill
                                        onValueChange={(
                                            valueAsNumber,
                                            valueAsString,
                                        ) =>
                                            setFieldValue(
                                                'round_material_harvesting_cost',
                                                valueAsString,
                                            )
                                        }
                                    />
                                    <FormField
                                        name="round_material_transport_cost"
                                        label={gettext(
                                            'Round material transport cost (€/tm)',
                                        )}
                                        inputComponent={NumericInput}
                                        leftIcon="euro"
                                        placeholder="0.00"
                                        min="0"
                                        minorStepSize={0.01}
                                        clampValueOnBlur
                                        fill
                                        onValueChange={(
                                            valueAsNumber,
                                            valueAsString,
                                        ) =>
                                            setFieldValue(
                                                'round_material_transport_cost',
                                                valueAsString,
                                            )
                                        }
                                    />
                                    <FormField
                                        name="energy_bush_harvesting_cost"
                                        label={gettext(
                                            'Energy bush harvesting cost (€/m3)',
                                        )}
                                        inputComponent={NumericInput}
                                        leftIcon="euro"
                                        placeholder="0.00"
                                        min="0"
                                        minorStepSize={0.01}
                                        clampValueOnBlur
                                        fill
                                        onValueChange={(
                                            valueAsNumber,
                                            valueAsString,
                                        ) =>
                                            setFieldValue(
                                                'energy_bush_harvesting_cost',
                                                valueAsString,
                                            )
                                        }
                                    />
                                    <FormField
                                        name="energy_bush_transport_cost"
                                        label={gettext(
                                            'Energy bush transport cost (€/m3)',
                                        )}
                                        inputComponent={NumericInput}
                                        leftIcon="euro"
                                        placeholder="0.00"
                                        min="0"
                                        minorStepSize={0.01}
                                        clampValueOnBlur
                                        fill
                                        onValueChange={(
                                            valueAsNumber,
                                            valueAsString,
                                        ) =>
                                            setFieldValue(
                                                'energy_bush_transport_cost',
                                                valueAsString,
                                            )
                                        }
                                    />
                                    <FormField
                                        name="stump_harvesting_cost"
                                        label={gettext(
                                            'Stump harvesting cost (€/m3)',
                                        )}
                                        inputComponent={NumericInput}
                                        leftIcon="euro"
                                        placeholder="0.00"
                                        min="0"
                                        minorStepSize={0.01}
                                        clampValueOnBlur
                                        fill
                                        onValueChange={(
                                            valueAsNumber,
                                            valueAsString,
                                        ) =>
                                            setFieldValue(
                                                'stump_harvesting_cost',
                                                valueAsString,
                                            )
                                        }
                                    />
                                    <FormField
                                        name="stump_transport_cost"
                                        label={gettext(
                                            'Stump transport cost (€/m3)',
                                        )}
                                        inputComponent={NumericInput}
                                        leftIcon="euro"
                                        placeholder="0.00"
                                        min="0"
                                        minorStepSize={0.01}
                                        clampValueOnBlur
                                        fill
                                        onValueChange={(
                                            valueAsNumber,
                                            valueAsString,
                                        ) =>
                                            setFieldValue(
                                                'stump_transport_cost',
                                                valueAsString,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="bp3-heading mt-2 mb-3">
                                        {gettext('M3 assortments')}
                                    </h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <FormField
                                        name="stump_quantity"
                                        label={gettext('Stump quantity (m3)')}
                                        inputComponent={NumericInput}
                                        placeholder="0.000"
                                        min="0"
                                        minorStepSize={0.001}
                                        clampValueOnBlur
                                        fill
                                        onValueChange={(
                                            valueAsNumber,
                                            valueAsString,
                                        ) =>
                                            setFieldValue(
                                                'stump_quantity',
                                                valueAsString,
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <FormField
                                        name="energy_bush_quantity"
                                        label={gettext(
                                            'Energy bush quantity (m3)',
                                        )}
                                        inputComponent={NumericInput}
                                        placeholder="0.000"
                                        min="0"
                                        minorStepSize={0.001}
                                        clampValueOnBlur
                                        fill
                                        onValueChange={(
                                            valueAsNumber,
                                            valueAsString,
                                        ) =>
                                            setFieldValue(
                                                'energy_bush_quantity',
                                                valueAsString,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="bp3-heading mt-2 mb-3">
                                        {gettext('TM assortments')}
                                    </h4>
                                </div>
                            </div>
                            <div className="row">
                                {treeSpeciesState.map(treeSpecie => {
                                    return renderTreeSpecieRow(treeSpecie.id);
                                })}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <ButtonGroup className="pr-3">
                                        <Button
                                            icon="add"
                                            onClick={() => {
                                                setTreeSpeciesState([
                                                    ...treeSpeciesState,
                                                    generateNewTreeSpecie(),
                                                ]);
                                            }}
                                        >
                                            {gettext('Add tree specie')}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </Card>

                        <div className="my-4">
                            <Button type="submit" intent={Intent.PRIMARY} large>
                                {gettext('Save')}
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

FellingRowForm.propTypes = {
    isCreationForm: PropTypes.bool.isRequired,
    fellingTypeChoices: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ).isRequired,
    // locationChoices: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         text: PropTypes.string.isRequired,
    //         value: PropTypes.number.isRequired,
    //     }),
    // ).isRequired,
    treeSpecieNameChoices: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ).isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    fellingRow: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

FellingRowForm.defaultProps = {
    fellingRow: null,
};

export default FellingRowForm;
