import Big from 'big.js';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Collapse, Intent, H4, Spinner } from '@blueprintjs/core';
import { Cell, Column, EditableCell, Table } from '@blueprintjs/table';

import { RTKApi } from '../../queries';
import { gettext } from '../../utils/text';
import { floatformat, big } from '../../utils/formatting';

/* If you are thinking of copying over this logic to other project, think again. Usually in our projects we do
 * most of the calculations on BE. It is good for consistency. If you start doing calculations in FE, you need to
 * worry about the whole new spectrum of problems with rounding and floating point number representation.
 *
 * Do not repeat this mistake. But if you do, use proper Decimal package of any sort. Do not rely on floating point. */

const COLUMN_NAMES = [
    gettext('Species'),
    gettext('Log %'),
    gettext('Log tm'),
    gettext('Thin wood %'),
    gettext('Thin wood tm'),
    gettext('Pulp wood %'),
    gettext('Pulp wood tm'),
    gettext('Fire wood %'),
    gettext('Fire wood tm'),
    gettext('Total tm'),
];

const [
    SPECIES_NAME_COLUMN_INDEX,
    THICK_SHARE_COLUMN_INDEX,
    THICK_AMOUNT_COLUMN_INDEX,
    THIN_SHARE_COLUMN_INDEX,
    THIN_AMOUNT_COLUMN_INDEX,
    PULP_SHARE_COLUMN_INDEX,
    PULP_AMOUNT_COLUMN_INDEX,
    FIRE_SHARE_COLUMN_INDEX,
    FIRE_AMOUNT_COLUMN_INDEX,
    TOTAL_AMOUNT_COLUMN_INDEX,
] = Array.apply(0, Array(COLUMN_NAMES.length)).map((element, index) => index);

const SHARE_PROPERTY_BY_COLUMN_INDEX = {
    [THICK_SHARE_COLUMN_INDEX]: 'thickShare',
    [THICK_AMOUNT_COLUMN_INDEX]: 'thickShare',
    [THIN_SHARE_COLUMN_INDEX]: 'thinShare',
    [THIN_AMOUNT_COLUMN_INDEX]: 'thinShare',
    [PULP_SHARE_COLUMN_INDEX]: 'pulpShare',
    [PULP_AMOUNT_COLUMN_INDEX]: 'pulpShare',
};

const INVALID_SHARE_PROPERTY_BY_COLUMN_INDEX = {
    [THICK_SHARE_COLUMN_INDEX]: 'invalidThickShare',
    [THIN_SHARE_COLUMN_INDEX]: 'invalidThinShare',
    [PULP_SHARE_COLUMN_INDEX]: 'invalidPulpShare',
};

const makeSummaryDataFromFellingRows = (fellingRows, initial) => {
    const summaryData = {};

    const initialData = (initial || []).reduce(
        (data, next) => ({ ...data, [next.name]: next }),
        {},
    );

    // Group row data by quantity
    fellingRows.forEach(row => {
        row.tree_species.forEach(treeSpecie => {
            summaryData[treeSpecie.name || ''] = {
                title: treeSpecie.name_display || '',
                name: treeSpecie.name || '',
                quantity: (
                    summaryData[treeSpecie.name]?.quantity || Big(0)
                ).plus(
                    big(treeSpecie.quantity)
                        .times(Big(row.percentage_of_exit || 0))
                        .div(Big(100)),
                ),
            };
        });
    });

    // Sort by name and return as array
    return Object.keys(summaryData)
        .sort((a, b) => summaryData[a].order - summaryData[b].order)
        .map(key => {
            const thisSpecieInitialData =
                initialData[summaryData[key].name] || {};

            return {
                ...summaryData[key],
                thickShare: big(thisSpecieInitialData.thick_wood_share || '0'),
                thinShare: big(thisSpecieInitialData.thin_wood_share || '0'),
                pulpShare: big(thisSpecieInitialData.pulp_wood_share || '0'),
                // If value is invalid during editing, save it here to allow fixing instead of overwriting
                invalidLogShare: null,
                invalidThinShare: null,
                invalidPulpShare: null,
            };
        });
};

const ForestEstimateSummary = ({
    loading,
    fellingRows,
    isOpen,
    toggleOpen,
    forestEstimateId,
}) => {
    const {
        useGetForestEstimateSpeciesQuery,
        useSetForestEstimateSpeciesMutation,
    } = RTKApi;
    const {
        data: initialSpeciesDataRaw,
        isFetching,
        isError,
    } = useGetForestEstimateSpeciesQuery(forestEstimateId, {
        skip: loading || !forestEstimateId || !fellingRows,
    });

    const initialSpeciesData = useMemo(
        () => (isFetching || isError ? [] : initialSpeciesDataRaw),
        [initialSpeciesDataRaw],
    );

    const [
        updateSpeciesData,
        speciesDataUpdateResult,
    ] = useSetForestEstimateSpeciesMutation();

    const [summaryData, setSummaryData] = useState(
        fellingRows
            ? makeSummaryDataFromFellingRows(fellingRows, initialSpeciesData)
            : [],
    );

    useEffect(() => {
        if (fellingRows && !loading && !isFetching) {
            setSummaryData(
                makeSummaryDataFromFellingRows(fellingRows, initialSpeciesData),
            );
        }
    }, [fellingRows, initialSpeciesData, loading, isFetching]);

    const onCellConfirm = useCallback(
        (value, row, columnIndex, rowIndex) => {
            const newRow = {
                ...row,
                [INVALID_SHARE_PROPERTY_BY_COLUMN_INDEX[columnIndex]]: null,
            };
            try {
                newRow[SHARE_PROPERTY_BY_COLUMN_INDEX[columnIndex]] = big(
                    value,
                ).round(2);
                updateSpeciesData({
                    id: forestEstimateId,
                    data: [
                        {
                            name: newRow.name,
                            thick_wood_share: newRow.thickShare.toFixed(2),
                            thin_wood_share: newRow.thinShare.toFixed(2),
                            pulp_wood_share: newRow.pulpShare.toFixed(2),
                        },
                    ],
                    keepCache: true,
                });
            } catch (e) {
                newRow[
                    INVALID_SHARE_PROPERTY_BY_COLUMN_INDEX[columnIndex]
                ] = value;
            }

            setSummaryData(data =>
                data.map((item, index) => (index === rowIndex ? newRow : item)),
            );
        },
        [setSummaryData, updateSpeciesData, forestEstimateId],
    );

    const renderCell = useCallback(
        (rowIndex, columnIndex) => {
            const row = summaryData[rowIndex];

            switch (columnIndex) {
                case SPECIES_NAME_COLUMN_INDEX:
                    // ignore prettier to avoid breaking gettext with bad characters
                    // prettier-ignore
                    return (<Cell>{row.title}</Cell>);
                case THICK_SHARE_COLUMN_INDEX:
                case THIN_SHARE_COLUMN_INDEX:
                case PULP_SHARE_COLUMN_INDEX:
                    return (
                        <EditableCell
                            value={floatformat(
                                row[
                                    INVALID_SHARE_PROPERTY_BY_COLUMN_INDEX[
                                        columnIndex
                                    ]
                                ] ||
                                    row[
                                        SHARE_PROPERTY_BY_COLUMN_INDEX[
                                            columnIndex
                                        ]
                                    ],
                                2,
                            )}
                            intent={
                                row[
                                    INVALID_SHARE_PROPERTY_BY_COLUMN_INDEX[
                                        columnIndex
                                    ]
                                ] ||
                                row[
                                    SHARE_PROPERTY_BY_COLUMN_INDEX[columnIndex]
                                ].lt(0) ||
                                row[
                                    SHARE_PROPERTY_BY_COLUMN_INDEX[columnIndex]
                                ].gt(100)
                                    ? Intent.DANGER
                                    : Intent.SUCCESS
                            }
                            onConfirm={value => {
                                onCellConfirm(
                                    value,
                                    row,
                                    columnIndex,
                                    rowIndex,
                                );
                            }}
                        />
                    );
                case THICK_AMOUNT_COLUMN_INDEX:
                case THIN_AMOUNT_COLUMN_INDEX:
                case PULP_AMOUNT_COLUMN_INDEX:
                    return (
                        <Cell>
                            {floatformat(
                                row[SHARE_PROPERTY_BY_COLUMN_INDEX[columnIndex]]
                                    .times(row.quantity)
                                    .div(100),
                                3,
                            )}
                        </Cell>
                    );
                case FIRE_SHARE_COLUMN_INDEX:
                case FIRE_AMOUNT_COLUMN_INDEX:
                    return (
                        <Cell
                            intent={
                                row.thickShare
                                    .plus(row.thinShare)
                                    .plus(row.pulpShare)
                                    .gt(100)
                                    ? Intent.DANGER
                                    : Intent.NONE
                            }
                        >
                            {floatformat(
                                Big(100)
                                    .minus(row.thickShare)
                                    .minus(row.thinShare)
                                    .minus(row.pulpShare)
                                    .times(
                                        columnIndex === FIRE_AMOUNT_COLUMN_INDEX
                                            ? row.quantity / Big(100)
                                            : Big(1),
                                    ),
                                columnIndex === FIRE_AMOUNT_COLUMN_INDEX
                                    ? 3
                                    : 2,
                            )}
                        </Cell>
                    );
                case TOTAL_AMOUNT_COLUMN_INDEX:
                    return <Cell>{floatformat(row.quantity, 3)}</Cell>;
                default:
                    return <Cell intent={Intent.DANGER}>&nbsp;</Cell>;
            }
        },
        [summaryData],
    );

    return (
        <>
            <Button
                onClick={toggleOpen}
                intent={Intent.PRIMARY}
                minimal
                outlined
                loading={loading}
                disabled={loading}
            >
                {isOpen && !loading
                    ? gettext('Hide summary')
                    : gettext('Show summary')}
            </Button>
            {fellingRows && (
                <Collapse isOpen={isOpen && !loading} className="mt-3">
                    <H4>
                        {gettext('Felling rows summary')}
                        {!speciesDataUpdateResult.isUninitialized && (
                            <Spinner
                                intent={
                                    speciesDataUpdateResult.isError
                                        ? Intent.DANGER
                                        : Intent.SUCCESS
                                }
                                size={16}
                                value={
                                    speciesDataUpdateResult.isLoading
                                        ? undefined
                                        : 1
                                }
                                tagName="span"
                                className="d-inline-block mx-2 mb-1"
                            />
                        )}
                    </H4>
                    <Table
                        numRows={summaryData.length}
                        className="mb-5"
                        loadingOptions={isFetching ? ['cells'] : []}
                    >
                        {COLUMN_NAMES.map(columnName => (
                            <Column
                                key={columnName}
                                cellRenderer={renderCell}
                                name={columnName}
                            />
                        ))}
                    </Table>
                </Collapse>
            )}
        </>
    );
};

export default ForestEstimateSummary;
