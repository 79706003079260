import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from '@blueprintjs/core';

import { createForestEstimate } from 'ducks/forest_estimates/forestEstimateCreation';
import ForestEstimateForm from 'forms/ForestEstimateForm';

import { fetchForestEstimateAnalyzerChoices } from '../../ducks/forest_estimates/forestEstimateAnalyzerChoices';

const ForestEstimateCreation = props => {
    const {
        onFormSubmit,
        fetchForestEstimateAnalyzers,
        forestEstimateAnalyzerChoices,
        loadingAnalyzerChoices,
    } = props;

    useEffect(() => {
        fetchForestEstimateAnalyzers();
    }, []);

    if (loadingAnalyzerChoices || forestEstimateAnalyzerChoices === null) {
        return (
            <>
                <Spinner className="mt-5" />
            </>
        );
    }

    return (
        <ForestEstimateForm
            onFormSubmit={onFormSubmit}
            isCreationForm
            analyzerChoices={forestEstimateAnalyzerChoices}
        />
    );
};

const mapStateToProps = state => ({
    forestEstimateAnalyzerChoices:
        state.forestEstimateAnalyzerChoices?.response,
    loadingAnalyzerChoices: state.forestEstimateAnalyzerChoices?.loading,
    forestEstimateLankChoices: state.forestEstimateLankChoices?.response,
    loadingLankChoices: state.forestEstimateLankChoices?.loading,
});

const mapDispatchToProps = {
    fetchForestEstimateAnalyzers: fetchForestEstimateAnalyzerChoices,
    onFormSubmit: createForestEstimate,
};

const ConnectedForestEstimateCreation = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ForestEstimateCreation);

ForestEstimateCreation.propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
    fetchForestEstimateAnalyzers: PropTypes.func.isRequired,
    forestEstimateAnalyzerChoices: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            }),
        ).isRequired,
        PropTypes.oneOf([null]),
    ]),
    loadingAnalyzerChoices: PropTypes.bool.isRequired,
};

ForestEstimateCreation.defaultProps = {
    forestEstimateAnalyzerChoices: null,
};

export default ConnectedForestEstimateCreation;
