import { call, put, takeEvery } from 'redux-saga/effects';

import { formatError } from 'utils/errors';
import {
    FETCH_FILTER_ITEMS,
    FETCH_FILTER_ITEMS_FAILED,
    FETCH_FILTER_ITEMS_STARTED,
    FETCH_FILTER_ITEMS_SUCCESSFUL,
} from 'ducks/filters';

export function* fetchFilterItems(action) {
    const { filter } = action;
    const { apiPath, stateKey } = filter;

    yield put({ type: FETCH_FILTER_ITEMS_STARTED, name: filter.stateKey });

    const query = {
        ...action.queryParams,
    };

    try {
        const response = yield call(apiPath, null, query);
        yield put({
            type: FETCH_FILTER_ITEMS_SUCCESSFUL,
            name: stateKey,
            response,
        });
    } catch (error) {
        yield put({
            type: FETCH_FILTER_ITEMS_FAILED,
            name: stateKey,
            error: formatError(error),
        });
    }
}

export default function* filterItemsSaga() {
    yield takeEvery(FETCH_FILTER_ITEMS, fetchFilterItems);
}
