import { call, put, spawn, takeLatest } from 'redux-saga/effects';

import api from '../api';
import {
    FETCH_LOCATION_CHOICES,
    FETCH_LOCATION_CHOICES_STARTED,
    FETCH_LOCATION_CHOICES_SUCCESSFUL,
    FETCH_LOCATION_CHOICES_FAILED,
} from '../ducks/forest_estimates/fellingRowLocationChoices';
import {
    FETCH_FELLING_TYPE_CHOICES,
    FETCH_FELLING_TYPE_CHOICES_STARTED,
    FETCH_FELLING_TYPE_CHOICES_SUCCESSFUL,
    FETCH_FELLING_TYPE_CHOICES_FAILED,
} from '../ducks/forest_estimates/fellingRowFellingTypeChoices';
import {
    FETCH_FELLING_ROW_TREE_SPECIE_NAME_CHOICES,
    FETCH_FELLING_ROW_TREE_SPECIE_NAME_CHOICES_STARTED,
    FETCH_FELLING_ROW_TREE_SPECIE_NAME_CHOICES_SUCCESSFUL,
    FETCH_FELLING_ROW_TREE_SPECIE_NAME_CHOICES_FAILED,
} from '../ducks/forest_estimates/fellingRowTreeSpecieNameChoices';

function* fetchFellingRowLocationChoices() {
    yield put({ type: FETCH_LOCATION_CHOICES_STARTED });
    try {
        const response = yield call(api.forestEstimates.locationChoices.fetch);
        yield put({ type: FETCH_LOCATION_CHOICES_SUCCESSFUL, response });
    } catch (error) {
        yield put({ type: FETCH_LOCATION_CHOICES_FAILED, error });
    }
}

function* fetchFellingRowLocationChoicesSaga() {
    yield takeLatest(FETCH_LOCATION_CHOICES, fetchFellingRowLocationChoices);
}

function* fetchFellingRowFellingTypeChoices() {
    yield put({ type: FETCH_FELLING_TYPE_CHOICES_STARTED });
    try {
        const response = yield call(
            api.forestEstimates.fellingTypeChoices.fetch,
        );
        yield put({ type: FETCH_FELLING_TYPE_CHOICES_SUCCESSFUL, response });
    } catch (error) {
        yield put({ type: FETCH_FELLING_TYPE_CHOICES_FAILED, error });
    }
}

function* fetchFellingRowFellingTypeChoicesSaga() {
    yield takeLatest(
        FETCH_FELLING_TYPE_CHOICES,
        fetchFellingRowFellingTypeChoices,
    );
}

function* fetchFellingRowTreeSpecieNameChoices() {
    yield put({ type: FETCH_FELLING_ROW_TREE_SPECIE_NAME_CHOICES_STARTED });
    try {
        const response = yield call(
            api.forestEstimates.fellingRowTreeSpecieNameChoices.fetch,
        );
        yield put({
            type: FETCH_FELLING_ROW_TREE_SPECIE_NAME_CHOICES_SUCCESSFUL,
            response,
        });
    } catch (error) {
        yield put({
            type: FETCH_FELLING_ROW_TREE_SPECIE_NAME_CHOICES_FAILED,
            error,
        });
    }
}

function* fetchFellingRowTreeSpecieNameChoicesSaga() {
    yield takeLatest(
        FETCH_FELLING_ROW_TREE_SPECIE_NAME_CHOICES,
        fetchFellingRowTreeSpecieNameChoices,
    );
}

export default function* actCreationRootSaga() {
    yield spawn(fetchFellingRowLocationChoicesSaga);
    yield spawn(fetchFellingRowFellingTypeChoicesSaga);
    yield spawn(fetchFellingRowTreeSpecieNameChoicesSaga);
}
