import { useCallback, useEffect, useRef, useState } from 'react';

export const useEffectExceptOnMount = (effect, dependencies) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current === false) {
            didMount.current = true;
            return;
        }
        effect();
    }, dependencies);
};

export const useIsOpenState = (initial = false) => {
    const [isOpen, setOpen] = useState(initial);
    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);
    const toggleOpen = useCallback(() => setOpen(!isOpen), [setOpen, isOpen]);

    return { isOpen, handleClose, handleOpen, toggleOpen };
};
