// TODO: Cover with tests
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form, FieldArray, getIn } from 'formik';
import {
    Button,
    Callout,
    Card,
    Elevation,
    Intent,
    NumericInput,
    Position,
    RadioGroup,
    TextArea,
} from '@blueprintjs/core';

import { gettext } from 'utils/text';
import {
    fromISODateString,
    toISODateString,
    toLocaleDateString,
} from 'utils/date';
import { reverseUrl } from 'utils/urls';
import { floatformat } from 'utils/formatting';
import { getFieldErrors } from 'utils/formErrors';
import { returnFirstArg } from 'utils/functional';
import { ActDetailsShape, InitialActDataShape } from 'shapes/acts';
import { getFormPropTypes } from 'shapes/formik';
import FormField from 'forms/fields/FormField';
import DateInput from 'components/DateInput';

//  Need to be kept synced with the backend values
const AUTH_BASE_B_CARD = 0;
const AUTH_BASE_ATTORNEY = 1;
const AUTH_BASE_NONE = 2;
const authorizationBaseOptions = [
    {
        label: gettext('B-card'),
        value: AUTH_BASE_B_CARD,
    },
    {
        label: gettext('Power of attorney'),
        value: AUTH_BASE_ATTORNEY,
    },
    {
        label: gettext('None'),
        value: AUTH_BASE_NONE,
    },
];

const Act = ({
    isCreationForm,
    act,
    handleSubmit,
    values,
    setFieldValue,
    errors,
}) => {
    // Waybill errors after submission
    const waybillErrors = getIn(errors, 'waybills', null);
    const nonFieldErrors = getIn(errors, 'nonFieldErrors', null);

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 1);

    const getRowSum = useCallback(
        rowValues => floatformat(rowValues.unit_cost * rowValues.amount, 2),
        [],
    );

    const assortmentFieldName = useCallback(
        index => `assortment_rows.${index}.assortment_name`,
        [],
    );

    const unitCostFieldName = useCallback(
        index => `assortment_rows.${index}.unit_cost`,
        [],
    );

    const unitCostOnValueChange = useCallback(
        (index, valueAsString) =>
            setFieldValue(unitCostFieldName(index), valueAsString),
        [setFieldValue, unitCostFieldName],
    );

    return (
        <Form onSubmit={handleSubmit}>
            <div className="row my-4">
                <div className="col col-auto">
                    <h3 className="bp3-heading">
                        {isCreationForm
                            ? gettext('Act creation')
                            : gettext('Act update')}
                    </h3>
                </div>
                <div className="col col-auto ml-auto">
                    <Button type="submit" intent={Intent.PRIMARY} large>
                        {gettext('Save')}
                    </Button>
                </div>
            </div>
            {waybillErrors ? (
                <Callout intent={Intent.DANGER} className="mb-3">
                    {waybillErrors}
                </Callout>
            ) : null}
            {nonFieldErrors ? (
                <Callout intent={Intent.DANGER} className="mb-3">
                    {nonFieldErrors}
                </Callout>
            ) : null}
            <Card elevation={Elevation.TWO}>
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="bp3-heading mt-2 mb-3">
                            {gettext('Act details')}
                        </h4>
                    </div>
                    <div className="col-md-12">
                        <FormField name="title" label={gettext('Act title')} />
                    </div>
                    <div className="col-md-6">
                        <FormField
                            name="number"
                            label={gettext('Act number')}
                        />
                        <FormField
                            name="date"
                            label={gettext('Act date')}
                            inputComponent={DateInput}
                            maxDate={maxDate}
                            fill
                            getNextValue={returnFirstArg}
                            popoverProps={{
                                position: Position.BOTTOM_LEFT,
                            }}
                        />
                        <FormField
                            name="purchase_invoice_nr"
                            label={gettext('Purchase invoice nr')}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormField
                            name="location"
                            label={gettext('Location of act creation')}
                        />
                        <FormField
                            name="places_of_delivery"
                            label={gettext('Places of delivery and acceptance')}
                            inputComponent={TextArea}
                            className="w-100"
                            growVertically
                        />
                        <FormField
                            name="payment_deadline"
                            label={gettext('Payment deadline')}
                            inputComponent={DateInput}
                            maxDate={maxDate}
                            fill
                            getNextValue={returnFirstArg}
                            popoverProps={{
                                position: Position.BOTTOM_LEFT,
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="bp3-heading mt-2 mb-3">
                            {gettext('Seller')}
                        </h4>
                        <FormField name="seller_name" label={gettext('Name')} />
                        <FormField
                            name="seller_reg_code"
                            label={gettext('Registry code')}
                        />
                        <FormField
                            name="seller_vat_number"
                            label={gettext('VAT number')}
                        />
                        <FormField
                            name="seller_address"
                            label={gettext('Address')}
                        />
                        <FormField
                            name="seller_email"
                            label={gettext('E-mail')}
                        />
                        <FormField
                            name="seller_bank_account_number"
                            label={gettext('Bank account')}
                        />
                    </div>
                    <div className="col-md-6">
                        <h4 className="bp3-heading mt-2 mb-3">
                            {gettext('Buyer')}
                        </h4>
                        <FormField name="buyer_name" label={gettext('Name')} />
                        <FormField
                            name="buyer_reg_code"
                            label={gettext('Registry code')}
                        />
                        <FormField
                            name="buyer_vat_number"
                            label={gettext('VAT number')}
                        />
                        <FormField
                            name="buyer_address"
                            label={gettext('Address')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="bp3-heading mt-2 mb-3">
                            {gettext('Seller representative')}
                        </h4>
                        <FormField
                            name="seller_representative_name"
                            label={gettext('Name')}
                        />
                        <FormField
                            name="seller_representative_code"
                            label={gettext('Code')}
                        />
                        <FormField
                            name="seller_representative_phone"
                            label={gettext('Phone')}
                        />
                        <FormField
                            name="seller_representative_email"
                            label={gettext('E-mail')}
                        />
                        <FormField
                            name="seller_authorization_base"
                            label={gettext('Authorization base')}
                            inputComponent={RadioGroup}
                            options={authorizationBaseOptions}
                            getNextValue={e => Number(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6">
                        <h4 className="bp3-heading mt-2 mb-3">
                            {gettext('Buyer representative')}
                        </h4>
                        <FormField
                            name="buyer_representative_name"
                            label={gettext('Name')}
                        />
                        <FormField
                            name="buyer_representative_code"
                            label={gettext('Code')}
                        />
                        <FormField
                            name="buyer_representative_phone"
                            label={gettext('Phone')}
                        />
                        <FormField
                            name="buyer_representative_email"
                            label={gettext('E-mail')}
                        />
                        <FormField
                            name="buyer_authorization_base"
                            label={gettext('Authorization base')}
                            inputComponent={RadioGroup}
                            options={authorizationBaseOptions}
                            getNextValue={e => Number(e.target.value)}
                        />
                    </div>
                </div>
            </Card>

            <h4 className="bp3-heading my-4">{gettext('Assortments')}</h4>

            <FieldArray
                name="assortment_rows"
                render={() =>
                    values.assortment_rows.map((row, index) => (
                        <Card
                            elevation={Elevation.TWO}
                            className="mt-3"
                            key={index} // eslint-disable-line react/no-array-index-key
                        >
                            <div className="row">
                                <div className="col-md-3 py-1">
                                    <b>{row.origin_name}</b>
                                </div>
                                <div className="col-md-3 py-1">
                                    <FormField
                                        name={assortmentFieldName(index)}
                                        formGroupClassName="mb-0"
                                        placeholder={gettext('Assortment name')}
                                        fill
                                    />
                                </div>
                                <div className="col-md-2 py-1">
                                    {floatformat(row.amount, 3)}
                                    &nbsp;
                                    {row.assortment_unit}
                                </div>
                                <div className="col-md-2 text-right">
                                    <FormField
                                        name={unitCostFieldName(index)}
                                        formGroupClassName="mb-0"
                                        inputComponent={NumericInput}
                                        leftIcon="euro"
                                        placeholder="0.000"
                                        min="0"
                                        max="999.999"
                                        minorStepSize={0.001}
                                        clampValueOnBlur
                                        onValueChange={(
                                            valueAsNumber,
                                            valueAsString,
                                        ) =>
                                            unitCostOnValueChange(
                                                index,
                                                valueAsString,
                                            )
                                        }
                                        fill
                                    />
                                </div>
                                <div className="col-md-2 py-1 text-md-right">
                                    {getRowSum(row)}
                                </div>
                            </div>
                        </Card>
                    ))
                }
            />

            <h4 className="bp3-heading my-4">
                {gettext('Transportation report')}
            </h4>

            {act.transport_rows.map(row => (
                <Card
                    key={`${row.waybill_number}-${row.assortment_unit}`}
                    elevation={Elevation.TWO}
                    className="mt-3"
                >
                    <div className="row">
                        <div className="col-md-3">
                            <b>{row.origin_name}</b>
                        </div>
                        <div className="col-md-3">
                            <b>{row.waybill_number}</b>
                        </div>
                        <div className="col-md-2">
                            {toLocaleDateString(
                                fromISODateString(row.waybill_date),
                            )}
                        </div>
                        <div className="col-md-2">{row.truck}</div>
                        <div className="col-md-2 text-right">
                            {floatformat(row.amount, 3)}
                            &nbsp;
                            {row.assortment_unit}
                        </div>
                    </div>
                </Card>
            ))}

            <h4 className="bp3-heading my-4">{gettext('Extra information')}</h4>

            <Card elevation={Elevation.TWO} className="mt-3">
                <div className="row">
                    <div className="col-md-12">
                        <FormField
                            name="extra_info"
                            label={gettext('Extra information')}
                            inputComponent={TextArea}
                            className="w-100"
                            growVertically
                        />
                    </div>
                </div>
            </Card>

            <h4 className="bp3-heading my-4">{gettext('In-house comments')}</h4>

            <Card elevation={Elevation.TWO} className="mt-3">
                <div className="row">
                    <div className="col-md-12">
                        <FormField
                            name="in_house_comments"
                            label={gettext('In-house comments')}
                            inputComponent={TextArea}
                            className="w-100"
                            growVertically
                        />
                    </div>
                </div>
            </Card>

            <div className="my-4">
                <Button type="submit" intent={Intent.PRIMARY} large>
                    {gettext('Save')}
                </Button>
            </div>
        </Form>
    );
};

Act.propTypes = {
    isCreationForm: PropTypes.bool,
    act: PropTypes.oneOfType([InitialActDataShape, ActDetailsShape]).isRequired,
    ...getFormPropTypes([
        // Act general details
        'number',
        'date',
        'location',
        'payment_deadline',
        'places_of_delivery',
        'title',
        'extra_info',
        'in_house_comments',
        // Buyer details
        'buyer_name',
        'buyer_reg_code',
        'buyer_vat_number',
        'buyer_address',
        'buyer_representative_name',
        'buyer_representative_code',
        'buyer_representative_phone',
        'buyer_representative_email',
        'buyer_authorization_base',
        // Seller details
        'seller_name',
        'seller_reg_code',
        'seller_vat_number',
        'seller_address',
        'seller_email',
        'seller_bank_account-number',
        'seller_representative_name',
        'seller_representative_code',
        'seller_representative_phone',
        'seller_representative_email',
        'seller_authorization_base',
        // Assortment rows
        'assortment_rows',
        // Accounting
        'purchase_invoice_nr',
    ]),
};

Act.defaultProps = {
    isCreationForm: true,
};

const ActForm = withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    mapPropsToValues: ({ act }) => {
        return {
            ...act,
            date: fromISODateString(act.date),
            payment_deadline: fromISODateString(act.payment_deadline),
            // Mutate unit_cost in assortment rows since null values are not accepted by NumericInput
            assortment_rows: act.assortment_rows.map(row => ({
                ...row,
                unit_cost:
                    row.unit_cost === null
                        ? NumericInput.VALUE_EMPTY
                        : row.unit_cost,
            })),
        };
    },
    handleSubmit: (
        values,
        { props: { onFormSubmit }, setErrors, setSubmitting },
    ) => {
        const payload = {
            ...values,
            date: toISODateString(values.date, 'date'),
            payment_deadline: toISODateString(values.payment_deadline, 'date'),
        };
        onFormSubmit(
            payload,
            () => {
                setSubmitting(false);
                window.location = reverseUrl('acts:list');
            },
            error => {
                const fieldErrors = getFieldErrors(error);
                if (fieldErrors !== null) {
                    setErrors(fieldErrors);
                } else {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                setSubmitting(false);
            },
        );
    },
    displayName: 'ActForm',
})(Act);

export default ActForm;
