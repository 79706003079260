import React from 'react';
import PropTypes from 'prop-types';
import { Intent, Tag } from '@blueprintjs/core';

import { getFieldErrors } from '../utils/formErrors';

const ValidationErrorsList = ({ validationErrors }) => {
    const fieldErrors = getFieldErrors(validationErrors);

    if (!fieldErrors) {
        return null;
    }

    return (
        <div>
            {Object.keys(fieldErrors).map(field => (
                <div className="text-left" key={field}>
                    <Tag minimal large intent={Intent.DANGER}>
                        {field}
                    </Tag>
                    <ul>
                        <li>{fieldErrors[field]}</li>
                    </ul>
                </div>
            ))}
        </div>
    );
};

ValidationErrorsList.propTypes = {
    validationErrors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default ValidationErrorsList;
