// Actions
export const FETCH_WAYBILL_TOTALS = 'WAYBILLS/FETCH_WAYBILL_TOTALS';
export const FETCH_WAYBILL_TOTALS_STARTED =
    'WAYBILLS/FETCH_WAYBILL_TOTALS_STARTED';
export const FETCH_WAYBILL_TOTALS_SUCCESSFUL =
    'WAYBILLS/FETCH_WAYBILL_TOTALS_SUCCESSFUL';
export const FETCH_WAYBILL_TOTALS_FAILED =
    'WAYBILLS/FETCH_WAYBILL_TOTALS_FAILED';

// Initial state for reducer
export const initialState = {
    loading: false,
    response: null,
    error: null,
};

// Reducer(s)
export default function waybillTotalsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_WAYBILL_TOTALS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case FETCH_WAYBILL_TOTALS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case FETCH_WAYBILL_TOTALS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchWaybillTotals = query => ({
    type: FETCH_WAYBILL_TOTALS,
    query,
});
