import PropTypes from 'prop-types';
import React from 'react';

import { WaybillFilterQueryShape } from 'shapes/waybills';
import { gettext } from '../../utils/text';
import { FILTERS } from '../../ducks/filters';
import TableEntityFilter from '../Table/TableEntityFilter';

const WaybillSellerFilter = ({ sellerRegCode, changeSellerRegCode, query }) => (
    <TableEntityFilter
        initialValue={sellerRegCode}
        onChange={changeSellerRegCode}
        itemsToOptions={item => ({
            text: `${item.previous_owner_name} [${item.previous_owner_reg_code}]`,
            value: item.previous_owner_reg_code,
        })}
        name="seller_filter"
        buttonPlaceholder={gettext('Select seller')}
        buttonLeftIcon="office"
        filterType={FILTERS.WAYBILL.SELLERS}
        query={query}
    />
);

WaybillSellerFilter.propTypes = {
    sellerRegCode: PropTypes.string.isRequired,
    changeSellerRegCode: PropTypes.func.isRequired,
    query: WaybillFilterQueryShape.isRequired,
};

export default WaybillSellerFilter;
