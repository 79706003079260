import React from 'react';
import ReactDOM from 'react-dom';
import Raven from 'raven-js';

import WaybillList from 'components/WaybillList';
import ActCreation from 'components/ActCreation';
import ActUpdate from 'components/ActUpdate';
import ActList from 'components/ActList';
import EnergyWarehouseList from 'components/EnergyWarehouseList';
import InvoiceCreation from 'components/InvoiceCreation';
import renderNavigationBar from 'components/NavigationBar';
import SecondPartySignature from 'components/SecondPartySingature';
import TimberWarehouseList from 'components/TimberWarehouseList';
import ForestEstimateList from 'components/ForestEstimateList';
import ForestEstimateUpdate from 'components/ForestEstimateUpdate';
import ForestEstimateCreation from 'components/ForestEstimateCreation';
import FellingRowsList from 'components/FellingRowsList';
import FellingRowCreation from 'components/FellingRowCreation';
import FellingRowUpdate from 'components/FellingRowUpdate';

import rootReducer from './reducers';
import configureStore from './configureStore';
import actCreationRootSaga from './sagas/actCreation';
import actUpdateRootSaga from './sagas/actUpdate';
import actListRootSaga from './sagas/actList';
import { fetchContainerSaga, signingSaga } from './sagas/signing';
import energyWarehouseListRootSaga from './sagas/energyWarehouseList';
import invoicesRootSaga from './sagas/invoiceCreation';
import waybillsRootSaga from './sagas/waybills';
import forestEstimatesRootSaga from './sagas/forestEstimates';
import forestEstimateChoicesSaga from './sagas/forestEstimateChoices';
import forestEstimateUpdateRootSaga from './sagas/forestEstimateUpdate';
import forestEstimateCreationRootSaga from './sagas/forestEstimateCreation';
import fellingRowsRootSaga from './sagas/fellingRows';
import fellingRowsChoicesSaga from './sagas/fellingRowsChoices';
import fellingRowsCreationRootSaga from './sagas/fellingRowsCreation';
import fellingRowUpdateRootSaga from './sagas/fellingRowUpdate';
import timberWarehouseListRootSaga from './sagas/timberWarehouseList';
import ForestEstimateAssessment from './components/ForestEstimateAssessment';
import { gettext } from './utils/text';
import { ViewBase } from './components/ViewBase';
import ForestEstimateAnalysis from './components/ForestEstimateAnalysis';

// Install Raven in production envs
if (process.env.NODE_ENV === 'production') {
    Raven.config(DJ_CONST.RAVEN_PUBLIC_DSN).install(); // eslint-disable-line
    // handle rejected promises
    window.addEventListener('unhandledrejection', evt => {
        Raven.captureException(evt.reason);
    });
    // If we have authenticated user, pass its data on to Raven
    if (DJ_CONST.user) {
        Raven.setUserContext({
            id: DJ_CONST.user.id,
            email: DJ_CONST.user.email,
            name: DJ_CONST.user.name,
        });
    }
}

// Create Redux store
const { store, sagaMiddleware } = configureStore(rootReducer);

function initNavigationBar() {
    renderNavigationBar('navigation-bar');
}

/* Waybills */
function initWaybillList() {
    const elem = document.getElementById('waybill-container');
    if (!elem) {
        return;
    }

    sagaMiddleware.run(waybillsRootSaga);

    ReactDOM.render(
        <ViewBase
            store={store}
            djConst={DJ_CONST}
            headerText={gettext('Waybills')}
        >
            <WaybillList />
        </ViewBase>,
        elem,
    );
}
/* End Waybills */

/* Acts */
function initActCreationView() {
    const elem = document.getElementById('act-creation-container');
    if (!elem) {
        return;
    }

    sagaMiddleware.run(actCreationRootSaga);

    ReactDOM.render(
        <ViewBase store={store} djConst={DJ_CONST}>
            <ActCreation />
        </ViewBase>,
        elem,
    );
}

function initActUpdateView(actID) {
    const elem = document.getElementById('act-update-container');
    if (!elem) {
        return;
    }

    sagaMiddleware.run(actUpdateRootSaga);

    ReactDOM.render(
        <ViewBase store={store} djConst={DJ_CONST}>
            <ActUpdate actID={actID} />
        </ViewBase>,
        elem,
    );
}

function initActListView() {
    const elem = document.getElementById('act-list-container');
    if (!elem) {
        return;
    }

    sagaMiddleware.run(actListRootSaga);

    ReactDOM.render(
        <ViewBase store={store} djConst={DJ_CONST} headerText={gettext('Acts')}>
            <ActList />
        </ViewBase>,
        elem,
    );
}
/* End Acts */

/* Signing */
function initSecondPartySignatureView(externalID) {
    const elem = document.getElementById('second-party-signature-container');
    if (!elem) {
        return;
    }

    sagaMiddleware.run(fetchContainerSaga);
    sagaMiddleware.run(signingSaga);

    ReactDOM.render(
        <ViewBase store={store} djConst={DJ_CONST}>
            <SecondPartySignature externalID={externalID} />
        </ViewBase>,
        elem,
    );
}
/* End Signing */

/* Stock Management */
function initEnergyWarehouseList() {
    const elem = document.getElementById('energy-warehouse-list-container');
    if (!elem) {
        return;
    }

    sagaMiddleware.run(energyWarehouseListRootSaga);

    ReactDOM.render(
        <ViewBase
            store={store}
            djConst={DJ_CONST}
            headerText={gettext('Energy Warehouses')}
        >
            <EnergyWarehouseList />
        </ViewBase>,
        elem,
    );
}

function initTimberWarehouseList() {
    const elem = document.getElementById('timber-warehouse-list-container');
    if (!elem) {
        return;
    }

    sagaMiddleware.run(timberWarehouseListRootSaga);

    ReactDOM.render(
        <ViewBase
            store={store}
            djConst={DJ_CONST}
            headerText={gettext('Timber Warehouses')}
        >
            <TimberWarehouseList />
        </ViewBase>,
        elem,
    );
}
/* End Stock Management */

/* Accounting */
function initInvoiceCreationView() {
    const elem = document.getElementById('invoice-creation-container');
    if (!elem) {
        return;
    }

    sagaMiddleware.run(invoicesRootSaga);

    ReactDOM.render(
        <ViewBase store={store} djConst={DJ_CONST}>
            <InvoiceCreation />
        </ViewBase>,
        elem,
    );
}
/* End Accounting */

// Forest Estimates
function initForestEstimateList() {
    const containerId = 'forest-estimate-list-container';
    const element = document.getElementById(containerId);

    if (!element) {
        throw Error(`Element with id: ${containerId} cannot be found`);
    }

    sagaMiddleware.run(forestEstimatesRootSaga);

    ReactDOM.render(
        <ViewBase
            store={store}
            djConst={DJ_CONST}
            headerText={gettext('Forest Estimates')}
        >
            <ForestEstimateList />
        </ViewBase>,
        element,
    );
}

function initForestEstimateUpdateView(forestEstimateId) {
    const elem = document.getElementById('forest-estimate-update-container');
    if (!elem) {
        return;
    }

    sagaMiddleware.run(forestEstimateUpdateRootSaga);
    sagaMiddleware.run(forestEstimateChoicesSaga);

    ReactDOM.render(
        <ViewBase store={store} djConst={DJ_CONST}>
            <ForestEstimateUpdate forestEstimateId={forestEstimateId} />
        </ViewBase>,
        elem,
    );
}

function initForestEstimateAssessmentView(forestEstimateId) {
    const elem = document.getElementById(
        'forest-estimate-assessment-container',
    );
    if (!elem) {
        return;
    }

    ReactDOM.render(
        <ViewBase store={store} djConst={DJ_CONST}>
            <ForestEstimateAssessment forestEstimateId={forestEstimateId} />
        </ViewBase>,
        elem,
    );
}

function initForestEstimateAnalysisView(forestEstimateId) {
    const elem = document.getElementById('forest-estimate-analysis-container');
    if (!elem) {
        return;
    }

    ReactDOM.render(
        <ViewBase store={store} djConst={DJ_CONST}>
            <ForestEstimateAnalysis forestEstimateId={forestEstimateId} />
        </ViewBase>,
        elem,
    );
}

function initForestEstimateCreationView() {
    const elem = document.getElementById('forest-estimate-creation-container');
    if (!elem) {
        return;
    }

    sagaMiddleware.run(forestEstimateCreationRootSaga);
    sagaMiddleware.run(forestEstimateChoicesSaga);

    ReactDOM.render(
        <ViewBase store={store} djConst={DJ_CONST}>
            <ForestEstimateCreation />
        </ViewBase>,
        elem,
    );
}

function initFellingRowsList() {
    const containerId = 'felling-row-list-container';
    const element = document.getElementById(containerId);

    if (!element) {
        throw Error(`Element with id: ${containerId} cannot be found`);
    }

    sagaMiddleware.run(fellingRowsRootSaga);

    ReactDOM.render(
        <ViewBase store={store} djConst={DJ_CONST}>
            <FellingRowsList />
        </ViewBase>,
        element,
    );
}

function initFellingRowCreationView() {
    const elem = document.getElementById('felling-row-creation-container');
    if (!elem) {
        return;
    }

    sagaMiddleware.run(fellingRowsCreationRootSaga);
    sagaMiddleware.run(fellingRowsChoicesSaga);

    ReactDOM.render(
        <ViewBase store={store} djConst={DJ_CONST}>
            <FellingRowCreation />
        </ViewBase>,
        elem,
    );
}

function initFellingRowUpdateView(fellingRowId) {
    const elem = document.getElementById('felling-row-update-container');
    if (!elem) {
        return;
    }

    sagaMiddleware.run(fellingRowUpdateRootSaga);
    sagaMiddleware.run(fellingRowsChoicesSaga);

    ReactDOM.render(
        <ViewBase store={store} djConst={DJ_CONST}>
            <FellingRowUpdate fellingRowId={fellingRowId} />
        </ViewBase>,
        elem,
    );
}
// End Forest Estimates

export {
    initNavigationBar,
    initWaybillList,
    initActCreationView,
    initActUpdateView,
    initActListView,
    initSecondPartySignatureView,
    initInvoiceCreationView,
    initEnergyWarehouseList,
    initTimberWarehouseList,
    initForestEstimateList,
    initForestEstimateUpdateView,
    initForestEstimateAssessmentView,
    initForestEstimateAnalysisView,
    initForestEstimateCreationView,
    initFellingRowsList,
    initFellingRowCreationView,
    initFellingRowUpdateView,
};
