import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Cookies from 'js-cookie';

const getFormInputs = formData => {
    return Object.keys(formData).map(name => {
        return (
            <input
                key={name}
                name={name}
                type="hidden"
                value={formData[name]}
            />
        );
    });
};

const FileDownloadForm = ({
    actionPath,
    formData,
    method,
    onDownloadComplete,
}) => {
    // This component does not render any visible form. Instead, it renders and immediately submits the form
    // to provide additional values for a POST endpoint that is expected to respond with a downloadable file.
    const downloadFormRef = React.useRef(null);

    useEffect(() => {
        downloadFormRef.current.submit();
        onDownloadComplete();
    }, []);

    return (
        <form
            action={actionPath}
            className="hidden"
            method={method}
            ref={downloadFormRef}
        >
            {getFormInputs(formData)}
            <input
                type="hidden"
                name="csrfmiddlewaretoken"
                value={Cookies.get('csrftoken')}
            />
        </form>
    );
};

FileDownloadForm.propTypes = {
    actionPath: PropTypes.string.isRequired,
    formData: PropTypes.objectOf(PropTypes.string).isRequired,
    method: PropTypes.oneOf(['post']).isRequired,
    onDownloadComplete: PropTypes.func.isRequired,
};

export default FileDownloadForm;
