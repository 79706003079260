// TODO: Cover with tests
// Actions
export const FETCH_INITIALS = 'ACTS/FETCH_INITIALS';
export const FETCH_INITIALS_STARTED = 'ACTS/FETCH_INITIALS_STARTED';
export const FETCH_INITIALS_SUCCESSFUL = 'ACTS/FETCH_INITIALS_SUCCESSFUL';
export const FETCH_INITIALS_FAILED = 'ACTS/FETCH_INITIALS_FAILED';

// Initial state for reducer
const initialState = {
    loading: false,
    response: null,
    error: null,
};

// Reducer(s)
export default function fetchActInitialsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_INITIALS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case FETCH_INITIALS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case FETCH_INITIALS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchActInitials = query => ({
    type: FETCH_INITIALS,
    query,
});
