// Actions
export const NON_SELECTABLES_FETCH = 'WAYBILLS/NON_SELECTABLES/FETCH';
export const NON_SELECTABLES_FETCH_STARTED =
    'WAYBILLS/NON_SELECTABLES/FETCH_STARTED';
export const NON_SELECTABLES_RECEIVED = 'WAYBILLS/NON_SELECTABLES/RECEIVED';
export const NON_SELECTABLES_ERRORED = 'WAYBILLS/NON_SELECTABLES/ERRORED';

// Initial state for reducer
export const initialState = {
    loading: false,
    response: null,
    error: null,
};

// Reducer(s)
export default function nonSelectableWaybillsReducer(
    state = initialState,
    action,
) {
    switch (action.type) {
        case NON_SELECTABLES_FETCH_STARTED:
            return {
                ...state,
                loading: true,
            };
        case NON_SELECTABLES_RECEIVED:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case NON_SELECTABLES_ERRORED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchNonSelectableWaybills = payload => ({
    type: NON_SELECTABLES_FETCH,
    payload,
});
