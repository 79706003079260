import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { gettext } from '../utils/text';
import { reverseUrl } from '../utils/urls';
import ConfirmationAlert from './ConfirmationAlert';

const InvoiceDeletionDialog = ({
    selectedInvoiceID,
    setSelectedInvoiceID,
    handleDeleteInvoice,
}) => {
    const onInvoiceDelete = useCallback(() => {
        handleDeleteInvoice(selectedInvoiceID, () => {
            setSelectedInvoiceID(null);
            window.location = reverseUrl('waybills:list');
        });
    }, [handleDeleteInvoice, selectedInvoiceID]);

    return (
        <ConfirmationAlert
            onClose={() => setSelectedInvoiceID(null)}
            onConfirm={onInvoiceDelete}
            isOpen={selectedInvoiceID !== null}
        >
            <p>{gettext('Are you sure to delete the selected invoice?')}</p>
        </ConfirmationAlert>
    );
};

InvoiceDeletionDialog.propTypes = {
    selectedInvoiceID: PropTypes.number,
    setSelectedInvoiceID: PropTypes.func.isRequired,
    handleDeleteInvoice: PropTypes.func.isRequired,
};

InvoiceDeletionDialog.defaultProps = {
    selectedInvoiceID: null,
};

export default InvoiceDeletionDialog;
