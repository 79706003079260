// TODO: Cover with tests
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Spinner } from '@blueprintjs/core';

import { formatError } from 'utils/errors';
import { gettext } from 'utils/text';
import { fetchActInitials } from 'ducks/acts/actInitials';
import { createAct } from 'ducks/acts/actCreation';
import { InitialActDataShape } from 'shapes/acts';
import ValidationErrorsList from 'components/ValidationErrorsList';
import { BackToWaybillsNonIdealState } from 'components/NonIdealStates';
import ActForm from 'forms/ActForm';

const ActCreation = props => {
    const {
        actInitialsError,
        fetchInitialActData,
        initialActData,
        loading,
        onFormSubmit,
    } = props;

    useEffect(() => {
        const query = qs.parse(window.location.search);
        fetchInitialActData({
            waybills: query.waybills,
        });
    }, []);

    if (!loading && actInitialsError !== null) {
        return (
            <BackToWaybillsNonIdealState
                title={gettext("Oops! Can't create an act due to error(s)..")}
                description={
                    actInitialsError.isValidationError ? (
                        <ValidationErrorsList
                            validationErrors={actInitialsError}
                        />
                    ) : (
                        formatError(actInitialsError)
                    )
                }
            />
        );
    }

    if (loading || initialActData === null) {
        return (
            <>
                <Spinner className="mt-5" />
            </>
        );
    }

    return <ActForm act={initialActData} onFormSubmit={onFormSubmit} />;
};

const mapStateToProps = state => ({
    loading: state.actInitials.loading,
    actInitialsError: state.actInitials.error,
    initialActData: state.actInitials?.response,
});

const mapDispatchToProps = {
    fetchInitialActData: fetchActInitials,
    onFormSubmit: createAct,
};

const ConnectedActCreation = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ActCreation);

ActCreation.propTypes = {
    actInitialsError: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    fetchInitialActData: PropTypes.func.isRequired,
    initialActData: InitialActDataShape,
    loading: PropTypes.bool,
    onFormSubmit: PropTypes.func.isRequired,
};

ActCreation.defaultProps = {
    loading: false,
    actInitialsError: null,
    initialActData: null,
};

export default ConnectedActCreation;
