// TODO: Cover with tests
import { call, put, spawn, takeLatest } from 'redux-saga/effects';

import api from 'api';
import { formatError } from 'utils/errors';
import {
    ERRORED,
    FETCH,
    FETCH_STARTED,
    RECEIVED,
} from 'ducks/stock_management/energyWarehouseList';
import filterItemsSaga from './filters';

function* fetchEnergyWarehouses(action) {
    yield put({ type: FETCH_STARTED });
    const { query } = action;
    try {
        const response = yield call(
            api.stockManagement.energyWarehouseList.fetch,
            null,
            query,
        );
        yield put({ type: RECEIVED, response });
    } catch (error) {
        yield put({ type: ERRORED, error: formatError(error) });
    }
}

function* energyWarehouseListSaga() {
    yield takeLatest(FETCH, fetchEnergyWarehouses);
}

export default function* energyWarehouseListRootSaga() {
    yield spawn(energyWarehouseListSaga);
    yield spawn(filterItemsSaga);
}
