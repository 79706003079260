import { call, put, spawn, takeLatest } from 'redux-saga/effects';
import api from '../api';
import {
    ERRORED,
    FETCH,
    FETCH_STARTED,
    RECEIVED,
} from '../ducks/forest_estimates/forest_estimates';
import { formatError } from '../utils/errors';

export function* fetchforestEstimates(action) {
    yield put({ type: FETCH_STARTED });
    const { query } = action;
    try {
        const response = yield call(api.forestEstimates.list.fetch, null, {
            ...query,
            page_size: 20,
        });
        yield put({ type: RECEIVED, response });
    } catch (error) {
        yield put({ type: ERRORED, error: formatError(error) });
    }
}

export function* forestEstimatesSaga() {
    yield takeLatest(FETCH, fetchforestEstimates);
}

export default function* forestEstimatesRootSaga() {
    yield spawn(forestEstimatesSaga);
}
