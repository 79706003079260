import React from 'react';
import { HTMLTable } from '@blueprintjs/core';

import { ForestEstimateDetailsShape } from '../../shapes/forestEstimates';
import { gettext, pgettext } from '../../utils/text';
import NumberCell from '../ForestEstimateAssessment/NumberCell';
import EditableCell from '../ForestEstimateAssessment/EditableCell';

const ForestEstimateAnalysisExpensesTable = ({ forestEstimate }) => (
    <HTMLTable striped className="w-100 pl-3 editable-table pb-5">
        <thead>
            <tr>
                <th>&nbsp;</th>
                <th colSpan={3} className="text-center">
                    {pgettext(
                        'Forest estimate analysis expenses',
                        'Planned expenses',
                    )}
                </th>
                <th colSpan={3} className="text-center">
                    {pgettext(
                        'Forest estimate analysis expenses',
                        'Realization expenses',
                    )}
                </th>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <th>
                    {pgettext('Forest estimate analysis expenses', 'Amount')}
                </th>
                <th>{gettext('€ / m3')}</th>
                <th>€</th>
                <th>
                    {pgettext('Forest estimate analysis expenses', 'Amount')}
                </th>
                <th>{gettext('€ / m3')}</th>
                <th>€</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th className="text-right">
                    {pgettext(
                        'Forest estimate analysis expenses',
                        'Purchase price',
                    )}
                </th>
                <NumberCell
                    value={forestEstimate.neto_amount}
                    decimalPlaces={3}
                />
                <NumberCell
                    value={forestEstimate.expected_average_profit}
                    decimalPlaces={2}
                />
                <NumberCell
                    value={forestEstimate.maximum_price_without_tax}
                    decimalPlaces={2}
                />
                <NumberCell
                    value={forestEstimate.realization_total_amount}
                    decimalPlaces={3}
                />
                <NumberCell
                    value={forestEstimate.realization_purchase_price}
                    decimalPlaces={3}
                />
                <NumberCell
                    value={forestEstimate.purchase_price}
                    decimalPlaces={3}
                />
            </tr>
            <tr>
                <th className="text-right">
                    {pgettext(
                        'Forest estimate analysis expenses',
                        'Harvesting cost',
                    )}
                </th>
                <NumberCell
                    value={forestEstimate.neto_amount}
                    decimalPlaces={3}
                />
                <NumberCell
                    value={
                        forestEstimate.round_material_harvesting_average_cost
                    }
                    decimalPlaces={2}
                />
                <NumberCell
                    value={forestEstimate.round_material_harvesting_total_cost}
                    decimalPlaces={2}
                />
                <NumberCell
                    value={forestEstimate.realization_total_amount}
                    decimalPlaces={3}
                />
                <NumberCell
                    value={forestEstimate.realization_harvesting_price}
                    decimalPlaces={2}
                />
                <EditableCell
                    forestEstimateId={forestEstimate.id}
                    valueKey="realization_harvesting_cost_override"
                    defaultFallbackKey="realization_harvesting_cost"
                    isEstimateField
                    nullable
                    decimalPlaces={2}
                />
            </tr>
            <tr>
                <th className="text-right">
                    {pgettext(
                        'Forest estimate analysis expenses',
                        'Transport cost',
                    )}
                </th>
                <NumberCell
                    value={forestEstimate.neto_amount}
                    decimalPlaces={3}
                />
                <NumberCell
                    value={forestEstimate.round_material_transport_average_cost}
                    decimalPlaces={2}
                />
                <NumberCell
                    value={forestEstimate.round_material_transport_total_cost}
                    decimalPlaces={2}
                />
                <NumberCell
                    value={forestEstimate.realization_total_amount}
                    decimalPlaces={3}
                />
                <NumberCell
                    value={forestEstimate.realization_transport_price}
                    decimalPlaces={2}
                />
                <EditableCell
                    forestEstimateId={forestEstimate.id}
                    valueKey="realization_transport_cost_override"
                    defaultFallbackKey="realization_transport_cost"
                    isEstimateField
                    nullable
                    decimalPlaces={2}
                />
            </tr>
            <tr>
                <th className="text-right">
                    {pgettext(
                        'Forest estimate analysis expenses',
                        'Truck shipping cost',
                    )}
                </th>
                <NumberCell
                    value={forestEstimate.neto_amount}
                    decimalPlaces={3}
                />
                <NumberCell
                    value={forestEstimate.shipping_average_cost}
                    decimalPlaces={2}
                />
                <NumberCell
                    value={forestEstimate.shipping_total_cost}
                    decimalPlaces={2}
                />
                <EditableCell
                    forestEstimateId={forestEstimate.id}
                    valueKey="realization_auto_shipping_amount_override"
                    defaultFallbackKey="realization_total_amount"
                    isEstimateField
                    nullable
                    decimalPlaces={3}
                />
                <NumberCell
                    value={forestEstimate.realization_auto_shipping_price}
                    decimalPlaces={2}
                />
                <EditableCell
                    forestEstimateId={forestEstimate.id}
                    valueKey="realization_auto_shipping_cost_override"
                    defaultFallbackKey="realization_auto_shipping_cost"
                    isEstimateField
                    nullable
                    decimalPlaces={2}
                />
            </tr>
            <tr>
                <th className="text-right">
                    {pgettext(
                        'Forest estimate analysis expenses',
                        'Railway cost',
                    )}
                </th>
                <NumberCell
                    value={forestEstimate.neto_amount}
                    decimalPlaces={3}
                />
                <NumberCell
                    value={forestEstimate.railway_average_cost}
                    decimalPlaces={2}
                />
                <NumberCell
                    value={forestEstimate.railway_total_cost}
                    decimalPlaces={2}
                />
                <EditableCell
                    forestEstimateId={forestEstimate.id}
                    valueKey="realization_railway_shipping_amount_override"
                    defaultFallbackValue="0.000"
                    isEstimateField
                    nullable
                    decimalPlaces={3}
                />
                <NumberCell
                    value={forestEstimate.realization_railway_shipping_price}
                    decimalPlaces={2}
                />
                <EditableCell
                    forestEstimateId={forestEstimate.id}
                    valueKey="realization_railway_shipping_cost_override"
                    defaultFallbackKey="realization_railway_shipping_cost"
                    isEstimateField
                    nullable
                    decimalPlaces={2}
                />
            </tr>
            <tr>
                <th className="text-right">
                    {pgettext(
                        'Forest estimate analysis expenses',
                        'Extra cost',
                    )}
                </th>
                <NumberCell
                    value={forestEstimate.neto_amount}
                    decimalPlaces={3}
                />
                <EditableCell
                    forestEstimateId={forestEstimate.id}
                    valueKey="extra_cost"
                    isEstimateField
                    decimalPlaces={2}
                />
                <NumberCell
                    value={forestEstimate.total_extra_cost}
                    decimalPlaces={2}
                />
                <NumberCell
                    value={forestEstimate.realization_total_amount}
                    decimalPlaces={3}
                />
                <NumberCell
                    value={forestEstimate.realization_extra_price}
                    decimalPlaces={2}
                />
                <EditableCell
                    forestEstimateId={forestEstimate.id}
                    valueKey="realization_extra_cost_override"
                    defaultFallbackKey="realization_extra_cost"
                    isEstimateField
                    nullable
                    decimalPlaces={2}
                />
            </tr>
            <tr>
                <th className="text-right">
                    {pgettext(
                        'Forest estimate analysis expenses',
                        'General cost',
                    )}
                </th>
                <td colSpan={3}>&nbsp;</td>
                <NumberCell
                    value={forestEstimate.realization_total_amount}
                    decimalPlaces={3}
                />
                <NumberCell
                    value={forestEstimate.realization_general_price}
                    decimalPlaces={2}
                />
                <EditableCell
                    forestEstimateId={forestEstimate.id}
                    valueKey="realization_general_cost_override"
                    defaultFallbackKey="realization_general_cost"
                    isEstimateField
                    nullable
                    decimalPlaces={2}
                />
            </tr>
            <tr>
                <th className="text-right">
                    {pgettext(
                        'Forest estimate analysis expenses',
                        'Total costs',
                    )}
                </th>
                <td>&nbsp;</td>
                <NumberCell
                    value={forestEstimate.estimated_average_cost}
                    decimalPlaces={2}
                />
                <NumberCell
                    value={forestEstimate.estimated_cost}
                    decimalPlaces={2}
                />
                <td>&nbsp;</td>
                <NumberCell
                    value={forestEstimate.realization_total_price}
                    decimalPlaces={2}
                />
                <NumberCell
                    value={forestEstimate.realization_total_cost}
                    decimalPlaces={2}
                />
            </tr>
            <tr>
                <th className="text-right" colSpan={5}>
                    {pgettext(
                        'Forest estimate analysis expenses',
                        'Order difference',
                    )}
                </th>
                <NumberCell
                    value={forestEstimate.order_difference}
                    decimalPlaces={2}
                />
                <td>
                    {forestEstimate.order_difference &&
                        (forestEstimate.order_difference < 0
                            ? pgettext(
                                  'Forest estimate analisys expenses increased',
                                  'Costs increased',
                              )
                            : pgettext(
                                  'Forest estimate analisys expenses decreased',
                                  'Costs saved',
                              ))}
                </td>
            </tr>
        </tbody>
    </HTMLTable>
);

ForestEstimateAnalysisExpensesTable.propTypes = {
    forestEstimate: ForestEstimateDetailsShape.isRequired,
};

export default ForestEstimateAnalysisExpensesTable;
