import PropTypes from 'prop-types';
import React from 'react';

import { gettext } from '../../utils/text';
import { FILTERS } from '../../ducks/filters';
import TableEntityFilter from '../Table/TableEntityFilter';

export const WAREHOUSE_TYPES = {
    ENERGY: 'energy',
    TIMBER: 'timber',
};

export const WarehouseStatusFilter = ({
    status,
    changeStatus,
    warehouseType,
}) => {
    return (
        <TableEntityFilter
            initialValue={status}
            onChange={changeStatus}
            itemsToOptions={item => ({
                text: item.name,
                value: item.code,
            })}
            name="status_filter"
            buttonPlaceholder={gettext('Select status')}
            buttonLeftIcon="office"
            filterType={
                warehouseType === WAREHOUSE_TYPES.ENERGY
                    ? FILTERS.ENERGY_WAREHOUSE.STATUSES
                    : FILTERS.TIMBER_WAREHOUSE.STATUSES
            }
            fetchItemsAsync={false}
            fill={false}
        />
    );
};

WarehouseStatusFilter.propTypes = {
    status: PropTypes.string.isRequired,
    changeStatus: PropTypes.func.isRequired,
    warehouseType: PropTypes.oneOf(Object.values(WAREHOUSE_TYPES)).isRequired,
};
