import React from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse, HTMLTable, Intent } from '@blueprintjs/core';

import { WaybillTotalsShape } from '../shapes/waybills';
import { gettext } from '../utils/text';
import { floatformat } from '../utils/formatting';
import { useDJConst } from '../utils/DJConstContext';

const WaybillTotals = ({ loading, waybillTotals, isOpen, toggleOpen }) => {
    const { user } = useDJConst();

    return (
        <>
            <Button
                onClick={toggleOpen}
                intent={Intent.PRIMARY}
                minimal
                outlined
                loading={loading}
                disabled={loading}
            >
                {isOpen && !loading
                    ? gettext('Hide waybill totals')
                    : gettext('Show waybill totals')}
            </Button>
            {waybillTotals && (
                <Collapse isOpen={isOpen && !loading} className="mt-3">
                    <HTMLTable
                        bordered
                        condensed
                        interactive
                        striped
                        className="w-100"
                    >
                        <thead>
                            <tr>
                                {user.is_logistics_user ? null : (
                                    <th>{gettext('Total amount')}</th>
                                )}
                                {user.is_logistics_user ? null : (
                                    <th>{gettext('Total MWh')}</th>
                                )}
                                {user.is_logistics_user ? null : (
                                    <th>{gettext('Total material cost')}</th>
                                )}
                                {user.is_logistics_user ? null : (
                                    <th>{gettext('Total chopping cost')}</th>
                                )}
                                <th>{gettext('Total transport cost')}</th>
                                {user.is_logistics_user ? null : (
                                    <th>{gettext('Total extra cost')}</th>
                                )}
                                <th>{gettext('Total mileage')}</th>
                                {user.is_logistics_user ? null : (
                                    <th>
                                        {gettext('Total transport sale price')}
                                    </th>
                                )}
                                {user.is_logistics_user ? null : (
                                    <th>
                                        {gettext('Total material sale price')}
                                    </th>
                                )}
                                {user.is_logistics_user ? null : (
                                    <th>{gettext('Total cost')}</th>
                                )}
                                {user.is_logistics_user ? null : (
                                    <th>{gettext('Total income')}</th>
                                )}
                                {user.is_logistics_user ? null : (
                                    <th>{gettext('Total profit')}</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {user.is_logistics_user ? null : (
                                    <td>
                                        {waybillTotals.amounts.map(obj => (
                                            <div key={obj.unit}>
                                                <span className="mr-1">
                                                    {floatformat(
                                                        obj.total_amount,
                                                        3,
                                                    )}
                                                </span>
                                                <span>{obj.unit}</span>
                                            </div>
                                        ))}
                                    </td>
                                )}
                                {user.is_logistics_user ? null : (
                                    <td>
                                        {floatformat(
                                            waybillTotals.total_mwh,
                                            3,
                                        )}
                                    </td>
                                )}
                                {user.is_logistics_user ? null : (
                                    <td>
                                        {floatformat(
                                            waybillTotals.total_material_cost,
                                            2,
                                        )}
                                    </td>
                                )}
                                {user.is_logistics_user ? null : (
                                    <td>
                                        {floatformat(
                                            waybillTotals.total_chopping_cost,
                                            2,
                                        )}
                                    </td>
                                )}
                                <td>
                                    {floatformat(
                                        waybillTotals.total_transport_cost,
                                        2,
                                    )}
                                </td>
                                {user.is_logistics_user ? null : (
                                    <td>
                                        {floatformat(
                                            waybillTotals.total_extra_cost,
                                            2,
                                        )}
                                    </td>
                                )}
                                <td>
                                    {floatformat(
                                        waybillTotals.total_mileage,
                                        1,
                                    )}
                                </td>
                                {user.is_logistics_user ? null : (
                                    <td>
                                        {floatformat(
                                            waybillTotals.total_transport_sale_price,
                                            2,
                                        )}
                                    </td>
                                )}
                                {user.is_logistics_user ? null : (
                                    <td>
                                        {floatformat(
                                            waybillTotals.total_material_sale_price,
                                            2,
                                        )}
                                    </td>
                                )}
                                {user.is_logistics_user ? null : (
                                    <td>
                                        {floatformat(
                                            waybillTotals.total_cost,
                                            2,
                                        )}
                                    </td>
                                )}
                                {user.is_logistics_user ? null : (
                                    <td>
                                        {floatformat(
                                            waybillTotals.total_sale_price,
                                            2,
                                        )}
                                    </td>
                                )}
                                {user.is_logistics_user ? null : (
                                    <td>
                                        {floatformat(
                                            waybillTotals.total_profit,
                                            2,
                                        )}
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </HTMLTable>
                </Collapse>
            )}
        </>
    );
};

WaybillTotals.propTypes = {
    loading: PropTypes.bool.isRequired,
    waybillTotals: WaybillTotalsShape,
    isOpen: PropTypes.bool.isRequired,
    toggleOpen: PropTypes.func.isRequired,
};

WaybillTotals.defaultProps = {
    waybillTotals: null,
};

export default WaybillTotals;
