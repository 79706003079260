import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Callout,
    Classes,
    Card,
    Dialog,
    FormGroup,
    HTMLTable,
    InputGroup,
    Intent,
} from '@blueprintjs/core';

import { gettext } from 'utils/text';
import { LightActShape } from 'shapes/acts';
import { getFieldErrors } from '../utils/formErrors';

const PurchaseInvoiceNumberDialog = ({
    isOpen,
    onClose,
    selectedActs,
    onConfirm,
    errors,
}) => {
    const [purchaseInvoiceNumber, setPurchaseInvoiceNumber] = useState('');
    const handlePurchaseInvoiceNumberChange = useCallback(event => {
        setPurchaseInvoiceNumber(event.target.value);
    }, []);
    const fieldErrors = errors ? getFieldErrors(errors) : null;

    return (
        <Dialog
            title={gettext('Update purchase invoice nr')}
            isOpen={isOpen}
            onClose={onClose}
        >
            {fieldErrors?.nonFieldErrors ? (
                <Callout intent={Intent.DANGER}>
                    {fieldErrors.nonFieldErrors}
                </Callout>
            ) : null}
            <div className="px-4 pt-3">
                <h5 className="bp3-heading">{gettext('Chosen acts:')}</h5>
                <Card>
                    <HTMLTable bordered condensed striped className="w-100">
                        <thead>
                            <tr>
                                <th>{gettext('Name')}</th>
                                <th>
                                    {gettext('Purchase invoice nr')} (
                                    {gettext('current')})
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedActs.map(act => (
                                <tr key={act.number}>
                                    <td>{act.number}</td>
                                    <td>{act.purchase_invoice_nr}</td>
                                </tr>
                            ))}
                        </tbody>
                    </HTMLTable>
                </Card>
                {fieldErrors?.acts ? (
                    <p className={`${Classes.TEXT_SMALL} text-danger mt-2`}>
                        {fieldErrors.acts}
                    </p>
                ) : null}
            </div>
            <div className="px-4 pt-5">
                <FormGroup
                    label={gettext('Purchase invoice nr')}
                    labelFor="purchase-invoice-nr"
                >
                    <InputGroup
                        id="purchase-invoice-nr"
                        onChange={handlePurchaseInvoiceNumberChange}
                    />
                    {/* eslint-disable camelcase */
                    fieldErrors?.purchase_invoice_nr ? (
                        <p className={`${Classes.TEXT_SMALL} text-danger mt-2`}>
                            {fieldErrors.purchase_invoice_nr}
                        </p>
                    ) : null
                    /* eslint-enable camelcase */
                    }
                </FormGroup>
                <Button
                    text={gettext('Confirm')}
                    onClick={() => onConfirm(purchaseInvoiceNumber)}
                />
            </div>
        </Dialog>
    );
};

PurchaseInvoiceNumberDialog.defaultProps = {
    errors: null,
};

PurchaseInvoiceNumberDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedActs: PropTypes.arrayOf(LightActShape).isRequired,
    onConfirm: PropTypes.func.isRequired,
    errors: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default PurchaseInvoiceNumberDialog;
