import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@blueprintjs/core';
import { RTKApi } from '../../queries';
import ForestEstimateHeader from '../ForestEstimateHeader';
import ForestEstimateNavigation from '../ForestEstimateNavigation';
import ForestEstimateAnalysisHeader from './ForestEstimateAnalysisHeader';
import ForestEstimateAnalysisAmountsTable from './ForestEstimateAnalysisAmountsTable';
import ForestEstimateAnalysisExpensesTable from './ForestEstimateAnalysisExpensesTable';
import ForestEstimateAnalysisTimberWarehousePriceTable from './ForestEstimateAnalysisTimberWarehousePriceTable';

const ForestEstimateAnalysis = ({ forestEstimateId }) => {
    const { useGetForestEstimateAssessmentQuery } = RTKApi;

    const {
        data: forestEstimate,
        isSuccess: isSuccessForestEstimate,
    } = useGetForestEstimateAssessmentQuery(forestEstimateId, {
        skip: !forestEstimateId,
    });

    if (!isSuccessForestEstimate) {
        return <Spinner className="mt-5" />;
    }

    return (
        <div className="container-fluid mb-4">
            <div className="float-right">
                <ForestEstimateNavigation
                    currentView="analysis"
                    forestEstimate={forestEstimate}
                />
            </div>
            <div style={{ paddingLeft: 11 }}>
                <ForestEstimateHeader forestEstimate={forestEstimate} />
            </div>
            <ForestEstimateAnalysisHeader forestEstimate={forestEstimate} />
            <ForestEstimateAnalysisAmountsTable
                forestEstimate={forestEstimate}
            />
            <ForestEstimateAnalysisExpensesTable
                forestEstimate={forestEstimate}
            />
            <ForestEstimateAnalysisTimberWarehousePriceTable
                forestEstimate={forestEstimate}
            />
        </div>
    );
};

ForestEstimateAnalysis.propTypes = {
    forestEstimateId: PropTypes.string.isRequired,
};

export default ForestEstimateAnalysis;
