// Actions
export const FETCH_FELLING_TYPE_CHOICES =
    'FELLING_ROWS/FETCH_FELLING_TYPE_CHOICES';
export const FETCH_FELLING_TYPE_CHOICES_STARTED =
    'FELLING_ROWS/FETCH_FELLING_TYPE_CHOICES_STARTED';
export const FETCH_FELLING_TYPE_CHOICES_SUCCESSFUL =
    'FELLING_ROWS/FETCH_FELLING_TYPE_CHOICES_SUCCESSFUL';
export const FETCH_FELLING_TYPE_CHOICES_FAILED =
    'FELLING_ROWS/FETCH_FELLING_TYPE_CHOICES_FAILED';

// Initial state for reducer
const initialState = {
    loading: false,
    response: null,
    error: null,
};

// Reducer(s)
export default function fetchFellingRowFellingTypeChoicesReducer(
    state = initialState,
    action,
) {
    switch (action.type) {
        case FETCH_FELLING_TYPE_CHOICES_STARTED:
            return {
                ...state,
                loading: true,
            };
        case FETCH_FELLING_TYPE_CHOICES_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case FETCH_FELLING_TYPE_CHOICES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchFellingRowFellingTypeChoices = query => ({
    type: FETCH_FELLING_TYPE_CHOICES,
    query,
});
