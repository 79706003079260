// TODO: Cover with tests
// Actions
export const DELETE_ACT = 'ACTS/DELETE_ACT';
export const DELETE_ACT_STARTED = 'ACTS/DELETE_ACT_STARTED';
export const DELETE_ACT_SUCCESSFUL = 'ACTS/DELETE_ACT_SUCCESSFUL';
export const DELETE_ACT_FAILED = 'ACTS/DELETE_ACT_FAILED';

// Action creators
export const deleteAct = (payload, callback, errorCallback) => ({
    type: DELETE_ACT,
    payload,
    callback,
    errorCallback,
});
