import { call, put, takeLeading } from 'redux-saga/effects';

import api from 'api';
import {
    UPDATE_TRANSPORT_INVOICE_NR,
    UPDATE_TRANSPORT_INVOICE_NR_STARTED,
    UPDATE_TRANSPORT_INVOICE_NR_FAILED,
    UPDATE_TRANSPORT_INVOICE_NR_SUCCESSFUL,
} from 'ducks/waybills/transportInvoiceNumberUpdate';

function* updateTransportInvoiceNumber(action) {
    yield put({ type: UPDATE_TRANSPORT_INVOICE_NR_STARTED });
    try {
        const response = yield call(
            api.waybills.updateTransportInvoiceNumber.post,
            null,
            action.payload,
        );
        yield put({ type: UPDATE_TRANSPORT_INVOICE_NR_SUCCESSFUL, response });
        yield call(action.callback);
    } catch (error) {
        yield put({ type: UPDATE_TRANSPORT_INVOICE_NR_FAILED, error });
        yield call(action.errorCallback, error);
    }
}

export default function* transportInvoiceNumberUpdateSaga() {
    yield takeLeading(
        UPDATE_TRANSPORT_INVOICE_NR,
        updateTransportInvoiceNumber,
    );
}
