import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const initialState = {
    user: undefined,
    reverse: {},
};

export const DJConstContext = createContext(initialState);
DJConstContext.displayName = 'DJConst';

/**
 * Provide the DJ_CONST value to its children via context. Meant to be consumed
 * using the `useDJConst` hook. This helps with making components easier to
 * test and less dependant on global variables.
 */
export const DJConstProvider = ({ djConst, children }) => (
    <DJConstContext.Provider value={djConst}>
        {children}
    </DJConstContext.Provider>
);

DJConstProvider.propTypes = {
    djConst: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export const useDJConst = () => useContext(DJConstContext);
