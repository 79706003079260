import PropTypes from 'prop-types';

import { SIGNATURE_CONTAINER_STATUS } from '../utils/constants';
import { LightActShape } from './acts';

export const ContainerShape = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(SIGNATURE_CONTAINER_STATUS))
        .isRequired,
    files: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            file_name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    signatures: PropTypes.arrayOf(
        PropTypes.shape({
            id_code: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    acts: PropTypes.arrayOf(LightActShape).isRequired,
});
