export const ITEM_SELECTION = {
    PAGE: 'page',
    PAGE_NONE: 'page-none',
};

export const toggleItemSelection = (toggledItem, items, setSelectedItems) =>
    setSelectedItems(selection => {
        if (toggledItem === ITEM_SELECTION.PAGE) {
            const prevItemIDs = selection.map(item => item.id);
            const newPageItemIds = items.filter(
                item => prevItemIDs.indexOf(item.id) < 0,
            );
            return [...selection, ...newPageItemIds];
        }
        if (toggledItem === ITEM_SELECTION.PAGE_NONE) {
            const pageItemIDs = items.map(item => item.id);
            return selection.filter(item => pageItemIDs.indexOf(item.id) < 0);
        }
        const itemIndex = selection
            .map(item => item.id)
            .indexOf(toggledItem.id);
        if (itemIndex >= 0) {
            return selection.filter(item => item.id !== toggledItem.id);
        } else {
            return [...selection, toggledItem];
        }
    });

export const getPageStatus = (selectedItems, items) => {
    const checkedOnPageCount = items.filter(
        item => selectedItems.map(i => i.id).indexOf(item.id) >= 0,
    ).length;
    const pageChecked = checkedOnPageCount === items.length;
    return {
        pageChecked,
        pageIndeterminate: !pageChecked && checkedOnPageCount > 0,
    };
};
