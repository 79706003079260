import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import endOfYear from 'date-fns/endOfYear';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import startOfYear from 'date-fns/startOfYear';
import subMonths from 'date-fns/subMonths';
import subWeeks from 'date-fns/subWeeks';
import subYears from 'date-fns/subYears';

import { makeDateRange } from './date';
import { gettext } from './text';

const createShortcut = (label, dateRange) => ({ dateRange, label });

export const createDefaultShortcuts = allowSingleDayRange => {
    const today = new Date(Date.now());
    const makeDate = action => {
        const returnVal = new Date(today);
        action(returnVal);
        returnVal.setDate(returnVal.getDate() + 1);
        return returnVal;
    };

    const yesterday = makeDate(d => d.setDate(d.getDate() - 2));
    const currentMonth = makeDateRange(0, subMonths, startOfMonth, endOfMonth);
    const pastWeek = makeDateRange(1, subWeeks, startOfWeek, endOfWeek);
    const pastMonth = makeDateRange(1, subMonths, startOfMonth, endOfMonth);
    const pastThreeMonths = makeDateRange(
        3,
        subMonths,
        startOfMonth,
        endOfMonth,
    );
    const pastSixMonths = makeDateRange(6, subMonths, startOfMonth, endOfMonth);
    const pastYear = makeDateRange(1, subYears, startOfYear, endOfYear);
    const pastTwoYears = makeDateRange(2, subYears, startOfYear, endOfYear);

    const singleDayShortcuts = allowSingleDayRange
        ? [
              createShortcut(gettext('Today'), [today, today]),
              createShortcut(gettext('Yesterday'), [yesterday, yesterday]),
          ]
        : [];

    return [
        ...singleDayShortcuts,
        createShortcut(gettext('Current month'), currentMonth),
        createShortcut(gettext('Past week'), pastWeek),
        createShortcut(gettext('Past month'), pastMonth),
        createShortcut(gettext('Past 3 months'), pastThreeMonths),
        createShortcut(gettext('Past 6 months'), pastSixMonths),
        createShortcut(gettext('Past year'), pastYear),
        createShortcut(gettext('Past 2 years'), pastTwoYears),
    ];
};
