import { HTMLTable, NonIdealState, Spinner } from '@blueprintjs/core';
import React, { useMemo, useState, useCallback } from 'react';
import DoubleScrollbar from '@proscom/react-double-scrollbar';

import { gettext, pgettext } from '../../utils/text';
import { reverseUrl } from '../../utils/urls';
import { CustomNonIdealState } from '../NonIdealStates';
import ForestEstimateSummary from './ForestEstimateSummary';
import { useIsOpenState } from '../../utils/hooks';
import ConfirmationAlert from '../ConfirmationAlert';

import { floatformat } from '../../utils/formatting';
import { RTKApi } from '../../queries';
import ForestEstimateHeader from '../ForestEstimateHeader';
import ForestEstimateNavigation from '../ForestEstimateNavigation';

const FellingRowsList = () => {
    const forestEstimateId = window.location.pathname.split('/')[2];

    const {
        useGetForestEstimateQuery,
        useGetForestEstimateFellingRowsQuery,
        useDeleteFellingRowMutation,
    } = RTKApi;

    const {
        data: forestEstimate,
        isSuccess: isSuccessForestEstimate,
        isError: isErrorForestEstimate,
    } = useGetForestEstimateQuery(forestEstimateId, {
        skip: !forestEstimateId,
    });

    const {
        data: fellingRows,
        isSuccess: isSuccessFellingRows,
        isError: isErrorFellingRows,
    } = useGetForestEstimateFellingRowsQuery(forestEstimateId, {
        skip: !forestEstimateId,
    });

    const [
        deleteFellingRow,
        deleteFellingRowResult,
    ] = useDeleteFellingRowMutation();

    const {
        isOpen: isSummaryOpen,
        toggleOpen: toggleIsSummaryOpen,
    } = useIsOpenState(true);

    const {
        isOpen: isDeleteFellingRowDialogOpen,
        handleOpen: openDeleteFellingRowDialog,
        handleClose: closeDeleteFellingRowDialog,
    } = useIsOpenState(false);

    const [
        selectedFellingRowForDeletionState,
        setSelectedFellingRowForDeletionState,
    ] = useState(null);

    const onFellingRowDelete = useCallback(() => {
        deleteFellingRow({ id: selectedFellingRowForDeletionState });
        closeDeleteFellingRowDialog();
    }, [closeDeleteFellingRowDialog, selectedFellingRowForDeletionState]);

    const error = useMemo(
        () =>
            isErrorForestEstimate ||
            isErrorFellingRows ||
            deleteFellingRowResult.isError,
        [
            isErrorForestEstimate,
            isErrorFellingRows,
            deleteFellingRowResult.isError,
        ],
    );

    const loading = useMemo(
        () => !isSuccessFellingRows || !isSuccessForestEstimate,
        [isSuccessFellingRows, isSuccessForestEstimate],
    );

    const summaryTable = useMemo(() => {
        if (!fellingRows || !fellingRows.length || error) {
            return null;
        }

        return (
            <>
                <ForestEstimateSummary
                    loading={loading}
                    fellingRows={fellingRows}
                    forestEstimateId={forestEstimateId}
                    isOpen={isSummaryOpen}
                    toggleOpen={toggleIsSummaryOpen}
                />
                {forestEstimate ? (
                    <>
                        <div>
                            <b>{gettext('Total stumps:')}</b>{' '}
                            {floatformat(
                                forestEstimate.total_stump_quantity,
                                3,
                            )}{' '}
                            m3.
                        </div>
                        <div>
                            <b>{gettext('Total energy bushes:')}</b>{' '}
                            {floatformat(
                                forestEstimate.total_energy_bush_quantity,
                                3,
                            )}{' '}
                            m3.
                        </div>
                    </>
                ) : null}
            </>
        );
    }, [loading, fellingRows, isSummaryOpen, toggleIsSummaryOpen]);

    const navigation = forestEstimate && (
        <div className="float-right">
            <ForestEstimateNavigation
                currentView="felling-row-list"
                forestEstimate={forestEstimate}
            />
        </div>
    );

    const htmlTable = useMemo(() => {
        if (!fellingRows) {
            return null;
        }

        if (!fellingRows.length) {
            return (
                <>
                    <CustomNonIdealState
                        icon="search"
                        title={gettext('Oops! No felling rows found..')}
                        description={
                            <div>
                                <p>{gettext('No felling rows found.')}</p>
                            </div>
                        }
                    />
                </>
            );
        }

        return (
            <div className="table-full-width">
                <DoubleScrollbar>
                    <HTMLTable bordered condensed interactive striped>
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th rowSpan={2} className="align-bottom">
                                    {gettext('Provision number')}
                                </th>
                                <th rowSpan={2} className="align-bottom">
                                    {gettext('Quarter number')}
                                </th>
                                <th rowSpan={2} className="align-bottom">
                                    {gettext('Provision area (ha)')}
                                </th>
                                <th rowSpan={2} className="align-bottom">
                                    {gettext('Felling type')}
                                </th>
                                <th rowSpan={2} className="align-bottom">
                                    {gettext('Felling time')}
                                </th>
                                <th rowSpan={2} className="align-bottom">
                                    {gettext('Percentage of exit')}
                                </th>
                                <th colSpan={2} className="align-bottom">
                                    {gettext('Round material (€/tm)')}
                                </th>
                                <th colSpan={2} className="align-bottom">
                                    {gettext('Energy bush (€/m3)')}
                                </th>
                                <th colSpan={2} className="align-bottom">
                                    {gettext('Stump (€/m3)')}
                                </th>
                                <th rowSpan={2} className="align-bottom">
                                    {gettext('Tree species')}
                                </th>
                            </tr>
                            <tr>
                                <th>&nbsp;</th>
                                <th className="align-bottom">
                                    {pgettext(
                                        'Felling row list header - logs',
                                        'Harvesting',
                                    )}
                                </th>
                                <th className="align-bottom">
                                    {pgettext(
                                        'Felling row list header - logs',
                                        'Transport',
                                    )}
                                </th>
                                <th className="align-bottom">
                                    {pgettext(
                                        'Felling row list header - energy bushes',
                                        'Harvesting',
                                    )}
                                </th>
                                <th className="align-bottom">
                                    {pgettext(
                                        'Felling row list header - energy bushes',
                                        'Transport',
                                    )}
                                </th>
                                <th className="align-bottom">
                                    {pgettext(
                                        'Felling row list header - stumps',
                                        'Harvesting',
                                    )}
                                </th>
                                <th className="align-bottom">
                                    {pgettext(
                                        'Felling row list header - stumps',
                                        'Transport',
                                    )}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {fellingRows.map(fellingRow => (
                                <tr key={fellingRow.id}>
                                    <td key="felling-row-quick-action-buttons">
                                        <a
                                            href={reverseUrl(
                                                'forest_estimates:felling-row-update',
                                                {
                                                    forestestimatepk: forestEstimateId,
                                                    pk: fellingRow.id,
                                                },
                                            )}
                                            className="mr-2"
                                        >
                                            <span className="bp3-icon-standard bp3-icon-edit" />
                                        </a>
                                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                                        <span
                                            onClick={() => {
                                                setSelectedFellingRowForDeletionState(
                                                    fellingRow.id,
                                                );
                                                openDeleteFellingRowDialog();
                                            }}
                                            className="bp3-icon-standard bp3-icon-delete text-danger ml-2"
                                        />
                                    </td>
                                    <td key="felling-row-provision-number">
                                        {fellingRow.provision_number}
                                    </td>
                                    <td key="felling-row-quarter-number">
                                        {fellingRow.quarter_number}
                                    </td>
                                    <td key="felling-row-provision-area">
                                        {fellingRow.provision_area}
                                    </td>
                                    <td key="felling-row-felling-type">
                                        {fellingRow.felling_type_display}
                                    </td>
                                    <td key="felling-row-felling-time">
                                        {fellingRow.felling_time}
                                    </td>
                                    <td key="felling-row-percentage-of-exit">
                                        {fellingRow.percentage_of_exit}
                                    </td>
                                    <td key="felling-row-round_material_harvesting_cost">
                                        {floatformat(
                                            fellingRow.round_material_harvesting_cost,
                                            2,
                                        )}
                                    </td>
                                    <td key="felling-row-round_material_transport_cost">
                                        {floatformat(
                                            fellingRow.round_material_transport_cost,
                                            2,
                                        )}
                                    </td>
                                    <td key="felling-row-energy_bush_harvesting_cost">
                                        {floatformat(
                                            fellingRow.energy_bush_harvesting_cost,
                                            2,
                                        )}
                                    </td>
                                    <td key="felling-row-energy_bush_transport_cost">
                                        {floatformat(
                                            fellingRow.energy_bush_transport_cost,
                                            2,
                                        )}
                                    </td>
                                    <td key="felling-row-stump_harvesting_cost">
                                        {floatformat(
                                            fellingRow.stump_harvesting_cost,
                                            2,
                                        )}
                                    </td>
                                    <td key="felling-row-stump_transport_cost">
                                        {floatformat(
                                            fellingRow.stump_transport_cost,
                                            2,
                                        )}
                                    </td>
                                    <td>
                                        {fellingRow.tree_species.map(
                                            treeSpecie => (
                                                <div
                                                    key={`tree-specie-${treeSpecie.id}`}
                                                >
                                                    <span className="mr-1">
                                                        {
                                                            treeSpecie.name_display
                                                        }
                                                    </span>
                                                    <span className="mr-1">
                                                        {floatformat(
                                                            treeSpecie.quantity,
                                                            3,
                                                        )}
                                                    </span>
                                                </div>
                                            ),
                                        )}
                                        {fellingRow.stump_quantity > 0 ? (
                                            <div>
                                                <span className="mr-1">
                                                    {gettext('Stumps (m3)')}
                                                </span>
                                                <span className="mr-1">
                                                    {floatformat(
                                                        fellingRow.stump_quantity,
                                                        3,
                                                    )}
                                                </span>
                                            </div>
                                        ) : null}
                                        {fellingRow.energy_bush_quantity > 0 ? (
                                            <div>
                                                <span className="mr-1">
                                                    {gettext(
                                                        'Energy bushes (m3)',
                                                    )}
                                                </span>
                                                <span className="mr-1">
                                                    {floatformat(
                                                        fellingRow.energy_bush_quantity,
                                                        3,
                                                    )}
                                                </span>
                                            </div>
                                        ) : null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </HTMLTable>
                </DoubleScrollbar>
            </div>
        );
    }, [loading, fellingRows]);

    if (!loading && error) {
        return (
            <>
                <div className="container-fluid">{navigation}</div>
                <NonIdealState
                    icon="issue"
                    title={gettext('Oops! Could not load felling rows..')}
                    description={error}
                />
            </>
        );
    }

    if (loading) {
        return (
            <>
                <div className="container-fluid">{navigation}</div>
                <Spinner className="mt-5" />
            </>
        );
    }

    return (
        <>
            {navigation}
            <div className="ml-3">
                <ForestEstimateHeader forestEstimate={forestEstimate} />
            </div>
            <div className="container-fluid pb-5">{htmlTable}</div>
            <div className="container-fluid">{summaryTable}</div>
            <ConfirmationAlert
                onClose={closeDeleteFellingRowDialog}
                onConfirm={onFellingRowDelete}
                isOpen={isDeleteFellingRowDialogOpen}
            >
                <p>{gettext('Are you sure you want to delete this entry?')}</p>
            </ConfirmationAlert>
        </>
    );
};

export default FellingRowsList;
