// TODO: Cover with tests
// Actions
export const FETCH = 'ACTS/FETCH';
export const FETCH_STARTED = 'ACTS/FETCH_STARTED';
export const RECEIVED = 'ACTS/RECEIVED';
export const ERRORED = 'ACTS/ERRORED';

// Initial state for reducer
const initialState = {
    loading: false,
    response: null,
    error: null,
};

// Reducer(s)
export default function actsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_STARTED:
            return {
                ...state,
                loading: true,
            };
        case RECEIVED:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case ERRORED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchActs = (page, search, dateBefore, dateAfter) => ({
    type: FETCH,
    page,
    search,
    dateBefore,
    dateAfter,
});
