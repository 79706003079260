// TODO: Cover with tests
// Actions
export const CREATE_INVOICE = 'ACCOUNTING/CREATE_INVOICE';
export const CREATE_INVOICE_STARTED = 'ACCOUNTING/CREATE_INVOICE_STARTED';
export const CREATE_INVOICE_SUCCESSFUL = 'ACCOUNTING/CREATE_INVOICE_SUCCESSFUL';
export const CREATE_INVOICE_FAILED = 'ACCOUNTING/CREATE_INVOICE_FAILED';

// Action creators
export const createInvoice = (payload, callback, errorCallback) => ({
    type: CREATE_INVOICE,
    payload,
    callback,
    errorCallback,
});
