// Actions
export const FETCH = 'FOREST_ESTIMATES/FETCH';
export const FETCH_STARTED = 'FOREST_ESTIMATES/FETCH_STARTED';
export const RECEIVED = 'FOREST_ESTIMATES/RECEIVED';
export const ERRORED = 'FOREST_ESTIMATES/ERRORED';

// Initial state for reducer
export const initialState = {
    loading: false,
    response: null,
    error: null,
};

// Reducer(s)
export default function forestEstimatesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_STARTED:
            return {
                ...state,
                loading: true,
            };
        case RECEIVED:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case ERRORED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchForestEstimates = query => ({
    type: FETCH,
    query,
});
