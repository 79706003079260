import { Intent } from '@blueprintjs/core';
import { put } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

export const CLEAR_TOASTS = 'ui/CLEAR_TOASTS';
export const ENQUEUE_TOAST = 'ui/ENQUEUE_TOAST';

const initialState = {
    toastQueue: [],
};

export default function toastsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_TOASTS:
            return {
                ...state,
                toastQueue: [],
            };

        case ENQUEUE_TOAST: {
            const toastQueue = [...state.toastQueue, action.toast];
            return {
                ...state,
                toastQueue,
            };
        }

        default:
            return state;
    }
}

export const clearToasts = () => ({ type: CLEAR_TOASTS });

// toast = { action, iconName, intent, message, onDismiss, timeout }
export const toast = toastObject => ({
    type: ENQUEUE_TOAST,
    toast: { id: uuidv4(), ...toastObject },
});

export const toastSuccess = toastObject =>
    toast({
        icon: 'tick',
        intent: Intent.SUCCESS,
        ...toastObject,
    });

export const toastWarning = toastObject =>
    toast({
        icon: 'warning-sign',
        intent: Intent.WARNING,
        ...toastObject,
    });

export const toastError = toastObject =>
    toast({
        icon: 'error',
        intent: Intent.DANGER,
        timeout: 0,
        ...toastObject,
    });

export const toastInfo = toastObject =>
    toast({
        icon: 'info-sign',
        intent: Intent.PRIMARY,
        ...toastObject,
    });

export const toastStatus = status => {
    switch (status.color) {
        case 'danger':
            return toastError(status);
        case 'warning':
            return toastWarning(status);
        case 'success':
            return toastSuccess(status);
        case 'info':
            return toastInfo(status);
        default:
            return toastError(status);
    }
};

export function* toastSetStatus(status) {
    yield put(toastStatus(status));
}
