// TODO: Cover with tests
// Actions
export const CREATE_ACT = 'ACTS/CREATE_ACT';
export const CREATE_ACT_STARTED = 'ACTS/CREATE_ACT_STARTED';
export const CREATE_ACT_SUCCESSFUL = 'ACTS/CREATE_ACT_SUCCESSFUL';
export const CREATE_ACT_FAILED = 'ACTS/CREATE_ACT_FAILED';

// Action creators
export const createAct = (payload, callback, errorCallback) => ({
    type: CREATE_ACT,
    payload,
    callback,
    errorCallback,
});
