import PropTypes from 'prop-types';

import { WaybillInvoiceShape } from './accounting';
import { WaybillActShape } from './acts';
import { ForestMasterShape } from './stockManagement';

const ChopperShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    person_code: PropTypes.string.isRequired,
    unit_cost: PropTypes.number,
});

export const WaybillShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    status_display: PropTypes.string.isRequired,

    previous_owner_name: PropTypes.string.isRequired,
    previous_owner_reg_code: PropTypes.string.isRequired,
    cadastral_number: PropTypes.string.isRequired,
    origin_name: PropTypes.string.isRequired,
    forest_master: ForestMasterShape,

    assortment_names: PropTypes.arrayOf(PropTypes.string).isRequired,
    total_amount: PropTypes.number.isRequired,

    receiver_reg_code: PropTypes.string.isRequired,
    receiver_name: PropTypes.string.isRequired,
    destination_name: PropTypes.string.isRequired,

    chopper: ChopperShape,
    chopping_amount: PropTypes.number,

    transporter_name: PropTypes.string.isRequired,
    transporter_reg_code: PropTypes.string.isRequired,
    driver_name: PropTypes.string.isRequired,
    driver_person_code: PropTypes.string.isRequired,
    truck: PropTypes.string.isRequired,
    trailer: PropTypes.string.isRequired,
    mileage: PropTypes.number.isRequired,
    backloading: PropTypes.bool.isRequired,

    dispatched_weight: PropTypes.number,
    accepted_weight: PropTypes.number,

    material_cost: PropTypes.number.isRequired,
    chopping_cost: PropTypes.number.isRequired,
    transport_cost: PropTypes.number,
    extra_cost: PropTypes.number,
    extra_cost_title: PropTypes.string.isRequired,
    total_costs: PropTypes.number.isRequired,

    mw_hours: PropTypes.number,
    unit_sale_price: PropTypes.number,
    mwh_sale_price: PropTypes.number,
    transport_sale_price: PropTypes.number,
    material_sale_price: PropTypes.number.isRequired,
    total_sale_price: PropTypes.number.isRequired,

    profit: PropTypes.number.isRequired,
    transport_invoice_nr: PropTypes.string.isRequired,
    sales_invoice_nr: PropTypes.string.isRequired,
    act: WaybillActShape,
    invoices: PropTypes.arrayOf(WaybillInvoiceShape),
    is_actable: PropTypes.bool.isRequired,
});

export const WaybillTotalsShape = PropTypes.shape({
    amounts: PropTypes.arrayOf(
        PropTypes.shape({
            unit: PropTypes.string.isRequired,
            total_amount: PropTypes.number.isRequired,
        }),
    ).isRequired,
    total_mwh: PropTypes.number,
    total_mileage: PropTypes.number,

    total_transport_cost: PropTypes.number,
    total_extra_cost: PropTypes.number,
    total_transport_sale_price: PropTypes.number,
    total_material_sale_price: PropTypes.number,
    total_material_cost: PropTypes.number,
    total_chopping_cost: PropTypes.number,
    total_cost: PropTypes.number,
    total_sale_price: PropTypes.number,
    total_profit: PropTypes.number,
});

export const WaybillFilterQueryShape = PropTypes.shape({
    date_before: PropTypes.string.isRequired,
    date_after: PropTypes.string.isRequired,
    buyer_name: PropTypes.string.isRequired,
    chopper_name: PropTypes.string.isRequired,
    destination_name: PropTypes.string.isRequired,
    driver_name: PropTypes.string.isRequired,
    material_name: PropTypes.string.isRequired,
    origin_name: PropTypes.string.isRequired,
    seller_name: PropTypes.string.isRequired,
    transporter_name: PropTypes.string.isRequired,
    truck_number: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    forest_master_name: PropTypes.string.isRequired,
});
