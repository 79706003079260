import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { enGB, et } from 'date-fns/locale';

import { DEFAULT_LOCALE_CODE } from './date';

// Currently supported locales on MH. Add more if needed
const locales = {
    [enGB.code]: enGB,
    [et.code]: et,
};

const getWeekDaysForLocale = (locale, width) => {
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
        weekDays.push(locale.localize.day(i, { width }));
    }
    return weekDays;
};

const getMonthsForLocale = (locale, width) => {
    const months = [];
    for (let i = 0; i < 12; i++) {
        months.push(locale.localize.month(i, { width }));
    }
    return months;
};

const formatDay = (day, localeCode = DEFAULT_LOCALE_CODE) => {
    const locale = locales[localeCode];
    return format(day, 'EEE PP', { locale });
};

const formatMonthTitle = (date, localeCode = DEFAULT_LOCALE_CODE) => {
    const locale = locales[localeCode];
    return format(date, 'MMMM YYYY', { locale });
};

const formatWeekdayShort = (day, localeCode = DEFAULT_LOCALE_CODE) => {
    const locale = locales[localeCode];
    return getWeekDaysForLocale(locale, 'narrow')[day];
};

const formatWeekdayLong = (day, localeCode = DEFAULT_LOCALE_CODE) => {
    const locale = locales[localeCode];
    return getWeekDaysForLocale(locale, 'wide')[day];
};

const getFirstDayOfWeek = (localeCode = DEFAULT_LOCALE_CODE) => {
    const locale = locales[localeCode];
    return locale.options.weekStartsOn;
};

const getMonths = (localeCode = DEFAULT_LOCALE_CODE) => {
    const locale = locales[localeCode];
    return getMonthsForLocale(locale, 'wide');
};

export const formatDate = (
    date,
    dateFormat = 'P',
    localeCode = DEFAULT_LOCALE_CODE,
) => {
    const locale = locales[localeCode];
    return format(date, dateFormat, { locale });
};

const parseDate = (str, dateFormat = 'P', localeCode = DEFAULT_LOCALE_CODE) => {
    const locale = locales[localeCode];
    return parse(str, dateFormat, new Date(), { locale });
};

export const DATE_FNS_LOCALE_UTILS = {
    formatDay,
    formatMonthTitle,
    formatWeekdayShort,
    formatWeekdayLong,
    getFirstDayOfWeek,
    getMonths,
    formatDate,
    parseDate,
};
