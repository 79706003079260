import React from 'react';
import PropTypes from 'prop-types';
import { NonIdealState } from '@blueprintjs/core';

export const CustomNonIdealState = ({ icon, title, description, action }) => {
    return (
        <NonIdealState
            icon={icon}
            title={title}
            description={description}
            action={action}
        />
    );
};

CustomNonIdealState.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    action: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
};

export default CustomNonIdealState;
