import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AnchorButton, Intent } from '@blueprintjs/core';
import qs from 'query-string';

import { gettext } from '../../utils/text';
import { reverseUrl } from '../../utils/urls';
import { CustomNonIdealState } from './CustomNonIdealState';

const BackToActsNonIdealState = ({ title, description }) => {
    const backURL = useMemo(() => {
        const url = reverseUrl('acts:list');
        const query = qs.parse(window.location.search);
        return qs.stringifyUrl({ url, query });
    }, []);

    return (
        <CustomNonIdealState
            icon="issue"
            title={title}
            description={description}
            action={
                <AnchorButton
                    intent={Intent.PRIMARY}
                    icon="chevron-left"
                    href={backURL}
                    text={gettext('Back to acts')}
                />
            }
        />
    );
};

BackToActsNonIdealState.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired,
};

export default BackToActsNonIdealState;
