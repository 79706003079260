// TODO: Cover with tests
import { call, put, spawn, takeLatest, takeLeading } from 'redux-saga/effects';

import api from '../api';
import {
    FETCH_ACT,
    FETCH_ACT_STARTED,
    FETCH_ACT_SUCCESSFUL,
    FETCH_ACT_FAILED,
} from '../ducks/acts/actDetails';
import {
    UPDATE_ACT,
    UPDATE_ACT_STARTED,
    UPDATE_ACT_SUCCESSFUL,
    UPDATE_ACT_FAILED,
} from '../ducks/acts/actUpdate';

function* fetchAct(action) {
    yield put({ type: FETCH_ACT_STARTED });
    try {
        const response = yield call(api.acts.details.fetch, {
            id: action.actID,
        });
        yield put({ type: FETCH_ACT_SUCCESSFUL, response });
    } catch (error) {
        yield put({ type: FETCH_ACT_FAILED, error });
    }
}

function* fetchActSaga() {
    yield takeLatest(FETCH_ACT, fetchAct);
}

function* updateAct(action) {
    yield put({ type: UPDATE_ACT_STARTED });
    try {
        const response = yield call(
            api.acts.update.put,
            { id: action.payload.id },
            action.payload,
        );
        yield put({ type: UPDATE_ACT_SUCCESSFUL, response });
        yield call(action.callback);
    } catch (error) {
        yield put({ type: UPDATE_ACT_FAILED, error });
        yield call(action.errorCallback, error);
    }
}

function* updateActSaga() {
    yield takeLeading(UPDATE_ACT, updateAct);
}

export default function* actUpdateRootSaga() {
    yield spawn(fetchActSaga);
    yield spawn(updateActSaga);
}
