import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Position, Toaster } from '@blueprintjs/core';
import { clearToasts } from 'ducks/toasts';
import { ToastShape } from '../shapes/toasts';

const ToastContainer = ({ toastQueue, onClearToasts }) => {
    const toaster = useRef(null);

    useEffect(() => {
        if (toastQueue.length > 0) {
            toastQueue.forEach(({ id, ...toast }) => {
                toaster.current.show(toast, id);
            });
            onClearToasts();
        }
    }, [toastQueue.length]);

    return <Toaster position={Position.TOP_RIGHT} ref={toaster} />;
};

ToastContainer.propTypes = {
    toastQueue: PropTypes.arrayOf(ToastShape).isRequired,
    onClearToasts: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    toastQueue: state.toasts.toastQueue,
});

const mapDispatchToProps = {
    onClearToasts: clearToasts,
};

const ConnectedToastContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ToastContainer);

export default ConnectedToastContainer;
