import PropTypes from 'prop-types';
import React from 'react';

import { WaybillFilterQueryShape } from 'shapes/waybills';
import { gettext } from '../../utils/text';
import { FILTERS } from '../../ducks/filters';
import TableEntityFilter from '../Table/TableEntityFilter';

const WaybillUnitFilter = ({ unit, changeUnit, query }) => {
    return (
        <TableEntityFilter
            initialValue={unit}
            onChange={changeUnit}
            itemsToOptions={item => ({
                text: item,
                value: item,
            })}
            name="unit_filter"
            buttonPlaceholder={gettext('Select unit')}
            buttonLeftIcon="numerical"
            filterType={FILTERS.WAYBILL.UNITS}
            query={query}
        />
    );
};

WaybillUnitFilter.propTypes = {
    unit: PropTypes.string.isRequired,
    changeUnit: PropTypes.func.isRequired,
    query: WaybillFilterQueryShape.isRequired,
};

export default WaybillUnitFilter;
