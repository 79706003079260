// TODO: Cover with tests
import { call, put, spawn, takeLatest, takeLeading } from 'redux-saga/effects';

import api from '../api';
import {
    FETCH_INVOICE_INITIALS,
    FETCH_INVOICE_INITIALS_STARTED,
    FETCH_INVOICE_INITIALS_SUCCESSFUL,
    FETCH_INVOICE_INITIALS_FAILED,
} from '../ducks/accounting/invoiceInitials';
import {
    CREATE_INVOICE,
    CREATE_INVOICE_STARTED,
    CREATE_INVOICE_SUCCESSFUL,
    CREATE_INVOICE_FAILED,
} from '../ducks/accounting/invoiceCreation';

function* fetchInvoiceInitials(action) {
    yield put({ type: FETCH_INVOICE_INITIALS_STARTED });
    try {
        const response = yield call(
            api.accounting.invoiceInitials.post,
            null,
            action.payload,
        );
        yield put({ type: FETCH_INVOICE_INITIALS_SUCCESSFUL, response });
    } catch (error) {
        yield put({ type: FETCH_INVOICE_INITIALS_FAILED, error });
    }
}

function* fetchInvoiceInitialsSaga() {
    yield takeLatest(FETCH_INVOICE_INITIALS, fetchInvoiceInitials);
}

function* createInvoice(action) {
    yield put({ type: CREATE_INVOICE_STARTED });
    try {
        const response = yield call(
            api.accounting.invoiceCreate.post,
            null,
            action.payload,
        );
        yield put({ type: CREATE_INVOICE_SUCCESSFUL, response });
        yield call(action.callback);
    } catch (error) {
        yield put({ type: CREATE_INVOICE_FAILED, error });
        yield call(action.errorCallback, error);
    }
}

function* createInvoiceSaga() {
    yield takeLeading(CREATE_INVOICE, createInvoice);
}

export default function* invoiceCreationRootSaga() {
    yield spawn(fetchInvoiceInitialsSaga);
    yield spawn(createInvoiceSaga);
}
