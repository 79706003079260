import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ButtonGroup,
    Menu,
    Popover,
    Position,
} from '@blueprintjs/core';

import { gettext } from 'utils/text';

export const TableColumnSelector = ({
    groups,
    selectedGroups,
    changeGroups,
}) => {
    const toggleGroup = useCallback(
        groupID =>
            changeGroups(gs => {
                const groupIndex = gs.indexOf(groupID);
                if (groupIndex >= 0) {
                    return gs.filter(g => g !== groupID);
                } else {
                    return [...gs, groupID];
                }
            }),
        [selectedGroups, changeGroups],
    );

    return (
        <ButtonGroup className="pr-3">
            <Popover position={Position.BOTTOM_RIGHT}>
                <Button
                    icon="list-columns"
                    rightIcon="caret-down"
                    data-testid="columns-btn"
                >
                    {gettext('Columns')}
                </Button>
                <Menu>
                    {groups.map(group => (
                        <Menu.Item
                            key={group.id}
                            icon={
                                selectedGroups.indexOf(group.id) >= 0
                                    ? 'eye-on'
                                    : 'eye-off'
                            }
                            active={selectedGroups.indexOf(group.id) >= 0}
                            onClick={() => toggleGroup(group.id)}
                            text={group.title}
                            shouldDismissPopover={false}
                            data-testid={`column-option-${group.id}`}
                        />
                    ))}
                </Menu>
            </Popover>
        </ButtonGroup>
    );
};

TableColumnSelector.propTypes = {
    groups: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }),
    ).isRequired,
    selectedGroups: PropTypes.arrayOf(PropTypes.string),
    changeGroups: PropTypes.func.isRequired,
};

TableColumnSelector.defaultProps = {
    selectedGroups: [],
};

export default TableColumnSelector;
