// Actions
export const FETCH_LOCATION_CHOICES = 'FELLING_ROWS/FETCH_LOCATION_CHOICES';
export const FETCH_LOCATION_CHOICES_STARTED =
    'FELLING_ROWS/FETCH_LOCATION_CHOICES_STARTED';
export const FETCH_LOCATION_CHOICES_SUCCESSFUL =
    'FELLING_ROWS/FETCH_LOCATION_CHOICES_SUCCESSFUL';
export const FETCH_LOCATION_CHOICES_FAILED =
    'FELLING_ROWS/FETCH_LOCATION_CHOICES_FAILED';

// Initial state for reducer
const initialState = {
    loading: false,
    response: null,
    error: null,
};

// Reducer(s)
export default function fetchFellingRowLocationChoicesReducer(
    state = initialState,
    action,
) {
    switch (action.type) {
        case FETCH_LOCATION_CHOICES_STARTED:
            return {
                ...state,
                loading: true,
            };
        case FETCH_LOCATION_CHOICES_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                response: action.response,
                error: null,
            };
        case FETCH_LOCATION_CHOICES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

// Action creators
export const fetchFellingRowLocationChoices = query => ({
    type: FETCH_LOCATION_CHOICES,
    query,
});
