// Actions
export const UPDATE_TRANSPORT_INVOICE_NR =
    'WAYBILLS/UPDATE_TRANSPORT_INVOICE_NR';
export const UPDATE_TRANSPORT_INVOICE_NR_STARTED =
    'WAYBILLS/UPDATE_TRANSPORT_INVOICE_NR_STARTED';
export const UPDATE_TRANSPORT_INVOICE_NR_SUCCESSFUL =
    'WAYBILLS/UPDATE_TRANSPORT_INVOICE_NR_SUCCESSFUL';
export const UPDATE_TRANSPORT_INVOICE_NR_FAILED =
    'WAYBILLS/UPDATE_TRANSPORT_INVOICE_NR_FAILED';

// Action creators
export const updateTransportInvoiceNumber = (
    payload,
    callback,
    errorCallback,
) => ({
    type: UPDATE_TRANSPORT_INVOICE_NR,
    payload,
    callback,
    errorCallback,
});
