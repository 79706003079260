import PropTypes from 'prop-types';
import React from 'react';

import { WaybillFilterQueryShape } from 'shapes/waybills';
import { gettext } from '../../utils/text';
import { FILTERS } from '../../ducks/filters';
import TableEntityFilter from '../Table/TableEntityFilter';

const WaybillChopperFilter = ({ chopperName, changeChopperName, query }) => {
    return (
        <TableEntityFilter
            initialValue={chopperName}
            onChange={changeChopperName}
            itemsToOptions={item => ({
                text: `${item.name} [${item.person_code}]`,
                value: item.name,
            })}
            name="chopper_filter"
            buttonPlaceholder={gettext('Select chopper')}
            buttonLeftIcon="person"
            filterType={FILTERS.WAYBILL.CHOPPERS}
            query={query}
        />
    );
};

WaybillChopperFilter.propTypes = {
    chopperName: PropTypes.string.isRequired,
    changeChopperName: PropTypes.func.isRequired,
    query: WaybillFilterQueryShape.isRequired,
};

export default WaybillChopperFilter;
