import PropTypes from 'prop-types';

const CommonInvoicePropertyShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
});

export const InitialInvoiceDataShape = PropTypes.shape({
    // Invoice general details
    number: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    default_customer_id: PropTypes.number,
    default_tax_id: PropTypes.number,
    customers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            reg_code: PropTypes.string.isRequired,
        }),
    ).isRequired,
    taxes: PropTypes.arrayOf(CommonInvoicePropertyShape).isRequired,
    items: PropTypes.arrayOf(CommonInvoicePropertyShape).isRequired,
    // Waybills
    waybills: PropTypes.arrayOf(PropTypes.number).isRequired,
    invoice_rows: PropTypes.arrayOf(
        PropTypes.shape({
            assortment_name: PropTypes.string.isRequired,
            assortment_unit: PropTypes.string,
            origin_name: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            amount: PropTypes.number.isRequired,
            price: PropTypes.number,
            waybill_numbers: PropTypes.arrayOf(PropTypes.string).isRequired,
            waybill_rows: PropTypes.arrayOf(PropTypes.number).isRequired,
        }),
    ).isRequired,
});

export const WaybillInvoiceShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    invoice_type: PropTypes.string.isRequired,
    invoice_type_display: PropTypes.string.isRequired,
});
